import { Uuid } from "_/types";

/**
 * Retrieves the URL for loading an image.
 */
export function imageURL(id: Uuid): string {
  return `/api/v0/images/${id}/contents`;
}

/**
 * Retrieves the URL for loading an image thumbnail.
 */
export function thumbURL(id: Uuid): string {
  const base = imageURL(id);
  return `${base}?thumbnail=true`;
}
