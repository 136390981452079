import React, { ReactNode, ReactElement } from "react";

import { useCheckUser } from "_/data/users";

import * as S from "./styled";

type InitializerProps = {
  children: ReactNode;
};

// The initializer is used to display a shade while we load the initial user info.
export const Initializer = (props: InitializerProps): ReactElement => {
  const { isLoading } = useCheckUser();

  return (
    <S.Wrapper>
      <S.Overlay $loading={isLoading} />
      {props.children}
    </S.Wrapper>
  );
};
