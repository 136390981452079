import styled from "styled-components";
import { Link as _Link } from ".";

// <NeverUnderline> allows users to copy/paste a project name and revision
// number together with a space in between, but to avoid the space being
// underlined on hover because it looks weird. It leverages the trick that
// inline-block elements won't inherit the text-decoration of their parent.
export const NeverUnderline = styled.span`
  display: inline-block;
`;

const textColors = {
  muted: {
    color: "muted",
    hover: "hover",
  },
  default: {
    color: "default",
    hover: "default",
  },
} as const;

/** Link using normal text color. On hover: same color and underlined. */
export const TextLink = styled(_Link)<{ $variant: "default" | "muted" }>`
  color: ${(p) => p.theme.text[textColors[p.$variant].color]};
  &:hover {
    color: ${(p) => p.theme.text[textColors[p.$variant].hover]};
    text-decoration: underline;
  }
  &:active,
  &:visited {
    color: ${(p) => p.theme.text[textColors[p.$variant].color]};
    &:hover {
      color: ${(p) => p.theme.text[textColors[p.$variant].hover]};
      text-decoration: underline;
    }
  }
`;
