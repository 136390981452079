import styled from "styled-components";
import { TextLink } from "_/components/router";
import { Icon } from "_/components/icon";
import * as JobsListingS from "_/components/jobs-listing/styled";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3.25rem;
  padding: 2.25rem 3.125rem;
  margin: auto;

  color: ${(p) => p.theme.text.default};

  width: 100%;
  height: 100%;
`;

export const NoAttemptsContainer = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  width: 100%;
  background-color: ${(p) => p.theme.background.secondary};
  color: ${(p) => p.theme.text.muted};
  border-radius: 8px;
`;

export const OneRevision = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const RevisionInfo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3rem;
  font-size: 0.875rem;
  padding: 0 0.125rem;
  width: 100%;
  justify-content: space-between;
  align-items: end;
`;

export const RevisionInfoColumn = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 0.5rem;
`;

export const RevisionLink = styled(TextLink).attrs({
  variant: "default",
})<{ variant?: never }>`
  grid-column: 1 / span 2;
  font-size: 1.25rem;
`;

export const RevisionInfoLabel = styled.div`
  color: ${(p) => p.theme.text.muted};
  justify-self: end;
`;

export const RevisionInfoValue = styled.div`
  display: inline-flex;
  color: ${(p) => p.theme.text.muted};
`;

// TODO: turn this into a shared component
export const FileDownloadLink = styled.a`
  display: inline-flex;
  gap: 0.25rem;
  color: ${(p) => p.theme.text.muted};
  &:hover {
    color: ${(p) => p.theme.text.hover};
    text-decoration: underline;
  }
  ${Icon} {
    font-size: 1.25rem;
  }
`;

export const RevisionNumber = styled(JobsListingS.RevisionOrJobNumber)`
  font-size: 1.25rem;
  padding: 0 0.25rem;
`;

export const JobNumber = styled(JobsListingS.RevisionOrJobNumber)`
  font-size: inherit;
  margin-left: 0;
  text-align: center;
`;
