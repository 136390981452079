import styled from "styled-components";

export const Wrapper = styled.div<{
  $invalid?: boolean;
}>`
  .react-select__control {
    padding: 0;
    font-size: 15px;

    background-color: ${(p) =>
      p.$invalid
        ? p.theme.input.invalid.background
        : p.theme.input.default.background};

    border-radius: 2px;
    border: 1px solid
      ${(p) =>
        p.$invalid
          ? p.theme.input.invalid.border
          : p.theme.input.default.border};

    &:hover {
      border-color: ${(p) =>
        p.$invalid ? p.theme.input.invalid.border : p.theme.input.hover.border};
    }
  }

  .react-select__value-container {
    margin: 0;
    padding: 0;
  }

  .react-select__value-container--is-multi {
    padding: 0 8px;

    display: flex;
    row-gap: 4px;
  }

  .react-select__single-value,
  .react-select__input-container,
  .react-select__placeholder {
    margin: 0;
    padding: 6px 16px 6px 13px;
    color: inherit;
  }

  .react-select__multi-value {
    margin: 0 2px;
  }

  .react-select__input-container {
    color: ${(p) =>
      p.$invalid ? p.theme.input.invalid.text : p.theme.input.default.text};
  }

  .react-select__control--is-focused {
    border-color: ${(p) =>
      p.$invalid ? p.theme.input.invalid.border : p.theme.input.focused.border};
    box-shadow: none;

    &:hover {
      border-color: ${(p) =>
        p.$invalid
          ? p.theme.input.invalid.border
          : p.theme.input.focused.border};
    }
  }

  .react-select__control--menu-is-open {
    .react-select__dropdown-indicator {
      transform: rotate(180deg);
    }
  }

  .react-select__menu {
    font-size: 15px;
    margin-top: 2px;
    border-radius: 2px;
    width: max-content;
    min-width: 100%;

    background-color: ${(p) => p.theme.input.default.background};
  }

  .react-select__option--is-focused {
    background-color: ${(p) => p.theme.input.hover.background};
    color: ${(p) => p.theme.input.hover.text};
  }
  .react-select__option--is-selected {
    background-color: ${(p) => p.theme.link.default};
    color: ${(p) => p.theme.color.palette.white};
  }
  .react-select__option:hover {
    background-color: ${(p) => p.theme.input.hover.background};
    color: ${(p) => p.theme.input.hover.text};
  }

  .react-select__dropdown-indicator {
    font-size: 1.25em;
    color: ${(p) =>
      p.$invalid ? p.theme.input.invalid.text : p.theme.input.default.text};
    transform: rotate(0);
    transition: transform 0.1s ease;

    &:hover {
      color: ${(p) => p.theme.input.hover.text};
    }
  }

  .react-select__multi-value__remove {
    color: hsl(0, 0%, 30%); /* adapted from react-select__multi-value__label */
  }
`;
