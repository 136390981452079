import styled from "styled-components";

import { Icon } from "_/components/icon";

export const FileItem = styled.div`
  border: 1px solid ${(p) => p.theme.color.palette.ashGrey};
  border-radius: 5px;

  padding: 8px 16px;

  display: flex;
  align-items: center;

  cursor: default;

  ${Icon} {
    cursor: pointer;

    &:hover {
      color: ${(p) => p.theme.color.palette.scarletRed};
    }
  }
`;

export const FileName = styled.span`
  display: block;

  margin-right: 1em;

  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const FileSize = styled.span`
  color: #a5a6a8;
  font-size: 11px;
  margin-left: auto;
  margin-right: 1em;
  white-space: nowrap;
`;
