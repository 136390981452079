import {
  useQuery,
  useMutation,
  useQueryClient,
  UseQueryOptions,
} from "react-query";
import { pick } from "ramda";
import { AxiosProgressEvent } from "axios";

import { api, ApiResponse, groupById } from "_/utils";
import { Tuple, Uuid } from "_/types";

import { useUploader } from "_/hooks/uploader";

import { BlobRecord, fetchBlobContentsParallel } from "_/data/blob";

const SCOPE = "projects";
const KEYS = {
  all: () => [SCOPE],
  project: (id: Uuid) => [SCOPE, "detail", id],
  revision: (projectId: Uuid, revisionId: Uuid) => [
    ...KEYS.project(projectId),
    "revision",
    revisionId,
  ],
  revisionResources: (projectId: Uuid, revisionId: Uuid) => [
    ...KEYS.revision(projectId, revisionId),
    "resources",
  ],
  simCreatedRevisions: (projectId: Uuid, simulationId: Uuid) => [
    ...KEYS.simulations(projectId),
    "createdRevisions",
    simulationId,
  ],
  attempts: (projectId?: Uuid) => {
    return [SCOPE, "attempts", projectId].filter(Boolean);
  },
  attempt: (projectId: Uuid, attemptId: Uuid) => [
    ...KEYS.attempts(projectId),
    attemptId,
  ],
  attemptData: (projectId: Uuid, attemptId: Uuid, datasetId: Uuid) => [
    ...KEYS.attempt(projectId, attemptId),
    datasetId,
  ],
  simulations: (projectId?: Uuid) => {
    return [SCOPE, "simulations", projectId].filter(Boolean);
  },
};

export type ProjectServerResponseData = {
  meta: Omit<Project, "revisions">;
  revisions: Revision[];
};

function transformResponse(data: ProjectServerResponseData): Project {
  return { ...data.meta, revisions: data.revisions };
}

export type Revision = {
  id: Uuid;
  projectId: Uuid;
  creatorId: Uuid;
  materials: Tuple<string | null>;
  createdAt: string;
  updatedAt: string;
  number: number;
};

export type Project = {
  id: Uuid;
  domainId: Uuid;
  ownerId: Uuid;
  name: string;
  description: string;
  status: "active" | "archived" | "failed" | "complete" | "hold";
  revisions: Revision[];
  updatedAt: string;
};

export type AttemptStatus =
  | "complete"
  | "cancelled"
  | "failed"
  | "printing"
  | "paused";

export type Attempt = {
  id: Uuid;
  machineId: Uuid;
  revisionId: Uuid;
  operatorId?: Uuid;
  fileName: string;
  startedAt: string;
  endedAt?: string;
  motion_data?: BlobRecord;
  status: AttemptStatus;
  // `number` is not present in the API response, but is added by the frontend
  // We'll probably want to add it to the server at some point.
  number: number;
};

export type AttemptDatasetManifest = {
  kind: string;
  samples: number;
  id: Uuid;
};

export interface RevisionMaterial {
  material1Id: Uuid | null;
  material2Id: Uuid | null;
}

type Resource = File | BlobRecord;

export interface RevisionChange {
  materials?: RevisionMaterial;
  gcode?: Resource;
  models?: Resource[];
}

export type JobStatus =
  | "queued"
  | "pending"
  | "started"
  | "completed"
  | "failed"
  | "cancelled";

export type Job<I, O> = {
  id: Uuid;
  createdAt: string;
  startedAt?: string;
  endedAt?: string;
  status: JobStatus;
  input: I;
  output: O;
  task: string;
  taskVersion: string;
};

export type Simulation = {
  job: Job<
    Record<string, unknown>,
    { status: string; errors?: ([number, string] | string)[] } | undefined
  >;
  analysisOutput: BlobRecord;
  optimizedGcode: BlobRecord | null;
  optimizedOutput: BlobRecord | null;
  revisionId: Uuid;
  number: number;
};

export type SimulationType = "analyze" | "analyze+optimize";

// Settings for a simulation job provided by the user
export type SimJobSettings = {
  email: string;
  gcodeDownloadEndpoint: string;
  getReturnUrl: (jobId: Uuid) => string;
  description: string;
  jobType: SimulationType;
  nozzle0MaterialId: Uuid;
  nozzle1MaterialId?: Uuid;
  plateMaterialId: Uuid;
  nozzle0MaterialName: string;
  nozzle1MaterialName?: string;
  plateMaterialName: string;
};

export function useProjects() {
  async function getProjects(): Promise<Project[]> {
    const response =
      await api.get<ApiResponse<ProjectServerResponseData[]>>("/projects");
    const data = response.data.data;

    return data.map(transformResponse);
  }

  const config: UseQueryOptions<Project[]> = {
    placeholderData: [],
    queryKey: KEYS.all(),
    queryFn: getProjects,
  };

  return useQuery(config);
}

export function useProject(id: Uuid) {
  async function getProject(): Promise<Project> {
    const response = await api.get<ApiResponse<ProjectServerResponseData>>(
      `/projects/${id}`
    );
    const data = response.data.data;

    return transformResponse(data);
  }

  const config: UseQueryOptions<Project | null> = {
    placeholderData: null,
    queryKey: KEYS.project(id),
    queryFn: getProject,
  };

  return useQuery(config);
}

export function useCreateProject() {
  const queryClient = useQueryClient();

  async function createProject(params: {
    name: string;
    description: string;
    domainId: Uuid;
  }): Promise<Project> {
    const response = await api.post<ApiResponse<ProjectServerResponseData>>(
      "/projects",
      params
    );
    const data = response.data.data;

    return transformResponse(data);
  }

  return useMutation({
    mutationFn: createProject,
    onSuccess: (_data: Project, _vars, _context) => {
      queryClient.invalidateQueries(KEYS.all());
    },
  });
}

export type ProjectUpdateArgs = Pick<
  Project,
  "name" | "description" | "ownerId" | "status"
>;

export function useUpdateProject() {
  const queryClient = useQueryClient();

  async function updateProject(project: Project): Promise<Project> {
    const projectId = project.id;

    const update: ProjectUpdateArgs = pick(
      ["name", "description", "ownerId", "status"],
      project
    );

    const response = await api.patch<ApiResponse<ProjectServerResponseData>>(
      `/projects/${projectId}`,
      update
    );

    const data = response.data.data;

    return transformResponse(data);
  }

  return useMutation({
    mutationFn: updateProject,
    onSuccess: (data: Project, vars: Project, _context) => {
      queryClient.invalidateQueries(KEYS.all());
      queryClient.invalidateQueries(KEYS.project(vars.id));
      queryClient.setQueryData(KEYS.project(data.id), data);
    },
  });
}

export function useDeleteProject() {
  const queryClient = useQueryClient();

  async function deleteProject({ id }: { id: Uuid }): Promise<void> {
    await api.delete<ApiResponse<void>>(`/projects/${id}`);
  }

  return useMutation({
    mutationFn: deleteProject,
    onSuccess: (_data, vars: { id: Uuid }, _context) => {
      queryClient.invalidateQueries(KEYS.all());
      queryClient.removeQueries(KEYS.project(vars.id));
    },
  });
}

export function useCreateRevision() {
  const queryClient = useQueryClient();

  async function createRevision(params: { projectId: Uuid }): Promise<Project> {
    const response = await api.post<ApiResponse<ProjectServerResponseData>>(
      `/projects/${params.projectId}/revisions`
    );
    const data = response.data.data;

    return transformResponse(data);
  }

  return useMutation({
    mutationFn: createRevision,
    onSuccess: (data: Project, _vars, _context) => {
      queryClient.invalidateQueries(KEYS.all());
      queryClient.invalidateQueries(KEYS.project(data.id));
      queryClient.setQueryData(KEYS.project(data.id), data);
    },
  });
}

async function getRevisionResources(
  projectId: Uuid | undefined,
  revisionId: Uuid | undefined
) {
  const response = await api.get<ApiResponse<BlobRecord[]>>(
    `/projects/${projectId}/revisions/${revisionId}/resources`
  );
  return response.data.data;
}

const KEYS_NOT_KNOWN_YET = {
  queryKey: undefined,
  queryFn: () => void 0,
};

export function useRevisionResources(revision: Revision | undefined) {
  const projectId = revision?.projectId;
  const revisionId = revision?.id;

  const queryConfig =
    projectId && revisionId
      ? {
          queryKey: KEYS.revisionResources(projectId, revisionId),
          queryFn: () => getRevisionResources(projectId, revisionId),
        }
      : KEYS_NOT_KNOWN_YET;

  return useQuery(queryConfig);
}

/**
 * For a simulation, find the revision (if any) that was created with this Sim's
 * optimized G-code. Note that there may be later revisions that share the same
 * G-code, so this hook returns the first revision that matches.
 *
 * We may want to add a backend endpoint to get this information without
 * iterating.
 */
export function useSimCreatedRevision(
  project: Project | undefined,
  simulation: Simulation | undefined
) {
  const projectId = project?.id;
  const revisionId = simulation?.revisionId;
  const simulationId = simulation?.job.id;
  const optimizedGcodeId = simulation?.optimizedGcode?.id;
  const revisions = project?.revisions;

  async function getSimCreatedRevision() {
    // can happen before the project is loaded
    if (!revisions) return undefined;

    // Iterate through later revisions until we either find one with the same ID
    // as the Sim's optimized G-code, or we run out of revisions. Note that we
    // iterate instead of running in parallel because the new revision is almost
    // always the next one, and we don't want to make unnecessary server
    // requests.
    const startIndex = revisions.findIndex((r) => r.id === revisionId);
    if (startIndex === -1) {
      throw new Error("Revision not found in project");
    }

    for (let i = startIndex + 1; i < revisions.length; i++) {
      const revision = revisions[i];
      const resources = await getRevisionResources(projectId, revision.id);
      if (resources.some((r) => r.id === optimizedGcodeId)) {
        return revision;
      }
    }
    return null; // No matching revision found
  }

  return useQuery(
    projectId && revisionId && simulationId
      ? {
          queryKey: KEYS.simCreatedRevisions(projectId, simulationId),
          queryFn: getSimCreatedRevision,
        }
      : KEYS_NOT_KNOWN_YET
  );
}

export function useUploadRevisionResource(revision: Revision) {
  const queryClient = useQueryClient();
  const upload = useUploader();

  const projectId = revision.projectId;
  const revisionId = revision.id;

  const endpoint = `/projects/${projectId}/revisions/${revisionId}/resources`;

  async function uploadResource(file: File) {
    await upload(endpoint, file);
  }

  return useMutation({
    mutationFn: uploadResource,
    onSuccess: (_data, _vars, _context) => {
      queryClient.invalidateQueries(KEYS.project(projectId));
    },
  });
}

export function useDeleteRevisionModels(revision: Revision) {
  const queryClient = useQueryClient();

  const projectId = revision.projectId;
  const revisionId = revision.id;

  const endpoint = `/projects/${projectId}/revisions/${revisionId}/resources`;

  async function deleteResource(id: Uuid) {
    await api.delete(`${endpoint}/${id}`);
  }

  return useMutation({
    mutationFn: deleteResource,
    onSuccess: (_data, _vars, _context) => {
      queryClient.invalidateQueries(KEYS.project(projectId));
    },
  });
}

export const sortAttempts = (a: Attempt, b: Attempt) => {
  return new Date(b.startedAt).getTime() - new Date(a.startedAt).getTime();
};

// Helper function to get a starting timestamp for either an Attempt or Simulation
// record, in order to be able to sort them together.
function getTimestampForAttemptOrSimulation(
  item: Attempt | Simulation
): number {
  if ("startedAt" in item) {
    // Attempt
    return new Date(item.startedAt).getTime();
  } else if ("job" in item && item.job.createdAt) {
    // Simulation
    return new Date(item.job.createdAt).getTime();
  }
  throw new Error("Unknown item type; cannot determine timestamp.");
}

export const sortAttemptsAndSimulations = (
  a: Attempt | Simulation,
  b: Attempt | Simulation
) => {
  return (
    getTimestampForAttemptOrSimulation(b) -
    getTimestampForAttemptOrSimulation(a)
  );
};

type AttemptWithoutNumber = Omit<Attempt, "number">;

export function useProjectAttempts(projectId: Uuid) {
  async function getAttempts(projectId: Uuid): Promise<Attempt[]> {
    const response = await api.get<ApiResponse<AttemptWithoutNumber[]>>(
      `/projects/${projectId}/attempts`
    );
    return appendNumberProperty(response.data.data);
  }

  const config: UseQueryOptions<Attempt[]> = {
    staleTime: 1000 * 60, // detect status changes after 1 minute
    placeholderData: [],
    queryKey: KEYS.attempts(projectId),
    queryFn: () => {
      return getAttempts(projectId);
    },
  };

  return useQuery(config);
}

type SimulationWithoutNumber = Omit<Simulation, "number">;

/**
 * Return an array of Attempt or Simulation objects with a `number` property
 * attached. This property is a 1-based index, grouped by revisionId. The oldest
 * attempt or simulation for a given revision will have `number: 1`. This
 * function assumes that the input data is already sorted in descending order
 * from the index order.
 *
 * At some point soon we'll probably want to move this logic to the server, and
 * then we can remove this function.
 * */
function appendNumberProperty<T extends { revisionId: Uuid }>(
  data: T[]
): (T & { number: number })[] {
  const grouped = groupById(data, "revisionId");
  return data.map((item) => {
    const itemsForNumbering = grouped[item.revisionId];
    const number = itemsForNumbering.length - itemsForNumbering.indexOf(item);
    return { ...item, number };
  });
}

export function useProjectSimulations(projectId: Uuid) {
  async function getSimulations(projectId: Uuid): Promise<Simulation[]> {
    const response = await api.get<ApiResponse<SimulationWithoutNumber[]>>(
      `/projects/${projectId}/simulations`
    );
    return appendNumberProperty(response.data.data);
  }

  const config: UseQueryOptions<Simulation[]> = {
    staleTime: 1000 * 60, // detect status changes after 1 minute
    placeholderData: [],
    queryKey: KEYS.simulations(projectId),
    queryFn: () => {
      return getSimulations(projectId);
    },
  };

  return useQuery(config);
}

const useAllAttemptsOmittedOptions = [
  "placeholderData",
  "queryKey",
  "queryFn",
  "refetchOnWindowFocus",
  "refetchInterval",
] as const;

/**
 * Get all attempts for all projects, for use in the home page dashboard.
 */
export function useAllAttempts<T = Attempt[]>(
  options: Omit<
    UseQueryOptions<Attempt[], unknown, T, string[]>,
    (typeof useAllAttemptsOmittedOptions)[number]
  > = {}
) {
  async function getAttempts(): Promise<Attempt[]> {
    const response =
      await api.get<ApiResponse<AttemptWithoutNumber[]>>(`/attempts`);
    return appendNumberProperty(response.data.data);
  }

  for (const option of useAllAttemptsOmittedOptions) {
    if ((options as UseQueryOptions)[option] !== undefined) {
      throw new Error(`Invalid option: ${option}`);
    }
  }

  return useQuery({
    ...options,
    placeholderData: [],
    queryKey: KEYS.attempts(),
    queryFn: getAttempts,
    refetchOnWindowFocus: false,
  });
}

/**
 * Get all simulations for all projects, for use in the home page dashboard.
 */
export function useAllSimulations() {
  async function getSimulations(): Promise<Simulation[]> {
    const response =
      await api.get<ApiResponse<SimulationWithoutNumber[]>>(`/simulations`);
    return appendNumberProperty(response.data.data);
  }

  return useQuery({
    placeholderData: [],
    staleTime: 1000 * 60,
    queryKey: KEYS.simulations(),
    queryFn: getSimulations,
    refetchOnWindowFocus: false,
  });
}

export function useAttemptDatasetManifest(
  { projectId, attemptId }: { projectId: Uuid; attemptId: Uuid },
  config?: UseQueryOptions<AttemptDatasetManifest[]>
) {
  async function getAttemptDatasetManifest(projectId: Uuid, attemptId: Uuid) {
    const response = await api.get<ApiResponse<AttemptDatasetManifest[]>>(
      `/projects/${projectId}/attempts/${attemptId}/data`
    );
    const data = response.data.data;

    return data;
  }

  const queryConfig: UseQueryOptions<AttemptDatasetManifest[]> = {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60,
    placeholderData: [],
    queryKey: KEYS.attempt(projectId, attemptId),
    queryFn: () => {
      return getAttemptDatasetManifest(projectId, attemptId);
    },
    ...config,
  };

  return useQuery(queryConfig);
}

/**
 * Returns the most recent attempt for each machine, as an object with the key
 * being the machine ID and the value being the `Attempt`. If a machine has no
 * attempts then it will not be included in the object.
 */
export function useLastAttemptForEachMachine() {
  return useAllAttempts({
    select: (data) => {
      // There's no need to sort the data because the server returns it sorted
      // with the most-recently-started attempts first.
      const grouped = groupById(data, "machineId");
      const result = Object.fromEntries(
        Object.entries(grouped).map(([machineId, attempts]) => [
          machineId,
          attempts[0],
        ])
      );

      // The cast below to add `undefined` was the cleanest way I could find to
      // force callers of this hook to check for the case where a machine might
      // not have any attempts.
      return result as Record<Uuid, Attempt | undefined>;
    },
  });
}

// Function to handle the multipart POST request to create a new project revision
const reviseProject = async (params: {
  projectId: string;
  revisionChange: RevisionChange;
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
  onSuccess?: (data: Revision) => void;
}) => {
  const { projectId, revisionChange, onUploadProgress } = params;

  const formData = new FormData();

  if (revisionChange.gcode) {
    const gcodeData =
      revisionChange.gcode instanceof File
        ? revisionChange.gcode
        : revisionChange.gcode.id;

    formData.append("gcode", gcodeData);
  }

  if (revisionChange.models) {
    revisionChange.models.forEach((model) => {
      const modelData = model instanceof File ? model : model.id;
      formData.append(`models`, modelData);
    });
  }

  if (revisionChange.materials) {
    formData.append(
      "materials",
      JSON.stringify([
        revisionChange.materials.material1Id,
        revisionChange.materials.material2Id,
      ])
    );
  }
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress,
  };

  return api.post<ApiResponse<Revision>>(
    `/projects/${projectId}/revisions`,
    formData,
    config
  );
};

export function useReviseProject() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: reviseProject,
    onSuccess: async (data, variables) => {
      const newRevision = data.data.data;

      queryClient.invalidateQueries(KEYS.project(newRevision.projectId));

      // Explicitly refetch the project data to ensure it includes the new revision.
      await queryClient.refetchQueries(KEYS.project(newRevision.projectId));

      variables.onSuccess?.(newRevision);
    },
  });
}

/**
 * Helper function to determine whether an attempt's datasets will be usable by the viewer.
 */
export function isViewableAttemptData(
  response: AttemptDatasetManifest[]
): boolean {
  const hasValidMotionData = response.some(
    (item) => item.kind === "motionData" && item.samples > 0
  );

  const hasValidToolForce0Data = response.some(
    (item) => item.kind === "toolForce0" && item.samples > 0
  );

  const hasValidToolForce1Data = response.some(
    (item) => item.kind === "toolForce1" && item.samples > 0
  );

  return hasValidMotionData && hasValidToolForce0Data && hasValidToolForce1Data;
}

export function useAttemptData(
  projectId: Uuid,
  attemptId: Uuid,
  datasetId: Uuid,
  setProgress?: (progress: number) => void
) {
  const pathFunc = (id: Uuid) => {
    return `/projects/${projectId}/attempts/${attemptId}/data/${id}`;
  };

  const config: UseQueryOptions<ArrayBuffer | undefined> = {
    queryKey: KEYS.attemptData(projectId, attemptId, datasetId),
    queryFn: async ({ signal }) => {
      const blobRecordResponse = await api.get<ApiResponse<BlobRecord>>(
        `/blobs/${datasetId}`
      );
      const blobRecord = blobRecordResponse.data.data;
      return await fetchBlobContentsParallel(
        blobRecord,
        pathFunc,
        setProgress,
        signal
      );
    },
    staleTime: Infinity,
  };

  return useQuery(config);
}

type SimSettings = {
  optimizeGcode: boolean;
};

// Create a simulation record for a revision
async function createSimulationRecord(
  revision: Revision,
  settings: SimSettings
): Promise<SimulationWithoutNumber> {
  const response = await api.post<ApiResponse<SimulationWithoutNumber>>(
    `/projects/${revision.projectId}/revisions/${revision.id}/simulations`,
    settings
  );
  return response.data.data;
}

// Custom hook to create a simulation
export function useCreateSimulation(revision: Revision) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (settings: SimSettings) =>
      await createSimulationRecord(revision, settings),
    onSuccess: () => queryClient.invalidateQueries(KEYS.simulations()),
  });
}
