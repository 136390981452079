import React, {
  ReactElement,
  ReactNode,
  useContext,
  createContext,
} from "react";
import { useAtomValue } from "jotai";
import { setupI18n, I18n } from "@lingui/core";

import { localeAtom } from "_/state";
import { isKeyOf } from "_/utils";
import { messages as enMessages } from "_/locales/en";
import { messages as frMessages } from "_/locales/fr";

const messages = { en: enMessages, fr: frMessages };
const names = { en: "English", fr: "Français" } as const;
type LanguageNames = typeof names;

// This exists so that the `Locale` type can be constructed, consisting of the
// union of all available locale strings.
const locales = Object.keys(messages);
export type Locale = (typeof locales)[number];

// The `i18n` must be exported for use by the lingui macro handling (and
// subsequently is specified to be imported in the `.linguirc.json`
// configuration file).
export const i18n = setupI18n({ messages });

const I18nContext = createContext(i18n);

interface I18nProps {
  children: ReactNode;
}

export function I18nProvider({ children }: I18nProps): ReactElement {
  const locale = useAtomValue(localeAtom);

  i18n.load(messages);
  i18n.activate(locale);

  return <I18nContext.Provider value={i18n}>{children}</I18nContext.Provider>;
}

export function useI18n(): I18n {
  return useContext(I18nContext);
}

type LocaleInfo = {
  code: Locale;
  name: string;
  active: boolean;
};

// Construct the list of available locales.
//
// Useful for retrieving the set of all locales with their names, codes, and
// whether or not the locale is currently active.
export function getLocales(): Array<LocaleInfo> {
  function makeLocale(code: Locale): LocaleInfo {
    return {
      code: code,
      name: names[code],
      active: i18n.locale === code,
    };
  }

  return locales.map(makeLocale);
}

export function getLanguageName(code: string) {
  if (!isKeyOf(names, code)) {
    throw new Error(`Unknown language code: ${code}`);
  }
  return names[code] as LanguageNames[keyof LanguageNames];
}
