import React, { useRef, useEffect, useMemo } from "react";
import {
  Group,
  Vector2,
  BufferGeometry,
  BufferAttribute,
  ShaderMaterial,
  Points,
  DataTexture,
  RGBAFormat,
} from "three";
import { useThree } from "@react-three/fiber";
import { scaleSequential } from "d3-scale";
import { interpolateTurbo } from "d3-scale-chromatic";
import { rgb } from "d3-color";
import { useAtom } from "jotai";

import { View, ViewMode, BED_DIMENSIONS } from "_/state/viewer";

import pointsVert from "./glsl/points.vert";
import pointsFrag from "./glsl/points.frag";

function generateLookupTexture() {
  // Color scale used to map sensor values to colors
  const scale = scaleSequential(interpolateTurbo).domain([0, 1]);

  // Generate a lookup texture to use the color scale in the shader
  const textureWidth = 256;
  const data = new Uint8Array(textureWidth * 4); // RGBA

  for (let i = 0; i < textureWidth; i++) {
    const color = rgb(scale(i / textureWidth));
    const offset = i * 4;
    data[offset] = color.r;
    data[offset + 1] = color.g;
    data[offset + 2] = color.b;
    data[offset + 3] = 255;
  }

  const lookupTexture = new DataTexture(data, textureWidth, 1, RGBAFormat);
  lookupTexture.needsUpdate = true;
  return lookupTexture;
}

type SimViewProps = {
  view: View<ViewMode.Sim>;
};

export const SimView = ({ view }: SimViewProps) => {
  const { invalidate } = useThree();

  const groupRef = useRef<Group>(null);

  const [viewControls] = useAtom(view.controlsAtom);

  const geometries = useMemo(() => {
    const analysisGeometry = new BufferGeometry();

    analysisGeometry.setAttribute(
      "position",
      new BufferAttribute(view.data.analysis.positions, 3)
    );

    analysisGeometry.setAttribute(
      "layerWelding",
      new BufferAttribute(view.data.analysis.layerWelding, 1)
    );

    analysisGeometry.setAttribute(
      "previousLayerTemperature",
      new BufferAttribute(view.data.analysis.previousLayerTemperature, 1)
    );

    const geometries: { analysis: BufferGeometry; optimized?: BufferGeometry } =
      {
        analysis: analysisGeometry,
      };

    if (view.data.optimized) {
      const optimizedGeometry = new BufferGeometry();

      optimizedGeometry.setAttribute(
        "position",
        new BufferAttribute(view.data.optimized.positions, 3)
      );

      optimizedGeometry.setAttribute(
        "layerWelding",
        new BufferAttribute(view.data.optimized.layerWelding, 1)
      );

      optimizedGeometry.setAttribute(
        "previousLayerTemperature",
        new BufferAttribute(view.data.optimized.previousLayerTemperature, 1)
      );

      geometries.optimized = optimizedGeometry;
    }

    return geometries;
  }, [view.data]);

  const material = useMemo(
    () =>
      new ShaderMaterial({
        uniforms: {
          lookupTexture: { value: generateLookupTexture() },
          zRange: { value: new Vector2() },
          valueRange: {
            value: new Vector2(),
          },
          colorLimit: {
            value: new Vector2(),
          },
          snapshot: {
            value: 0,
          },
        },
        vertexShader: pointsVert,
        fragmentShader: pointsFrag,
      }),
    []
  );

  const points = useMemo(() => {
    geometries.analysis.computeBoundingBox();

    const bbox = geometries.analysis.boundingBox;

    // Compute the offset by finding the center of the bounding box (in x and y)
    const offset = bbox
      ? bbox.min.clone().lerp(bbox.max, 0.5)
      : new Vector2(0, 0);

    const points = new Points(geometries.analysis, material);

    // Set the position of the points to be centered around the origin
    points.position.set(-offset.x, -offset.y, 0);

    return points;
  }, [geometries, material]);

  useEffect(() => {
    if (groupRef.current) {
      groupRef.current.add(points);
    }
  }, [points]);

  useEffect(() => {
    const sourceGeometry = geometries[viewControls.source];
    if (sourceGeometry) {
      points.geometry = sourceGeometry;
      invalidate();
    }
  }, [geometries, points, viewControls.source, invalidate]);

  // Update shader material uniforms based on control changes
  useEffect(() => {
    const { zRange } = viewControls.values;
    const { colorRange, valueRange } =
      viewControls.values[viewControls.snapshot];

    material.uniforms.zRange.value.x = zRange[0];
    material.uniforms.zRange.value.y = zRange[1];
    material.uniforms.valueRange.value.x = valueRange[0];
    material.uniforms.valueRange.value.y = valueRange[1];
    material.uniforms.colorLimit.value.x = colorRange[0];
    material.uniforms.colorLimit.value.y = colorRange[1];
    material.uniformsNeedUpdate = true;
    invalidate();
  }, [viewControls.snapshot, viewControls.values, material, invalidate]);

  useEffect(() => {
    if (viewControls.snapshot) {
      material.uniforms.snapshot.value =
        viewControls.snapshot === "previousLayerTemperature" ? 0 : 1;
      material.uniformsNeedUpdate = true;
      invalidate();
    }
  }, [viewControls.snapshot, material, invalidate]);

  return (
    <group
      ref={groupRef}
      // Position the group at the center of the bed (which is the default lookAt target of the camera)
      position={[BED_DIMENSIONS.width / 2, BED_DIMENSIONS.height / 2, 0]}
    />
  );
};
