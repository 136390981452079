import styled from "styled-components";

export const Overlay = styled.div<{ $loading: boolean }>`
  height: 100vh;
  width: 100vw;
  position: fixed;
  pointer-events: none;
  z-index: 1000;
  transition: opacity 0.8s ease;
  background-color: ${(p) => p.theme.background.primary};

  opacity: ${(p) => (p.$loading ? 1 : 0)};
`;

export const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;
