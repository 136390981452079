import { createContext } from "react";

import { Manifest } from "_/data/docs";

type Context = {
  manifest: Manifest;
  currentSlug?: string;
};

// Cast the empty object as the correct type to avoid undefined errors.
// This may result in a runtime error _if_ the context is used before it is
// initialized, however this should never happen in practice and would be a
// programming error which should be caught at development time.
export const DocsContext = createContext<Context>({} as Context);
