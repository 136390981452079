import styled from "styled-components";
import { Icon as _Icon } from "_/components/icon";

export const Wrapper = styled.div<{ $kind: string }>`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  font-size: 12px;

  width: fit-content;
  height: fit-content;
  padding: 2px 8px 2px 6px;

  // arbitrarily large value to make the corners completely rounded
  border-radius: 100px;

  color: ${(p) => p.theme.color.palette.ashGrey};

  background-color: ${(p) => {
    switch (p.$kind) {
      case "info":
        return p.theme.color.palette.twilightBlue;
      case "success":
        return p.theme.color.palette.mintGreen;
      case "error":
        return p.theme.color.palette.blushRed;
      case "warning":
        return p.theme.color.palette.butterYellow;
      case "highlight":
        return p.theme.color.palette.blizzardTeal;
      case "muted":
      default:
        return p.theme.color.palette.aluminumGrey;
    }
  }};
`;

export const Icon = styled(_Icon)<{ $kind: string }>`
  font-size: 8px;

  color: ${(p) => {
    switch (p.$kind) {
      case "info":
        return p.theme.color.palette.electricBlue;
      case "success":
        return p.theme.color.palette.forestGreen;
      case "error":
        return p.theme.color.palette.crimsonRed;
      case "warning":
        return p.theme.color.palette.mustardBrown;
      case "highlight":
        return p.theme.color.palette.deepTeal;
      case "muted":
      default:
        return p.theme.color.palette.ashGrey;
    }
  }};
`;
