import React, { ReactNode } from "react";
import * as S from "./styled";
import { NotImplementedLink } from "_/components/home-dashboard";

const FeaturesUpdate = () => (
  <S.WhatsNewBox>
    <h3>What&apos;s New</h3>
    <h4>Basis</h4>
    <ul>
      <li>
        <NotImplementedLink>
          Create a new revision from simulation-optimized G-code
        </NotImplementedLink>
      </li>
      <li>
        <NotImplementedLink>
          Easier workflow for revising projects with new G-code
        </NotImplementedLink>
      </li>
      <li>
        <NotImplementedLink>Project/machine search</NotImplementedLink>
      </li>
      <li>
        <NotImplementedLink>
          Start simulations from the Prepare tab
        </NotImplementedLink>
      </li>
      <li>
        <NotImplementedLink>Analyze tab loads 50%+ faster</NotImplementedLink>
      </li>
      <li>
        <NotImplementedLink>
          Prepare and Optimize tabs load 2x+ faster
        </NotImplementedLink>
      </li>
      {/*
      <p>
        Plus 19 more recent improve&shy;ments in the{" "}
        <S.BlueLink to="#">Change Log</S.BlueLink>
      </p>
    */}
    </ul>

    <h4>Hylo</h4>
    <ul>
      <li>
        <NotImplementedLink>Easier-to-use home screen</NotImplementedLink>
      </li>
      <li>
        <NotImplementedLink>
          Graphical display of filament spool status and loading
        </NotImplementedLink>
      </li>
    </ul>
    {/*
      <p>
        Plus 19 more recent improve&shy;ments in the{" "}
        <S.BlueLink to="#">Change Log</S.BlueLink>
      </p>
    */}
  </S.WhatsNewBox>
);

const MarketingLink = ({
  href,
  children,
}: {
  href: string;
  children: ReactNode;
}) => {
  const urlTyped = new URL(href);
  const isInternal = urlTyped.hostname.endsWith("aon3d.com");
  const rel = isInternal ? "" : "noopener noreferrer";
  const linkProps = { href, target: "_blank", rel };
  return <S.MarketingLink {...linkProps}>{children}</S.MarketingLink>;
};

const MarketingUpdate = () => (
  <S.WhatsNewBox>
    <h3>Explore</h3>
    <ul>
      <li>
        <MarketingLink href="https://www.rapid3devent.com/">
          AON3D @ RAPID + TCT (Booth #2457)
        </MarketingLink>
      </li>
      <li>
        <MarketingLink href="https://www.aon3d.com/wp-content/uploads/2024/02/AON3D-Build-Surface-Selection-Guide.pdf">
          Build Surface Selection Guide
        </MarketingLink>
      </li>
      <li>
        <MarketingLink href="https://www.aon3d.com/applications/custom-tooling/">
          Discover: AM Tooling Applications
        </MarketingLink>
      </li>
      <li>
        <MarketingLink href="https://www.aon3d.com/hardware/dfam-guide/">
          AON3D DFAM Guide
        </MarketingLink>
      </li>
    </ul>
  </S.WhatsNewBox>
);

export const HomeWhatsNew = () => (
  <>
    <FeaturesUpdate />
    <MarketingUpdate />
  </>
);
