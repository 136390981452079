import React, { ReactElement } from "react";

export const Projects = (): ReactElement => {
  return (
    <svg
      width="1em"
      height="1em"
      stroke="currentColor"
      strokeWidth="0.075em"
      strokeLinecap="round"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M35.9846 6L36.0262 18.7699" />
      <path d="M14.2535 28.8913L36.0464 18.9765" />
      <path d="M14.068 29.0458L36 39" />
      <path d="M57.9938 28.984L36.4173 38.8061" />
      <path d="M57.9938 28.984L35.9846 18.9766" />
      <path d="M13.9753 29.0458L13.9753 52.9833" />
      <path d="M35.7682 62.8672L13.9753 52.9833" />
      <path d="M36.0154 62.9908L35.9845 38.9605" />
      <path d="M36.0154 62.9908L58.3647 52.798" />
      <path d="M68.0415 57.8944L58.3648 53.1687" />
      <path d="M57.9938 29.0262L57.9938 53.1687" />
      <path d="M3.45015 58.4061L13.5 53.1667" />
    </svg>
  );
};
