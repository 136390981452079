import styled from "styled-components";

import heroImage from "./hero-parts.webp";

export const Wrapper = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  background-color: ${(props) => props.theme.background.primary};
  height: 100%;
`;

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-image: url("${heroImage}");
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;
