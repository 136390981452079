import React from "react";
import { createRoot } from "react-dom/client";
import { getWebInstrumentations, initializeFaro } from "@grafana/faro-react";

import { App } from "_/components/app";
import { Wrappers } from "_/components/wrappers";

type Faro = ReturnType<typeof initializeFaro>;

function initFaro(): Faro {
  if ("faro" in window) {
    return window.faro as Faro;
  }

  const faro = initializeFaro({
    url: __GRAFANA_FARO_ENDPOINT__,
    app: {
      name: "webclient",
      environment: __ENVIRONMENT__,
    },
    instrumentations: getWebInstrumentations(),
  });

  // Enabled only in stage and prod by default. To re-enable, use `faro.unpause()`.
  if (__ENVIRONMENT__ === "development") {
    faro.pause();
  }

  return faro;
}

function initReact() {
  const ROOT_ELEMENT = document.getElementById("app-root");

  if (ROOT_ELEMENT === null) {
    const error = [
      "Document root does not have a mount point. If you are seeing this error, ",
      "please email dev@aon3d.com and we will resolve the issue immediately. ",
      "This error should never occur.",
    ].join("");
    document.body.textContent = error;
  } else {
    const Root = () => {
      return (
        <Wrappers>
          <App />
        </Wrappers>
      );
    };

    const root = createRoot(ROOT_ELEMENT);
    root.render(<Root />);
  }
}

function main() {
  initFaro();
  initReact();
}

main();
