import { isKeyOf } from "_/utils";

export type MotionData = {
  timestamp: number;
  x: number;
  y: number;
  z: number;
  e: number;
  tool: number;
};

export type ForceData = {
  timestamp: number;
  sensorValue: number;
};

type RecordType = MotionData | ForceData;

// All headers are 8 bytes long, matching the pattern "BASISnnn".
const HEADER_LENGTH = 8;
const VERSIONS = {
  BASIS000: {
    recordSize: 8 + 4 * 3 + 1,
    parseRecord: (view: DataView, offset: number): MotionData => {
      const timestamp = view.getBigUint64(offset, true);
      const x = view.getFloat32(offset + 8, true);
      const y = view.getFloat32(offset + 12, true);
      const z = view.getFloat32(offset + 16, true);
      const tool = view.getUint8(offset + 20);

      return {
        timestamp: Number(timestamp),
        x,
        y,
        z,
        e: 1,
        tool,
      };
    },
  },
  BASIS001: {
    recordSize: 8 + 4 * 3 + 2 + 1,
    parseRecord: (view: DataView, offset: number): MotionData => {
      const timestamp = view.getBigUint64(offset, true);
      const x = view.getFloat32(offset + 8, true);
      const y = view.getFloat32(offset + 12, true);
      const z = view.getFloat32(offset + 16, true);
      const e0e = view.getInt8(offset + 20);
      const e1e = view.getInt8(offset + 21);
      const tool = view.getUint8(offset + 22);

      return {
        timestamp: Number(timestamp),
        x,
        y,
        z,
        e: [e0e, e1e][tool],
        tool,
      };
    },
  },
  BASIS200: {
    recordSize: 8 + 4,
    parseRecord: (view: DataView, offset: number): ForceData => {
      const timestamp = view.getBigUint64(offset, true);
      const sensorValue = view.getFloat32(offset + 8, true);

      return {
        timestamp: Number(timestamp),
        sensorValue,
      };
    },
  },
};

export interface PrintDataRecords<T extends RecordType> {
  length: number;
  at: (index: number) => T;
}

export function bufferToRecords<T extends RecordType>(
  buffer: ArrayBuffer
): PrintDataRecords<T> {
  const view = new DataView(buffer);

  let header = "";
  for (let i = 0; i < HEADER_LENGTH; i++) {
    header += String.fromCharCode(view.getUint8(i));
  }

  if (!isKeyOf(VERSIONS, header)) {
    throw new Error(`Invalid file header: ${header}`);
  }

  const version = VERSIONS[header];
  const length =
    (buffer.byteLength - HEADER_LENGTH) / VERSIONS[header].recordSize;

  const at = (index: number): T => {
    if (index < 0 || index >= length) {
      throw new Error(`Invalid record index: ${index}`);
    }
    const offset = HEADER_LENGTH + index * version.recordSize;
    return version.parseRecord(view, offset) as T;
  };
  return { length, at };
}
