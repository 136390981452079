import styled, { css } from "styled-components";

const activeTab = css`
  color: ${(p) => p.theme.text.default};
  &::before {
    background-color: ${(p) => p.theme.text.default};
    width: 100%;
    left: 0;
    opacity: 1;
  }
`;

type ExtraTabProps = {
  $active: boolean;
  $disabled?: boolean;
};

export const Tab = styled(styled.span<ExtraTabProps>``).attrs<ExtraTabProps>(
  (p) => ({
    role: "tab",
    "aria-selected": p.$active ? "true" : "false",
  })
)`
  display: inline-block;
  position: relative;
  padding: 12px 0;
  border-bottom: 2px solid transparent;

  cursor: ${(p) => (p.$disabled ? "not-allowed" : "pointer")};

  color: ${(p) => (p.$disabled ? p.theme.text.disabled : p.theme.text.muted)};

  &:hover {
    color: ${(p) => (p.$disabled ? "default" : p.theme.text.hover)};
  }

  &::before {
    position: absolute;
    display: block;
    content: "";
    width: 0;
    height: 3px;
    background-color: ${(p) => p.theme.text.default};
    border-radius: 3px;

    bottom: -3px;
    left: 50%;
    opacity: 0;

    transition:
      left 0.1s ease,
      width 0.1s ease,
      opacity 0.1s ease-in;
  }

  ${(p) => (p.$active ? activeTab : null)}
`;

export const Divider = styled.div`
  background-color: ${(p) => p.theme.divider.small};
  width: 1px;
  height: 24px;
`;

export const TabBar = styled.div.attrs({
  role: "tablist",
})`
  display: flex;
  align-items: center;
  gap: 24px;
`;
