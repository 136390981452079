/**
 * PLY header data and descriptors.
 */
export type PLYHeader = {
  comments: Array<string>;
  elements: Array<PLYElementMeta>;
  objInfo?: string;
  format?: string;
  version?: string;
};

// Available PLY scalar types.
export type PLYScalar =
  | "char"
  | "uchar"
  | "short"
  | "ushort"
  | "int"
  | "int8"
  | "int16"
  | "int32"
  | "uint"
  | "uint8"
  | "uint16"
  | "uint32"
  | "float"
  | "float32"
  | "float64"
  | "double"
  | "list";

// PLY element type.
export type PLYElementMeta = {
  name: string;
  count: number;
  properties: Array<PLYPropertyMeta>;
};

export type PLYPropertyMeta = {
  name: string;
  type: PLYScalar;
  countType?: PLYScalar;
  itemType?: PLYScalar;
};

export type PLYElement = Record<string, number | number[]>;
export type PLYData = Record<string, PLYElement[]>;
export type PLYBlocks = Record<string, DataView>;

export type PLY = {
  header: PLYHeader;
  data: PLYData;
  raw: PLYBlocks;
};

export function parsePLY(buf: ArrayBuffer, construct: boolean): PLY {
  const headerText = extractHeader(buf);

  if (headerText === null) {
    throw new Error("Invalid PLY file: `ply` and `end_header` must be present");
  }

  const header = parseHeader(headerText);

  if (header.format === "ascii") {
    throw new Error("ASCII PLY files not yet supported");
  }

  // Possible options are "binary_little_endian" and "binary_big_endian".
  const le = header.format !== "binary_big_endian";

  const bodyData = new DataView(buf, headerText.length);

  const data = construct ? parseData(bodyData, header.elements, le) : {};
  const raw = parseBlocks(bodyData, header.elements, le);

  return { header, data, raw };
}

// Extract the header text from a PLY data buffer.
function extractHeader(buf: ArrayBuffer): string | null {
  const data = new DataView(buf);
  const endTag = "end_header";

  let found = false;

  // Iterate through the buffer until the header end tag string sequence is
  // matched. Starting at the length of the end tag allows reading backwards
  // when checking for a match and avoids issues where a preceding string
  // segment is a prefix of the ending tag, e.g. "endend_header".
  let i = endTag.length - 1;
  while (i < buf.byteLength) {
    let matched = 0;

    for (let n = 0; n < endTag.length; n++) {
      const char = data.getUint8(i - matched);
      const matchIndex = endTag.length - matched - 1;

      if (char === endTag.charCodeAt(matchIndex)) {
        matched++;
      } else {
        break;
      }
    }

    i++;

    if (matched === endTag.length) {
      found = true;
      break;
    }
  }

  if (!found) {
    return null;
  }

  // If any newlines or carriage returns are present between the end of the
  // header and the following binary data, we must index past them so that the
  // correct start position for the binary data is used.

  try {
    if (data.getUint8(i) === "\r".charCodeAt(0)) {
      i++;
    }

    if (data.getUint8(i) === "\n".charCodeAt(0)) {
      i++;
    }
  } catch (_e) {
    console.warn("PLY file may be corrupt or not have any binary data.");
  }

  const headerData = new Uint8Array(buf, 0, i);
  const text = new TextDecoder("utf-8").decode(headerData);

  return text;
}

// Parse PLY header text to a well structured PLYHeader.
function parseHeader(data: string): PLYHeader {
  const headerRegex = /^ply\n([\s\S]+)end_header\r?\n?/;
  const match = headerRegex.exec(data);

  if (!match) {
    const err = `PLY header incomplete: no element types defined:\n${data}`;
    throw new Error(err);
  }

  const lines = match[1].trim().split("\n");

  const emptyHeader: PLYHeader = {
    comments: [],
    elements: [],
  };

  let currentElement: PLYElementMeta;

  const header = lines.reduce((acc, line, i) => {
    const [lineType, ...lineValues] = line.trim().split(/\s+/);

    switch (lineType) {
      case "format":
        acc.format = lineValues[0];
        acc.version = lineValues[1];
        break;

      case "comment":
        acc.comments.push(lineValues.join(" "));
        break;

      case "element":
        if (currentElement !== undefined) {
          acc.elements.push(currentElement);
        }

        currentElement = {
          name: lineValues[0],
          count: parseInt(lineValues[1]),
          properties: [],
        };
        break;

      case "property":
        currentElement.properties.push(makeProperty(lineValues));
        break;

      case "obj_info":
        acc.objInfo = line;
        break;

      default:
        console.error("Unexpected PLY element type:", lineType, lineValues);
    }

    // If this is the last iteration of the reducer and all of the lines have
    // been consumed, we push any "in progress" elements into the list.
    if (currentElement !== undefined && i === lines.length - 1) {
      acc.elements.push(currentElement);
    }

    return acc;
  }, emptyHeader);

  return header;
}

// Parse a array of values into a PLYPropertyMeta.
function makeProperty(values: Array<string>): PLYPropertyMeta {
  const isList = values[0] === "list";

  const property = {
    name: isList ? values[3] : values[1],
    type: values[0] as PLYScalar,
    countType: isList ? (values[1] as PLYScalar) : undefined,
    itemType: isList ? (values[2] as PLYScalar) : undefined,
  };

  return property;
}

type PLYValueParser = (
  view: DataView,
  offset: number
) => [string, number | number[], number];

// Parse the raw data buffer into the structure specified by the element metadata.
function parseData(
  data: DataView,
  elsMeta: Array<PLYElementMeta>,
  littleEndian?: boolean
): PLYData {
  let offset = 0;
  const blocks: Record<string, PLYElement[]> = {};

  // Element blocks are in the same order as defined in the header metadata, so
  // we can iterate through the element specifications as we parse the raw data
  // buffer and shift the reader `offset` as data is consumed.
  for (let e = 0; e < elsMeta.length; e++) {
    const element = elsMeta[e];

    // Each element "block" in the final ply structure can be accessed like:
    // `ply.fooElement[123].fooProperty`. If the property is a list, then it
    // can be indexed into as a normal array as well.
    blocks[element.name] = [];

    // Construct parse config.
    const parseConfig = element.properties.map((prop) => {
      if (prop.type === "list" && prop.countType && prop.itemType) {
        const itemCountParser = scalarParser(prop.countType);
        const itemCountSize = scalarSize(prop.countType);

        // Get the parser and size for each item in the list.
        const itemSize = scalarSize(prop.itemType);
        const itemParser = scalarParser(prop.itemType);

        return (data: DataView, offset: number) => {
          let dOffset = 0;

          // Get the number of items in the list.
          const itemCount = itemCountParser.call(data, offset, littleEndian);
          dOffset += itemCountSize;

          const values: Array<number> = [];

          // Parse all the items in the list.
          for (let n = 0; n < itemCount; n++) {
            const value = itemParser.call(data, offset + dOffset, littleEndian);
            dOffset += itemSize;
            values.push(value);
          }

          return [prop.name, values, dOffset] as ReturnType<PLYValueParser>;
        };
      } else {
        const dOffset = scalarSize(prop.type);
        const parser = scalarParser(prop.type);

        return (data: DataView, offset: number) => {
          const value = parser.call(data, offset, littleEndian);
          return [prop.name, value, dOffset] as ReturnType<PLYValueParser>;
        };
      }
    });

    // Parse data.
    for (let i = 0; i < element.count; i++) {
      const elementBlock = {} as Record<string, number | number[]>;

      parseConfig.forEach((pc) => {
        const [propName, value, dOffset] = pc(data, offset);
        elementBlock[propName] = value;
        offset += dOffset;
      });

      blocks[element.name].push(elementBlock);
    }
  }

  return blocks;
}

function parseBlocks(
  data: DataView,
  elsMeta: Array<PLYElementMeta>,
  littleEndian?: boolean
): PLYBlocks {
  const blocks: PLYBlocks = {};
  let offset = 0;

  for (let e = 0; e < elsMeta.length; e++) {
    const element = elsMeta[e];
    const size = blockSize(data, element, offset, littleEndian);

    // DataViews use the underlying buffer, so the offset provided must factor
    // in any existing offset that the passed in `data` DataView is using. In
    // this case, we must account for the bytes used for the header.
    const viewOffset = data.byteOffset + offset;

    blocks[element.name] = new DataView(data.buffer, viewOffset, size);
    offset += size;
  }

  return blocks;
}

function blockSize(
  data: DataView,
  element: PLYElementMeta,
  offset: number,
  littleEndian?: boolean
): number {
  return element.properties.reduce((acc, prop) => {
    if (prop.type === "list" && prop.countType && prop.itemType) {
      const countSize = scalarSize(prop.countType);
      const countParser = scalarParser(prop.countType).bind(data);
      const itemSize = scalarSize(prop.itemType);

      const elOffset = offset;

      for (let i = 0; i < element.count; i++) {
        const listSize = countParser(elOffset, littleEndian);
        acc += countSize + listSize * itemSize;
      }
    } else {
      acc += scalarSize(prop.type) * element.count;
    }

    return acc;
  }, 0);
}

// Return the size in bits for each scalar type.
function scalarSize(t: PLYScalar): number {
  switch (t) {
    case "char":
    case "uchar":
    case "int8":
    case "uint8":
      return 1;

    case "short":
    case "ushort":
    case "int16":
    case "uint16":
      return 2;

    case "int":
    case "uint":
    case "int32":
    case "uint32":
    case "float":
    case "float32":
      return 4;

    case "double":
    case "float64":
      return 8;

    case "list":
      // Unreachable in normal operation - this is a library error.
      throw new Error('Scalar type "list" has variable size');

    default:
      throw new Error(`Unknown PLY scalar type: ${t}`);
  }
}

function scalarParser(
  t: PLYScalar
): (byteOffset: number, littleEndian?: boolean | undefined) => number {
  switch (t) {
    case "char":
    case "int8":
      return DataView.prototype.getInt8;

    case "uchar":
    case "uint8":
      return DataView.prototype.getUint8;

    case "short":
    case "int16":
      return DataView.prototype.getInt16;

    case "ushort":
    case "uint16":
      return DataView.prototype.getUint16;

    case "int":
    case "int32":
      return DataView.prototype.getInt32;

    case "uint":
    case "uint32":
      return DataView.prototype.getUint32;

    case "float":
    case "float32":
      return DataView.prototype.getFloat32;

    case "double":
    case "float64":
      return DataView.prototype.getFloat64;

    case "list":
      // Unreachable in normal operation - this is a library error.
      throw new Error('Scalar type "list" cannot be parsed directly');

    default:
      throw new Error(`Unknown PLY scalar type: ${t}`);
  }
}

export const testables = {
  extractHeader,
  makeProperty,
  parseHeader,
  parseData,
};
