import styled from "styled-components";

import * as JobsListingS from "_/components/jobs-listing/styled";
import { Select as _Select } from "_/components/select";
import { Icon } from "_/components/icon";

export const Actions = styled.div`
  display: flex;
  align-items: center;

  > * {
    margin-left: 16px;
  }
`;

export const Details = styled.div`
  display: flex;
  padding: 8px 0;
  width: 100%;
`;

export const Meta = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.span`
  font-size: 26px;
  color: inherit;
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 2rem;
  background-color: ${(p) => p.theme.subheader.background};
  padding: 0 24px;
  border-bottom: 1px solid ${(p) => p.theme.subheader.border};
`;

export const LeftSection = styled.div``;

export const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  padding: 1rem 0;
  margin-left: auto;
`;

export const RightSectionItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
  font-size: 0.875rem;
`;

export const PickerOptionWrapper = styled.div`
  margin-left: -0.25rem;
  margin-right: -1rem;
`;

export const PrintOrRevisionNumberInSelect = styled(
  JobsListingS.RevisionOrJobNumber
)`
  font-size: 0.875rem;
  margin-right: 0.25rem;
`;

export const RevisionNumber = styled(JobsListingS.RevisionOrJobNumber)`
  font-size: 0.875rem;
`;

export const PickerLabel = styled.label`
  color: ${(p) => p.theme.text.muted};
  font-size: 0.875rem;
  padding-bottom: 0.125rem;
  margin-left: 0.3125rem;
`;

export const FileDownloadLink = styled.a`
  display: inline-flex;
  gap: 0.25rem;
  color: ${(p) => p.theme.text.muted};
  white-space: nowrap;
  &:hover {
    color: ${(p) => p.theme.text.hover};
    text-decoration: underline;
  }
  ${Icon} {
    font-size: 1.25rem;
  }
`;

export const DeleteModalButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;

  > * {
    margin-left: 8px;
  }
`;
