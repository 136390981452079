import React, { ReactElement, useContext } from "react";

import { Link } from "_/components/router";
import { LayoutNode } from "_/data/docs";
import { docsUrls } from "_/routes";

import * as S from "./styled";
import { DocsContext } from "./context";

type ContentsProps = {
  title?: string;
};

export const Contents = ({ title }: ContentsProps): ReactElement => {
  const { manifest, currentSlug } = useContext(DocsContext);

  const children = findChildren(manifest.layout, currentSlug || "");

  const contents = children.map((child) => (
    <li key={child.slug}>
      <Link href={docsUrls.page(child.slug)}>{child.title}</Link>
    </li>
  ));

  return (
    <S.ContentsListing>
      {title && <h3>{title}</h3>}
      <ul>{contents}</ul>
    </S.ContentsListing>
  );
};

function findChildren(layout: LayoutNode[], needle: string): LayoutNode[] {
  for (const node of layout) {
    if (node.slug === needle) {
      return node.children;
    }

    if (needle.startsWith(node.slug)) {
      return findChildren(node.children, needle);
    }
  }

  return [];
}
