import React, { ReactElement, useRef, useState, useEffect } from "react";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { Vector3, BufferGeometry, BufferAttribute } from "three";

import { Camera } from "_/components/camera";
import { ViewerState, INITIAL_VIEWER_STATE } from "_/state/viewer";
import { Mesh } from "_/data/mesh";
import { useBlobRecord, useBlobContents } from "_/data/blob";
import { Uuid } from "_/types";

import { parseGCode } from "_/components/view-loaders/gcode/gcode-parser";
import { GCodeMesh } from "_/components/view-loaders/gcode/gcode-mesh";
import { GCodePrintData } from "_/components/view-loaders/gcode/gcode-print";
import { GCodeMachine } from "_/components/view-loaders/gcode/gcode-machine";

import { Update } from "_/types";

import { Task, TaskCallback, RenderPreviewTaskData } from "./types";

/**
 * Helper component for saving the canvas to an image when the render is complete.
 */
const SaveOnComplete = ({
  complete,
  callback,
}: {
  complete: boolean;
  callback: TaskCallback;
}): ReactElement => {
  const { gl, size } = useThree();
  const [blankRenderCount, setBlankRenderCount] = useState(0);

  useFrame(() => {
    if (!complete) {
      return;
    }

    const width = size.width;
    const height = size.height;

    // RGBA so 4 bytes per pixel.
    const pixels = new Uint8Array(width * height * 4);
    const ctx = gl.getContext();

    ctx.readPixels(0, 0, width, height, ctx.RGBA, ctx.UNSIGNED_BYTE, pixels);

    let isBlank = true;
    for (let i = 3; i < pixels.length; i += 4) {
      if (pixels[i] !== 0) {
        isBlank = false;
        break;
      }
    }

    if (isBlank) {
      console.warn("Render complete but canvas is blank.");
      setBlankRenderCount((n) => n + 1);

      if (blankRenderCount > 5) {
        console.error("Rendered image is blank too many times. Aborting.");
        return callback({ error: "Rendered image is blank." });
      }

      return;
    }

    const imageUrl = gl.domElement.toDataURL("image/png");
    callback({ error: null, imageUrl: imageUrl });
  });

  return <></>;
};

export const RenderPreview = (
  props: Task<RenderPreviewTaskData>
): ReactElement => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [renderComplete, setRenderComplete] = useState(false);

  const [viewerState, setViewerState] =
    useState<ViewerState>(INITIAL_VIEWER_STATE);

  const canvasStyle = {
    width: `${props.meta.width}px`,
    height: `${props.meta.height}px`,
  };

  console.debug("Starting canvas rendering for preview image...");

  const object =
    props.meta.taskName === "render-gcode" ? (
      <GcodeThumbnail
        blobId={props.data.blobId}
        width={props.meta.width}
        height={props.meta.height}
        setViewerState={setViewerState}
        onComplete={() => setRenderComplete(true)}
      />
    ) : (
      <StlThumbnail
        blobId={props.data.blobId}
        width={props.meta.width}
        height={props.meta.height}
        setViewerState={setViewerState}
        onComplete={() => setRenderComplete(true)}
      />
    );

  return (
    <Canvas
      ref={canvasRef}
      style={canvasStyle}
      gl={{ preserveDrawingBuffer: true }}
      frameloop="demand"
    >
      <SaveOnComplete complete={renderComplete} callback={props.callback} />
      <Camera viewerState={viewerState} />
      <ambientLight intensity={0.2} />
      <pointLight
        position={[-400, -400, 400]}
        intensity={0.5 * Math.PI}
        decay={0}
      />
      <pointLight
        position={[400, -400, 400]}
        intensity={0.5 * Math.PI}
        decay={0}
      />
      <pointLight
        position={[0, -200, 600]}
        intensity={0.4 * Math.PI}
        decay={0}
      />
      <group name="testGroup">{object}</group>
    </Canvas>
  );
};

const StlThumbnail = ({
  blobId,
  width,
  height,
  setViewerState,
  onComplete,
}: {
  blobId: Uuid;
  width: number;
  height: number;
  setViewerState: (update: Update<ViewerState>) => void;
  onComplete: () => void;
}): ReactElement => {
  const [mesh, setMesh] = useState<Mesh | null>(null);
  const { data: blobRecord } = useBlobRecord(blobId);
  const contentData = useBlobContents(blobRecord);

  const content = contentData.data;

  console.log("STL Thumbnail rendering...");

  useEffect(() => {
    if (!blobRecord || !content) {
      return;
    }

    console.debug("Parsing STL from file...");

    const meshStl = Mesh.fromStl(content);
    const meshPly = Mesh.fromPly(content);

    const run = async () => {
      // One of these will fail, so we use `Promise.any` to use the successful result.
      const mesh = await Promise.any([meshStl, meshPly]);

      mesh.id = blobRecord.id;

      // Initialize the mesh geometry.
      mesh.geometry();

      // To position the camera in a semi-sensible way for the thumbnail, we
      // move to a position where the top, front, and left faces of the object
      // are visible and scale the zoom such that the object's bounding sphere
      // will always be contained within (using an orthographic projection).
      const minDim = Math.min(width, height);
      const r = mesh.bufferGeometry?.boundingSphere?.radius || 200;

      setViewerState((s: ViewerState) => ({
        ...s,
        cameraTarget: [0, 0, 0],
        cameraPosition: [-r, -r, r],
        cameraZoom: minDim / (r * 2.5),
        cameraOrtho: true,
      }));

      setMesh(mesh);

      console.debug("STL load complete...");
    };

    run();
  }, [content, blobRecord, height, width, setViewerState]);

  if (mesh === null) {
    return <></>;
  }

  console.debug("Computing geometry and material...");

  const geometry = mesh.geometry();
  const material = mesh.material("physical");

  console.debug("STL webgl object construction complete.");

  return (
    <mesh
      geometry={geometry}
      material={material}
      position={[0, 0, 0]}
      onAfterRender={onComplete}
    />
  );
};

const GcodeThumbnail = ({
  blobId,
  width,
  height,
  setViewerState,
  onComplete,
}: {
  blobId: Uuid;
  width: number;
  height: number;
  setViewerState: (update: Update<ViewerState>) => void;
  onComplete: () => void;
}): ReactElement => {
  const [printData, setPrintData] = useState<GCodePrintData>();
  const { data: blobRecord } = useBlobRecord(blobId);
  const contentData = useBlobContents(blobRecord, undefined, true);

  const buffer = contentData.data;

  console.debug("Gcode Thumbnail rendering...");

  useEffect(() => {
    if (!buffer) {
      return;
    }

    const decoder = new TextDecoder("utf-8", { fatal: true });
    const result = decoder.decode(buffer);
    console.debug("Gcode file content loaded...");

    const machine = new GCodeMachine();
    const parsed = parseGCode(result, machine.getCommands());
    const gcodePrint = machine.executeGCode(parsed);

    const printData = gcodePrint.getData();

    const geometry = new BufferGeometry();

    geometry.setAttribute(
      "position",
      new BufferAttribute(printData.segmentEndPoints, 3)
    );

    console.debug("Geometry loaded...");

    // To position the camera in a semi-sensible way for the thumbnail, we
    // move to a position where the top, front, and left faces of the object
    // are visible and scale the zoom such that the object's bounding sphere
    // will always be contained within (using an orthographic projection).
    const minDim = Math.min(width, height);

    geometry.computeBoundingSphere();
    const r = geometry.boundingSphere?.radius || 200;
    const c = geometry.boundingSphere?.center || new Vector3();

    setViewerState((s) => ({
      ...s,
      cameraTarget: [c.x, c.y, c.z],
      cameraPosition: [-r, -r, r * 2],
      cameraZoom: minDim / (r * 2.5),
      cameraOrtho: true,
    }));

    setPrintData(printData);
  }, [buffer, width, height, setPrintData, setViewerState]);

  if (!printData) {
    return <></>;
  }

  console.debug("Rendering...");

  return (
    <GCodeMesh
      printData={printData}
      color={0x0088ff}
      onAfterRender={onComplete}
    />
  );
};
