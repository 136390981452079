import React, { ReactElement } from "react";
import { JSONTree } from "react-json-tree";

import { JsonValue } from "_/types";

type JsonTreeProps = {
  /**
   * Arbirary JSON data to render.
   */
  data: JsonValue;

  /**
   * Hide the root element of the JSON tree.
   */
  hideRoot?: boolean;
};

const THEME = {
  extend: "tomorrow",
  tree: { backgroundColor: "transparent", fontFamily: "monospace" },
};

/**
 * A generic JSON tree component that renders JSON data in a collapsible tree view.
 */
export const JsonTree = (props: JsonTreeProps): ReactElement => {
  return <JSONTree theme={THEME} {...props} />;
};
