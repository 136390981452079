import styled from "styled-components";

export const Details = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Meta = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Title = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 1.5rem;
  margin-bottom: 0.25rem;
  line-height: 1;
`;

export const Model = styled.span`
  font-size: 0.875rem;
  font-weight: 400;
  color: ${(p) => p.theme.text.muted};
`;

export const Wrapper = styled.div`
  color: ${(p) => p.theme.text.default};
  background-color: ${(p) => p.theme.subheader.background};
  border-bottom: 1px solid ${(p) => p.theme.subheader.border};
  padding: 1.75rem 2rem 0 2rem;
`;

export const Divider = styled.div`
  background-color: ${(p) => p.theme.divider.large};
  height: 1px;
  margin: 0.625rem 0 0.25rem 0;
`;

export const LeftSection = styled.div``;

export const RightSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  margin-left: auto;
`;

export const RightSectionItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3rem;
`;

export const Label = styled.label`
  display: block;
  color: ${(p) => p.theme.text.muted};
  font-size: 0.875rem;
  padding-bottom: 0.125rem;
  margin-left: 0.3125rem;
`;

export const RightSectionItem = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 0.875rem;
  gap: 0.75rem;
`;
