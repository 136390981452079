import React, {
  useEffect,
  useRef,
  useState,
  ReactElement,
  MouseEventHandler,
} from "react";
import axios from "axios";
import { t } from "@lingui/macro";
import { Link, useLocation } from "_/components/router";
import { useAtom } from "jotai";

import { useLoginUser, useCheckUser } from "_/data/users";
import { useSetCurrentOrg } from "_/data/orgs";

import { nextUrlAtom, useTargetOrg } from "_/state";

import * as S from "./styled";
import { publicUrls, routeUrls } from "_/routes";
import { TextField } from "_/components/text-field";

const LoadingAnimation = (
  <S.LoadAnimation>
    <div />
    <div />
    <div />
    <div />
  </S.LoadAnimation>
);

export const Login = (): ReactElement => {
  const [_location, setLocation] = useLocation();
  const [nextUrl, setNextUrl] = useAtom(nextUrlAtom);
  const [targetOrg, setTargetOrg] = useTargetOrg();
  const setCurrentOrg = useSetCurrentOrg();

  const { data: user } = useCheckUser();
  const loginUser = useLoginUser();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const emailRef = useRef<HTMLInputElement>(null);

  const loggedIn = !!user;

  useEffect(() => {
    if (loggedIn) {
      if (targetOrg) {
        setCurrentOrg.mutate({ id: targetOrg });
        setTargetOrg(null);
        setLocation("/");
      } else {
        setLocation(nextUrl || routeUrls.index);
        setNextUrl(null);
      }
    }
  }, [
    loggedIn,
    nextUrl,
    setNextUrl,
    targetOrg,
    setTargetOrg,
    setLocation,
    setCurrentOrg,
  ]);

  // Helper for setting email validation message before submission.
  function validate(): boolean {
    if (!emailRef.current?.checkValidity()) {
      const msg = emailRef.current?.validationMessage || "";
      setErrorMessage(msg);

      return false;
    }

    setErrorMessage("");
    return true;
  }

  const login: MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault();

    if (!validate()) {
      return;
    }

    try {
      await loginUser.mutateAsync({ email, password });
    } catch (err) {
      // Type guard - throw any unexpected errors.
      if (!axios.isAxiosError(err)) {
        throw err;
      }

      if (err.response?.status !== 401) {
        throw err;
      }

      const msg = t`component.login.login-error`;
      setErrorMessage(msg);

      return;
    }
  };

  const titleText = t`component.login.title`;
  const emailLabel = t`component.login.email-label`;
  const passwordLabel = t`component.login.password-label`;
  const loginButtonText = t`component.login.login-button`;
  const forgotPasswordText = t`component.login.forgot-password`;

  const buttonContent = loginUser.isLoading
    ? LoadingAnimation
    : loginButtonText;

  return (
    <S.Wrapper>
      <S.LoginHeader>
        <S.Title>{titleText}</S.Title>
      </S.LoginHeader>
      <TextField
        ref={emailRef}
        id="email"
        type="email"
        placeholder={emailLabel}
        disabled={loginUser.isLoading}
        onChange={setEmail}
        onBlur={validate}
      />
      <TextField
        id="password"
        type="password"
        placeholder={passwordLabel}
        disabled={loginUser.isLoading}
        onChange={setPassword}
      />
      <S.ErrorSpan>{errorMessage}</S.ErrorSpan>
      <S.LoginButton
        size="medium"
        kind="primary"
        onClick={login}
        disabled={loginUser.isLoading}
      >
        {buttonContent}
      </S.LoginButton>
      <S.ExtraLinks>
        <Link to={publicUrls.recovery}>{forgotPasswordText}</Link>
      </S.ExtraLinks>
    </S.Wrapper>
  );
};
