import styled from "styled-components";

export const RoleItem = styled.span`
  white-space: nowrap;
`;

export const RolesContainer = styled.span`
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.25rem;
`;
