import React, { ReactNode, Suspense, ReactElement } from "react";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ThemeProvider } from "styled-components";
import { useAtomValue } from "jotai";

import { I18nProvider } from "_/i18n";
import { themeAtom } from "_/state";
import { GlobalStyle, getTheme } from "_/style";

import { LoadingOverlay } from "_/components/loading-overlay";
import { Initializer } from "_/components/initializer";
import { ErrorBoundary } from "_/components/error-boundary";
import { RequestInterceptor } from "_/components/request-interceptor";

type WrappersProps = {
  children: ReactNode;
};

// The query client is essentially the cache and datastore used
// by react-query for caching returned data and managing
// query/request state.
//
// Direct access can be done via the `useQueryClient` hook.
const queryClient = new QueryClient();

export const Wrappers = ({ children }: WrappersProps): ReactElement => {
  const theme = useAtomValue(themeAtom);

  const notHeadless = !navigator.webdriver;
  const queryDevTools =
    __DEV__ && notHeadless ? (
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    ) : null;

  return (
    <QueryClientProvider client={queryClient}>
      <RequestInterceptor>
        <ThemeProvider theme={getTheme(theme)}>
          <GlobalStyle />
          <I18nProvider>
            <ErrorBoundary>
              <Suspense fallback={<LoadingOverlay />}>
                <Initializer>{children}</Initializer>
              </Suspense>
            </ErrorBoundary>
          </I18nProvider>
        </ThemeProvider>
      </RequestInterceptor>
      {queryDevTools}
    </QueryClientProvider>
  );
};
