import styled from "styled-components";

import { cond, equals, always, T } from "ramda";

import { CopyState } from "./types";

export const Target = styled.span`
  cursor: pointer;
`;

export const Content = styled.span`
  display: flex;
  gap: 0.5rem;
`;

export const UuidFullText = styled.input`
  width: 20rem;
  text-align: center;
  background-color: ${(p) => p.theme.tooltip.background};
  border-radius: 4px;
  border: 2px solid ${(p) => p.theme.input.default.border};
  color: ${(p) => p.theme.text.default};

  &:focus {
    border: 2px solid ${(p) => p.theme.input.focused.border};
  }
`;

export const CopyButton = styled.button<{ $state: CopyState }>`
  cursor: pointer;

  background-color: ${(p) => p.theme.tooltip.background};

  color: ${(p) =>
    cond([
      [equals("copied"), always(p.theme.color.palette.kellyGreen)],
      [equals("error"), always(p.theme.color.palette.tomatoRed)],
      [T, always(p.theme.text.default)],
    ])(p.$state)};

  border-radius: 4px;

  border: none;

  font-size: larger;

  padding: 0.5rem;

  &:hover {
    background-color: ${(p) => p.theme.background.primary};
  }

  &:active {
    background-color: ${(p) => p.theme.background.secondary};
  }
`;
