import styled from "styled-components";

import { Button } from "_/components/button";
export { FileDownloadLink } from "_/components/attempts-listing/styled";
export { RevisionNumber } from "_/components/project-header/styled";

export const LayerControlContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  height: 100%;
  width: max-content;

  padding: 1rem;

  border-radius: 5px;

  background-color: ${(p) => p.theme.background.secondary};
`;

export const PointCloudControlContainer = styled.div`
  width: 100%;
  height: max-content;

  border-radius: 5px;

  background-color: ${(p) => p.theme.background.secondary};
`;

export const ControlsBoxSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  &:not(:first-child) {
    padding-top: 0.625rem;
  }
`;

export const ControlsBoxTitle = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const ControlsBox = styled.div`
  font-size: 0.875rem;
  font-weight: 600;

  color: ${(p) => p.theme.text.muted};

  padding: 1rem;

  display: flex;
  flex-direction: column;

  gap: 1rem;
`;

export const RadioOptions = styled.div`
  color: ${(p) => p.theme.text.default};
  font-weight: 400;
`;

export const CreateRevisionButton = styled(Button)`
  width: 100%;
`;

export const LayerSliderContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;

export const ControlLabel = styled.span`
  font-size: 0.875rem;
  color: ${(p) => p.theme.text.muted};
  font-weight: 600;
`;

export const RightSideContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

export const ProgressWrapper = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const SimErrorsContainer = styled.div`
  margin-top: 1rem;
`;
