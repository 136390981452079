import React, { ReactElement } from "react";
import { Router, Route, Switch } from "_/components/router";

import { publicRoutes } from "_/routes";

import { Private } from "_/components/private";
import { Public } from "_/components/public";
import { Headless } from "_/components/headless";
import { Header } from "_/components/header";
import { Toasts } from "_/components/toasts";

// Main app component.
//
// This component includes providers and the top level wrapper components that
// act as providers for children, or otherwise add functionality on the global
// scope.
export const App = (): ReactElement => {
  return (
    <Router>
      <Header />
      <Switch>
        <Route path={publicRoutes.headless} component={Headless} />
        {Object.values(publicRoutes)
          .filter((route) => route !== publicRoutes.headless)
          .map((route) => (
            <Route key={route} path={route} component={Public} />
          ))}
        <Route component={Private} />
      </Switch>
      <Toasts />
    </Router>
  );
};
