import React, { ReactElement, ReactNode } from "react";

import { Overlay, PopoverProps } from "_/components/overlay";

import * as S from "./styled";

export type TooltipProps = Omit<PopoverProps, "event"> & {
  title?: string;
  footerContent?: ReactNode;
};

export const Tooltip = ({
  title,
  footerContent,
  content,
  placement = "bottomStart",
  target,
  style,
  keepOpen,
  fullWidth,
  wait = 500,
  delay = 2000,
}: TooltipProps): ReactElement => {
  const header = title ? <S.Title>{title}</S.Title> : null;
  const body = content ? <S.Body>{content}</S.Body> : null;
  const footer = footerContent ? <S.Footer>{footerContent}</S.Footer> : null;

  const empty = !title && !content && !footerContent;

  const tooltipContent = !empty ? (
    <S.Wrapper style={style} $placement={placement}>
      {header}
      {body}
      {footer}
    </S.Wrapper>
  ) : (
    <></>
  );

  return (
    <Overlay
      placement={placement}
      target={target}
      content={tooltipContent}
      delay={delay}
      wait={wait}
      keepOpen={keepOpen}
      fullWidth={fullWidth}
    />
  );
};
