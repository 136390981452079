/**
 * Overlay menu component.
 *
 * The context menu can be used as a dropdown menu that pops above the regular
 * document layout, providing access to additional hidden menu items that do
 * not need to always be displayed.
 *
 * @category components
 */

import React, { ReactElement, ReactNode } from "react";

import { Overlay, PopoverProps } from "_/components/overlay";
import { Icon, IconVariant } from "_/components/icon";

import * as S from "./styled";

export type MenuItem = {
  /**
   * Content to display, or a render function that returns the content to display.
   *
   * If the value provided is a function, any `icon` prop value will be ignored.
   */
  render: ReactNode | (() => ReactElement);

  /**
   * An icon variant to display alongside content.
   *
   * By default, the icon will display to the right.
   */
  icon?: IconVariant;

  /**
   * An `onClick` handler applied to the entire menu item.
   *
   * If more granular control of the click event is required within
   * the menu item area, use a `render` function with manually configured
   * event handlers.
   */
  onClick?: () => void;

  /**
   * Flag to indicate the menu item should be disabled or otherwise cannot
   * be interacted with.
   */
  disabled?: boolean;
};

type OverlayMenuProps = {
  items: ReadonlyArray<MenuItem>;
  placement?: PopoverProps["placement"];
  target: PopoverProps["target"];
};

export const OverlayMenu = ({
  items,
  placement = "bottomStart",
  target,
}: OverlayMenuProps): ReactElement => {
  const itemEls = items.map(
    ({ render, onClick, disabled = false, icon }, i) => {
      const iconEl = icon ? <Icon variant={icon} /> : null;

      const menuItem =
        typeof render === "function" ? (
          render()
        ) : (
          <S.TextItem tabIndex={0}>
            {render} {iconEl}
          </S.TextItem>
        );

      return (
        <S.MenuItem
          key={i}
          onClick={() => !disabled && onClick?.()}
          $disabled={disabled}
        >
          {menuItem}
        </S.MenuItem>
      );
    }
  );

  const menu = <S.Wrapper $placement={placement}>{itemEls}</S.Wrapper>;

  return (
    <Overlay
      event="click"
      placement={placement}
      target={target}
      content={menu}
    />
  );
};
