import styled, { css } from "styled-components";

import { Icon as _Icon } from "_/components/icon";
import { Tooltip as _Tooltip } from "_/components/tooltip";

const invalidStyle = css`
  color: ${(p) => p.theme.input.invalid.text};
`;

export const LabelContainer = styled.label`
  color: ${(p) => p.theme.text.muted};
  font-weight: 200;
  font-size: 0.75em;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.25em;
`;

export const Hint = styled.span<{ $invalid?: boolean }>`
  font-size: 0.75em;

  ${(p) => p.$invalid && invalidStyle}
`;

export const InfoIcon = styled(_Icon)`
  font-size: 1em;
  color: ${(p) => p.theme.text.muted};
`;

export const FormField = styled.div`
  margin: 0 0 1em;
  width: 100%;
`;
