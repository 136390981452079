import React, { ReactElement, ReactNode } from "react";

import { Help } from "_/components/form-field";

import * as S from "./styled";
import { ButtonProps } from "./types";

export type { ButtonKind, ButtonSize, ButtonVariant } from "./types";

export const ButtonGroup = S.ButtonGroup;

const InfoMessageWrapper = ({
  helpContent,
  children,
}: {
  helpContent: ReactNode;
  children: ReactNode;
}) => {
  return (
    <S.InfoMessageWrapper>
      {children}
      <S.HelpIconWrapper>
        <Help content={helpContent} placement="bottomEnd" />
      </S.HelpIconWrapper>
    </S.InfoMessageWrapper>
  );
};

export const Button = (props: ButtonProps): ReactElement => {
  const {
    children,
    size = "medium",
    kind = "primary",
    variant = "contained",
    round = false,
    icon = false,
    helpContent,
    ...rest
  } = props;

  const button = (
    <S.Button
      $kind={kind}
      $size={size}
      $variant={variant}
      $icon={icon}
      $round={round}
      {...rest}
    >
      {children}
    </S.Button>
  );
  return helpContent ? (
    <InfoMessageWrapper helpContent={helpContent}>{button}</InfoMessageWrapper>
  ) : (
    button
  );
};
