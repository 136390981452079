import styled from "styled-components";

import { Icon } from "_/components/icon";

export const Table = styled.table<{ $columnCount: number }>`
  display: grid;
  grid-template-columns: ${(p) => `${"auto ".repeat(p.$columnCount)} 1fr`};
  background-color: ${(p) => p.theme.background.secondary};
  border: 1px solid ${(p) => p.theme.border.default};
  border-radius: 8px;
  width: 100%;
  min-width: min-content;
`;

export const Thead = styled.thead`
  display: contents;
`;

export const Tbody = styled.tbody`
  display: contents;
`;

export const Tr = styled.tr`
  display: contents;

  &:hover > td {
    background-color: ${(p) => p.theme.background.tertiary};
  }
`;

export const Th = styled.th`
  font-weight: 400;
  font-size: 12px;
  line-height: auto;
  text-align: left;
  user-select: none;
  height: 100%;

  letter-spacing: 0.2px;
  padding: 0.5rem 1rem;
  white-space: nowrap;

  color: ${(p) => p.theme.text.muted};
  border-bottom: 1px solid ${(p) => p.theme.border.default};

  &:last-of-type {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const ThCell = styled.div`
  display: flex;
  align-items: center;

  cursor: pointer;

  ${Icon} {
    font-size: 18px;
    margin-left: 4px;
  }
`;

export const Td = styled.td.attrs({
  role: "gridcell",
})`
  display: flex;
  align-items: center;
  align-self: center;
  align-content: center;
  height: 100%;
  min-height: 3.5rem;

  font-weight: 400;
  font-size: 0.875rem;

  color: ${(p) => p.theme.text.default};
  border-bottom: 1px solid ${(p) => p.theme.border.default};
  padding: 0.625rem 1rem;

  /*
    the last cell in each row is a placeholder to take up all remaining space
    without unnaturally stretching the other cells.
   */
  &:last-of-type {
    padding-left: 0;
    padding-right: 0;
  }
`;

/**
 * Component to use for table cells that may contain clickable content, as
 * opposed to the entire row being clickable.
 *
 * Most tables in Basis have the entire row clickable, but some tables only have
 * some cells clickable. For the cells that are links, we make the entire cell
 * into a link target (which makes the link easier to click on and more
 * discoverable via the hover underline. In order to make the whole cell
 * clickable and hover-able, we must remove the padding on the parent TD and
 * apply padding to the cell content instead.
 */
export const CustomTd = styled(Td)`
  padding: 0;
`;

export const CustomTdContent = styled.div`
  align-content: center;
  padding: 0.625rem 1rem;
  height: 100%;
`;

export const NoDataTd = styled(Td)`
  display: grid;
  grid-column: 1 / -1; /* span all rows */
`;

export const NoData = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 150px 0 150px;
`;

// Infuriatingly, a wrapper element is required to get height:100% grid cells to
// work properly in Safari. See https://stackoverflow.com/a/63197330 It doesn't
// seem to matter if it's a flex or grid container, as long as it's there.
export const TableContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  /* Prevent weird behavior when browser width is very small */
  min-width: min-content;
`;
