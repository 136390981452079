import { css } from "styled-components";

import LE from "_/assets/fonts/neufile_grotesk_light_extended.woff";
import LE_2 from "_/assets/fonts/neufile_grotesk_light_extended.woff2";

import LEI from "_/assets/fonts/neufile_grotesk_light_extended_italic.woff";
import LEI_2 from "_/assets/fonts/neufile_grotesk_light_extended_italic.woff2";

import RE from "_/assets/fonts/neufile_grotesk_regular_extended.woff";
import RE_2 from "_/assets/fonts/neufile_grotesk_regular_extended.woff2";

import REI from "_/assets/fonts/neufile_grotesk_regular_extended_italic.woff";
import REI_2 from "_/assets/fonts/neufile_grotesk_regular_extended_italic.woff2";

import ME from "_/assets/fonts/neufile_grotesk_medium_extended.woff";
import ME_2 from "_/assets/fonts/neufile_grotesk_medium_extended.woff2";

import MEI from "_/assets/fonts/neufile_grotesk_medium_extended_italic.woff";
import MEI_2 from "_/assets/fonts/neufile_grotesk_medium_extended_italic.woff2";

import SBE from "_/assets/fonts/neufile_grotesk_semi_bold_extended.woff";
import SBE_2 from "_/assets/fonts/neufile_grotesk_semi_bold_extended.woff2";

import SBEI from "_/assets/fonts/neufile_grotesk_semi_bold_extended_italic.woff";
import SBEI_2 from "_/assets/fonts/neufile_grotesk_semi_bold_extended_italic.woff2";

import BE from "_/assets/fonts/neufile_grotesk_bold_extended.woff";
import BE_2 from "_/assets/fonts/neufile_grotesk_bold_extended.woff2";

import BEI from "_/assets/fonts/neufile_grotesk_bold_extended_italic.woff";
import BEI_2 from "_/assets/fonts/neufile_grotesk_bold_extended_italic.woff2";

import EBE from "_/assets/fonts/neufile_grotesk_extra_bold_extended.woff";
import EBE_2 from "_/assets/fonts/neufile_grotesk_extra_bold_extended.woff2";

import EBEI from "_/assets/fonts/neufile_grotesk_extra_bold_extended_italic.woff";
import EBEI_2 from "_/assets/fonts/neufile_grotesk_extra_bold_extended_italic.woff2";

function makeFont(
  woff: string,
  woff2: string,
  family: string,
  weight: number,
  style: string
) {
  return css`
    @font-face {
      font-family: "${family}";
      src:
        url(${woff2}) format("woff2"),
        url(${woff}) format("woff");
      font-weight: ${weight};
      font-style: ${style};
    }
  `;
}

export const fonts = css`
  ${makeFont(LE, LE_2, "Neufile Grotesk", 300, "normal")}
  ${makeFont(LEI, LEI_2, "Neufile Grotesk", 300, "italic")}

  ${makeFont(RE, RE_2, "Neufile Grotesk", 400, "normal")}
  ${makeFont(REI, REI_2, "Neufile Grotesk", 400, "italic")}

  ${makeFont(ME, ME_2, "Neufile Grotesk", 500, "normal")}
  ${makeFont(MEI, MEI_2, "Neufile Grotesk", 500, "italic")}

  ${makeFont(SBE, SBE_2, "Neufile Grotesk", 600, "normal")}
  ${makeFont(SBEI, SBEI_2, "Neufile Grotesk", 600, "italic")}

  ${makeFont(BE, BE_2, "Neufile Grotesk", 700, "normal")}
  ${makeFont(BEI, BEI_2, "Neufile Grotesk", 700, "italic")}

  ${makeFont(EBE, EBE_2, "Neufile Grotesk", 800, "normal")}
  ${makeFont(EBEI, EBEI_2, "Neufile Grotesk", 800, "italic")}
`;
