import styled from "styled-components";

import { MaterialColorBox } from "_/components/materials/styled";

export const LayerControlContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  height: 100%;
  width: max-content;

  padding: 1rem;

  border-radius: 5px;

  background-color: ${(p) => p.theme.background.secondary};
`;

export const SliderContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;

export const SliderLabel = styled.span`
  font-size: 0.875rem;
  color: ${(p) => p.theme.text.muted};
  font-weight: 600;
`;

export const RightSideContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

export const ProjectInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  height: max-content;
  flex-grow: 1;
  width: auto;

  border-radius: 5px;
  padding: 1rem 1.25rem;
  background-color: ${(p) => p.theme.background.secondary};
`;

export const ProjectInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-size: 0.875rem;
  color: ${(p) => p.theme.text.default};
`;

export const ProjectInfoButtonsItem = styled(ProjectInfoItem)`
  padding-top: 0.25rem;
  gap: 1.25rem;
  /* 
    TS complained about using the Button element below, so using
    button instead.
  */
  & button {
    display: flex;
    width: 100%;

    /* slightly smaller than default to avoid word wrap */
    font-size: 0.9375rem;
  }
`;

export const ProjectInfoLabel = styled.div`
  color: ${(p) => p.theme.text.muted};
  font-weight: 600;
`;

export const ProjectInfoValue = styled.div`
  & ${MaterialColorBox} {
    margin-right: 0.5rem;
  }
`;

export const UserInfoContainer = styled.div`
  margin: -0.375rem 0; /* less space to label to account for avatar */
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.25rem;
`;

export const SimTypeLabel = styled.div`
  font-size: 0.9375rem;
  font-weight: 600;
`;

export const SimTypeOption = styled.div<{ $disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 0.1875rem;
  align-items: start;
  font-size: 0.875rem;
  color: ${(p) => p.theme.text.muted};
  margin-bottom: 0.375rem;
  ${SimTypeLabel} {
    color: ${(p) => (p.$disabled ? p.theme.text.muted : p.theme.text.default)};
  }
`;

export const SimTypeLongDescription = styled.div``;

export const NotOptimizableWarningBox = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(p) => p.theme.background.quaternary};
  font-size: 0.875rem;
  padding: 1rem;
  border-radius: 5px;
`;

export const MakeChildrenInline = styled.div`
  /* for the simulation capabilities icon */
  & > * {
    display: inline-block;
    vertical-align: middle;
  }
`;

export const SimModalMessage = styled.div`
  text-align: center;
  margin-top: 1rem;
`;

export const ButtonsRow = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: end;
`;

export const ModalItem = styled.div`
  padding: 0.75rem 0;
`;

export const ModalItemLabelText = styled.div`
  color: ${(p) => p.theme.text.muted};
  font-weight: 600;
  padding-bottom: 0.25rem;
  font-size: 0.875rem;
`;

export const SimTypeRadioGroupLabel = styled(ModalItemLabelText)`
  padding-bottom: 0.5rem;
`;

export const UserName = styled.div``;

export const ProgressWrapper = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
