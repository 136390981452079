import React, { Fragment } from "react";

import { Slider } from "_/components/slider";

import { Tuple } from "_/types";

import * as S from "./styled";

type LabelledSliderProps<T extends number | Tuple<number>> = {
  /**
   * Label to display above the slider.
   */
  label: string;

  /**
   * Minimum value of the slider.
   */
  min: number;

  /**
   * Maximum value of the slider.
   */
  max: number;

  /**
   * Step size of the slider.
   */
  step: number;

  /**
   * Default value of the slider.
   */
  value: T;

  /**
   * Called when the slider value changes.
   */
  onChange: (value: T) => void;
};

export const LabeledSlider = <T extends number | Tuple<number>>({
  label,
  min,
  max,
  step,
  value,
  onChange,
}: LabelledSliderProps<T>) => {
  // Normalize scalar (single thumb) and tuple (dual thumb) values into an array
  const values: number[] = typeof value === "number" ? [value] : value;

  const decimalPlaces = step.toString().split(".")[1]?.length ?? 0;
  const toValueLabel = (v: number) => {
    return (Math.round(v / step) * step).toFixed(decimalPlaces);
  };

  return (
    <S.LabelledSlider>
      <S.ControlLabel>
        <span>{label}</span>
        <S.SliderValueLabel>
          {values.map((v, i) => (
            <Fragment key={i}>
              {i > 0 ? <>&nbsp;&mdash;&nbsp;</> : null}
              {toValueLabel(v)}
            </Fragment>
          ))}
        </S.SliderValueLabel>
      </S.ControlLabel>
      <Slider
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={(val) => {
          onChange(val);
        }}
      />
    </S.LabelledSlider>
  );
};
