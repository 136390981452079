import React, { useEffect, ReactElement } from "react";
import { isEmpty } from "ramda";
import { useLocation } from "_/components/router";
import { t } from "@lingui/macro";

import { useToasts } from "_/components/toasts";
import { useUserVerification } from "_/data/users";
import { routeUrls } from "_/routes";

export const EmailVerify = (): ReactElement => {
  const [location, setLocation] = useLocation();
  const [_, addToast] = useToasts();
  const userVerification = useUserVerification();

  const searchParams = new URLSearchParams(window.location.search);
  const queryParams = Object.fromEntries(searchParams.entries());

  useEffect(() => {
    if (isEmpty(queryParams)) {
      return;
    }

    // Rewrite history to clear query params on pageload.
    setLocation(routeUrls.index, { replace: true });

    const successTitle = t`component.email-verify.success-title`;
    const successContent = t`component.email-verify.success-content`;

    const errorTitle = t`component.email-verify.error-title`;
    const errorContent = t`component.email-verify.error-content`;

    userVerification
      .mutateAsync({ id: queryParams.i, token: queryParams.t })
      .then(() => {
        setLocation(routeUrls.public.login);
        addToast({
          title: successTitle,
          content: successContent,
        });
      })
      .catch(() => {
        addToast({
          title: errorTitle,
          content: errorContent,
        });
      });
  }, [location, setLocation, queryParams, userVerification, addToast]);

  return <></>;
};
