import { Vector3, BufferGeometry } from "three";
import { mergeGeometries } from "three/examples/jsm/utils/BufferGeometryUtils";

import { FeatureType } from "./gcode-parser";

/**
 * A single layer of a print.
 */
interface Layer {
  extrusionGeometry: Vector3[];
  featureType: number[];
  toolhead: number[];
  z: number;
}

/**
 * Geometry data for a print.
 * Used to create a BufferGeometry for rendering.
 */
export interface GCodePrintData {
  segmentLayer: Int32Array;
  segmentFeatureType: Int32Array;
  segmentStartPoints: Float32Array;
  segmentEndPoints: Float32Array;
  topLayer: number;
  totalSegments: number;
}

/**
 * Serialized version of a single layer of a print.
 */
interface SerializedLayer {
  extrusionGeometry: number[][];
  featureType: number[];
  toolhead: number[];
  z: number;
}

/**
 * Serialized version of a print, consisting of multiple layers.
 */
export interface SerializedGCodePrint {
  layers: SerializedLayer[];
  toolheadMovementGeometry: number[][];
  featureTypes: number[];
}

/**
 * A print, consisting of multiple layers.
 * Manages the geometry of the print as well as that of the toolhead movement.
 */
export class GCodePrint {
  private layers: Layer[];
  private toolheadMovementGeometry: Vector3[];
  private totalExtrusionSegments = 0;

  private featureTypes: number[];

  constructor() {
    this.layers = [];
    this.toolheadMovementGeometry = [];
    this.featureTypes = Array(Object.keys(FeatureType).length / 2).fill(0);
  }

  public getCurrentLayer(): Layer | null {
    if (this.layers.length === 0) {
      return null;
    } else {
      return this.layers[this.layers.length - 1];
    }
  }

  public startNewLayer(z: number) {
    this.layers.push({
      extrusionGeometry: [],
      featureType: [],
      toolhead: [],
      z,
    });
  }

  public addExtrusionSegment(
    start: Vector3,
    end: Vector3,
    featureType: number,
    toolhead: number
  ) {
    const currentLayer = this.layers[this.layers.length - 1];
    currentLayer.extrusionGeometry.push(start.clone());
    currentLayer.extrusionGeometry.push(end.clone());
    currentLayer.featureType?.push(featureType);
    currentLayer.toolhead.push(toolhead);

    this.featureTypes[featureType] = 1;
    this.totalExtrusionSegments++;
  }

  public addToolheadMovementSegment(start: Vector3, end: Vector3) {
    this.toolheadMovementGeometry.push(start.clone());
    this.toolheadMovementGeometry.push(end.clone());
  }

  // Returns an array of the feature types used in the print.
  public getFeatureTypes(): number[] {
    return this.featureTypes;
  }

  public getData(): GCodePrintData {
    const segmentLayer = new Int32Array(this.totalExtrusionSegments);
    const segmentFeatureType = new Int32Array(this.totalExtrusionSegments);

    const segmentStartPoints = new Float32Array(
      this.totalExtrusionSegments * 3
    );
    const segmentEndPoints = new Float32Array(this.totalExtrusionSegments * 3);

    let currentIndex = 0;

    for (let i = 0; i < this.layers.length; i++) {
      const layer = this.layers[i];

      for (let j = 0; j < layer.extrusionGeometry.length / 2; j++) {
        segmentLayer[currentIndex] = i;
        segmentFeatureType[currentIndex] = layer.featureType[j];

        const startVertex = layer.extrusionGeometry[j * 2];
        const endVertex = layer.extrusionGeometry[j * 2 + 1];

        segmentStartPoints[currentIndex * 3] = startVertex.x;
        segmentStartPoints[currentIndex * 3 + 1] = startVertex.y;
        segmentStartPoints[currentIndex * 3 + 2] = startVertex.z;

        segmentEndPoints[currentIndex * 3] = endVertex.x;
        segmentEndPoints[currentIndex * 3 + 1] = endVertex.y;
        segmentEndPoints[currentIndex * 3 + 2] = endVertex.z;

        currentIndex++;
      }
    }

    return {
      segmentLayer,
      segmentFeatureType,
      segmentStartPoints,
      segmentEndPoints,
      topLayer: this.layers.length - 1,
      totalSegments: this.totalExtrusionSegments,
    };
  }

  // Vector3 accessor methods

  public getLayeredExtrusionVertices(): Vector3[][] {
    const extrusionVertices = this.layers.map((layer) => {
      return layer.extrusionGeometry;
    });

    return extrusionVertices;
  }

  public getConsolidatedExtrusionVertices(): Vector3[] {
    const extrusionVertices = this.getLayeredExtrusionVertices().flat();

    return extrusionVertices;
  }

  public getToolheadMovementVertices(): Vector3[] {
    return this.toolheadMovementGeometry;
  }

  // Buffer Geometry accessor methods

  public getLayeredExtrusionBufferGeometry(): BufferGeometry[] {
    const extrusionGeometry = this.layers.map((layer) => {
      return new BufferGeometry().setFromPoints(layer.extrusionGeometry);
    });

    return extrusionGeometry;
  }

  public getConsolidatedExtrusionBufferGeometry(): BufferGeometry {
    const extrusionGeometry = mergeGeometries(
      this.getLayeredExtrusionBufferGeometry()
    );

    return extrusionGeometry;
  }

  public getToolheadMovementBufferGeometry(): BufferGeometry {
    const toolheadMovementGeometry = new BufferGeometry().setFromPoints(
      this.toolheadMovementGeometry
    );

    return toolheadMovementGeometry;
  }
}
