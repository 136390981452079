import React, { ReactElement, useCallback, useState } from "react";
import { Tuple } from "_/types";

import { SliderSize } from "./styled";
import * as S from "./styled";

type SliderProps<T extends number | Tuple<number>> = {
  /**
   * Minimum value of the slider.
   */
  min?: number;
  /**
   * Maximum value of the slider.
   */
  max?: number;
  /**
   * Values of the slider thumbs.
   * The number of values determines the number of thumbs.
   */
  value: T;
  /**
   * The amount to increment/decrement the slider value when the thumb is moved.
   */
  step: number;
  /**
   * Callback function that is called when the slider value changes.
   */
  onChange?: (value: T) => void;
  /**
   * Optional text to show next to a slider thumb when hovered or dragged.
   */
  tooltipText?: (value: T) => string[];
  /**
   * If a thumb tooltip is shown, where should it be shown?
   *
   * `"before"` will show the tooltip on top of a thumb in horizontal
   * orientation and to the left of a thumb in vertical orientation. `"after"`
   * will show the tooltip below the thumb in horizontal orientation and to the
   * right of the thumb in vertical orientation.
   *
   * Ignored if `tooltipText` is `undefined`.
   */
  tooltipPlacement?: "before" | "after";
  /**
   * The size of the slider.
   */
  size?: SliderSize;
  /**
   * True if the slider should be oriented vertically.
   */
  vertical?: boolean;
};

export const Slider = <T extends number | Tuple<number>>({
  min,
  max,
  value,
  step,
  onChange,
  tooltipText,
  tooltipPlacement = "before",
  size = "small",
  vertical = false,
}: SliderProps<T>): ReactElement => {
  // Normalize scalar (single thumb) and tuple (dual thumb) values into an array
  const valueCount = typeof value === "number" ? 1 : value.length;
  const values: number[] = typeof value === "number" ? [value] : value;

  const [committedValues, setCommittedValues] = useState(values);

  // The "active thumb" is the one that is currently being dragged.
  // This is needed because our slider component frustratingly sorts
  // thumb positions while dragging; there's no persistent concept of
  // a first thumb and a second thumb. We need to know which thumb is
  // currently being dragged so we can prevent the user from dragging
  // one thumb to the other side of the other thumb.
  const [activeThumbIndex, setActiveThumbIndex] = useState<1 | 0 | undefined>(
    undefined
  );

  // This handler can be called 100s of times while dragging, so it's helpful to
  // memoize it.
  const onValueChange = useCallback(
    (newVals: number[]) => {
      const newValues = [...newVals]; // Clone in case mutation is bad here.
      if (valueCount === 2) {
        const differentFromCommitted = newValues.map(
          (v, i) => v !== committedValues[i]
        );
        const adjustValues = (thumbIndex: number) => {
          // Prevent thumbs from crossing each other while dragging
          if (thumbIndex === 0) {
            newValues[0] = Math.min(newValues[0], committedValues[1]);
            newValues[1] = committedValues[1];
          } else {
            newValues[1] = Math.max(newValues[1], committedValues[0]);
            newValues[0] = committedValues[0];
          }
        };

        if (activeThumbIndex !== undefined) {
          adjustValues(activeThumbIndex);
        } else if (
          // If the last committed values are the same, then allow the user to
          // move the thumb anywhere.
          committedValues[0] !== committedValues[1]
        ) {
          if (differentFromCommitted[0] && differentFromCommitted[1]) {
            // If neither thumb is in the same place as the committed values,
            // then the user is trying to drag one thumb to the other side of
            // the other thumb. This might not be possible given the code below
            // but it can't hurt to prevent it just in case.
            console.warn("Unexpected change of both slider thumbs.");
            return;
          } else if (differentFromCommitted[0]) {
            setActiveThumbIndex(0);
          } else if (differentFromCommitted[1]) {
            setActiveThumbIndex(1);
          }
        }
      }
      const valueForHandler = (
        valueCount === 1 ? newValues[0] : newValues
      ) as T;
      onChange?.(valueForHandler);
    },
    [activeThumbIndex, committedValues, onChange, valueCount]
  );

  const tooltipTextResult = tooltipText?.(value) ?? [];

  const tooltipPlacementForStyles = (
    vertical
      ? ({ before: "vertical-before", after: "vertical-after" } as const)
      : ({ before: "horizontal-before", after: "horizontal-after" } as const)
  )[tooltipPlacement];

  return (
    <S.SliderRoot
      min={min}
      max={max}
      value={values}
      step={step}
      onValueCommit={(newVals: Tuple<number>) => {
        setCommittedValues(newVals);
        setActiveThumbIndex(undefined);
      }}
      onPointerDown={() => {
        // Recover from the case where the previous drag was not committed, e.g.
        // if the user lifted the mouse over another window.
        setCommittedValues(values);
        setActiveThumbIndex(undefined);
      }}
      onValueChange={onValueChange}
      $size={size}
      orientation={vertical ? "vertical" : "horizontal"}
    >
      <S.SliderTrack $size={size}>
        <S.SliderRange />
      </S.SliderTrack>
      {values.map((_values, index) => (
        <S.SliderThumb
          key={index}
          // We use an attribute instead of a prop because the tooltip text
          // changes so frequently that it would be expensive to re-create the
          // styled component styles on every change. Using an attribute
          // (combined with the css `attr()` function) lets us use the same
          // styles for all cases.
          data-value={tooltipTextResult[index]}
          $tooltipPlacement={
            tooltipText !== undefined ? tooltipPlacementForStyles : undefined
          }
          $size={size}
        />
      ))}
    </S.SliderRoot>
  );
};
