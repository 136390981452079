import React, { Component, ErrorInfo, ReactNode } from "react";

import { Button } from "_/components/button";

import * as S from "./styled";

type State = {
  error: Error | null;
  errorInfo: ErrorInfo | null;
};

type Props = {
  children: ReactNode;
};

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    error: null,
    errorInfo: null,
  };

  public static getDerivedStateFromError(error: Error): State {
    return { error, errorInfo: null };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({ error, errorInfo });
  }

  render(): ReactNode {
    if (this.state.error === null) {
      return this.props.children;
    }

    return (
      <S.Shade>
        <div>
          <h1>Something went wrong!</h1>
          <p>This is likely an error on our end and not your fault.</p>
          <S.DetailWrapper>
            <S.StackTrace>{this.state.error.toString().trim()}</S.StackTrace>
            <S.StackTrace>
              {this.state.errorInfo?.componentStack?.trim()}
            </S.StackTrace>
          </S.DetailWrapper>
          <Button onClick={() => window.location.assign("/")}>
            Reload Basis
          </Button>
        </div>
      </S.Shade>
    );
  }
}
