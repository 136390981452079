export interface SimOutput {
  formatVersion: number;
  jsonHeaderText: string;
  binaryHeader: {
    simVersionMajor: number;
    simVersionMinor: number;
    simVersionPatch: number;
    voxelCount: number;
    simFlags: number;
    snapshotCount: number;
    snapshotThresholdValues: Float32Array;
  };
  xyzPositions: Float32Array;
  gcodeLineIndex: Uint32Array;
  previousLayerTemperature: Float32Array;
  layerWelding: Float32Array;
}

export function parseSimResultBinary(buffer: ArrayBuffer): SimOutput | null {
  let offset = 0;
  const view = new DataView(buffer);

  const expectedFormatVersion = 1;

  const formatVersion = view.getUint32(offset, true);

  if (formatVersion !== expectedFormatVersion) {
    console.error(
      `Unsupported format version: ${formatVersion} (expected ${expectedFormatVersion})`
    );
    return null;
  }

  offset += 4;

  const jsonHeaderSize = Number(view.getBigUint64(offset, true));
  offset += 8;

  // Binary header size
  offset += 8;

  const jsonHeaderText = new TextDecoder().decode(
    buffer.slice(offset, offset + jsonHeaderSize)
  );
  offset += jsonHeaderSize;

  // Binary header details
  const simVersionMajor = view.getUint32(offset, true);
  offset += 4;

  const simVersionMinor = view.getUint32(offset, true);
  offset += 4;

  const simVersionPatch = view.getUint32(offset, true);
  offset += 4;

  const voxelCount = Number(view.getBigUint64(offset, true));
  offset += 8;

  const simFlags = view.getUint32(offset, true);
  offset += 4;

  const snapshotCount = view.getUint32(offset, true);
  offset += 4;

  const snapshotThresholdValues = new Float32Array(snapshotCount);
  for (let i = 0; i < snapshotCount; i++) {
    snapshotThresholdValues[i] = view.getFloat32(offset, true);
    offset += 4;
  }

  // Binary body
  const xyzPositions = new Float32Array(voxelCount * 3);
  for (let i = 0; i < voxelCount * 3; i++) {
    xyzPositions[i] = view.getFloat32(offset, true);
    offset += 4;
  }

  const gcodeLineIndex = new Uint32Array(voxelCount);
  for (let i = 0; i < voxelCount; i++) {
    gcodeLineIndex[i] = view.getUint32(offset, true);
    offset += 4;
  }

  const previousLayerTemperature = new Float32Array(voxelCount);
  for (let i = 0; i < voxelCount; i++) {
    previousLayerTemperature[i] = view.getFloat32(offset, true);
    offset += 4;
  }

  const layerWelding = new Float32Array(voxelCount);
  for (let i = 0; i < voxelCount; i++) {
    layerWelding[i] = view.getFloat32(offset, true);
    offset += 4;
  }

  return {
    formatVersion,
    jsonHeaderText,
    binaryHeader: {
      simVersionMajor,
      simVersionMinor,
      simVersionPatch,
      voxelCount,
      simFlags,
      snapshotCount,
      snapshotThresholdValues,
    },
    xyzPositions,
    gcodeLineIndex,
    previousLayerTemperature,
    layerWelding,
  };
}
