import styled from "styled-components";

export const OuterWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: ${(p) => p.theme.text.default};
  padding: 2.5rem 2.5rem;
  gap: 2.25rem;
`;

export const Carousel = styled.ul`
  display: grid;
  gap: 1.5rem;
  padding: 0;
  margin: 0;
  overflow-y: hidden;
  height: 200px;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
`;

export const HomeWidget = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  gap: 1rem;
`;

export const NestedWidgetContainer = styled.div`
  display: flex;
  gap: 3rem;
  width: 100%;
`;

export const UseRemainingWidth = styled.div`
  flex-grow: 1;
`;

export const HomeWidgetTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  h2 {
    margin: 0;
    padding: 0 0 0 1px;
    line-height: 1;
  }
`;

export const RightPane = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
  width: 300px;
  min-width: 300px;
  min-height: 100%;
  height: fit-content;
  flex-shrink: 0;
  background-color: ${(p) => p.theme.background.secondary};
  color: ${(p) => p.theme.text.default};
  padding: 1.25rem;
`;
