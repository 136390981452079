import React, { ReactElement } from "react";
import { t } from "@lingui/macro";

import { AttemptDatasetManifest } from "_/data/projects";

import { Uuid } from "_/types";

import * as S from "./styled";

function datasetDownloadURL(
  projectId: Uuid,
  attemptId: Uuid,
  datasetId: Uuid
): string {
  return `/api/v0/projects/${projectId}/attempts/${attemptId}/data/${datasetId}`;
}

// Constructs translated label for dataset kinds and sample count.
function translatedLabel(kind: string, samples: number): string {
  return t`components.attempts-download-list ${kind} ${samples}`;
}

/**
 * Listing of downloadable attempt data files to be rendered within modal.
 */
export const AttemptDataDownloadList = ({
  projectId,
  attemptId,
  manifest,
}: {
  projectId: Uuid;
  attemptId: Uuid;
  manifest: AttemptDatasetManifest[];
}): ReactElement => {
  const downloadList = manifest.map((manifestItem) => (
    <S.DownloadItem key={manifestItem.id}>
      <span>{translatedLabel(manifestItem.kind, manifestItem.samples)}</span>
      <a href={datasetDownloadURL(projectId, attemptId, manifestItem.id)}>
        <S.Icon variant="FileDownload" />
      </a>
    </S.DownloadItem>
  ));

  return <>{downloadList}</>;
};
