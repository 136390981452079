import React, { ReactElement } from "react";

export const Square = (): ReactElement => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="1em" height="1em" rx="1" fill="currentColor" />
    </svg>
  );
};
