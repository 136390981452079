import React, { ReactElement, useState } from "react";
import { t } from "@lingui/macro";

import {
  Project,
  Attempt,
  AttemptStatus,
  useProjects,
  Simulation,
  JobStatus,
  useAllAttempts,
  useAllSimulations,
  Revision,
  useAttemptDatasetManifest,
  sortAttempts,
  sortAttemptsAndSimulations,
  AttemptDatasetManifest,
} from "_/data/projects";
import { Machine, useMachines } from "_/data/machines";
import { Material, useMaterials } from "_/data/materials";

import { Uuid } from "_/types";
import { indexById } from "_/utils";

import {
  ColumnDef,
  CustomCellLink,
  CustomCellStatic,
  Table,
} from "_/components/table";
import { AttemptStatusDisplay } from "_/components/attempts-listing";
import { DateTime } from "_/components/date-time";
import { Button, ButtonGroup } from "_/components/button";
import { Link, NeverUnderlineSpace } from "_/components/router";
import { Modal } from "_/components/modal";
import { AttemptDataDownloadList } from "_/components/attempt-data-download-list";
import { Badge, BadgeKind } from "_/components/badge";
import { MaterialsList } from "_/components/materials";

import { machineUrls, projectUrls } from "_/routes";

import * as S from "./styled";

type ModalProps = {
  projectId: Uuid;
  attemptId: Uuid;
  manifest: AttemptDatasetManifest[];
};

const AttemptActionButtons = ({
  revision,
  attempt,
  setModalProps,
  variant,
}: {
  variant: JobsListingProps["variant"];
  revision: Revision;
  attempt: Attempt;
  setModalProps: (modalProps: ModalProps) => void;
}): ReactElement => {
  const manifestQuery = useAttemptDatasetManifest(
    { projectId: revision.projectId, attemptId: attempt.id },
    { enabled: false }
  );

  return (
    <ButtonGroup>
      {variant !== "home" && attempt.endedAt && (
        <Button
          size="small"
          kind="secondary"
          onMouseOver={() => {
            // Only attempt to load the data if it hasn't been yet.
            if (manifestQuery.isStale || !manifestQuery.isIdle) {
              manifestQuery.refetch();
            }
          }}
          onClick={() =>
            setModalProps({
              attemptId: attempt.id,
              manifest: manifestQuery.data ?? [],
              projectId: revision.projectId,
            })
          }
        >
          {t`common.download`}
        </Button>
      )}
      <Link to={projectUrls.analyze(revision.projectId, attempt.id)}>
        <Button size="small">{t`common.analyze`}</Button>
      </Link>
    </ButtonGroup>
  );
};

/**
 * Helper component for rendering "Status" column of an Attempts entry.
 */
export const SimulationStatusDisplay = ({
  status,
}: {
  status: JobStatus;
}): ReactElement => {
  let kind: BadgeKind = "info";
  let statusLabel = t`common.unknown`;

  switch (status) {
    case "started":
      kind = "info";
      statusLabel = t`common.simulating`;
      break;
    case "queued":
      kind = "warning";
      statusLabel = t`common.queued`;
      break;
    case "completed":
      kind = "success";
      statusLabel = t`common.complete`;
      break;
    case "cancelled":
      kind = "muted";
      statusLabel = t`common.cancelled`;
      break;
    case "failed":
      kind = "error";
      statusLabel = t`common.failed`;
      break;
  }

  return <Badge kind={kind}>{statusLabel}</Badge>;
};

export interface JobsListingProps {
  /** Maximum number of jobs to render. */
  limit: number;

  /** Filter results to this machine, or `undefined` for unfiltered */
  machine?: Machine;

  /**
   * If present, filter out jobs that ran for a short time before being
   * cancelled, typically as part of a calibration or testing task before
   * printing a real part.
   */
  minCancelledJobMinutes?: number;

  /**
   * Determines which columns to show or hide. Machine is hidden on Machine
   * Detail because it's redundant. The end date/time and download button are
   * hidden on home page to reduce space and complexity.
   * */
  variant: "home" | "machine";
}

const RevisionNumber = ({ revision }: { revision: Revision }) => (
  <S.RevisionOrJobNumber title={t`common.revision-name ${revision.number}`}>
    #{revision.number}
  </S.RevisionOrJobNumber>
);

const AttemptNumber = ({
  revision,
  attempt,
}: {
  revision: Revision;
  attempt: Attempt;
}) => {
  return (
    <S.RevisionOrJobNumber
      title={t`common.job-description ${revision.number} ${attempt.number}`}
    >
      #{revision.number}-{attempt.number}
    </S.RevisionOrJobNumber>
  );
};

export function RevisionCell({
  project,
  revision,
}: {
  project: Project;
  revision: Revision;
}): ReactElement {
  return (
    <CustomCellLink to={projectUrls.prepare(project.id, revision.id)}>
      <span>{project.name}</span>
      <NeverUnderlineSpace />
      <RevisionNumber revision={revision} />
    </CustomCellLink>
  );
}

export function AttemptCell({
  project,
  revision,
  attempt,
}: {
  project: Project;
  revision: Revision;
  attempt: Attempt;
}): ReactElement {
  return (
    <CustomCellLink to={projectUrls.analyze(project.id, attempt.id)}>
      <span>{project.name}</span>
      <NeverUnderlineSpace />
      <AttemptNumber revision={revision} attempt={attempt} />
    </CustomCellLink>
  );
}

export const JobsListing = ({
  limit,
  machine,
  minCancelledJobMinutes,
  variant,
}: JobsListingProps) => {
  const [modalProps, setModalProps] = useState<ModalProps | null>(null);

  const allAttemptsResult = useAllAttempts();
  const allSimulationsResult = useAllSimulations();
  const { data: projects = [] } = useProjects();
  const revisions = projects.flatMap((p) => p.revisions) ?? [];
  const { data: machines = [] } = useMachines();
  const { data: materials = [] } = useMaterials();
  const machineId = machine?.id;

  if (limit < 1) {
    throw new Error("`limit` must be >= 1");
  }
  if (minCancelledJobMinutes !== undefined && minCancelledJobMinutes < 1) {
    throw new Error("`minCancelledJobMinutes` must be >= 1");
  }

  // Wait until the full list has loaded before displaying anything to the user.
  if (
    allAttemptsResult.isLoading ||
    !allAttemptsResult.data ||
    allSimulationsResult.isLoading ||
    !allSimulationsResult.data
  ) {
    return <></>;
  }

  const attempts = allAttemptsResult.data
    .filter((a) => !machineId || a.machineId === machineId)
    .filter(
      // Filter out cancelled jobs that only ran for a short time, usually
      // calibration-only jobs that aren't needed on home page.
      (a) =>
        !(
          minCancelledJobMinutes &&
          a.status === "cancelled" &&
          a.endedAt &&
          new Date(a.endedAt).getTime() - new Date(a.startedAt).getTime() <
            minCancelledJobMinutes * 60 * 1000
        )
    );

  const jobs: (Attempt | Simulation)[] = [];

  if (variant === "home") {
    const simulations = allSimulationsResult.data;
    jobs.push(
      ...[...attempts, ...simulations].sort(sortAttemptsAndSimulations) // TODO: change to `toSorted` in 2025
    );
  } else {
    jobs.push(...attempts.sort(sortAttempts)); // TODO: change to `toSorted` in 2025
  }

  const projectByKey = indexById(projects);
  const machineByKey = indexById(machines);
  const materialByKey = indexById(materials);
  const revisionsByKey = indexById(revisions);

  type JobType = "attempt" | "simulation";

  type RowData<T extends JobType> = {
    jobType: T;
    job: T extends "attempt" ? Attempt : Simulation;
    project: Project;
    revision: Revision;
    machine: T extends "attempt" ? Machine : "simulation";
    material0?: Material;
    material1?: Material;
    startedAt?: string;
    endedAt?: string;
    status: T extends "attempt" ? AttemptStatus : JobStatus;
  };

  const topJobs = jobs.slice(0, limit);

  // Denormalize the attempts.
  const tableData: RowData<JobType>[] = topJobs
    .map((job) => {
      const revision = revisionsByKey[job.revisionId];
      if (!revision) return;
      const project = projectByKey[revision.projectId];
      if (!project) return;
      const machine =
        "machineId" in job
          ? machineByKey[job.machineId]
          : ("simulation" as const);
      if (!machine) return;
      const material0 = revision.materials[0]
        ? materialByKey[revision.materials[0]]
        : undefined;
      const material1 = revision.materials[1]
        ? materialByKey[revision.materials[1]]
        : undefined;

      const jobType: JobType =
        machine === "simulation" ? "simulation" : "attempt";

      const startedAt =
        jobType === "attempt"
          ? (job as Attempt).startedAt
          : (job as Simulation).job.createdAt; // using "createdAt" because "startedAt" is currently `null` for all simulation jobs.

      const endedAt =
        jobType === "attempt"
          ? (job as Attempt).endedAt
          : (job as Simulation).job.endedAt;

      const status =
        jobType === "attempt"
          ? (job as Attempt).status
          : (job as Simulation).job.status;

      const row = {
        job,
        jobType,
        project,
        revision,
        machine,
        material0,
        material1,
        startedAt,
        endedAt,
        status,
      };
      const rowDataAll = { row, ...row };
      return rowDataAll;
    })
    .filter(Boolean);

  const simulationLabel = `(${t`common.simulation`.toLowerCase()})`;

  const columns: (ColumnDef<RowData<JobType>> | false)[] = [
    {
      header: t`common.project-and-revision`,
      meta: { customTd: true },
      cell: ({ row: { original: rowData } }) => (
        <RevisionCell project={rowData.project} revision={rowData.revision} />
      ),
    },
    variant !== "machine" && {
      header: t`common.machine`,
      meta: { customTd: true },
      cell: ({ row: { original: rowData } }) =>
        rowData.machine === "simulation" ? (
          <CustomCellStatic>{simulationLabel}</CustomCellStatic>
        ) : (
          <CustomCellLink to={machineUrls.index(rowData.machine.id)}>
            {rowData.machine.name}
          </CustomCellLink>
        ),
    },
    variant === "machine" && {
      header: t`common.materials`,
      cell: ({ row: { original: rowData } }) => {
        const materials = [rowData.material0, rowData.material1].filter(
          Boolean
        );
        return <MaterialsList materials={materials} />;
      },
    },
    {
      header: t`common.start-date`,
      cell: ({ row: { original: rowData } }) => (
        <DateTime value={rowData.startedAt} />
      ),
    },
    variant !== "home" && {
      header: t`common.end-date`,
      cell: ({ row: { original: rowData } }) => (
        <DateTime value={rowData.endedAt} />
      ),
    },
    {
      header: t`common.status`,
      cell: ({ row: { original: rowData } }) =>
        rowData.jobType === "attempt" ? (
          <AttemptStatusDisplay status={rowData.status as AttemptStatus} />
        ) : (
          <SimulationStatusDisplay status={rowData.status as JobStatus} />
        ),
    },
    {
      header: t`common.actions`,
      cell: ({ row: { original: rowData } }) =>
        rowData.jobType === "attempt" ? (
          <AttemptActionButtons
            revision={rowData.revision}
            attempt={rowData.job as Attempt}
            setModalProps={setModalProps}
            variant={variant}
          />
        ) : (
          <ButtonGroup>
            {rowData.endedAt && (
              <Link
                to={projectUrls.optimize(
                  rowData.revision.projectId,
                  (rowData.job as Simulation).job.id
                )}
              >
                <Button size="small">{t`common.results`}</Button>
              </Link>
            )}
          </ButtonGroup>
        ),
    },
  ];

  const filteredColumns = columns.filter(Boolean);

  return (
    <>
      <Table columns={filteredColumns} data={tableData} />
      <Modal
        title={t`components.attempts-listing.downloads`}
        open={modalProps !== null}
        onClose={() => {
          setModalProps(null);
        }}
      >
        {modalProps && (
          <AttemptDataDownloadList
            projectId={modalProps.projectId}
            attemptId={modalProps.attemptId}
            manifest={modalProps.manifest}
          />
        )}
      </Modal>
    </>
  );
};
