import styled from "styled-components";
import { transparentize } from "polished";
import { Icon } from "_/components/icon";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;

  background-color: ${(p) => p.theme.viewer.background};
`;

export const ControlPanelContainer = styled.div`
  height: 100%;
  width: 400px;
  min-width: 400px;
  max-width: 400px;

  display: flex;
  flex-direction: row;
  gap: 1.25rem;

  padding: 1.25rem;

  color: ${(p) => p.theme.color.palette.white};
  background-color: ${(p) => p.theme.sidebar.background};
`;

export const GPUNotificationBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 500px;
`;

export const GPUWarningContainer = styled.div`
  position: absolute;
  bottom: 12px;
  left: 12px;
  z-index: 1;
`;

export const DocsLink = styled.a`
  color: ${(p) => p.theme.link.default};
  &:hover {
    color: ${(p) => p.theme.link.hover};
    text-decoration: underline 1.5px
      ${(p) => transparentize(0.3, p.theme.link.hover)};
  }
`;

export const WarningIcon = styled(Icon)`
  font-size: 36px;
  color: ${(p) => p.theme.color.palette.pineappleYellow};
`;
