export enum FeatureType {
  /**
   * The line that is printed around the object on the first layer to ensure that the filament is flowing correctly.
   * The brim is similar but it is attached to the edge of the object, which can help with adhesion to the print bed and stability for objects with a small base.
   */
  SkirtBrim = 0,
  /**
   * The outer walls of the print. Multiple perimeters can be set to increase the strength of the print.
   */
  Perimeter = 1,
  /**
   * The most outer line of the print, it often requires slower printing speed for better quality, since it's the visible part.
   */
  ExternalPerimeter = 2,
  /**
   * Internal structure of the print.
   * It can have different patterns and densities depending on the required strength and the attempt to save material and time.
   */
  InternalInfill = 3,
  /**
   * The extrusion type for parts of the model that overhang and often require supports to print correctly.
   */
  OverhangPerimeter = 4,
  /**
   * The completely solid layers within the print. Typically, these are the top and bottom layers of the print, but also apply to any solid internal structures.
   */
  SolidInfill = 5,
  /**
   * The last few solid layers on the top of a print. A greater number of top layers may be used to ensure a smooth finish.
   */
  TopSolidInfill = 6,
  /**
   * A feature that moves the nozzle over the top solid layer after printing it, while extruding a very small amount of material. This gives the top layer a smoother finish.
   */
  Ironing = 7,
  /**
   * When the printer needs to print a flat section between two higher sections, it prints a bridge. The software will often adjust the print speed and cooling for these sections.
   */
  BridgeInfill = 8,
  /**
   * When the printer fills in any small gaps that the other infill and perimeter settings did not cover.
   */
  GapFill = 9,
  /**
   * Similar to "Skirt/Brim," a skirt is a line printed around the object but not connected to it, this helps prime the extruder and establish a smooth flow of filament.
   */
  Skirt = 10,
  /**
   * Material that is printed solely to support overhangs during the print. It is removed post printing.
   */
  SupportMaterial = 11,
  /**
   * The part of the support that directly interfaces with the print. It's often denser or otherwise different from the rest of the support to provide better support and still be easy to remove.
   */
  SupportMaterialInterface = 12,
  /**
   * A tower-like structure printed alongside the main model when using multiple extruders. The extruder moves to the wipe tower to "wipe" and purge the nozzle when switching filaments, ensuring clean color or material changes.
   */
  WipeTower = 13,
  /**
   * Could include various types of extrusion not categorized under the other headings.
   */
  Mixed = 14,
  None = 15,
}

/**
 * Metadata about a GCode file.
 */
export type GCodeMetadata = {
  slicer?: string;
};

/**
 * A commented line in a GCode file, or trailing comment on a GCode instruction.
 */
export type GCodeComment = {
  featureType?: FeatureType;
};

/**
 * A GCode instruction that changes the state of the machine.
 */
export type GCodeInstruction = {
  letter: string;
  value: number;
  parameters: Record<string, number>;
};

/**
 * One line of the parsed GCode file.
 */
export type GCodeParsedResultLine = {
  instruction?: GCodeInstruction;
  comment?: GCodeComment;
};

/**
 * The result of parsing a GCode file.
 */
export type GCodeParsedResult = {
  metadata: GCodeMetadata;
  lines: GCodeParsedResultLine[];
};

type SlicerSettings = {
  feature: string;
  featureTypes: { [key: string]: FeatureType };
};

const supportedSlicers = [
  "Simplify3D",
  "SuperSlicer",
  "Cura",
  "PrusaSlicer",
] as const;

type Slicer = (typeof supportedSlicers)[number] | "unknown";

const slicerStandards: { [key: string]: SlicerSettings } = {
  Simplify3D: {
    feature: "feature",
    featureTypes: {
      skirt: FeatureType.SkirtBrim,
      "outer perimeter": FeatureType.ExternalPerimeter,
      "inner perimeter": FeatureType.Perimeter,
      "gap fill": FeatureType.GapFill,
      infill: FeatureType.InternalInfill,
      "solid layer": FeatureType.SolidInfill,
      bridge: FeatureType.BridgeInfill,
      support: FeatureType.SupportMaterial,
      "dense support": FeatureType.SupportMaterialInterface,
      "prime pillar": FeatureType.WipeTower,
      "ooze shield": FeatureType.None,
      raft: FeatureType.SupportMaterial,
      "internal single extrusion": FeatureType.None,
    },
  },
  SuperSlicer: {
    feature: "TYPE:",
    featureTypes: {
      "skirt/brim": FeatureType.SkirtBrim,
      perimeter: FeatureType.Perimeter,
      "external perimeter": FeatureType.ExternalPerimeter,
      "internal infill": FeatureType.InternalInfill,
      "overhang perimeter": FeatureType.OverhangPerimeter,
      "solid infill": FeatureType.SolidInfill,
      "top solid infill": FeatureType.TopSolidInfill,
      ironing: FeatureType.Ironing,
      "bridge infill": FeatureType.BridgeInfill,
      "gap fill": FeatureType.GapFill,
      skirt: FeatureType.Skirt,
      "support material": FeatureType.SupportMaterial,
      "support material interface": FeatureType.SupportMaterialInterface,
      "wipe tower": FeatureType.WipeTower,
      mixed: FeatureType.Mixed,
      none: FeatureType.None,
      custom: FeatureType.Mixed,
      "internal perimeter": FeatureType.Perimeter,
      "internal bridge infill": FeatureType.BridgeInfill,
    },
  },
  Cura: {
    feature: "TYPE:",
    featureTypes: {
      skirt: FeatureType.SkirtBrim,
      "wall-outer": FeatureType.ExternalPerimeter,
      "wall-inner": FeatureType.Perimeter,
      fill: FeatureType.SolidInfill,
      skin: FeatureType.InternalInfill,
      support: FeatureType.SupportMaterial,
      "support-interface": FeatureType.SupportMaterialInterface,
      "prime-tower": FeatureType.WipeTower,
    },
  },
  PrusaSlicer: {
    feature: "TYPE:",
    featureTypes: {
      "skirt/brim": FeatureType.SkirtBrim,
      perimeter: FeatureType.Perimeter,
      "external perimeter": FeatureType.ExternalPerimeter,
      "internal infill": FeatureType.InternalInfill,
      "overhang perimeter": FeatureType.OverhangPerimeter,
      "solid infill": FeatureType.SolidInfill,
      "top solid infill": FeatureType.TopSolidInfill,
      ironing: FeatureType.Ironing,
      "bridge infill": FeatureType.BridgeInfill,
      "gap fill": FeatureType.GapFill,
      skirt: FeatureType.Skirt,
      "support material": FeatureType.SupportMaterial,
      "support material Interface": FeatureType.SupportMaterialInterface,
      "wipe tower": FeatureType.WipeTower,
      mixed: FeatureType.Mixed,
      custom: FeatureType.Mixed,
      none: FeatureType.None,
    },
  },
};

function strip(s: string, pre: string): string {
  return s.substring(pre.length).trim();
}

function getSlicerName(line: string): Slicer {
  for (const name of supportedSlicers) {
    if (line.includes(name)) {
      return name;
    }
  }
  return "unknown";
}

const GCODE_LINE_REGEX = /^\s*(?:[GgMmTt][0-9]+.*)?(?:;.*)?$/;

/**
 * Parses a GCode string into an array of GCode instructions.
 */
export function parseGCode(
  gcode: string,
  // If supportedCommands is specified, only instructions with commands in this array will be parsed.
  supportedCommands?: string[],
  // If onProgress is specified, it will be called with a number between 0 and 1 representing the progress of the parsing.
  onProgress?: (progress: number) => void
): GCodeParsedResult {
  const resultLines: GCodeParsedResultLine[] = [];

  let slicer: Slicer = "unknown";

  const fileLines = gcode.split("\n");

  const totalLines = fileLines.length;

  for (const line of fileLines) {
    // Skip parsing for lines of the file that aren't loosely in the form of a line G-code
    // These lines are likely Klipper macros and should be ignored
    if (!GCODE_LINE_REGEX.test(line)) {
      continue;
    }

    const commandPart = line.split(";")[0].trim();
    const commentPart = line.split(";")[1]?.trim();

    const resultLine: GCodeParsedResultLine = {};

    if (line === "" && !commentPart) {
      continue;
    }

    if (commentPart && slicer === "unknown") {
      slicer = getSlicerName(commentPart);
    }

    if (commandPart.length > 0) {
      const parts = commandPart.split(" ");

      const instruction: GCodeInstruction = {
        // Get the letter of the command
        letter: parts[0].slice(0, 1),
        value: parseInt(parts[0].slice(1)),
        parameters: {},
      };

      if (
        !supportedCommands ||
        supportedCommands.includes(
          instruction.letter +
            (instruction.letter === "T" ? "" : instruction.value)
        )
      ) {
        const parameters: Record<string, number> = {};

        for (let i = 1; i < parts.length; i++) {
          const param = parts[i];

          if (isNaN(parseFloat(param.substring(1)))) {
            // If parameter has no numeric value, split it into individual letters and assign them a value of 1
            for (const letter of param.split("")) {
              parameters[letter] = 1;
            }
          } else {
            const key = param[0];
            const value = parseFloat(param.substring(1));

            parameters[key] = value;
          }
        }

        instruction.parameters = parameters;

        resultLine.instruction = instruction;
      }
    }

    if (commentPart && slicer !== "unknown") {
      const standardKeys = slicerStandards[slicer];

      if (commentPart.startsWith(standardKeys.feature)) {
        const featureTypeName = strip(commentPart, standardKeys.feature);
        const featureType =
          standardKeys.featureTypes[featureTypeName.toLowerCase()];

        resultLine.comment = { featureType };
      }
    }

    if (resultLine.instruction || resultLine.comment) {
      resultLines.push(resultLine);
    }

    onProgress?.(resultLines.length / totalLines);
  }

  return {
    metadata: slicer ? { slicer } : {},
    lines: resultLines,
  };
}
