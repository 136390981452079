import React, { useRef, useState, useMemo, useCallback } from "react";
import { ThreeEvent, useFrame, useThree } from "@react-three/fiber";
import { OrthographicCamera, Hud } from "@react-three/drei";
import { t } from "@lingui/macro";
import {
  Group,
  Matrix4,
  CanvasTexture,
  Vector2,
  Vector3,
  OrthographicCamera as Cam,
} from "three";

import { Triple } from "_/types";

const CUBE_SIZE = 80;
const CORNER_SIZE = 15;
const EDGE_LENGTH = CUBE_SIZE - CORNER_SIZE * 2;
const EDGE_THICKNESS = 10;
const PADDING = 20;

const OUTLINE_COLOR = "#aaaaaa";
const DEFAULT_COLOR = "#bbbfc7";
const DEFAULT_TEXT_COLOR = "#000000";
const HOVER_COLOR = "#88888f";
const HOVER_TEXT_COLOR = "#f9f9fb";

// Corner mini-cube positions.
const corners: Array<number>[] = [
  [1, 1, 1],
  [1, 1, -1],
  [1, -1, 1],
  [1, -1, -1],
  [-1, 1, 1],
  [-1, 1, -1],
  [-1, -1, 1],
  [-1, -1, -1],
];

// Edge rect positions.
const edges: Triple<number>[] = [
  [0, 1, 1],
  [0, 1, -1],
  [0, -1, 1],
  [0, -1, -1],
  [1, 1, 0],
  [1, 0, 1],
  [1, -1, 0],
  [1, 0, -1],
  [-1, 1, 0],
  [-1, 0, 1],
  [-1, -1, 0],
  [-1, 0, -1],
];

// Helper to construct labeled face materials for the cube.
const FaceMat = ({
  label,
  index,
  hover,
}: {
  label: string;
  index: number;
  hover: boolean;
}) => {
  const gl = useThree((state) => state.gl);

  const texture = useMemo(() => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    if (context === null) {
      const msg = [
        "Browser does not support canvas 2D.",
        "This is required for the NavCube,",
        "and there is no way to resolve this.",
      ].join(" ");
      throw new Error(msg);
    }

    const fontSize = 18;
    const dimension = 128;

    canvas.width = dimension;
    canvas.height = dimension;

    context.font = `${fontSize}px Neufile Grotesk`;
    context.textAlign = "center";
    context.textBaseline = "alphabetic";

    context.fillStyle = hover ? HOVER_COLOR : DEFAULT_COLOR;
    context.strokeStyle = OUTLINE_COLOR;
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.strokeRect(0, 0, canvas.width, canvas.height);

    context.fillStyle = hover ? HOVER_TEXT_COLOR : DEFAULT_TEXT_COLOR;
    context.fillText(label, 64, 64 + fontSize / 2);

    const texture = new CanvasTexture(canvas);

    // Deal with rotations next. The faces are by default not oriented
    // correctly, so we need to apply rotations to some faces to bring
    // them into a correct Z-up orientation.

    // Move texture transform center to the center of the square image.
    texture.center = new Vector2(0.5, 0.5);

    // Right face.
    if (index === 0) {
      texture.rotation = Math.PI / 2;
    }

    // Left face.
    if (index === 1) {
      texture.rotation = -Math.PI / 2;
    }

    // Rear face.
    if (index === 2) {
      texture.rotation = Math.PI;
    }

    // Bottom face.
    if (index === 5) {
      texture.rotation = Math.PI;
    }

    return texture;
  }, [label, index, hover]);

  texture.anisotropy = gl.capabilities.getMaxAnisotropy() || 1;

  return <meshLambertMaterial attach={`material-${index}`} map={texture} />;
};

// Cube base faces.
const Faces = ({ onClick }: { onClick: (dir: Vector3) => void }) => {
  const [hoverFace, setHoverFace] = useState(-1);

  const material = useMemo(() => {
    // Order here is determined by how this is unpacked by threejs when
    // attaching materials to a cube.
    const faceLabels = [
      t`navcube.face.right`,
      t`navcube.face.left`,
      t`navcube.face.rear`,
      t`navcube.face.front`,
      t`navcube.face.top`,
      t`navcube.face.bottom`,
    ];

    return faceLabels.map((label, i) => (
      <FaceMat
        key={i}
        label={label.toUpperCase()}
        index={i}
        hover={hoverFace == i}
      />
    ));
  }, [hoverFace]);

  const hover = (e: ThreeEvent<PointerEvent>) => {
    // Face indices are for triangle faces - 2 per cube face.
    const cubeFace = Math.floor((e.faceIndex ?? 0) / 2);
    setHoverFace(cubeFace);
  };

  const unhover = (e: ThreeEvent<PointerEvent>) => {
    e.stopPropagation();
    setHoverFace(-1);
  };

  const click = (e: ThreeEvent<MouseEvent>) => {
    e.stopPropagation();
    if (e.face) onClick(e.face.normal);
  };

  return (
    <mesh onPointerMove={hover} onPointerOut={unhover} onClick={click}>
      <boxGeometry args={[CUBE_SIZE, CUBE_SIZE, CUBE_SIZE]} />
      {material}
    </mesh>
  );
};

const Corners = ({ onClick }: { onClick: (dir: Vector3) => void }) => {
  const [hoverCorner, setHoverCorner] = useState(-1);

  const cornerCubes = useMemo(
    () =>
      corners.map((c, i) => {
        const pos = c.map((p) => p * (CUBE_SIZE / 2 - CORNER_SIZE / 2));

        const hover = (e: ThreeEvent<PointerEvent>) => {
          e.stopPropagation();
          setHoverCorner(i);
        };

        const unhover = (e: ThreeEvent<PointerEvent>) => {
          e.stopPropagation();
          setHoverCorner(-1);
        };

        const click = (e: ThreeEvent<MouseEvent>) => {
          e.stopPropagation();
          onClick(new Vector3(...c).normalize());
        };

        const hovered = hoverCorner === i;

        return (
          <mesh
            key={i}
            scale={1.001}
            position={new Vector3(...pos)}
            onClick={click}
            onPointerMove={hover}
            onPointerOut={unhover}
          >
            <boxGeometry args={[CORNER_SIZE, CORNER_SIZE, CORNER_SIZE]} />
            <meshLambertMaterial
              color={hovered ? HOVER_COLOR : DEFAULT_COLOR}
              opacity={hovered ? 1 : 0}
              transparent
            />
          </mesh>
        );
      }),
    [onClick, hoverCorner, setHoverCorner]
  );

  return <>{cornerCubes}</>;
};

// Cube edges.
const Edges = ({ onClick }: { onClick: (dir: Vector3) => void }) => {
  const [hoverEdge, setHoverEdge] = useState(-1);

  const edgeCubes = useMemo(
    () =>
      edges.map((edge, i) => {
        const halfCube = CUBE_SIZE / 2;
        const halfEdge = EDGE_THICKNESS / 2;

        // Position the edges along the cube, inset by half of their thickness.
        const position = edge.map((p) => p * (halfCube - halfEdge));

        // Compute the dimensions of each edge by starting with the full edge
        // length, then removing the difference between the edge length and
        // thickness for the edge when the position axes are equal to 1 or -1.
        const dimensions = edge.map((p) => {
          const diff = EDGE_LENGTH - EDGE_THICKNESS;
          return EDGE_LENGTH - Math.abs(p * diff);
        }) as Triple<number>;

        const hover = (e: ThreeEvent<PointerEvent>) => {
          e.stopPropagation();
          setHoverEdge(i);
        };

        const unhover = (e: ThreeEvent<PointerEvent>) => {
          e.stopPropagation();
          setHoverEdge(-1);
        };

        const click = (e: ThreeEvent<MouseEvent>) => {
          e.stopPropagation();
          onClick(new Vector3(...edge));
        };

        const hovered = hoverEdge === i;

        return (
          <mesh
            key={i}
            scale={1.001}
            position={new Vector3(...position)}
            onClick={click}
            onPointerMove={hover}
            onPointerOut={unhover}
          >
            <boxGeometry args={dimensions} />
            <meshLambertMaterial
              color={hovered ? HOVER_COLOR : DEFAULT_COLOR}
              opacity={hovered ? 1 : 0}
              transparent
            />
          </mesh>
        );
      }),
    [onClick, hoverEdge, setHoverEdge]
  );

  return <>{edgeCubes}</>;
};

// Three doesn't seem to export a type for the Controls, so this stub is just to help out.
interface Controls {
  update: () => void;
  target: Vector3;
  enabled: boolean;
}

export const NavCube = () => {
  const size = useThree((state) => state.size);
  const camera = useThree((state) => state.camera);
  const controls = useThree((state) => state.controls as unknown) as Controls;
  const invalidate = useThree((state) => state.invalidate);

  const cubeRef = useRef<Group | null>(null);
  const virtualCam = useRef<Cam>(null);

  // Position of the nav cube in the viewport (2D coordinates).
  const x = size.width / 2 - (CUBE_SIZE + PADDING);
  const y = size.height / 2 - (CUBE_SIZE + PADDING);

  // FIXME: This is using a direct method to set the new camera position
  // without any slerp with a movement animation. This should be modified
  // to smoothly animate the camera orbit around the target using quaternions.
  const onClick = useCallback(
    (direction: Vector3) => {
      const radius = camera.position.distanceTo(controls.target);

      const camPosition = direction
        .clone()
        .normalize()
        .multiplyScalar(radius)
        .add(controls.target);

      camera.position.copy(camPosition);
      camera.lookAt(controls.target);

      controls.update();
      invalidate();
    },
    [controls, camera, invalidate]
  );

  useFrame((state, _delta) => {
    const cubeMat = new Matrix4();
    cubeMat.copy(state.camera.matrix).invert();
    cubeRef.current?.quaternion.setFromRotationMatrix(cubeMat);
  });

  return (
    <Hud>
      <OrthographicCamera
        ref={virtualCam}
        position={[0, 0, CUBE_SIZE]}
        makeDefault
      />
      <group ref={cubeRef} position={[x, y, 0]}>
        <Faces onClick={onClick} />
        <Edges onClick={onClick} />
        <Corners onClick={onClick} />
      </group>
      <ambientLight intensity={2} />
      <pointLight intensity={1_000_000} />
    </Hud>
  );
};
