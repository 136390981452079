import styled from "styled-components";
import { transparentize } from "polished";

export const Wrapper = styled.div<{ $size: number }>`
  border: 1px solid ${(p) => transparentize(0.84, p.theme.color.palette.white)};
  border-radius: 100%;
  overflow: hidden;

  width: ${(p) => p.$size}px;
  height: ${(p) => p.$size}px;
`;

export const Image = styled.div<{ $imageUrl: string }>`
  background-image: url(${(p) => p.$imageUrl});
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
`;
