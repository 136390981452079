/**
 * Settings view page component.
 *
 * This component manages the required data, queries, and mutations, and
 * renders the subcomponents for each settings category.
 *
 * @category pages
 * @module settings-view
 */

import React, { ReactElement, useRef, useMemo, ChangeEvent } from "react";
import { pick, all, has, without, difference, isEmpty, __ } from "ramda";
import { useForm, Controller } from "react-hook-form";
import { t } from "@lingui/macro";
import { useAtomValue, useSetAtom } from "jotai";

import { getLocales } from "_/i18n";
import { localeAtom } from "_/state";
import { Uuid } from "_/types";
import { publicUrls, settingsRoutes, settingsUrls } from "_/routes";

import { UserAvatar } from "_/components/avatar";
import { Button } from "_/components/button";
import { useToasts } from "_/components/toasts";
import { Icon } from "_/components/icon";
import { Table, ColumnDef } from "_/components/table";
import { FormTextField } from "_/components/text-field";
import { Select, Option } from "_/components/select";
import { TabBar, TabForRoute } from "_/components/tab-bar";
import { SubmitCancelButtons, useModal } from "_/components/modal";
import { Route, Link } from "_/components/router";
import { Roles } from "_/components/rbac";

import {
  useCurrentUser,
  useRemoveAvatar,
  useUpdateUser,
  useUploadAvatar,
  User,
  useChangePassword,
  useInviteUser,
  useIsAonUser,
  isAonEmail,
} from "_/data/users";
import { Org, useMemberOrgs } from "_/data/orgs";
import {
  useUserRoles,
  useUpdateUserRoles,
  Role,
  DomainRole,
  ROOT_DOMAIN,
  useIsRootAdmin,
  translateRoleName,
} from "_/data/rbac";

import * as S from "./styled";

const AvatarChangeForm = ({ user }: { user: User }): ReactElement => {
  const fileUploadRef = useRef<HTMLInputElement>(null);
  const uploadAvatar = useUploadAvatar();
  const removeAvatar = useRemoveAvatar();

  async function uploadFiles(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.files?.length !== 1) {
      return;
    }

    const file = e.target.files[0];

    const reader = new FileReader();
    reader.onloadend = () => {
      const result = reader.result;

      const canvas = document.createElement("canvas");
      canvas.width = 512;
      canvas.height = 512;

      const ctx = canvas.getContext("2d");
      if (ctx === null || result == null) {
        throw new Error("Canvas required for avatar resizing.");
      }

      const img = new Image();
      img.onload = () => {
        const cx = img.width / 2;
        const cy = img.height / 2;

        const isPortrait = img.width < img.height;
        const crop = Math.min(img.width, img.height);

        const sx = isPortrait ? 0 : cx - crop / 2;
        const sy = isPortrait ? cy - crop / 2 : 0;

        ctx.drawImage(
          img,
          sx,
          sy,
          crop,
          crop,
          0,
          0,
          canvas.width,
          canvas.height
        );

        canvas.toBlob((blob) => {
          if (blob === null) {
            throw new Error("Could not save avatar image as blob!");
          }

          if (!user) {
            throw new Error("Unreachable!");
          }

          const file = new File([blob], `avatar_${user.id}.png`);

          uploadAvatar.mutate({ id: user.id, file });
        });
      };
      img.src = result.toString();
    };

    reader.readAsDataURL(file);

    // Reset to the default (null/unset) file value.
    e.target.value = e.target.defaultValue;
  }

  const removeButtonText = t`components.settings-view.remove-avatar-button`;

  return (
    <S.AvatarLayout>
      <UserAvatar resource={user} size="large" />
      <S.AvatarActionsContainer>
        <S.ChangeAvatarButton onClick={() => fileUploadRef.current?.click()}>
          <Icon variant="PhotoCamera"></Icon>
          <input
            ref={fileUploadRef}
            id="file-upload"
            type="file"
            onChange={uploadFiles}
            accept="image/png,image/jpg,image/jpeg,image/gif"
            hidden
          />
        </S.ChangeAvatarButton>
        {user.avatarId ? (
          <S.RemoveAvatarButton
            size="small"
            variant="text"
            kind="secondary"
            onClick={() => removeAvatar.mutate({ id: user.id })}
          >
            {removeButtonText}
          </S.RemoveAvatarButton>
        ) : null}
      </S.AvatarActionsContainer>
    </S.AvatarLayout>
  );
};

// Takes the initial array of roles, the updated array of roles and the domainId to which these roles apply
// and returns two arrays of DomainRoles, one with roles to be added and one with roles to be removed.
const getRoleArrayDifferences = (
  before: Role[],
  after: Role[],
  domainId: Uuid
): { added: DomainRole[]; removed: DomainRole[] } => {
  const added = difference(after, before).map((role) => ({
    domainId,
    role,
  }));

  const removed = difference(before, after).map((role) => ({
    domainId,
    role,
  }));

  return { added, removed };
};

export const UserDetailForm = ({
  user,
  adminData,
  onClose,
}: {
  user: User;
  adminData?: {
    org: Org;
    assignableRoles: Role[];
  };
  onClose?: () => void;
}): ReactElement => {
  const defaultLocale = useAtomValue(localeAtom);
  const setLocale = useSetAtom(localeAtom);

  const updateUser = useUpdateUser();

  const updateUserRoles = useUpdateUserRoles();

  const isRootAdmin = useIsRootAdmin();

  const isAonUser = useIsAonUser();

  const userInfoFields = ["name", "email", "phone", "language"] as const;

  const pickFields = pick(userInfoFields);

  const basicRoles = useMemo(
    () => ["member", "organization_admin", "owner"] as Role[],
    []
  );

  const { data: orgDomainRoles } = useUserRoles(
    user.id,
    adminData?.org.domainId,
    true
  );

  const { data: globalDomainRoles } = useUserRoles(user.id, ROOT_DOMAIN, true);

  const orgRoles = orgDomainRoles?.map((dr) => dr.role) ?? [];

  const globalRoles = globalDomainRoles?.map((dr) => dr.role) ?? [];

  // Check if the assigned org-level roles are only "organization_admin" or "member"
  const onlyBasicOrgRoles = orgRoles.every((role) => basicRoles.includes(role));

  // Check if the current user and the user being edited are both on AON3D emails.
  const userAndActorBothAon = isAonUser && isAonEmail(user.email);

  const showMultiSelectForOrgRoles = userAndActorBothAon || !onlyBasicOrgRoles;

  // Determine whether the user is an admin (organization_admin) or just a user (member) of the org.
  // Used for the org role selector in its single-value mode
  const singleOrgRole = orgRoles.includes("organization_admin")
    ? "organization_admin"
    : "member";

  const orgRolesValue = showMultiSelectForOrgRoles ? orgRoles : singleOrgRole;

  const {
    control,
    formState: { isDirty, dirtyFields },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      ...pickFields({
        ...user,
        language: user.language ?? defaultLocale,
      }),
      orgRoles: orgRolesValue,
      globalRoles,
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    const userInfoNeedsUpdate = !isEmpty(pickFields(dirtyFields));

    const orgRolesNeedsUpdate = !!dirtyFields.orgRoles;

    const globalRolesNeedsUpdate = !!dirtyFields.globalRoles;

    if (userInfoNeedsUpdate) {
      const newValues = await updateUser.mutateAsync({
        id: user.id,
        update: pickFields(data),
      });
      reset(pickFields(newValues));
    }

    if (adminData) {
      const domainId = adminData.org.domainId;

      const addRoles: DomainRole[] = [];
      const removeRoles: DomainRole[] = [];

      if (orgRolesNeedsUpdate) {
        const updatedOrgRolesArray: Role[] = Array.isArray(data.orgRoles)
          ? data.orgRoles
          : data.orgRoles === "organization_admin" || data.orgRoles === "owner"
            ? ["member", data.orgRoles]
            : ["member"];

        const { added, removed } = getRoleArrayDifferences(
          orgRoles,
          updatedOrgRolesArray,
          domainId
        );

        addRoles.push(...added);

        removeRoles.push(...removed);
      }

      if (globalRolesNeedsUpdate) {
        const { added, removed } = getRoleArrayDifferences(
          globalRoles,
          data.globalRoles,
          ROOT_DOMAIN
        );

        addRoles.push(...added);

        removeRoles.push(...removed);
      }

      updateUserRoles.mutateAsync({
        userId: user.id,
        update: { add: addRoles, remove: removeRoles },
      });
    }

    onClose?.();
  });

  // Notify the user if the "member" role is not present on the user
  // when displaying the org-level roles in multi-select mode
  const validateOrgRoles = (roles: string | Role[]) => {
    if (showMultiSelectForOrgRoles && !roles.includes("member")) {
      return t`components.settings-view.member-role-required`;
    }
    return true;
  };

  const localeOptions: Option<string>[] = useMemo(
    () =>
      getLocales().map((locale) => {
        return { label: locale.name, value: locale.code };
      }),
    []
  );

  const basicRoleOptions = useMemo(
    () =>
      without(["owner"], basicRoles).map((role) => {
        const translatedRoleName = translateRoleName(role);

        return {
          label: translatedRoleName,
          value: role,
          labelJsx: <S.OrgRoleLabel>{translatedRoleName}</S.OrgRoleLabel>,
        };
      }),
    [basicRoles]
  );

  // Remove "owner" role from roles list role from roles list
  const globalRoleOptions = useMemo(
    () =>
      without(basicRoles, adminData?.assignableRoles ?? []).map((role) => {
        return { label: translateRoleName(role), value: role };
      }),
    [basicRoles, adminData?.assignableRoles]
  );

  // In cases where organization-level roles are displayed as a multi-select,
  // add the global roles as options (in a different color)
  const additionalOrgRoleOptionsForMultiSelect = useMemo(
    () =>
      globalRoleOptions.map((gr) => ({
        ...gr,
        labelJsx: <S.OrgRoleLabel $global>{gr.label}</S.OrgRoleLabel>,
      })),
    [globalRoleOptions]
  );

  const multiSelectOrgRoleOptions = useMemo(
    () => [...basicRoleOptions, ...additionalOrgRoleOptionsForMultiSelect],
    [basicRoleOptions, additionalOrgRoleOptionsForMultiSelect]
  );

  function onCancel() {
    reset(pickFields(user));
    onClose?.();
  }

  return (
    <S.Form onSubmit={onSubmit}>
      <FormTextField
        control={control}
        name="name"
        rules={{ required: t`components.settings-view.name-required` }}
        label={t`common.name`}
      />
      <FormTextField
        control={control}
        name="email"
        rules={{ required: t`components.settings-view.email-required` }}
        label={t`common.email`}
      />
      <FormTextField
        control={control}
        name="phone"
        label={t`common.phone`}
        type="tel"
      />
      <Controller
        control={control}
        name="language"
        render={({ field, fieldState }) => (
          <S.SelectWrapper>
            <Select
              {...field}
              label={t`common.language`}
              options={localeOptions}
              onChange={(val: string) => {
                field.onChange(val);
                setLocale(val);
              }}
              invalid={fieldState.invalid}
              hint={fieldState.error?.message}
              isSearchable={false}
            />
          </S.SelectWrapper>
        )}
      />
      {adminData && (
        <>
          <Controller
            control={control}
            name="orgRoles"
            rules={{ validate: validateOrgRoles }}
            render={({ field, fieldState }) => {
              return (
                <S.SelectWrapper>
                  <Select
                    {...field}
                    label={t`common.role`}
                    options={
                      showMultiSelectForOrgRoles
                        ? multiSelectOrgRoleOptions
                        : basicRoleOptions
                    }
                    onChange={(val: string) => {
                      field.onChange(val);
                    }}
                    invalid={fieldState.invalid}
                    hint={fieldState.error?.message}
                    isSearchable={false}
                    multiple={showMultiSelectForOrgRoles}
                  />
                </S.SelectWrapper>
              );
            }}
          />
          {isRootAdmin && (
            <Controller
              control={control}
              name="globalRoles"
              render={({ field, fieldState }) => {
                return (
                  <S.SelectWrapper>
                    <Select
                      {...field}
                      label={t`common.global-role`}
                      options={globalRoleOptions}
                      onChange={(val: string) => {
                        field.onChange(val);
                      }}
                      invalid={fieldState.invalid}
                      hint={fieldState.error?.message}
                      isSearchable={false}
                      multiple
                    />
                  </S.SelectWrapper>
                );
              }}
            />
          )}
        </>
      )}
      <SubmitCancelButtons disableSubmit={!isDirty} onCancel={onCancel} />
    </S.Form>
  );
};

const PasswordChangeForm = (): ReactElement => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { dirtyFields },
  } = useForm({
    defaultValues: {
      old: "",
      new: { password: "", valid: false },
      confirm: "",
    },
  });
  const { mutate, isLoading } = useChangePassword();
  const [_, addToast] = useToasts();

  const forgotPasswordText = t`component.login.forgot-password`;

  const onSubmit = handleSubmit(async (data) => {
    if (!data.new.valid) {
      addToast({
        kind: "danger",
        title: t`common.error`,
        content: t`common.new-password-too-weak`,
        timeout: 5000,
      });

      return;
    }

    const submitData = {
      old: data.old,
      new: data.new.password,
    };

    if (data.new.password !== data.confirm) {
      addToast({
        kind: "danger",
        title: t`common.error`,
        content: t`common.new-password-confirm-doesnt-match`,
        timeout: 5000,
      });

      return;
    }

    mutate(submitData, {
      onSuccess: () => {
        reset();
        addToast({
          kind: "success",
          title: t`common.password-change-success-toast-title`,
          timeout: 5000,
        });
      },
      onError: () => {
        addToast({
          kind: "danger",
          title: t`common.error`,
          content: t`common.password-change-error-toast-content`,
          timeout: 5000,
        });
      },
    });
  });

  const allFieldsDirty = all(has(__, dirtyFields), ["old", "new", "confirm"]);

  return (
    <S.Form onSubmit={onSubmit}>
      <FormTextField
        control={control}
        name="old"
        rules={{ required: t`common.password-required` }}
        label={t`components.settings-view.old-password-label`}
        type="password"
        autoComplete="current-password"
      />
      <S.ForgotPasswordWrapper>
        <Link to={publicUrls.recovery}>{forgotPasswordText}</Link>
      </S.ForgotPasswordWrapper>
      <FormTextField
        control={control}
        name="new"
        rules={{ required: t`common.password-required` }}
        label={t`common.new-password-label`}
        type="new-password"
        autoComplete="new-password"
      />
      <FormTextField
        control={control}
        name="confirm"
        rules={{ required: t`common.password-required` }}
        label={t`components.settings-view.confirm-new-password-label`}
        type={"password"}
        autoComplete="confirm-password"
      />
      <SubmitCancelButtons
        submitButtonLabel={t`common.change-password-submit`}
        disableSubmit={isLoading && allFieldsDirty}
      />
    </S.Form>
  );
};

export const InviteUserForm = ({
  org,
  onClose,
}: {
  org: Org;
  onClose: () => void;
}): ReactElement => {
  const inviteUser = useInviteUser();
  const defaultValues = { email: "", name: "" };
  const {
    control,
    formState: { isDirty },
    handleSubmit,
  } = useForm({ defaultValues });

  const onSubmit = handleSubmit(async function submit(data) {
    await inviteUser.mutateAsync({
      email: data.email,
      name: data.name,
      org,
    });
    onClose();
  });

  return (
    <form onSubmit={onSubmit}>
      <FormTextField
        control={control}
        name="name"
        rules={{ required: t`components.settings-view.name-required` }}
        label={t`common.name`}
      />
      <FormTextField
        control={control}
        name="email"
        rules={{ required: t`components.settings-view.email-required` }}
        label={t`common.email`}
      />
      <SubmitCancelButtons
        submitButtonLabel={t`components.settings-view.invite`}
        onCancel={onClose}
        disableSubmit={!isDirty}
      />
    </form>
  );
};

const OrganizationsList = ({ user }: { user: User }): ReactElement => {
  const { data: orgs } = useMemberOrgs();
  const { openModal, closeModal, modalData, Modal } = useModal<Org>();

  if (orgs.length === 0) {
    return <span>{t`components.settings-view.no-orgs`}</span>;
  }

  if (!user) {
    throw new Error("Unreachable!");
  }

  const columns: ColumnDef<Org>[] = [
    {
      id: "name",
      header: t`components.settings-view.org-name`,
      cell: ({ row: { original: rowData } }) => (
        <S.OrgNameCell>
          <Link to={settingsUrls.organization(rowData.id)}>{rowData.name}</Link>
        </S.OrgNameCell>
      ),
    },
    {
      id: "roles",
      header: t`components.settings-view.org-roles`,
      cell: ({ row: { original: rowData } }) => (
        <Roles user={user} org={rowData} showGlobalRoles={false} />
      ),
    },
    {
      id: "actions",
      cell: ({ row: { original: rowData } }) => (
        <Button size="small" kind="primary" onClick={() => openModal(rowData)}>
          {t`components.settings-view.invite`}
        </Button>
      ),
    },
  ];

  return (
    <>
      <Table columns={columns} data={orgs} />
      {modalData && (
        <Modal title={t`common.invite-user-to-org ${modalData.name}`}>
          <InviteUserForm org={modalData} onClose={closeModal} />
        </Modal>
      )}
    </>
  );
};

export const SettingsView = (): ReactElement => {
  const { data: user } = useCurrentUser();

  return (
    <S.Wrapper>
      <S.Header>
        <AvatarChangeForm user={user} />
      </S.Header>
      <S.Nav>
        <TabBar>
          <TabForRoute route={settingsUrls.general}>
            {t`components.settings-view.general`}
          </TabForRoute>
          <TabForRoute route={settingsUrls.security}>
            {t`components.settings-view.security`}
          </TabForRoute>
          <TabForRoute route={settingsUrls.organizations}>
            {t`components.settings-view.organizations`}
          </TabForRoute>
        </TabBar>
      </S.Nav>
      <Route path={settingsRoutes.general}>
        <S.Section>
          <S.SectionTitle>{t`components.settings-view.profile-title`}</S.SectionTitle>
          <UserDetailForm user={user} />
        </S.Section>
      </Route>
      <Route path={settingsRoutes.organizations}>
        <S.Section>
          <S.SectionTitle>{t`components.settings-view.organizations-title`}</S.SectionTitle>
          <OrganizationsList user={user} />
        </S.Section>
      </Route>
      <Route path={settingsRoutes.security}>
        <S.Section>
          <S.SectionTitle>{t`components.settings-view.password-change-title`}</S.SectionTitle>
          <PasswordChangeForm />
        </S.Section>
      </Route>
      <Route path={settingsRoutes.organization}>Not yet implemented!</Route>
    </S.Wrapper>
  );
};
