import React, { ReactElement, useState } from "react";
import { Switch, Route } from "_/components/router";
import { t } from "@lingui/macro";

import { routes } from "_/routes";

import { Button } from "_/components/button";
import { Modal } from "_/components/modal";
import { ProjectDetail } from "_/components/project-detail";
import { Icon } from "_/components/icon";
import { H5 } from "_/components/typography";
import { ProjectCreationForm } from "_/components/project-forms";
import { ProjectCards } from "_/components/project-card";

import * as S from "./styled";

/**
 * View for displaying listing of project cards, sorting, and filtering.
 *
 * @category components
 */
export const ProjectsView = (): ReactElement => {
  const [showModal, setShowModal] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  function openModal() {
    setShowModal(true);
  }

  function closeModal() {
    setShowModal(false);
  }

  return (
    <Switch>
      <Route path={routes.project.wildcards.index}>
        {(p) => <ProjectDetail projectId={p.projectId} />}
      </Route>
      <Route path={routes.projects.index}>
        <S.Wrapper>
          <S.SubHeader>
            <H5>{t`common.projects`}</H5>
            <Button onClick={openModal}>
              <Icon variant="Add" />
              {t`common.create`}
            </Button>
          </S.SubHeader>
          <S.ProjectCards>
            <ProjectCards />
          </S.ProjectCards>
          <Modal
            title={t`components.project-view.create-new`}
            open={showModal}
            onClose={isUploading ? undefined : closeModal}
          >
            <ProjectCreationForm
              onComplete={closeModal}
              isUploading={isUploading}
              setIsUploading={setIsUploading}
            />
          </Modal>
        </S.Wrapper>
      </Route>
    </Switch>
  );
};
