import styled, { css } from "styled-components";
import { setLightness, lighten } from "polished";
import { Icon } from "_/components/icon";

import { ButtonKind, ButtonSize, ButtonVariant } from "./types";

type StyleProps = {
  $icon: boolean;
  $round: boolean;
  $kind: ButtonKind;
  $size: ButtonSize;
  $variant: ButtonVariant;
};

const tiny = css<{ $icon: boolean }>`
  padding: ${(p) => (p.$icon ? "4px" : "4px 8px")};
  font-size: 10px;
`;

const small = css<{ $icon: boolean }>`
  padding: ${(p) => (p.$icon ? "6px" : "6px 16px")};
  font-size: 14px;
`;

const medium = css<{ $icon: boolean }>`
  padding: ${(p) => (p.$icon ? "8px" : "8px 24px")};
  font-size: 16px;
`;

const large = css<{ $icon: boolean }>`
  padding: ${(p) => (p.$icon ? "12px" : "12px 30px")};
  font-size: 20px;
`;

// Variant == 'contained' styles.
const contained = css<StyleProps>`
  border: 1px solid ${(p) => p.theme.button[p.$kind].default};
  background-color: ${(p) => p.theme.button[p.$kind].default};
  color: ${(p) => p.theme.button[p.$kind].text};

  &:hover:enabled {
    background-color: ${(p) => p.theme.button[p.$kind].hover};
    border: 1px solid ${(p) => p.theme.button[p.$kind].hover};
  }

  &:active:enabled {
    background-color: ${(p) => p.theme.button[p.$kind].pressed};
    border: 1px solid ${(p) => p.theme.button[p.$kind].pressed};
  }
`;

// Variant == 'hollow' styles.
const hollow = css<StyleProps>`
  border: 1px solid ${(p) => p.theme.button[p.$kind].default};
  color: ${(p) => p.theme.button[p.$kind].default};

  &:hover:enabled {
    border-color: ${(p) => p.theme.button[p.$kind].hover};
    color: ${(p) => p.theme.button[p.$kind].hover};
    background-color: ${(p) => {
      if (p.theme.dark) {
        return setLightness(0.1, p.theme.button[p.$kind].hover);
      } else {
        return setLightness(0.95, p.theme.button[p.$kind].hover);
      }
    }};
  }

  &:disabled {
    background-color: transparent;
  }
`;

// Variant == 'text' styles.
const text = css<StyleProps>`
  color: ${(p) => p.theme.button[p.$kind].default};

  &:hover:enabled {
    color: ${(p) => p.theme.button[p.$kind].hover};
  }

  &:disabled {
    text-decoration: none;
  }
`;

const sizes = { tiny, small, medium, large };
const variants = { contained, hollow, text };

export const Button = styled.button<StyleProps>`
  background-color: transparent;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  cursor: pointer;
  font-weight: 500;
  // border-radius "100px" is just an arbitrary value to achieve "pill" shape
  border-radius: ${(p) => (p.$round ? "100px" : "2px")};
  transition:
    transform 0.05s ease,
    background-color 0.05s ease,
    border-color 0.05s ease;

  &:disabled {
    cursor: not-allowed;
    background-color: ${(p) => p.theme.button[p.$kind].disabled};
    border-color: ${(p) => p.theme.button[p.$kind].disabled};
    color: ${(p) => lighten(0.3, p.theme.button[p.$kind].disabled)};
  }

  &:focus:enabled {
    outline: 1px solid ${(p) => p.theme.button[p.$kind].pressed};
  }

  &:active:enabled {
    transform: scale(1.02);
  }

  ${Icon} {
    // Use relative sizes so that the icon scales with the button s ize.
    font-size: 1.25em;
    margin-right: ${(p) => (p.$icon ? 0 : "0.625em")};
    pointer-events: none;
  }

  ${(p) => sizes[p.$size]}
  ${(p) => variants[p.$variant]}
`;

export const InfoMessageWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: end;
  justify-content: start;
  gap: 8px;
`;

export const HelpIconWrapper = styled.div`
  position: absolute;
  right: 0;
  height: 100%;
  display: flex;
  padding-right: 0.25rem;
`;

export const ButtonGroup = styled.span`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
`;
