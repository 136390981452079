import React, { ReactElement, ReactNode } from "react";
import { t } from "@lingui/macro";

import { ColumnDef, Table } from "_/components/table";

import { Machine, useMachineThermals } from "_/data/machines";

import { formatTemperature } from "_/utils";

import * as S from "./styled";
import { MachineHeatingState } from "./types";

const STABLE_TEMPERATURE_TOLERANCE = 5;

function getMachineHeatingState(
  temperature: number,
  target: number
): MachineHeatingState {
  if (!temperature || !target) {
    return "notAvailable";
  } else if (temperature > target + STABLE_TEMPERATURE_TOLERANCE) {
    return "cooling";
  } else if (temperature < target - STABLE_TEMPERATURE_TOLERANCE) {
    return "heating";
  } else {
    return "atTarget";
  }
}

const TemperatureItemDisplay = ({
  title,
  temperature,
  target,
}: {
  title?: string;
  temperature: number;
  target: number;
}): ReactElement => {
  const infoIcon = (
    <S.Tooltip
      content={`${t`common.current-temp`} / ${t`common.set-temp`}`}
      placement="rightStart"
      delay={100}
      target={<S.TooltipIcon variant="Info" $kind="info" />}
    />
  );

  const heatingState = getMachineHeatingState(temperature, target);

  const stateInfoMap = {
    heating: t`common.heating-up`,
    cooling: t`common.cooling-down`,
    atTarget: t`common.at-target-temp`,
  };

  const stateIconMap = {
    heating: "ArrowCircleUp",
    cooling: "ArrowCircleDown",
    atTarget: "CheckCircle",
  } as const;

  const stateIcon =
    heatingState != "notAvailable" ? (
      <S.Tooltip
        content={stateInfoMap[heatingState]}
        placement="rightStart"
        delay={100}
        target={
          <S.TooltipIcon
            variant={stateIconMap[heatingState]}
            $kind={heatingState}
          />
        }
      />
    ) : null;

  return (
    <S.TemperatureDisplayBlock>
      {title && (
        <S.Title>
          {title} {infoIcon} {stateIcon}
        </S.Title>
      )}
      <S.TemperatureRatioDisplay>
        <S.CurrentTemperature $state={heatingState}>
          {formatTemperature({ value: temperature, kind: "current" })}
        </S.CurrentTemperature>
        <span>/</span>
        <span>{formatTemperature({ value: target, kind: "target" })}</span>
      </S.TemperatureRatioDisplay>
    </S.TemperatureDisplayBlock>
  );
};

export const MachineTemperatureDisplay = ({
  machine,
  view,
  className,
}: {
  machine: Machine;
  view: "wide" | "table";
  className?: string;
}): ReactNode => {
  const { data: thermals } = useMachineThermals(machine);

  if (!thermals) return null;

  const {
    bedTemperature,
    bedTarget,
    chamberTemperature,
    chamberTarget,
    t0Temperature,
    t0Target,
    t1Temperature,
    t1Target,
  } = thermals;

  const tableViewModel = [
    {
      label: t`common.bed`,
      temperature: bedTemperature[bedTemperature.length - 1],
      target: bedTarget[bedTarget.length - 1],
    },
    {
      label: t`common.chamber`,
      temperature: chamberTemperature[chamberTemperature.length - 1],
      target: chamberTarget[chamberTarget.length - 1],
    },
    {
      label: t`common.left-tool`,
      temperature: t0Temperature[t0Temperature.length - 1],
      target: t0Target[t0Target.length - 1],
    },
    {
      label: t`common.right-tool`,
      temperature: t1Temperature[t1Temperature.length - 1],
      target: t1Target[t1Target.length - 1],
    },
  ];

  const columns: ColumnDef<(typeof tableViewModel)[number]>[] = [
    {
      header: t`common.sensor`,
      cell: ({ row: { original: rowData } }) => rowData.label,
    },
    {
      header: t`common.current-and-target-temp`,
      cell: ({ row: { original: rowData } }) => (
        <TemperatureItemDisplay
          temperature={rowData.temperature}
          target={rowData.target}
        />
      ),
    },
  ];

  if (view === "wide") {
    return (
      <S.Wrapper className={className}>
        {tableViewModel.map((item) => (
          <TemperatureItemDisplay
            key={item.label}
            title={item.label}
            temperature={item.temperature}
            target={item.target}
          />
        ))}
      </S.Wrapper>
    );
  } else {
    return <Table columns={columns} data={tableViewModel} />;
  }
};
