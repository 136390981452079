import styled from "styled-components";
import { propOr } from "ramda";

import { Icon } from "_/components/icon";
import { Tooltip as _Tooltip } from "_/components/tooltip";

import { MachineHeatingState } from "./types";

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-items: stretch;
  justify-content: space-between;
  align-items: center;
  background-color: ${(p) => p.theme.background.secondary};
  border-radius: 8px;
  padding: 1.25rem;
`;

export const TemperatureRatioDisplay = styled.span`
  display: flex;
  gap: 16px;
  font-size: 20px;
  align-items: center;
`;

export const CurrentTemperature = styled.span<{
  $state: MachineHeatingState;
}>`
  font-size: 24px;
  color: ${(p) =>
    propOr("inherit", p.$state, {
      heating: p.theme.color.palette.tomatoRed,
      cooling: p.theme.color.palette.electricBlue,
      atTarget: p.theme.color.palette.kellyGreen,
    })};
`;

export const TemperatureDisplayBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.span`
  font-size: 12px;
  color: ${(p) => p.theme.text.muted};
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const TooltipIcon = styled(Icon)<{
  $kind: "heating" | "cooling" | "atTarget" | "info";
}>`
  font-size: 20px;
  color: ${(p) =>
    ({
      heating: p.theme.color.palette.tomatoRed,
      cooling: p.theme.color.palette.electricBlue,
      atTarget: p.theme.color.palette.kellyGreen,
      info: p.theme.text.muted,
    })[p.$kind]};
`;

export const Tooltip = styled(_Tooltip)`
  display: inline;
`;
