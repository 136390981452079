import React, { ReactElement } from "react";

export const Support = (): ReactElement => {
  return (
    <svg
      width="1em"
      height="1em"
      stroke="currentColor"
      strokeWidth="0.075em"
      viewBox="0 0 72 72"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M60.9953 36C60.9953 49.5758 49.8005 60.5809 35.9906 60.5809C22.1809 60.5809 10.9859 49.5758 10.9859 36C10.9859 22.4244 22.1809 11.4191 35.9906 11.4191C49.8005 11.4191 60.9953 22.4244 60.9953 36Z" />
      <path d="M28.4386 27.8712C29.0446 26.2722 30.2417 24.9239 31.8168 24.0651C33.3922 23.2059 35.2444 22.8921 37.0455 23.1787C38.8458 23.4653 40.4796 24.3337 41.6564 25.6307C42.8331 26.9274 43.4768 28.5687 43.4744 30.2636C43.4744 35.0487 37.9418 36.3087 36.9426 38.1064C36.8367 38.2969 36.7477 38.4531 36.6716 38.5859C35.9778 39.7978 35.9433 41.2479 35.9433 42.6444V42.7179" />
      <circle cx="35.9565" cy="49.1043" r="1.25" fill="currentColor" />
    </svg>
  );
};
