import { Vector3 } from "three";
import { GCodeInstruction, GCodeParsedResult } from "./gcode-parser";
import { GCodePrint } from "./gcode-print";
import { FeatureType } from "./gcode-parser";

/**
 * Function that handles a single GCode instruction.
 */
type GCodeInstructionHandler = (instruction: GCodeInstruction) => void;

/**
 * Map from a GCode instruction number to a function that handles that instruction.
 */
type GCodeInstructionHandlerMap = Record<number, GCodeInstructionHandler>;

/**
 * Map from a GCode command letter to a map of instruction numbers to instruction handlers.
 */
type GCodeCommandMap = {
  G: GCodeInstructionHandlerMap;
  M: GCodeInstructionHandlerMap;
  T: GCodeInstructionHandler;
};

/**
 * Class representing a single toolhead. Keeps track of its position and extrusion coordinates.
 */
class Toolhead {
  public position: Vector3;
  public extrusion: number;

  constructor() {
    this.position = new Vector3();
    this.extrusion = 0;
  }
}

/**
 * Type for the state of the GCodeMachine.
 */
type MachineState = {
  toolheads: Toolhead[];
  print: GCodePrint;
  positionMode: "absolute" | "relative";
  extrusionMode: "absolute" | "relative";
  activeToolhead: number;
  currentFeatureType: FeatureType;
};

/**
 * Class that represents a virtual machine that executes GCode instructions.
 */
export class GCodeMachine {
  private state: MachineState;
  private commands: GCodeCommandMap;

  constructor() {
    this.state = {
      toolheads: [],
      print: new GCodePrint(),
      positionMode: "absolute",
      extrusionMode: "absolute",
      activeToolhead: 0,
      currentFeatureType: FeatureType.None,
    };

    this.commands = {
      G: {
        0: this.executeG1,
        1: this.executeG1,
        90: this.executeG90,
        91: this.executeG91,
        92: this.executeG92,
      },
      M: {
        82: this.executeM82,
        83: this.executeM83,
      },
      T: this.executeT,
    };
  }

  /**
   * Returns a list of supported G-code commands.
   */
  public getCommands(): string[] {
    const commands: string[] = [];

    for (const key of Object.keys(this.commands)) {
      if (key === "T") {
        commands.push(key);
      } else {
        for (const subKey in this.commands[key]) {
          commands.push(key + subKey);
        }
      }
    }
    return commands;
  }

  /**
   * Resets the state of the machine.
   */
  private resetState = () => {
    this.state = {
      toolheads: [],
      print: new GCodePrint(),
      positionMode: "absolute",
      extrusionMode: "absolute",
      activeToolhead: 0,
      currentFeatureType: FeatureType.None,
    };

    this.state.toolheads.push(new Toolhead());
  };

  /**
   * G1 is a linear movement command.
   * G1 X<position> Y<position> Z<position> E<position>
   * X, Y, Z, and E are optional parameters.
   * X<position> An absolute or relative coordinate on the X axis.
   * Y<position> An absolute or relative coordinate on the Y axis.
   * Z<position> An absolute or relative coordinate on the Z axis.
   * E<position> An absolute or relative coordinate on the E (extruder) axis (in current units).
   * The E axis describes the position of the filament in terms of input to the extruder feeder.
   */
  private executeG1 = (instruction: GCodeInstruction) => {
    const toolhead = this.state.toolheads[this.state.activeToolhead];
    const params = instruction.parameters;

    const startPosition = toolhead.position.clone();
    const endPosition = toolhead.position.clone();

    const extrusionStart = toolhead.extrusion;

    const absolutePosition = this.state.positionMode === "absolute";
    const absoluteExtrusion = this.state.extrusionMode === "absolute";

    let isExtruding = false;

    if ("X" in params) {
      endPosition.x = absolutePosition ? params.X : startPosition.x + params.X;
    }
    if ("Y" in params) {
      endPosition.y = absolutePosition ? params.Y : startPosition.y + params.Y;
    }
    if ("Z" in params) {
      endPosition.z = absolutePosition ? params.Z : startPosition.z + params.Z;
    }
    if ("E" in params) {
      toolhead.extrusion = absoluteExtrusion
        ? params.E
        : extrusionStart + params.E;

      isExtruding = absoluteExtrusion
        ? toolhead.extrusion > 0
        : toolhead.extrusion > extrusionStart;
    }

    const isMoving = startPosition.distanceTo(endPosition) > 0;

    // If we are extruding and the toolhead has moved, add a new segment to the print.
    if (isExtruding && isMoving) {
      // Determine if we are extruding at a new z position. If so, start a new layer.
      const currentLayer = this.state.print.getCurrentLayer();
      if (!currentLayer || currentLayer.z !== endPosition.z) {
        this.state.print.startNewLayer(endPosition.z);
      }
      // Add a new extrusion segment to the current layer.
      this.state.print.addExtrusionSegment(
        startPosition,
        endPosition,
        this.state.currentFeatureType,
        this.state.activeToolhead
      );
    } else {
      this.state.print.addToolheadMovementSegment(startPosition, endPosition);
    }
    toolhead.position.copy(endPosition);
  };

  /**
   * G90: Set to Absolute Positioning & Extrusion
   */
  private executeG90 = () => {
    this.state.positionMode = "absolute";
    this.state.extrusionMode = "absolute";
  };

  /**
   * G91: Set to Relative Positioning % Extrusion
   */
  private executeG91 = () => {
    this.state.positionMode = "relative";
    this.state.extrusionMode = "relative";
  };

  /**
   * G92: Set Position
   * This command is used to set the current position of the axes to any arbitrary value.
   * X<position> An absolute or relative coordinate on the X axis.
   * Y<position> An absolute or relative coordinate on the Y axis.
   * Z<position> An absolute or relative coordinate on the Z axis.
   * E<position> An absolute or relative coordinate on the E (extruder) axis (in current units).
   */
  private executeG92 = (instruction: GCodeInstruction) => {
    const toolhead = this.state.toolheads[this.state.activeToolhead];
    const params = instruction.parameters;
    if ("X" in params) {
      toolhead.position.x = params.X;
    }
    if ("Y" in params) {
      toolhead.position.y = params.Y;
    }
    if ("Z" in params) {
      toolhead.position.z = params.Z;
    }
    if ("E" in params) {
      toolhead.extrusion = params.E;
    }
  };

  /**
   * M82: Set E codes absolute (default)
   * This command is used to override G91 and put the E axis into absolute mode independent of the other axes.
   * G90 and G91 clear this mode.
   */
  private executeM82 = () => {
    this.state.extrusionMode = "absolute";
  };

  /**
   * M83: Set E codes relative while in Absolute Coordinates (E0 remains absolute)
   * This command is used to override G90 and put the E axis into relative mode independent of the other axes.
   * G90 and G91 clear this mode.
   */
  private executeM83 = () => {
    this.state.extrusionMode = "relative";
  };

  private executeT = (instruction: GCodeInstruction) => {
    const toolIndex = instruction.value;

    if (toolIndex !== undefined) {
      while (this.state.toolheads.length <= toolIndex) {
        this.state.toolheads.push(new Toolhead());
      }

      this.state.activeToolhead = toolIndex;
    } else {
      console.warn(
        `T command without a tool index: ${JSON.stringify(instruction)}`
      );
    }
  };

  /**
   * Execute a list of GCode instructions.
   * Returns an array of GCodePrints, one for each toolhead.
   */
  public executeGCode = (
    parseGCode: GCodeParsedResult,
    onProgress?: (progress: number) => void
  ): GCodePrint => {
    this.resetState();

    const unsupportedCommand = (instruction: GCodeInstruction) => {
      console.warn(
        `Unsupported command: ${instruction.letter + instruction.value}`
      );
    };

    const gCodeLines = parseGCode.lines;

    const totalLines = gCodeLines.length;

    for (const [index, { instruction, comment }] of gCodeLines.entries()) {
      if (instruction) {
        if (instruction.letter in this.commands) {
          const letter = instruction.letter;

          if (letter === "T") {
            this.commands["T"](instruction);
          } else if (letter === "G" || letter === "M") {
            const commandHandlerMap = this.commands[letter];

            const instructionHandler = commandHandlerMap[instruction.value];

            if (instructionHandler) {
              instructionHandler(instruction);
            } else {
              unsupportedCommand(instruction);
            }
          } else {
            unsupportedCommand(instruction);
          }
        } else {
          unsupportedCommand(instruction);
        }
      }
      if (comment && comment.featureType) {
        // set state of current feature type to label extrusions following this line of the file
        this.state.currentFeatureType = comment.featureType;
      }

      if (onProgress) {
        onProgress(index / totalLines);
      }
    }
    return this.state.print;
  };
}
