/**
 * Tab bar controls
 */

import React, { ReactNode } from "react";
import { Link, useRoute } from "_/components/router";

import * as S from "./styled";

const Divider = S.Divider;
const TabBar = S.TabBar;

export { Divider, TabBar };

interface TabProps {
  active?: boolean;
  disabled?: boolean;
  to: string;
  children: ReactNode;
}

export const Tab = ({ to, active, disabled, children }: TabProps) => {
  const tab = (
    <S.Tab $active={!!active} $disabled={!!disabled}>
      {children}
    </S.Tab>
  );

  return disabled ? tab : <Link to={to}>{tab}</Link>;
};

type TabForRouteProps = Omit<TabProps, "active" | "to"> & {
  route: string;
  url?: string;
};

/*
Tab component for navigation items in tab bar.
*/
export const TabForRoute = (props: TabForRouteProps) => {
  const { route, url, ...rest } = props;
  const [match, _] = useRoute(route);

  return <Tab to={url ?? route} active={match} {...rest} />;
};
