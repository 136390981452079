import React, { ReactElement } from "react";

export const Machines = (): ReactElement => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 72 72"
      fill="none"
      stroke="currentColor"
      strokeWidth="0.075em"
      strokeLinecap="round"
      strokeLinejoin="round"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19.6456 9.5H53.2456C54.5656 9.5 55.6256 10.57 55.6256 11.88V63.48C55.6256 63.48 55.6256 63.5 55.6056 63.5H17.2756C17.2756 63.5 17.2556 63.5 17.2556 63.48V11.89C17.2556 10.57 18.3256 9.51 19.6356 9.51L19.6456 9.5Z" />
      <path d="M17.2556 17.9301H55.6256" />
      <path d="M17.2556 41.61H55.7256" />
      <path d="M46.9356 17.9301V32.6101L49.7556 33.7801V41.6101" />
      <path d="M24.3056 17.9301V41.6101" />
      <path d="M42.0256 49.9301H30.8256C30.6986 49.9301 30.5956 50.033 30.5956 50.1601V56.6601C30.5956 56.7871 30.6986 56.8901 30.8256 56.8901H42.0256C42.1527 56.8901 42.2556 56.7871 42.2556 56.6601V50.1601C42.2556 50.033 42.1527 49.9301 42.0256 49.9301Z" />
    </svg>
  );
};
