import React, {
  ReactElement,
  InputHTMLAttributes,
  Ref,
  ReactNode,
  ChangeEvent,
  forwardRef,
} from "react";

import * as S from "./styled";

type RadioButtonProps = InputHTMLAttributes<HTMLInputElement> & {
  /**
   * Label to be displayed when used with `RadioGroup`. If no label is
   * set, the value is used instead.
   */
  label?: ReactNode;
};

export const RadioButton = (props: RadioButtonProps): ReactElement => {
  if (!props.name) {
    console.warn("RadioButton component being used without a `name` prop.");
  }

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) {
      props.onChange(e);
    }
  };

  return <S.Radio {...props} type="radio" onChange={onChange} />;
};

type RadioGroupProps<T extends string> = {
  /** Name used for the form input field. */
  name: string;
  /** Value used for controlled component. */
  value?: T;
  /** When set, rendered horizontally. */
  row?: boolean;
  /** When set, labels come after buttons. */
  politeLabels?: boolean;
  /** Change handler fired when radio button clicked. */
  onChange: (value: T) => void;
  /** RadioButton child components. */
  children: ReactElement<RadioButtonProps>[];
};

const _RadioGroup = <T extends string>(
  { row = false, politeLabels = true, ...props }: RadioGroupProps<T>,
  ref: Ref<HTMLDivElement>
): ReactElement => {
  // Apply the name to each of the children radio elements so that they
  // toggle the others off.
  const children = React.Children.map(props.children, (child) => {
    const label = <span>{child.props.label ?? child.props.value}</span>;

    return (
      <S.Label $polite={politeLabels}>
        {!politeLabels ? label : null}
        {React.cloneElement(child, {
          name: props.name,
          checked: child.props.value == props.value,
          onChange: (e: ChangeEvent<HTMLInputElement>) => {
            props.onChange(e.target.value as T);
          },
        })}
        {politeLabels ? label : null}
      </S.Label>
    );
  });

  return (
    <S.GroupWrapper ref={ref} $row={row}>
      {children}
    </S.GroupWrapper>
  );
};

export const RadioGroup = forwardRef(_RadioGroup);
