import React, { ReactNode } from "react";
import { t } from "@lingui/macro";
import { ROOT_DOMAIN, translateRoleName, useUserRoles } from "_/data/rbac";

import * as S from "./styled";
import { Org } from "_/data/orgs";
import { User } from "_/data/users";

export function Roles({
  user,
  org,
  showGlobalRoles,
}: {
  user: User;
  org: Org;
  showGlobalRoles: boolean;
}): ReactNode {
  const { data: orgDomainRoles } = useUserRoles(user.id, org.domainId, true);
  const { data: rootDomainRoles } = useUserRoles(user.id, ROOT_DOMAIN, true);

  if (orgDomainRoles === undefined || rootDomainRoles === undefined) {
    return null;
  }

  const localRolesTranslated = orgDomainRoles
    .filter(
      (dr) =>
        // If `organization_admin` role exists, do not display the `member` role
        !(
          dr.role === "member" &&
          orgDomainRoles.some((dr) =>
            ["organization_admin", "owner"].includes(dr.role)
          )
        )
    )
    .map((dr) => translateRoleName(dr.role));

  const globalRolesTranslated = showGlobalRoles
    ? rootDomainRoles.map(
        (dr) => `${translateRoleName(dr.role)} (${t`common.global`})`
      )
    : [];

  return (
    <S.RolesContainer>
      {[...localRolesTranslated, ...globalRolesTranslated].map(
        (role, i, arr) => (
          <S.RoleItem key={i}>
            {role}
            {i < arr.length - 1 ? "," : ""}
          </S.RoleItem>
        )
      )}
    </S.RolesContainer>
  );
}
