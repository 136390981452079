import styled from "styled-components";

import { Button } from "_/components/button";
import { transparentize } from "polished";

export const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: ${(props) => props.theme.background.primary};
  border-radius: 8px;
  transition: all 0.3s;
  @media (max-width: 510px) {
    border-radius: 0;
  }
  width: 100%;
  max-width: 440px;
  display: flex;
  flex-direction: column;
  padding: 4rem 3rem 6rem 3rem;
  box-shadow:
    0 0 4px ${(p) => transparentize(0.7, p.theme.overlayMenu.border)},
    0 2px 12px ${(p) => transparentize(0.7, p.theme.border.default)};
`;

export const LoginHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 3rem;
`;

export const Title = styled.h1`
  padding: 0;
  margin: 0;
  font-size: 62px;
  color: ${(p) => p.theme.text.default};
  font-weight: 200;
`;

export const ErrorSpan = styled.span`
  color: ${(p) => p.theme.color.brand.red};
  display: block;

  // Zero-width character inserted to prevent the span from collapsing height.
  &::before {
    content: "\u200b";
  }
`;

export const LoginButton = styled(Button)`
  margin: 16px 0 16px;
  width: 100%;
  height: 2.375rem;
`;

export const LoadAnimation = styled.span`
  display: flex;
  align-items: center;
  height: 12px;

  div {
    margin: 0 4px;
    width: 12px;
    height: 12px;
    background-color: white;
    border-radius: 100%;
    animation-name: wave;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }

  div:nth-of-type(2) {
    animation-delay: 0.5s;
  }

  div:nth-of-type(3) {
    animation-delay: 1s;
  }

  div:nth-of-type(4) {
    animation-delay: 1.5s;
  }

  @keyframes wave {
    0% {
      width: 12px;
      height: 12px;
    }

    100% {
      width: 4px;
      height: 4px;
    }
  }
`;

export const ExtraLinks = styled.p`
  margin: 0;
  display: flex;
  justify-content: space-between;

  a {
    color: ${(p) => p.theme.text.muted};
    text-decoration: underline;
  }

  a:hover {
    color: ${(p) => p.theme.text.default};
  }
`;
