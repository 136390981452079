import styled from "styled-components";

export const Icon = styled.span`
  display: flex;
  color: inherit;
  font-size: inherit;

  svg {
    font-size: inherit;
  }
`;
