import React, { ReactElement, useEffect, useState } from "react";
import {
  NeverUnderlineSpace,
  TextLink,
  useLocation,
  useRoute,
} from "_/components/router";
import { t } from "@lingui/macro";
import { atom, useAtom, PrimitiveAtom } from "jotai";
import { assocPath, pipe, any, equals } from "ramda";

import {
  Project,
  Revision,
  Simulation,
  useProjectSimulations,
  useReviseProject,
  useRevisionResources,
  useSimCreatedRevision,
} from "_/data/projects";
import { blobDownloadURL, useBlobContents } from "_/data/blob";
import { useCurrentUser } from "_/data/users";

import {
  viewAtom,
  ViewMode,
  SimViewControls,
  SimInfo,
  SimViewData,
  SimResultSource,
} from "_/state/viewer";

import { Slider } from "_/components/slider";
import { RadioButton, RadioGroup } from "_/components/radio";
import { LabeledSlider } from "_/components/viewer-controls/sliders";
import { ProgressBar } from "_/components/progress-bar";
import { ViewerOverlayNotice } from "_/components/modal";
import { dateTimeString } from "_/components/date-time";
import { projectRoutes, projectUrls } from "_/routes";
import { useToasts } from "_/components/toasts";

import { Uuid, Tuple } from "_/types";

import { SimOutput, parseSimResultBinary } from "./parseBin";
import * as S from "./styled";
import { Icon } from "_/components/icon";

// Compute the ranges for a sim result's data
function getSimResultInfo(sim: SimOutput): SimInfo {
  const { xyzPositions, layerWelding, previousLayerTemperature } = sim;
  let minZ = Infinity;
  let maxZ = -Infinity;
  let minLayerWelding = Infinity;
  let maxLayerWelding = -Infinity;
  let minPreviousLayerTemperature = Infinity;
  let maxPreviousLayerTemperature = -Infinity;

  for (let i = 0; i < xyzPositions.length; i += 3) {
    const z = xyzPositions[i + 2];
    minZ = Math.min(minZ, z);
    maxZ = Math.max(maxZ, z);
  }

  for (let i = 0; i < layerWelding.length; i++) {
    const welding = layerWelding[i];

    // 0 denotes invalid data, so it is ignored when computing the range
    const nonZeroWelding = welding > 0;
    minLayerWelding = Math.min(
      minLayerWelding,
      nonZeroWelding ? welding : Infinity
    );
    maxLayerWelding = Math.max(
      maxLayerWelding,
      nonZeroWelding ? welding : -Infinity
    );
  }

  for (let i = 0; i < previousLayerTemperature.length; i++) {
    const temp = previousLayerTemperature[i];

    // 0 denotes invalid data, so it is ignored when computing the range
    const nonZeroTemp = temp > 0;
    minPreviousLayerTemperature = Math.min(
      minPreviousLayerTemperature,
      nonZeroTemp ? temp : Infinity
    );
    maxPreviousLayerTemperature = Math.max(
      maxPreviousLayerTemperature,
      nonZeroTemp ? temp : -Infinity
    );
  }

  return {
    zRange: [minZ, maxZ],
    layerWelding: [minLayerWelding, maxLayerWelding],
    previousLayerTemperature: [
      minPreviousLayerTemperature,
      maxPreviousLayerTemperature,
    ],
  };
}

// Parse the sim result blob and return the data + metadata in the forms needed for the viewer and controls
async function unpackSimResultBuffer(simBinary: ArrayBuffer): Promise<{
  simResultInfo: SimInfo;
  simResultSource: SimResultSource;
} | null> {
  const parsedSimData = parseSimResultBinary(simBinary);

  // Return null if parsing fails
  if (!parsedSimData) {
    return null;
  }

  const simResultInfo = getSimResultInfo(parsedSimData);
  const simResultSource: SimResultSource = {
    positions: parsedSimData.xyzPositions,
    layerWelding: parsedSimData.layerWelding,
    previousLayerTemperature: parsedSimData.previousLayerTemperature,
  };

  return { simResultInfo, simResultSource };
}

type SimControlsProps = {
  project: Project;

  simulation: Simulation;

  /** Jotai atom to access the control state for the sim visualization. */
  controlsAtom: PrimitiveAtom<SimViewControls>;
};

/**
 * Renders controls for the sim point cloud.
 */
const SimControls = ({
  project,
  simulation,
  controlsAtom,
}: SimControlsProps): ReactElement => {
  const [controls, setControls] = useAtom(controlsAtom);
  const [_, addToast] = useToasts();
  const reviseProject = useReviseProject();
  const [_location, setLocation] = useLocation();

  const revision = project.revisions.find(
    (r) => r.id === simulation?.revisionId
  );
  const { data: files = [] } = useRevisionResources(revision);
  const { data: simCreatedRevision } = useSimCreatedRevision(
    project,
    simulation
  );

  const originalGcodeRecord = files.find((f) => f.kind === "gcode");
  const optimizedGcodeRecord = simulation?.optimizedGcode ?? undefined;
  const optimizedBlobRecord = simulation?.optimizedOutput ?? undefined;

  if (!controls) {
    return <></>;
  }
  const { source, snapshot } = controls;

  const zRangeRange = controls.ranges.zRange;

  const zRangeValues = controls.values.zRange;

  const valueRangeRange = controls.ranges[snapshot];

  // Use this as both the valueRange value and the colorRange range
  const valueRangeValues = controls.values[snapshot].valueRange;

  const colorRangeValues = controls.values[snapshot].colorRange;

  const showOptimizedOption = controls.includesOptimized;

  function setSource(value: string) {
    setControls(assocPath(["source"], value));
  }

  function setSnapshot(value: string) {
    setControls(assocPath(["snapshot"], value));
  }

  async function createRevisionFromOptimized() {
    if (!controls.includesOptimized || !optimizedBlobRecord) {
      console.error("Optimized simulation result is unexpectedly missing");
      return; // Async function, so exceptions can't be caught by the caller.
    }
    await reviseProject.mutateAsync({
      projectId: project.id,
      revisionChange: { gcode: optimizedGcodeRecord },
      onSuccess: (newRevision: Revision) => {
        addToast({
          title: t`components.viewer-controls-optimize.revision-number-created ${newRevision.number}`,
          kind: "success",
          timeout: 5_000,
        });

        setLocation(projectUrls.prepare(project.id, newRevision.id));
      },
    });
  }

  const simulationString = t`common.simulation`;
  const originalGcodeString = t`components.viewer-controls.original-gcode`;
  const simulationOptimizedString = t`components.viewer-controls.simulation-optimized`;
  const simulatedMeasuresString = t`components.viewer-controls.simulated-measures`;
  const layerTemperatureString = t`components.viewer-controls.layer-temperature`;
  const layerWeldingString = t`components.viewer-controls.layer-welding`;

  return (
    <>
      <S.LayerControlContainer>
        <S.ControlLabel>{t`common.layer`}</S.ControlLabel>
        <S.LayerSliderContainer>
          <Slider
            min={zRangeRange[0]}
            max={zRangeRange[1]}
            step={0.5}
            value={zRangeValues}
            size="large"
            vertical
            tooltipPlacement="before"
            tooltipText={(values) => values.map((v) => `Z: ${v.toFixed(2)}mm`)}
            onChange={(values) => {
              setControls(
                assocPath(["values", "zRange"], values as Tuple<number>)
              );
            }}
          />
        </S.LayerSliderContainer>
      </S.LayerControlContainer>
      <S.RightSideContainer>
        <S.PointCloudControlContainer>
          <S.ControlsBox>
            {originalGcodeRecord?.id && revision && (
              <S.ControlsBoxSection>
                <S.ControlsBoxTitle>
                  {t`components.viewer-controls.original-gcode`}{" "}
                  <S.FileDownloadLink
                    href={blobDownloadURL(originalGcodeRecord.id)}
                    title={t`common.download-file ${originalGcodeRecord.name}`}
                  >
                    <Icon variant="FileDownload" />
                  </S.FileDownloadLink>
                </S.ControlsBoxTitle>
                <TextLink
                  variant="default"
                  to={projectUrls.prepare(project.id, revision.id)}
                >
                  <span>{t`common.revision`}</span>
                  <NeverUnderlineSpace />
                  <S.RevisionNumber>#{revision.number}</S.RevisionNumber>
                </TextLink>
              </S.ControlsBoxSection>
            )}
            {optimizedGcodeRecord?.id && (
              <S.ControlsBoxSection>
                <S.ControlsBoxTitle>
                  {t`common.optimized-gcode`}
                  <S.FileDownloadLink
                    href={blobDownloadURL(optimizedGcodeRecord.id)}
                    title={t`common.download-file ${optimizedGcodeRecord.name}`}
                  >
                    <Icon variant="FileDownload" />
                  </S.FileDownloadLink>
                </S.ControlsBoxTitle>
                {simCreatedRevision ? (
                  <TextLink
                    variant="default"
                    to={projectUrls.prepare(project.id, simCreatedRevision.id)}
                  >
                    <span>{t`common.revision`}</span>
                    <NeverUnderlineSpace />
                    <S.RevisionNumber>
                      #{simCreatedRevision.number}
                    </S.RevisionNumber>
                  </TextLink>
                ) : (
                  <S.CreateRevisionButton
                    kind="primary"
                    onClick={() => void createRevisionFromOptimized()}
                  >
                    {t`components.controls.optimize.create-revision`}
                  </S.CreateRevisionButton>
                )}
              </S.ControlsBoxSection>
            )}
          </S.ControlsBox>
        </S.PointCloudControlContainer>
        <S.PointCloudControlContainer>
          <S.ControlsBox>
            {simulationString}
            <S.RadioOptions>
              {showOptimizedOption ? (
                <RadioGroup name="source" onChange={setSource} value={source}>
                  <RadioButton label={originalGcodeString} value={"analysis"} />
                  <RadioButton
                    label={simulationOptimizedString}
                    value={"optimized"}
                  />
                </RadioGroup>
              ) : (
                <>{originalGcodeString}</>
              )}
            </S.RadioOptions>
          </S.ControlsBox>
        </S.PointCloudControlContainer>
        <S.PointCloudControlContainer>
          <S.ControlsBox>
            {simulatedMeasuresString}
            <S.RadioOptions>
              <RadioGroup
                name="snapshot"
                onChange={setSnapshot}
                value={snapshot}
              >
                <RadioButton
                  label={layerTemperatureString}
                  value={"previousLayerTemperature"}
                />
                <RadioButton
                  label={layerWeldingString}
                  value={"layerWelding"}
                />
              </RadioGroup>
            </S.RadioOptions>
          </S.ControlsBox>
        </S.PointCloudControlContainer>
        <S.PointCloudControlContainer>
          <LabeledSlider
            key={`${snapshot}-colorRange`}
            label={t`common.color`}
            min={valueRangeValues[0]}
            max={valueRangeValues[1]}
            step={0.1}
            value={colorRangeValues}
            onChange={(values) => {
              setControls(
                assocPath(
                  ["values", snapshot, "colorRange"],
                  values as Tuple<number>
                )
              );
            }}
          />
          <LabeledSlider
            key={`${snapshot}-valueRange`}
            label={t`common.value`}
            min={valueRangeRange[0]}
            max={valueRangeRange[1]}
            step={0.1}
            value={valueRangeValues}
            onChange={(values) => {
              setControls(
                pipe(
                  assocPath(
                    ["values", snapshot, "valueRange"],
                    values as Tuple<number>
                  ),
                  assocPath(
                    ["values", snapshot, "colorRange"],
                    values as Tuple<number>
                  )
                )
              );
            }}
          />
        </S.PointCloudControlContainer>
      </S.RightSideContainer>
    </>
  );
};

type SimResultLoaderProps = {
  project: Project;
  simulation: Simulation;
};

/**
 * Loads the simulation result data.
 */
const SimResultLoader = ({
  project,
  simulation,
}: SimResultLoaderProps): ReactElement => {
  // Loading progress for the sim data
  const [analysisProgress, setAnalysisProgress] = useState(0);
  const [optimizedProgress, setOptimizedProgress] = useState(0);

  const [_view, setView] = useAtom(viewAtom);

  const [controlsAtom, setControlsAtom] =
    useState<PrimitiveAtom<SimViewControls>>();

  const [parseFailed, setParseFailed] = useState(false);

  const {
    analysisOutput: analysisBlobRecord,
    optimizedOutput: optimizedBlobRecord,
  } = simulation;

  const {
    data: analysisBuffer,
    error: analysisLoadingError,
    isFetched: analysisFetched,
  } = useBlobContents(
    analysisBlobRecord ?? undefined,
    setAnalysisProgress,
    true
  );

  const {
    data: optimizedBuffer,
    error: optimizedLoadingError,
    isFetched: optimizedFetched,
  } = useBlobContents(
    optimizedBlobRecord ?? undefined,
    setOptimizedProgress,
    true
  );

  useEffect(() => {
    if (analysisFetched) {
      setAnalysisProgress(1);
    }
  }, [analysisFetched]);

  useEffect(() => {
    if (optimizedFetched) {
      setOptimizedProgress(1);
    }
  }, [optimizedFetched]);

  const loadingError = !!analysisLoadingError || !!optimizedLoadingError;

  useEffect(() => {
    // Ensure the expected blobs are loaded before proceeding
    if (!analysisBuffer || (optimizedBlobRecord && !optimizedBuffer)) {
      return;
    }

    (async () => {
      const analysisUnpacked = await unpackSimResultBuffer(analysisBuffer);

      // Return early if parsing fails
      if (!analysisUnpacked) {
        setParseFailed(true);
        return;
      }

      const {
        simResultInfo: analysisInfo,
        simResultSource: analysisResultSource,
      } = analysisUnpacked;

      let optimizedResultSource: SimResultSource | undefined;

      const aggregateSimInfo = analysisInfo;

      if (optimizedBuffer) {
        const optimizedUnpacked = await unpackSimResultBuffer(optimizedBuffer);

        if (optimizedUnpacked) {
          const optimizedInfo = optimizedUnpacked.simResultInfo;
          optimizedResultSource = optimizedUnpacked.simResultSource;

          const aggregateMinMax = (
            a: Tuple<number>,
            b: Tuple<number>
          ): Tuple<number> => {
            return [Math.min(a[0], b[0]), Math.max(a[1], b[1])];
          };

          // Aggregate the ranges for the analysis and optimized data
          const aggregateLayerWelding = aggregateMinMax(
            analysisInfo.layerWelding,
            optimizedInfo.layerWelding
          );

          const aggregatePreviousLayerTemperature = aggregateMinMax(
            analysisInfo.previousLayerTemperature,
            optimizedInfo.previousLayerTemperature
          );

          aggregateSimInfo.layerWelding = aggregateLayerWelding;
          aggregateSimInfo.previousLayerTemperature =
            aggregatePreviousLayerTemperature;
        }
      }

      const simViewData: SimViewData = {
        analysis: analysisResultSource,
        optimized: optimizedResultSource,
      };

      const cAtom = atom<SimViewControls>({
        source: "analysis",
        snapshot: "previousLayerTemperature",
        includesOptimized: !!optimizedBuffer,
        ranges: aggregateSimInfo,
        values: {
          zRange: aggregateSimInfo.zRange,
          layerWelding: {
            valueRange: aggregateSimInfo.layerWelding,
            colorRange: aggregateSimInfo.layerWelding,
          },
          previousLayerTemperature: {
            valueRange: aggregateSimInfo.previousLayerTemperature,
            colorRange: aggregateSimInfo.previousLayerTemperature,
          },
        },
      });

      setView({
        mode: ViewMode.Sim,
        id: analysisBlobRecord.id,
        data: simViewData,
        controlsAtom: cAtom,
      });

      setControlsAtom(cAtom);
    })();
  }, [
    analysisBuffer,
    optimizedBlobRecord,
    optimizedBuffer,
    setView,
    setControlsAtom,
    analysisBlobRecord,
  ]);

  if (parseFailed) {
    setView(null);
    return (
      <ViewerOverlayNotice
        title={t`components.controls.optimize.parse-failed-title`}
      >{t`components.controls.optimize.parse-failed-message`}</ViewerOverlayNotice>
    );
  }

  if (!controlsAtom) {
    return (
      <ViewerOverlayNotice
        title={t`components.controls.optimize.loading-sim-data`}
      >
        <S.ProgressWrapper>
          <ProgressBar
            title={t`components.viewer-controls.original-gcode`}
            value={analysisProgress}
          />
          {optimizedBlobRecord && (
            <ProgressBar
              title={t`components.viewer-controls.simulation-optimized`}
              value={optimizedProgress}
            />
          )}
          {loadingError && t`components.controls.loading-error`}
        </S.ProgressWrapper>
      </ViewerOverlayNotice>
    );
  }

  return (
    <SimControls
      project={project}
      simulation={simulation}
      controlsAtom={controlsAtom}
    />
  );
};

type SimDataLoaderProps = {
  /** Project to view. */
  project: Project;

  /**
   * ID of the simulation job to view. If not provided, the latest simulation in
   * the latest revision with a sim will be used.
   */
  simulationId?: Uuid;
};

/**
 * Loads the metadata for simulations on the current revision.
 */
const SimDataLoader = ({
  project,
  simulationId,
}: SimDataLoaderProps): ReactElement | null => {
  const { data: simulations } = useProjectSimulations(project.id);
  const { data: user } = useCurrentUser();

  if (!project?.revisions) return null;

  // Get the latest sim for the latest revision with a sim
  const sim = simulationId
    ? simulations?.find((s) => s.job.id === simulationId)
    : project.revisions
        .map((r) => simulations?.filter((s) => s.revisionId === r.id).at(-1))
        .at(-1);

  if (!sim) {
    return simulations?.length ? (
      <ViewerOverlayNotice
        title={t`components.controls.optimize.no-sims-title`}
      >{t`components.controls.optimize.unrecognized-sim-message`}</ViewerOverlayNotice>
    ) : (
      <ViewerOverlayNotice
        title={t`components.controls.optimize.no-sims-title`}
      >{t`components.controls.optimize.no-sims-project-message`}</ViewerOverlayNotice>
    );
  }

  const date = dateTimeString({
    value: sim.job.createdAt,
    defaultValue: "noDate",
    nowrap: false,
    precision: "minute",
  });

  const requestedByUser = sim.job.input.initiatingUserId === user.id;

  if (sim.job.status !== "completed") {
    if (any(equals(sim.job.status), ["queued", "pending", "started"])) {
      return (
        <ViewerOverlayNotice
          title={t`components.controls.optimize.sim-in-progress-title`}
        >{t`components.controls.optimize.sim-in-progress-message ${date} ${requestedByUser}`}</ViewerOverlayNotice>
      );
    }
    if (sim.job.status === "failed") {
      const errors = sim.job.output?.errors?.map((e) =>
        // If the error is an array, then it has an error code for which there is a localized string.
        // Otherwise, just use the error message string as is.
        Array.isArray(e)
          ? t`components.controls.optimize.error-message ${e[0]}`
          : e
      );

      return (
        <ViewerOverlayNotice
          title={t`components.controls.optimize.sim-failed-title`}
        >
          {t`components.controls.optimize.sim-failed-message`}
          {errors && errors.length > 0 && (
            <S.SimErrorsContainer>
              <p>{t`common.errors` + ":"}</p>
              <ul>
                {errors.map((e, i) => (
                  <li key={i}>{e}</li>
                ))}
              </ul>
            </S.SimErrorsContainer>
          )}
        </ViewerOverlayNotice>
      );
    }
  }

  // if the sim is completed, but there is no analysis output, then there is no sim data to display
  if (!sim.analysisOutput) {
    return (
      <ViewerOverlayNotice
        title={t`components.controls.optimize.no-sim-analysis-title`}
      >{t`components.controls.optimize.no-sim-analysis-message`}</ViewerOverlayNotice>
    );
  }

  return <SimResultLoader project={project} simulation={sim} />;
};

type OptimizeViewerControlPanelProps = {
  /**
   * Project to view.
   */
  project: Project;
};

export const OptimizeViewerControlPanel = ({
  project,
}: OptimizeViewerControlPanelProps): ReactElement => {
  const [_match, params] = useRoute(projectRoutes.optimize);

  const simulationId = params?.simulationId;

  return <SimDataLoader project={project} simulationId={simulationId} />;
};
