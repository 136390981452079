import styled, { css } from "styled-components";

const typographyBase = css`
  font-weight: 400;
  margin: 0;
`;

export const H1 = styled.h1`
  ${typographyBase}

  font-size: 48px;
  letter-spacing: -2px;
`;

export const H2 = styled.h2`
  ${typographyBase}

  font-size: 40px;
  letter-spacing: -0.5px;
`;

export const H3 = styled.h3`
  ${typographyBase}

  font-size: 32px;
  letter-spacing: 0;
`;

export const H4 = styled.h4`
  ${typographyBase}

  font-size: 28px;
  letter-spacing: 0.25px;
`;

export const H5 = styled.h5`
  ${typographyBase}

  font-size: 24px;
  letter-spacing: 0;
`;

export const H6 = styled.h6`
  ${typographyBase}

  font-size: 20px;
  letter-spacing: 0.15px;
`;

export const P = styled.p`
  ${typographyBase}

  font-size: 16px;
  font-weight: 400;
`;

export const Caption = styled.span`
  color: ${(props) => props.theme.text.muted};
  font-size: 12px;
  font-weight: 300;
`;
