import styled from "styled-components";

import { Table as _Table } from "_/components/table";
import { Link as _Link } from "_/components/router";
import { Image as _Image } from "_/components/image";
import { MachineTemperatureDisplay as _MachineTemperatureDisplay } from "_/components/machine-temperature-display";
import * as HomeDashboardS from "_/components/home-dashboard/styled";

export const WidgetTitleContainer = styled(
  HomeDashboardS.HomeWidgetTitleContainer
)`
  min-height: 2.25rem;
`;
export const Widget = HomeDashboardS.HomeWidget;
export const NestedWidgetContainer = HomeDashboardS.NestedWidgetContainer;
export const UseRemainingWidth = HomeDashboardS.UseRemainingWidth;

export const Wrapper = styled.div`
  height: 100%;
`;

export const TemperatureWidget = styled.div`
  display: flex;
  flex-direction: column;

  padding: 1.25rem;
  gap: 1.25rem;

  background-color: ${(p) => p.theme.background.secondary};
  border-radius: 8px;
`;

export const MachineOverviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: ${(p) => p.theme.text.default};
  padding: 2rem;
  gap: 2.25rem;
`;

export const Link = styled(_Link)`
  margin-left: auto;
  color: ${(p) => p.theme.link.default};
`;

export const MachineInfo = styled.div`
  display: flex;
  gap: 3rem;
  margin-top: 1rem;
`;

export const MachineInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  gap: 0.375rem;
`;

export const MachineInfoLabel = styled.label`
  color: ${(p) => p.theme.text.muted};
`;

export const MachineInfoValue = styled.span`
  color: ${(p) => p.theme.text.default};
`;

export const LastMachineImage = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  height: 200px;
  align-items: center;
  color: ${(p) => p.theme.text.muted};
`;

export const Image = styled(_Image)`
  width: 100%;
  height: 100%;
  aspect-ratio: 4 / 3;
  border-radius: 8px;
`;

export const MachineOverviewInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-size: 0.875rem;
`;

export const MachineOverviewLabel = styled.label`
  color: ${(p) => p.theme.text.muted};
`;

export const MachineTemperatureDisplay = styled(_MachineTemperatureDisplay)`
  height: calc(200px - 1.25rem);
  align-content: center;
`;
