import React, { ReactElement, HTMLAttributes } from "react";

import { IconVariant } from "_/components/icon";

import * as S from "./styled";

export type BadgeKind =
  | "muted"
  | "info"
  | "error"
  | "warning"
  | "success"
  | "highlight";

type BadgeProps = HTMLAttributes<HTMLDivElement> & {
  kind?: BadgeKind;
  /**
   * Name of icon variant to display to the left of the label;
   * by default, the "Circle" icon variant will be displayed.
   */
  icon?: IconVariant;
};

export const Badge = ({
  kind = "muted",
  icon = "Circle",
  children,
  ...props
}: BadgeProps): ReactElement => {
  return (
    <S.Wrapper {...props} $kind={kind}>
      {icon ? <S.Icon variant={icon} $kind={kind} /> : null}
      {children}
    </S.Wrapper>
  );
};
