import React, { ProgressHTMLAttributes, ReactElement } from "react";
import { t } from "@lingui/macro";

import * as S from "./styled";

type ProgressBarProps = Omit<
  ProgressHTMLAttributes<HTMLProgressElement>,
  "value" | "max"
> & {
  /**
   * The title / label of the progress bar.
   */
  title?: string;

  /**
   * The current value of the progress bar.
   */
  value?: number;

  /**
   * The maximum value of the progress bar.
   */
  max?: number;
};
export const ProgressBar = ({
  title,
  value = 0,
  max = 1,
}: ProgressBarProps): ReactElement => {
  // If the value is greater than 0, calculate the percentage.
  // Otherwise, show "Pending".
  const percentage =
    value > 0 ? `${Math.round((value / max) * 100)}%` : t`common.pending`;

  return (
    <S.Wrapper>
      <S.Label>
        <S.Header>
          <S.Title>{title}</S.Title>
          <S.Percentage>{percentage}</S.Percentage>
        </S.Header>
        <S.Progress value={value} max={max}></S.Progress>
      </S.Label>
    </S.Wrapper>
  );
};
