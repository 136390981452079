import React, { useState, useRef, useEffect, useMemo } from "react";
import { useLoader } from "@react-three/fiber";
import {
  BufferGeometry,
  NormalBufferAttributes,
  Mesh,
  Material,
  Box3,
  BackSide,
  ShapeGeometry,
  MeshBasicMaterial,
  Color,
  LineSegments,
  LineBasicMaterial,
  BufferAttribute,
} from "three";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import { SVGLoader, SVGResult } from "three/examples/jsm/loaders/SVGLoader";

import { Triple } from "_/types";

import bedSTL from "./HYLO_Vacuum_Chuck.stl";
import logoSVG from "./AON3D_Combo_White-01.svg";

// Position offset for the bed
const OFFSET = [-15, -15, 0] as Triple<number>;

export type BedConfig = {
  surfaceSideLength: number;
};

type GridProps = {
  position?: Triple<number>;
};

/**
 * Grid lines for the bed.
 */
export const Grid = ({ position = [0, 0, 0] }: GridProps) => {
  const [lines, gridWidth, gridHeight] = useMemo(() => {
    const cellWidth = 66.5;
    const cellHeight = 46.5;

    const cellCountX = 10;
    const cellCountY = 10;

    const gridWidth = cellWidth * cellCountX;
    const gridHeight = cellHeight * cellCountY;

    const vertexArray: number[] = [];

    for (let i = 0; i <= cellCountX; i++) {
      const x = i * cellWidth;
      vertexArray.push(x, 0, 0);
      vertexArray.push(x, gridHeight, 0);
    }

    for (let i = 0; i <= cellCountY; i++) {
      const y = i * cellHeight;
      vertexArray.push(0, y, 0);
      vertexArray.push(gridWidth, y, 0);
    }

    const positionArray = new Float32Array(vertexArray.length);

    for (let i = 0; i < vertexArray.length; i++) {
      positionArray[i * 3 + 0] = vertexArray[i * 3 + 0];
      positionArray[i * 3 + 1] = vertexArray[i * 3 + 1];
      positionArray[i * 3 + 2] = vertexArray[i * 3 + 2];
    }

    const geometry = new BufferGeometry();
    geometry.setAttribute("position", new BufferAttribute(positionArray, 3));

    const lineMaterial = new LineBasicMaterial({ color: 0x000000 });
    const lines = new LineSegments(geometry, lineMaterial);
    lines.frustumCulled = false;
    return [lines, gridWidth, gridHeight];
  }, []);

  return (
    <primitive
      object={lines}
      position={[
        position[0] - gridWidth / 2,
        position[1] - gridHeight / 2,
        position[2] -
          1.5 /* "sink the grid lines into the spaces they line up with on the bed so that they're flush with the lower surface */,
      ]}
    />
  );
};

type LogoOverlayProps = {
  scale?: number;
  position?: Triple<number>;
};

/**
 * Logo overlay.
 */
const LogoOverlay = ({ scale = 1, position }: LogoOverlayProps) => {
  // Load the SVG file
  const svg = useLoader(SVGLoader, logoSVG) as SVGResult;

  // Parse the SVG
  const shapes = useMemo(
    () =>
      svg.paths.flatMap((path, i) => {
        const shapes = path.toShapes(true);
        return shapes.map((shape, j) => {
          const geometry = new ShapeGeometry(shape);
          const material = new MeshBasicMaterial({
            color: new Color(0xadadad),
            side: BackSide,
          });
          return (
            <mesh geometry={geometry} material={material} key={`${i}-${j}`} />
          );
        });
      }),
    [svg]
  );

  return (
    <group
      scale={[scale, scale, scale]}
      position={position}
      rotation={[Math.PI, 0, 0]}
    >
      {shapes}
    </group>
  );
};

type BedProps = {
  visible?: boolean;
};

export const Bed: React.FC<BedProps> = ({ visible = true }) => {
  const loadedGeometry = useLoader(STLLoader, bedSTL);

  const [dimensions, setDimensions] = useState<{
    width: number;
    height: number;
  } | null>(null);

  const geometry = Array.isArray(loadedGeometry)
    ? loadedGeometry[0]
    : loadedGeometry;

  const meshRef = useRef<Mesh<
    BufferGeometry<NormalBufferAttributes>,
    Material | Material[]
  > | null>(null);

  useEffect(() => {
    if (geometry && meshRef.current) {
      // Compute the bounding box of the geometry
      const bbox = new Box3().setFromObject(meshRef.current);

      // Compute the translation vector to move the bottom left corner to the origin
      const offset = bbox.min;

      // Translate the geometry
      geometry.translate(-offset.x, -offset.y, 0);

      // Calculate the dimensions
      setDimensions({
        width: bbox.max.x - bbox.min.x,
        height: bbox.max.y - bbox.min.y,
      });
    }
  }, [geometry]);

  const gridPosition: Triple<number> = dimensions
    ? [dimensions.width / 2, dimensions.height / 2, 0]
    : [0, 0, 0];

  // Logo position coordinates "eyeballed" from the initial Hylo bed overlay .png
  const logoPosition: Triple<number> = [22, 50, 0.1];

  return (
    <group position={OFFSET}>
      <mesh geometry={geometry} ref={meshRef} visible={visible}>
        <meshStandardMaterial attach="material" color={0x444444} />
      </mesh>
      <LogoOverlay scale={0.28} position={logoPosition} />
      <Grid position={gridPosition} />
    </group>
  );
};
