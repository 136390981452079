import React, { useEffect, ReactElement, ReactNode } from "react";
import { isEmpty } from "ramda";

import { Button, ButtonKind } from "_/components/button";

import { isKeyOf } from "_/utils";

import * as S from "./styled";
import { ToastKind } from "./types";

export type ToastProps = {
  title: string;
  content?: ReactNode;
  kind?: ToastKind;
  timeout?: number;
  actions?: Array<{ label: string; action: () => void; kind?: ButtonKind }>;
  onClose: () => void;
};

export const Toast = (props: ToastProps): ReactElement => {
  const {
    title,
    content,
    kind = "primary",
    actions = [],
    timeout = 0,
    onClose,
  } = props;

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | null = null;

    // Only add a delay to close if a timeout has been specified.
    if (timeout > 0) {
      timer = setTimeout(onClose, timeout);
    }

    return () => {
      // Cleanup the close timeout when the toast is unmounted - either
      // due to a manual close, or if a page navigation has unmounted it.
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [onClose, timeout]);

  const header = (
    <S.Header>
      <S.Title>{title}</S.Title>
      <S.CloseIcon variant="Close" role="button" onClick={onClose} />
    </S.Header>
  );

  const body = content ? (
    <S.Body>
      <S.Content>{content}</S.Content>
    </S.Body>
  ) : null;

  const buttons = !isEmpty(actions) ? (
    <S.Actions>
      {actions.map((action, i) => {
        return (
          <Button
            key={i}
            kind={action.kind}
            size="small"
            onClick={() => {
              action.action();
              onClose();
            }}
          >
            {action.label}
          </Button>
        );
      })}
    </S.Actions>
  ) : null;

  // Set the icon variant to "Info" if toast kind is primary | secondary | info
  const variantMap = {
    success: "CheckCircle",
    warning: "Warning",
    danger: "Block",
    info: "Info",
  } as const;

  const iconVariant = isKeyOf(variantMap, kind) ? variantMap[kind] : "Info";

  return (
    <S.Wrapper>
      <S.KindIconContainer>
        <S.KindIcon variant={iconVariant} $kind={kind} />
      </S.KindIconContainer>
      <S.MainContainer>
        {header}
        {body}
        {buttons}
      </S.MainContainer>
    </S.Wrapper>
  );
};
