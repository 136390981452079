import React, {
  HTMLProps,
  MouseEventHandler,
  ReactElement,
  useEffect,
  useState,
} from "react";
import styled from "styled-components";

import * as S from "./styled";

type ImageProps = {
  /** Full size image URL.*/
  src: string;

  /**
   * Scaled down thumbnail URL.
   *
   * If this is not provided, the main image source will be used for
   * the scaled down thumbnail preview.
   */
  thumb?: string;
} & HTMLProps<HTMLDivElement>;

const _Image = ({ src, thumb, style, className }: ImageProps): ReactElement => {
  const [expanded, setExpanded] = useState(false);
  const [hovered, setHovered] = useState(false);

  function openShade() {
    setExpanded(true);
  }

  const closeShade: MouseEventHandler = (e) => {
    e.stopPropagation();
    setExpanded(false);
  };

  useEffect(() => {
    const handleKeyDown = ({ key }: KeyboardEvent) => {
      if (key === "Escape") setExpanded(false);
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  });

  // If the user has hovered over the thumbnail, we preload the fullsize image
  // by adding the hidden-but-prepared full size image into the dom.
  const load = hovered || expanded;
  const shade = load ? (
    <S.Shade onClick={closeShade} $visible={expanded}>
      <S.FullSize src={src} />
    </S.Shade>
  ) : null;

  return (
    <S.Thumbnail
      className={className}
      style={style}
      onClick={openShade}
      onMouseOver={() => setHovered(true)}
      $src={thumb || src}
    >
      {shade}
    </S.Thumbnail>
  );
};

export const Image = styled(_Image)``;
