import {
  ShaderLib,
  UniformsUtils,
  ShaderMaterial,
  Color,
  FrontSide,
} from "three";

import { FeatureType } from "./gcode-parser";

import preLambertVert from "./glsl/pre-lambert.vert";
import postLambertVert from "./glsl/post-lambert.vert";

// The value of .length is halved because the typescript enum generates both string->number and number->string mappings.
const NUM_FEATURE_TYPES = Object.keys(FeatureType).length / 2;

const colorMapping = {
  [FeatureType.SkirtBrim]: new Color("#2009F6"),
  [FeatureType.Perimeter]: new Color("#00FBFF"),
  [FeatureType.ExternalPerimeter]: new Color("#0084FF"),
  [FeatureType.InternalInfill]: new Color("#FF5D00"),
  [FeatureType.OverhangPerimeter]: new Color("#FFF300"),
  [FeatureType.SolidInfill]: new Color("#FF6900"),
  [FeatureType.TopSolidInfill]: new Color("#23FF00"),
  [FeatureType.Ironing]: new Color("#FF6868"),
  [FeatureType.BridgeInfill]: new Color("#74E667"),
  [FeatureType.GapFill]: new Color("#FFFFFF"),
  [FeatureType.Skirt]: new Color("#2009F6"),
  [FeatureType.SupportMaterial]: new Color("#DBDBDB"),
  [FeatureType.SupportMaterialInterface]: new Color("#707070"),
  [FeatureType.WipeTower]: new Color("#B3E3AB"),
  [FeatureType.Mixed]: new Color("#28CC94"),
  [FeatureType.None]: new Color("#C0C0C0"),
};

type GCodeMaterialParameters = {
  color?: Color | string | number;
};

export class GCodeMaterial extends ShaderMaterial {
  constructor(parameters: GCodeMaterialParameters = {}) {
    const shader = ShaderLib["lambert"];

    const uniforms = UniformsUtils.merge([
      UniformsUtils.clone(shader.uniforms),
      {
        bottomLayer: { value: 0 },
        topLayer: { value: -1 },
        featureTypes: {
          value: new Int32Array(Array(NUM_FEATURE_TYPES).fill(1)),
        },
      },
    ]);

    // Add color uniforms for each feature type
    for (const [key, color] of Object.entries(colorMapping)) {
      uniforms[`color_${FeatureType[key]}`] = { value: color.toArray() };
    }

    if (parameters.color) uniforms.diffuse.value = new Color(parameters.color);

    super({
      defines: {
        NUM_FEATURE_TYPES,
      },
      uniforms: uniforms,
      vertexShader: `
        ${preLambertVert}
          
        ${shader.vertexShader
          .replace("void main()", "void lambert(mat4 instanceMatrix)")
          .replace(
            "#include <project_vertex>",
            `
        vec4 mvPosition = vec4( transformed, 1.0 );
        
        mvPosition = instanceMatrix * mvPosition;
        
        mvPosition = modelViewMatrix * mvPosition;
        
        gl_Position = projectionMatrix * mvPosition;
        `
          )}


        ${postLambertVert}
      `,
      fragmentShader: `
      
        varying vec3 vColor;
        
        ${shader.fragmentShader
          .replace("vec4( diffuse, opacity )", "vec4( vColor, opacity )")
          .replace("void main()", "void lambert()")}

        void main() {
          lambert();
        }
     `,
      lights: true,
      transparent: false,
      side: FrontSide,
    });
  }
}
