import styled from "styled-components";
import { darken } from "polished";

export const MaterialName = styled.div`
  display: flex;
  align-items: center;
`;

export const MaterialColorBox = styled.div<{ $color: string }>`
  border: 1px solid ${(p) => darken(0.1, p.$color)};
  background-color: ${(p) => p.$color};
  width: 0.75rem;
  height: 0.75rem;
  flex-shrink: 0;
  border-radius: 2px;
  margin-right: 0.375rem;
`;

export const SimulationIconContainer = styled.div<{ $optimizable: boolean }>`
  display: flex;
  font-size: 0.875rem;
  padding-left: 0.375em;
  color: ${(p) =>
    p.$optimizable
      ? p.theme.color.brand.blue
      : p.theme.color.palette.anchorGrey};
`;
