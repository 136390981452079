import React, { ReactElement } from "react";

import { Route, Redirect } from "_/components/router";
import { HomeDashboard } from "_/components/home-dashboard";
import { SettingsView } from "_/components/settings-view";
import { Sidebar } from "_/components/sidebar";
import { DocsView } from "_/components/docs-view";
import { ProjectsView } from "_/components/projects-view";
import { MachinesView } from "_/components/machines-view";
import { AdminView } from "_/components/admin-view";
import { routes } from "_/routes";
import { t } from "@lingui/macro";

import {
  useCheckCurrentOrg,
  useCheckOrgs,
  useHasAdminAccess,
  Org,
  useMemberOrgs,
} from "_/data/orgs";
import { useCheckUser } from "_/data/users";
import { useCheckRootPermissions } from "_/data/rbac";

import * as S from "./styled";

// Component to intercept loading of `/admin` routes if the user does not have access
function AdminViewPreload() {
  const hasAdminAccess = useHasAdminAccess();

  return hasAdminAccess ? <AdminView /> : <Redirect to={routes.index} />;
}

// Component to display when there is no current organization
function NoCurrentOrg({ orgs }: { orgs: Org[] }) {
  const { data: memberOrgs } = useMemberOrgs();

  return (
    <S.NoCurrentOrgContainer>
      <S.NoCurrentOrgMessage>
        {memberOrgs.length > 0
          ? t`components.private.no-current-org`
          : orgs.length > 0
            ? t`components.private.visible-orgs-but-not-member`
            : t`components.private.not-member-of-any-orgs`}
      </S.NoCurrentOrgMessage>
    </S.NoCurrentOrgContainer>
  );
}

export const Private = (): ReactElement => {
  const { data: user } = useCheckUser();
  const { data: orgs } = useCheckOrgs();
  const { data: rootPermissions } = useCheckRootPermissions();
  const { data: currentOrg } = useCheckCurrentOrg();

  // Ensure that the user, orgs and root domain permissions are not `undefined` before navigating
  // to private routes. By doing so here, we can safely use the `useCurrentUser`, `useOrgs` and
  // `useCurrentOrg` hooks on any private page without having to handle the `undefined` case, as well
  // as ensure that the user has been checked for root domain permissions before navigating to any
  // admin routes.
  if (!user || !orgs || !rootPermissions) return <></>;

  if (!currentOrg) return <NoCurrentOrg orgs={orgs} />;

  return (
    <S.Wrapper>
      <Sidebar />
      <S.MainArea>
        <Route path={routes.index} component={HomeDashboard} />
        <Route
          path={routes.projects.wildcards.index}
          component={ProjectsView}
        />
        <Route
          path={routes.settings.wildcards.index}
          component={SettingsView}
        />
        <Route
          path={routes.machines.wildcards.index}
          component={MachinesView}
        />
        <Route
          path={routes.admin.wildcards.index}
          component={AdminViewPreload}
        />
        <Route path={routes.docs.wildcards.index} component={DocsView} />
      </S.MainArea>
    </S.Wrapper>
  );
};
