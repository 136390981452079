/**
 * Machines view page component.
 *
 * This component manages the required data, queries, and mutations, and
 * renders the subcomponents for each machines category.
 *
 * @category pages
 * @module machines-view
 */

import React, { ReactElement, ReactNode } from "react";
import { Route } from "_/components/router";
import { t } from "@lingui/macro";

import { MachineDetails } from "_/components/machine-details";
import { FullMachineListing } from "_/components/machine-listing";
import { H5 } from "_/components/typography";
import { routes } from "_/routes";
import { useMachines } from "_/data/machines";
import { isValidUuid } from "_/utils";

import * as S from "./styled";

const UrlParamError = (props: {
  heading: string;
  description: string;
}): ReactElement => (
  <S.Wrapper>
    <H5>{props.heading}</H5>
    <p>{props.description}</p>
  </S.Wrapper>
);

const NotFoundError = (): ReactElement => (
  <UrlParamError
    heading={t`common.project-not-found`}
    description={t`common.project-not-found-description`}
  />
);

const BadUrlError = (): ReactElement => (
  <UrlParamError
    heading={t`common.invalid-url`}
    description={t`common.invalid-id-description`}
  />
);

export const MachinesView = (): ReactNode => {
  const machines = useMachines();
  if (machines.isLoading) return null;

  return (
    <>
      <Route path={routes.machines.index}>
        <S.Wrapper>
          <H5>{t`common.machines`}</H5>
          <FullMachineListing />
        </S.Wrapper>
      </Route>
      <Route path={routes.machine.wildcards.index}>
        {(p) => {
          const { machineId } = p;

          if (!isValidUuid(machineId)) return <BadUrlError />;

          const machine = machines.data?.find((m) => m.id === machineId);
          if (!machine) return <NotFoundError />;

          // TODO: add more error checking here. If the machine isn't found,
          // then add a permission check here to make sure that this user has
          // access to this machine in this org. If they don't then show a
          // message explaining that they don't have access, and that they
          // should contact their org admin.

          // TODO: if the user has access to this machine, but the machine is
          // in a different org, then switch to that org.

          return <MachineDetails machine={machine} />;
        }}
      </Route>
    </>
  );
};
