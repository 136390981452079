import { useSearch } from "wouter";

/**
 * Retrieve an object map of the query string key:value pairs. All
 * values will be strings, so must be converted to their appropriate types
 * by the caller if required.
 */
export function useQueryParams(): Record<string, string> {
  const search = useSearch();
  const params: Record<string, string> = {};
  const s = new URLSearchParams(search);

  s.forEach((v, k) => {
    params[k] = v;
  });

  return params;
}
