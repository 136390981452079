import { atom, useAtom } from "jotai";
import { atomWithStorage, createJSONStorage } from "jotai/utils";

import { Uuid } from "_/types";

// Style theme key.
export const themeAtom = atomWithStorage("theme", "dark");

// Localization setting key.
export const localeAtom = atomWithStorage("locale", "en");

// Active uploads.
export const uploadsAtom = atom({});

// Records the timestamp for when the user last acknowledged the message informing
// them that their Windows GPU settings may need to be configured properly.
export const gpuWarningLastAcknowledgedAtom = atomWithStorage(
  "gpuMessageLastAcknowledged",
  0,
  createJSONStorage(() => window.localStorage),
  { getOnInit: true }
);

export const nextUrlAtom = atomWithStorage<null | string>(
  "nextUrl",
  null,
  createJSONStorage(() => window.sessionStorage)
);

const targetOrgAtom = atomWithStorage<null | Uuid>(
  "targetOrg",
  null,
  createJSONStorage(() => window.sessionStorage)
);

export function useTargetOrg() {
  return useAtom(targetOrgAtom);
}
