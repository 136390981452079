import React, { ChangeEvent } from "react";
import { Controller, useForm } from "react-hook-form";
import { t } from "@lingui/macro";
import { pick } from "ramda";

import { Link } from "_/components/router";
import { Button, ButtonGroup } from "_/components/button";
import { FormTextField } from "_/components/text-field";
import { SubmitCancelButtons, useModal } from "_/components/modal";
import { ColumnDef, Table } from "_/components/table";
import { UuidTooltip } from "_/components/uuid-tooltip";
import { PermissionTest } from "_/components/permission-test";

import { Org, useOrgs, useCreateOrg, useUpdateOrg } from "_/data/orgs";
import { ROOT_DOMAIN, permissions } from "_/data/rbac";

import { adminUrls } from "_/routes";

import * as S from "./styled";

const OrgCreateForm = ({ onClose }: { onClose: () => void }) => {
  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
  } = useForm({
    defaultValues: {
      name: "",
      makeCurrentUserMember: false,
    },
  });

  const createOrg = useCreateOrg();

  const onSubmit = handleSubmit(async (data) => {
    createOrg.mutateAsync(data);
    onClose?.();
  });

  return (
    <form onSubmit={onSubmit}>
      <FormTextField
        control={control}
        name="name"
        rules={{
          required: t`components.admin-view.name-required`,
          maxLength: {
            value: 255,
            message: t`components.admin-view.validate-org-name-length`,
          },
        }}
        placeholder={t`components.projects-view.org-name`}
      />
      <Controller
        control={control}
        name="makeCurrentUserMember"
        render={({ field }) => (
          <S.LabelledCheckboxContainer>
            <S.Checkbox
              type="checkbox"
              checked={field.value}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                field.onChange(e.target.checked)
              }
            />
            <S.ControlLabel>{t`components.admin-view.make-current-user-first-member`}</S.ControlLabel>
          </S.LabelledCheckboxContainer>
        )}
      />
      <SubmitCancelButtons
        submitButtonLabel={t`common.create`}
        onCancel={onClose}
        disableSubmit={!dirtyFields.name}
      />
    </form>
  );
};

const OrgEditForm = ({ org, onClose }: { org: Org; onClose: () => void }) => {
  const pickFields = pick(["name"]);

  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    defaultValues: pickFields(org),
  });

  const updateOrg = useUpdateOrg();

  const onSubmit = handleSubmit(async (data) => {
    updateOrg.mutateAsync({ id: org.id, update: data });
    onClose?.();
  });

  return (
    <form onSubmit={onSubmit}>
      <FormTextField
        control={control}
        name="name"
        label={t`common.name`}
        placeholder={t`components.projects-view.org-name`}
        rules={{ required: t`components.admin-view.name-required` }}
      />
      <SubmitCancelButtons onCancel={onClose} disableSubmit={!isDirty} />
    </form>
  );
};

export const Organizations = () => {
  const { data: orgs } = useOrgs();
  const createOrgModal = useModal<void>();
  const editOrgModal = useModal<Org>();

  // Filter out organizations that the user does not have permission to view
  const readOrgs = orgs.filter((org) =>
    org.permissions.includes(permissions.organizations.read)
  );

  const columns: ColumnDef<Org>[] = [
    {
      id: "name",
      header: t`components.settings-view.org-name`,
      accessorKey: "name",
      cell: ({ row: { original: rowData } }) => rowData.name,
    },
    {
      id: "id",
      header: t`common.id`,
      accessorKey: "id",
      cell: ({ row: { original: rowData } }) => (
        <UuidTooltip uuid={rowData.id} />
      ),
    },
    {
      id: "actions",
      header: t`common.actions`,
      cell: ({ row: { original: rowData } }) => (
        <ButtonGroup>
          <Link to={adminUrls.organization(rowData.id)}>
            <Button size="small">{t`common.details`}</Button>
          </Link>
          <PermissionTest
            domainId={rowData.domainId}
            requiredPermissions={"organizations/write"}
            render={({ allowed }) => (
              <Button
                size="small"
                kind="secondary"
                onClick={() => editOrgModal.openModal(rowData)}
                disabled={!allowed}
              >
                {t`common.edit`}
              </Button>
            )}
          />
        </ButtonGroup>
      ),
    },
  ];

  return (
    <S.AdminContent>
      <S.AdminContentSection>
        <S.AdminContentSectionTitleContainer>
          <h2></h2>
          <PermissionTest
            domainId={ROOT_DOMAIN}
            requiredPermissions={"organizations/create"}
            render={({ allowed }) => (
              <Button
                onClick={() => createOrgModal.openModal(undefined)}
                disabled={!allowed}
              >{t`components.admin-view.create-org`}</Button>
            )}
          />
        </S.AdminContentSectionTitleContainer>
        <Table
          columns={columns}
          data={readOrgs}
          initialState={{ sorting: [{ id: "name", desc: false }] }}
        />
      </S.AdminContentSection>
      {createOrgModal.isModalOpen && (
        <createOrgModal.Modal title={t`components.admin-view.create-org`}>
          <OrgCreateForm onClose={createOrgModal.closeModal} />
        </createOrgModal.Modal>
      )}
      {editOrgModal.modalData && (
        <editOrgModal.Modal title={t`components.admin-view.edit-org`}>
          <OrgEditForm
            org={editOrgModal.modalData}
            onClose={editOrgModal.closeModal}
          />
        </editOrgModal.Modal>
      )}
    </S.AdminContent>
  );
};
