import React from "react";

import { GCodeMesh } from "./gcode-mesh";

import { View, ViewMode } from "_/state/viewer";
import { useAtom } from "jotai";

type GcodeViewProps = {
  view: View<ViewMode.Gcode>;
};

export const GCodeView = ({ view }: GcodeViewProps) => {
  const color = 0x0088ff;

  const [viewControls] = useAtom(view.controlsAtom);

  const printData = view.data.printData;

  const [bottomLayer, topLayer] = viewControls.layer.value;

  return (
    <GCodeMesh
      printData={printData}
      color={color}
      bottomLayer={bottomLayer}
      topLayer={topLayer}
    />
  );
};
