import React, { ReactElement, useState, useEffect } from "react";

import { RenderPreview } from "./render-preview";
import {
  Task,
  TaskMeta,
  TaskCallback,
  RenderPreviewTaskData,
  TaskMap,
} from "./types";

const TASKS: TaskMap = {
  "render-stl": RenderPreview,
  "render-gcode": RenderPreview,
};

/**
 * Check whether webgl is supported or not.
 */
function webgl_support() {
  try {
    const canvas = document.createElement("canvas");
    return (
      !!window.WebGLRenderingContext &&
      (canvas.getContext("webgl") || canvas.getContext("experimental-webgl"))
    );
  } catch (_e) {
    return false;
  }
}

/**
 * Headless view rendering component.
 *
 * From this component, we can dispatch out to desired operations by making
 * use of the specified tasks called by the `run` method.
 */
export const Headless = (): ReactElement => {
  const [task, setTask] = useState<Task<RenderPreviewTaskData> | null>(null);

  useEffect(() => {
    window.onerror = (msg) => {
      if (task) {
        task.callback({ error: `Headless error: ${msg}` });
      }
    };
  }, [task]);

  useEffect(() => {
    // Set the "run" function on the window which is called by the headless
    // driver in rust.
    const runFunc = (
      taskMeta: TaskMeta,
      taskData: RenderPreviewTaskData,
      done: TaskCallback
    ) => {
      if (!webgl_support()) {
        const msg = "WebGL not supported in this headless context.";
        console.error(msg);
        done({ error: msg });
      }

      setTask({
        data: taskData,
        meta: taskMeta,
        callback: done,
      });
    };
    (window as unknown as { run: typeof runFunc }).run = runFunc;
  }, [setTask]);

  if (task === null) {
    return <></>;
  }

  const TaskComponent = TASKS[task.meta.taskName];

  return <TaskComponent {...task} />;
};
