import React, { ReactElement, useEffect, useRef } from "react";
import { t } from "@lingui/macro";
import uPlot, { Range, Options } from "uplot";
import UplotReact from "uplot-react";

import {
  useMachineThermals,
  ThermalsDataCleaned,
  Machine,
} from "_/data/machines";

import { formatTemperature } from "_/utils";

import { MachineTemperatureDisplay } from "_/components/machine-temperature-display";
import { dateTimeString, timeOnlyString } from "_/components/date-time";

import * as S from "./styled";

import "uplot/dist/uPlot.min.css";

type MachineTemperatureChartProps = {
  machine: Machine;

  /**
   * The maximum temperature to display on the chart.
   */
  maxTemp?: number;

  /**
   * How far back in time (in seconds) to display data for.
   */
  timeWindow?: number;
};

export const MachineTemperature = ({
  machine,
  maxTemp = 450,
  timeWindow = 60,
}: MachineTemperatureChartProps): ReactElement => {
  const chartWrapperRef = useRef<HTMLDivElement | null>(null);
  const resizeObserverRef = useRef<ResizeObserver | null>(null);

  const chartRef = useRef<uPlot | null>(null);

  const lastRenderedTimestampRef = useRef(0);

  useEffect(() => {
    if (chartWrapperRef.current) {
      resizeObserverRef.current = new ResizeObserver(resize);
      resizeObserverRef.current.observe(chartWrapperRef.current);
    }
    return () => {
      resizeObserverRef?.current?.disconnect();
    };
  }, []);

  function onData(data: ThermalsDataCleaned) {
    const latestTimestamp = data.timestamp[data.timestamp.length - 1];

    if (lastRenderedTimestampRef.current != latestTimestamp) {
      // timestamp minimum cutoff in microseconds
      const timestampMin = (Date.now() - timeWindow * 1000) * 1000;

      // find index of first timestamp that is greater than the minimum cutoff
      const minIndex = data.timestamp.findIndex((t) => t > timestampMin);

      const timestamp = data.timestamp.slice(minIndex).map((t) => t / 1000);

      chartRef.current?.setData([
        timestamp,
        data.bedTemperature.slice(minIndex),
        data.chamberTemperature.slice(minIndex),
        data.t0Temperature.slice(minIndex),
        data.t1Temperature.slice(minIndex),
      ]);

      chartRef.current?.setScale("time", {
        min: timestamp[0],
        max: timestamp[timestamp.length - 1],
      });
      lastRenderedTimestampRef.current = latestTimestamp;
    }
  }

  useMachineThermals(machine, onData);

  function resize() {
    if (!chartWrapperRef.current) {
      return;
    }
    const bb = chartWrapperRef.current.getBoundingClientRect();
    chartRef.current?.setSize({ width: bb.width, height: bb.height });
  }

  function onCreate(c: uPlot) {
    chartRef.current = c;
    resize();
  }

  function formatTemp(_self: uPlot, rawValue: number) {
    return formatTemperature({ value: rawValue, kind: "current" });
  }

  const options: Options = {
    width: 800,
    height: 800,
    series: [
      {
        label: t`common.time-axis-label`,
        value: (_self, rawValue) => dateTimeString({ value: rawValue }),
      },
      {
        label: t`common.bed`,
        stroke: "blue",
        scale: "temp",
        value: formatTemp,
      },
      {
        label: t`common.chamber`,
        stroke: "red",
        scale: "temp",
        value: formatTemp,
      },
      {
        label: t`common.left-tool`,
        stroke: "green",
        scale: "temp",
        value: formatTemp,
      },
      {
        label: t`common.right-tool`,
        stroke: "gold",
        scale: "temp",
        value: formatTemp,
      },
    ],
    axes: [
      {
        scale: "time",
        incrs: [5000],
        values: (_self, ticks) =>
          ticks.map((rawValue) => timeOnlyString({ value: rawValue })),
      },
      {
        scale: "temp",
        label: t`common.temp-axis-label`,
        labelFont: "Neufile Grotesk",
        space: (_self, _axisIdx, _scaleMin, _scaleMax, plotDim) => {
          const tempRange = maxTemp;
          const tickIncrement = 25;
          const pixelPerTick = (plotDim / tempRange) * tickIncrement;
          return pixelPerTick;
        },
      },
    ],
    scales: {
      time: { auto: true, dir: -1 },
      temp: { auto: true, range: [0, maxTemp] as Range.MinMax },
    },
  };

  return (
    <S.MachineTemperatureContainer>
      <MachineTemperatureDisplay machine={machine} view="wide" />
      <S.ChartWidget>
        <S.Wrapper ref={chartWrapperRef}>
          <UplotReact options={options} data={[]} onCreate={onCreate} />
        </S.Wrapper>
      </S.ChartWidget>
    </S.MachineTemperatureContainer>
  );
};
