import styled from "styled-components";

type ThumbnailProps = {
  $src: string;
};
export const Thumbnail = styled.div.attrs<ThumbnailProps>((p) => ({
  style: {
    backgroundImage: `url(${p.$src})`,
  },
}))<ThumbnailProps>`
  width: 30px;
  height: 30px;

  background-size: cover;
  background-position: center;

  cursor: pointer;
`;

export const Shade = styled.div<{ $visible: boolean }>`
  background-color: rgba(0, 0, 0, 0.5);

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  padding: 2rem;

  display: ${(p) => (p.$visible ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

export const FullSize = styled.img`
  height: 94%;
`;
