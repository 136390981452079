import React, {
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { t } from "@lingui/macro";

import { Project, useAllAttempts, useProjects } from "_/data/projects";
import { Machine, useMachines } from "_/data/machines";
import { useMaterials } from "_/data/materials";
import { useUsers } from "_/data/users";

import { indexById } from "_/utils";

import { Link, useLocation } from "_/components/router";

import * as S from "./styled";
import Highlight, { wordMatch } from "./highlight";
import { routeUrls } from "_/routes";
import { Uuid } from "_/types";

const SearchBoxIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 23 23"
    fill="none"
    strokeWidth="2"
    strokeLinecap="round"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9.5" cy="9.5" r="8.5" />
    <path d="M21.5 21.5L15.8431 15.8431" />
  </svg>
);

interface ProjectSearchResult {
  id: Uuid;
  project: Project;
  url: string;
  name: string;
  descriptionStrings: string[];
}
interface MachineSearchResult {
  id: Uuid;
  machine: Machine;
  url: string;
  name: string;
}

type SearchResultSections = {
  query: string;
  emptyQuery: boolean;
  machineResults: MachineSearchResult[];
  projectResults: ProjectSearchResult[];
};

export function useSearch(query: string): SearchResultSections | undefined {
  const allAttemptsQueryResult = useAllAttempts();
  const projectsQueryResult = useProjects();
  const machinesQueryResult = useMachines();
  const materialsQueryResult = useMaterials();
  const usersQueryResult = useUsers();

  // Wait until everything is loaded before displaying anything to the user.
  if (
    allAttemptsQueryResult.isLoading ||
    projectsQueryResult.isLoading ||
    machinesQueryResult.isLoading ||
    materialsQueryResult.isLoading ||
    usersQueryResult.isLoading
  ) {
    return undefined;
  }

  const allAttempts = allAttemptsQueryResult.data ?? [];
  const projects = projectsQueryResult.data ?? [];
  const machines = machinesQueryResult.data ?? [];
  const materials = materialsQueryResult.data ?? [];
  const users = usersQueryResult.data ?? [];

  const machinesById = indexById(machines);
  const materialsById = indexById(materials);
  const usersById = indexById(users);

  const makeProjectSearchResult = (p: Project) => {
    const materialIds = p.revisions[0]?.materials ?? [];
    const maybeDupeMaterialNames = materialIds
      .map((id) => (id ? materialsById[id] : undefined))
      .filter(Boolean)
      .map((m) => m.name);
    const materialNames = dedupeStrings(maybeDupeMaterialNames);
    const maybeDupeMachineNames = p.revisions
      .flatMap((r) => allAttempts.filter((a) => a.revisionId === r.id))
      .map((a) => (a.machineId ? machinesById[a.machineId]?.name : undefined))
      .filter(Boolean);
    const machineNames = dedupeStrings(maybeDupeMachineNames);
    const projectOwner = usersById[p.ownerId];
    const revisionUsers = p.revisions
      .map((r) => (r.creatorId ? usersById[r.creatorId] : undefined))
      .filter(Boolean);
    const maybeDupeUserNames = [projectOwner, ...revisionUsers]
      .filter(Boolean)
      .map((u) => u.name);
    const userNames = dedupeStrings(maybeDupeUserNames);

    // Assemble all readable text for this project into a long multi-word
    // string. Then match it against the user's query split into words. Each
    // word matches independently, so the order doesn't matter.
    const fullMatchString = [
      p.name,
      ...materialNames,
      ...machineNames,
      ...userNames,
    ].join(" ");
    const isMatch = wordMatch(fullMatchString, query);

    return isMatch
      ? {
          id: p.id,
          project: p,
          url: routeUrls.project.index(p.id),
          name: p.name,
          descriptionStrings: [
            materialNames.join(" / "),
            machineNames.join(" "),
            userNames.join(" / "),
          ].filter(Boolean),
        }
      : undefined;
  };

  const dedupeStrings = (strings: string[]) => Array.from(new Set(strings));

  let projectResults: ProjectSearchResult[];
  let machineResults: MachineSearchResult[];
  const emptyQuery = !query.trim();
  if (!emptyQuery) {
    // There's a search query, so filter all projects that match it
    projectResults = projects.map(makeProjectSearchResult).filter(Boolean);

    // Show machines (if any) that match the user's query
    const matchingMachines = machines
      .map((m) => (wordMatch(m.name, query) ? m : undefined))
      .filter(Boolean);
    machineResults = matchingMachines.map((machine) => ({
      id: machine.id,
      machine,
      url: routeUrls.machine.index(machine.id),
      name: machine.name,
    }));
  } else {
    // if no query, just show the 5 most recently updated projects
    projectResults = projects
      .slice(0, 5)
      .map(makeProjectSearchResult)
      .filter(Boolean);

    // Add a single result for all machines
    machineResults = machines.map((machine) => ({
      id: machine.id,
      machine,
      url: routeUrls.machine.index(machine.id),
      name: machine.name,
    }));
  }
  return {
    query,
    emptyQuery,
    projectResults,
    machineResults,
  };
}

const MachinesSearchResultView = (props: {
  query: string;
  machineResults: MachineSearchResult[];
  onClick: () => void;
  selectedId: Uuid | undefined;
  startingTabIndex: number;
}) => {
  const { query, machineResults, onClick, selectedId, startingTabIndex } =
    props;

  return (
    <S.MachinesSearchResult>
      {machineResults.map((d, i) => (
        <Fragment key={i}>
          {i > 0 && <S.DescriptionSeparator> | </S.DescriptionSeparator>}
          <S.MachineLink
            to={d.url}
            onClick={onClick}
            $selected={d.id === selectedId}
            tabIndex={startingTabIndex + i}
          >
            <Highlight query={query} text={d.name} />
          </S.MachineLink>
        </Fragment>
      ))}
    </S.MachinesSearchResult>
  );
};

const NoProjectResult = () => {
  return (
    <S.MessageSearchResult>
      <S.NoResultsLabel>{t`search-no-matching-projects`}</S.NoResultsLabel>
    </S.MessageSearchResult>
  );
};

const ProjectSearchResultView = (props: {
  query: string;
  searchResult: ProjectSearchResult;
  onClick: () => void;
  selectedId: Uuid | undefined;
  tabIndex: number;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { query, searchResult, onClick, selectedId, tabIndex } = props;
  const { name, descriptionStrings } = searchResult;

  const description = (
    <>
      {descriptionStrings.map((d, i) => (
        <React.Fragment key={i}>
          {i > 0 && <S.DescriptionSeparator> | </S.DescriptionSeparator>}
          <Highlight query={query} text={d} />
        </React.Fragment>
      ))}
    </>
  );
  useEffect(() => {
    if (searchResult.id === selectedId && ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  });
  return (
    <Link to={searchResult.url} onClick={onClick} tabIndex={tabIndex}>
      <S.ProjectSearchResult
        $selected={searchResult.id === selectedId}
        ref={ref}
      >
        <S.SearchResultTitle>
          <Highlight query={query} text={name} />
        </S.SearchResultTitle>
        <S.SearchResultDescription>{description}</S.SearchResultDescription>
      </S.ProjectSearchResult>
    </Link>
  );
};

/**
 * Hook to ensure that a container's height is aligned to the height of its children,
 * so that a partial child item is not shown unless the container is scrolled.
 */
function useEvenHeight(
  containerRef: React.RefObject<HTMLElement>,
  dependency: string
) {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    // Function to handle the window resize event
    const handleResize = () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    };

    // Add the event listener for the window resize event
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, [containerRef]);

  useLayoutEffect(() => {
    const adjustContainerHeight = () => {
      if (!containerRef.current) return;

      const container = containerRef.current;
      const children = container.children;

      // Restrict to properties with string names and string values.
      type StringPropertyNames<T> = {
        [K in keyof T]: T[K] extends string ? K : never;
      }[keyof T];

      function getNumericStyles<
        T extends StringPropertyNames<CSSStyleDeclaration>,
      >(container: HTMLElement, styles: T[]) {
        const computedStyle = window.getComputedStyle(container);
        const result = {} as Partial<Record<T, number>>;
        for (const style of styles) {
          result[style] = parseFloat(computedStyle[style]) ?? 0;
        }
        return result as Record<T, number>;
      }
      const { paddingTop, paddingBottom, borderTop, borderBottom, maxHeight } =
        getNumericStyles(container, [
          "paddingTop",
          "paddingBottom",
          "borderTop",
          "borderBottom",
          "maxHeight",
        ]);

      const verticalPaddingAndBorder =
        paddingTop + paddingBottom + borderTop + borderBottom;

      let currentHeight = verticalPaddingAndBorder;

      for (let i = 0; i < children.length; i++) {
        const child = children[i] as HTMLElement;
        const childHeight = child?.getBoundingClientRect().height ?? 0;
        if (currentHeight + childHeight > maxHeight) {
          break;
        }
        currentHeight += childHeight;
      }

      container.style.height = currentHeight + "px";
    };
    adjustContainerHeight();
  }, [containerRef, windowSize, dependency]);
}

export const SearchBox = () => {
  const [query, setQuery] = useState("");
  const searchInputRef = React.useRef<HTMLInputElement>(null);
  const searchResults = useSearch(query);
  const [collapsed, setCollapsed] = useState(false);
  const [, navigate] = useLocation();
  const projectResultsRef = useRef<HTMLDivElement>(null);
  useEvenHeight(projectResultsRef, JSON.stringify(searchResults));

  // ID of whatever is selected by keyboard navigation. Cleared when the user
  // does anything other than keyboard navigation.
  const [arrowSelected, setArrowSelected] = useState<Uuid | undefined>();

  const combinedResults = [
    ...(searchResults?.projectResults ?? []),
    ...(searchResults?.machineResults ?? []),
  ];

  const selectResult = (result: ProjectSearchResult | MachineSearchResult) => {
    if (combinedResults.length === 0 || !result) {
      setArrowSelected(undefined);
      return;
    }

    setArrowSelected(result.id);
    setCollapsed(false);
  };

  const reset = () => {
    collapse();
    setQuery("");
    (document.activeElement as HTMLElement)?.blur();
  };

  const collapse = () => {
    setCollapsed(true);
    setArrowSelected(undefined);
  };

  useEffect(() => {
    const moveArrowSelection = (direction: 1 | -1) => {
      if (arrowSelected === undefined) return;
      const currentResultIndex = combinedResults.findIndex(
        (r) => r.id === arrowSelected
      );
      if (currentResultIndex === -1) {
        // for some reason the current selection isn't there anymore, so just
        // select the first result.
        selectResult(combinedResults?.[0]);
        return;
      }
      const nextIndex = currentResultIndex + direction;
      if (nextIndex < 0 || nextIndex >= combinedResults.length) return;

      const nextResult = combinedResults[nextIndex];
      selectResult(nextResult);
    };

    const handleKeyDown = ({ key }: KeyboardEvent) => {
      switch (key) {
        case "Escape":
          collapse();
          break;
        case "ArrowDown":
          if (arrowSelected === undefined) {
            // if nothing is selected, select the first result
            selectResult(combinedResults?.[0]);
          } else {
            // otherwise move down one result
            moveArrowSelection(1);
          }
          break;
        case "ArrowUp":
          moveArrowSelection(-1);
          break;
        case "Enter":
          {
            const selectedResult = combinedResults.find(
              (r) => r.id === arrowSelected
            );
            if (selectedResult) {
              navigate(selectedResult.url);
              reset();
            }
          }
          break;
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  });

  return (
    <S.OuterWrapper>
      <S.InnerWrapper>
        <S.SearchControlContainer $collapsed={collapsed}>
          <S.SearchInput
            ref={searchInputRef}
            type="text"
            name="q"
            onChange={(e) => {
              setQuery(e.target.value);
              setArrowSelected(undefined);
            }}
            value={query}
            onFocus={() => setCollapsed(false)}
            tabIndex={1}
            placeholder={t`search.search-box-placeholder`}
            autoComplete="off"
          />
          <S.SearchIconWrapper>
            <SearchBoxIcon />
          </S.SearchIconWrapper>
          {searchResults && (
            <S.SearchResults>
              <S.ProjectSearchResultSection>
                <S.SearchResultSectionHeader>
                  <S.SectionIcon variant="Projects" />
                  {t`common.projects`}
                </S.SearchResultSectionHeader>
                <S.SearchResultsScrollContainer ref={projectResultsRef}>
                  {searchResults.projectResults.map((result, i) => (
                    <ProjectSearchResultView
                      key={result.id}
                      query={query}
                      searchResult={result}
                      onClick={reset}
                      selectedId={arrowSelected}
                      tabIndex={i + 2}
                    />
                  ))}
                </S.SearchResultsScrollContainer>
              </S.ProjectSearchResultSection>
              {!searchResults.projectResults.length &&
                !searchResults.emptyQuery && <NoProjectResult />}
              {searchResults.machineResults.length > 0 && (
                <S.MachineSearchResultSection>
                  <S.SearchResultSectionHeader>
                    <S.MachinesIconWrapper>
                      <S.SectionIcon variant="Machines" />
                    </S.MachinesIconWrapper>
                    {t`common.machines`}
                  </S.SearchResultSectionHeader>
                  <MachinesSearchResultView
                    query={query}
                    machineResults={searchResults.machineResults}
                    onClick={reset}
                    selectedId={arrowSelected}
                    startingTabIndex={searchResults.projectResults.length + 2}
                  />
                </S.MachineSearchResultSection>
              )}
            </S.SearchResults>
          )}
        </S.SearchControlContainer>
      </S.InnerWrapper>
    </S.OuterWrapper>
  );
};
