import styled, { css } from "styled-components";

import { Placement } from "./types";

export const Wrapper = styled.div<{ $fullWidth: boolean }>`
  position: relative;
  display: inline-flex;
  ${(p) => p.$fullWidth && "width: 100%;"}
`;

export const TargetWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const PanelWrapper = styled.div`
  pointer-events: none;
  position: absolute;

  // This is somewhat hacky to ensure that the panel content is above
  // anything else. It is likely to cause issues down the line, but
  // we can cross that bridge when we get there. <3
  z-index: 1;

  // Mirror the dimensions of the Label so that the panel is anchored
  // appropriately.
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const placements = {
  topStart: css`
    bottom: 100%;
    left: 0%;
  `,
  top: css`
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
  `,
  topEnd: css`
    bottom: 100%;
    right: 0%;
  `,
  leftStart: css`
    right: 100%;
    top: 0%;
  `,
  left: css`
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
  `,
  leftEnd: css`
    right: 100%;
    bottom: 0%;
  `,
  rightStart: css`
    left: 100%;
    top: 0%;
  `,
  right: css`
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
  `,
  rightEnd: css`
    left: 100%;
    bottom: 0%;
  `,
  bottomStart: css`
    top: 100%;
    left: 0%;
  `,
  bottom: css`
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  `,
  bottomEnd: css`
    top: 100%;
    right: 0%;
  `,
};

export const PanelContent = styled.div<{ $placement: Placement }>`
  pointer-events: auto;
  position: absolute;

  ${(p) => placements[p.$placement]}
`;
