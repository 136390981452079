import { flatten } from "ramda";
import {
  BufferGeometry,
  Color,
  Float32BufferAttribute,
  Material,
  MeshNormalMaterial,
  MeshPhongMaterial,
  MeshPhysicalMaterial,
  Triangle,
  Vector3,
} from "three";
import { v4 } from "uuid";

import { parsePLY } from "_/data/ply";
import { Uuid } from "_/types";

import { parseSTL } from "./parse";

export class Mesh {
  id: Uuid;

  color: Color;

  positions: Array<Vector3>;
  normals: Array<Vector3>;
  center: Vector3;

  bufferGeometry: BufferGeometry | null;
  materials: Record<string, Material>;

  constructor() {
    this.id = v4();
    this.color = new Color(0x9db0d3);

    this.positions = [];
    this.normals = [];
    this.center = new Vector3(0, 0, 0);

    this.bufferGeometry = null;
    this.materials = {};

    this.makeMaterials();
  }

  private makeMaterials() {
    this.materials = {
      default: new MeshPhongMaterial({ color: this.color }),
      normal: new MeshNormalMaterial(),
      physical: new MeshPhysicalMaterial({
        color: this.color,
        attenuationDistance: 0.1,
        clearcoat: 0.05,
        clearcoatRoughness: 0.05,
        roughness: 0.5,
        reflectivity: 0.1,
      }),
    };
  }

  scale(scalar: number): void {
    this.positions = this.positions.map((p: Vector3) => {
      p.multiplyScalar(scalar);
      return p;
    });
  }

  material(mat = "default"): Material {
    return this.materials[mat] || this.materials.default;
  }

  geometry(): BufferGeometry {
    if (this.bufferGeometry !== null) {
      return this.bufferGeometry;
    }

    const geometry = new BufferGeometry();

    const positions = flatten(this.positions.map((p) => p.toArray()));
    const positionAttribute = new Float32BufferAttribute(positions, 3);

    const normals = flatten(this.normals.map((n) => n.toArray()));
    const normalAttribute = new Float32BufferAttribute(normals, 3);

    geometry.setAttribute("position", positionAttribute);
    geometry.setAttribute("normal", normalAttribute);

    geometry.computeBoundingBox();
    geometry.computeBoundingSphere();

    if (geometry.boundingBox === null) {
      throw new Error("Unreachable!");
    }

    // Re-position the mesh so that it is centered on the origin.
    const position = geometry.boundingBox.getCenter(new Vector3());
    const inverse = position.negate();
    geometry.translate(...inverse.toArray());

    this.center = geometry.boundingBox.getCenter(this.center);
    this.bufferGeometry = geometry;

    return geometry;
  }

  static async fromStl(stlData: ArrayBuffer): Promise<Mesh> {
    return parseSTL(stlData);
  }

  static async fromPly(plyData: ArrayBuffer): Promise<Mesh> {
    const ply = parsePLY(plyData, true);

    const mesh = new Mesh();

    ply.data.face.forEach((f, i) => {
      const vertices = f.vertex_indices as number[];

      if (vertices.length !== 3) {
        throw new Error(
          `Meshes must contain only triangles. Face ${i} contains ${vertices.length} vertices.`
        );
      }

      const facet: Vector3[] = [];

      for (let j = 0; j < 3; j++) {
        const vertex = ply.data.vertex[vertices[j]];

        const point = new Vector3(
          vertex.x as number,
          vertex.y as number,
          vertex.z as number
        );

        facet.push(point);
      }

      mesh.addFacet(facet);
    });

    return mesh;
  }

  addFacet(facet: Vector3[]): void {
    const n = new Triangle(...facet).getNormal(new Vector3());

    for (let i = 0; i < 3; i++) {
      this.positions.push(facet[i]);
      this.normals.push(n);
    }
  }
}
