import styled from "styled-components";
import { transparentize } from "polished";

import { Icon } from "_/components/icon";
import { Placement } from "_/components/overlay/types";

// Offset distance to shift the menu by away from it's target element.
const MENU_OFFSET = "8px";

// Dropdown menu border radius.
const BORDER_RADIUS = "4px";

// Position correction based off of the placement of the overlay.
//
// This should move the overlay slightly away from the target element
// by the predefined `OFFSET` amount.
const placement = (p: Placement): string => {
  if (p.startsWith("top")) {
    return `translateY(-${MENU_OFFSET})`;
  } else if (p.startsWith("bottom")) {
    return `translateY(${MENU_OFFSET})`;
  } else if (p.startsWith("left")) {
    return `translateX(-${MENU_OFFSET})`;
  } else if (p.startsWith("right")) {
    return `translateX(${MENU_OFFSET})`;
  }

  return "none";
};

export const Wrapper = styled.div<{ $placement: Placement }>`
  display: inline-flex;
  flex-direction: column;

  min-width: 160px;

  background-color: ${(p) => p.theme.background.tertiary};
  color: ${(p) => p.theme.text.default};

  border-radius: ${BORDER_RADIUS};

  box-shadow:
    0 0 4px ${(p) => transparentize(0.8, p.theme.overlayMenu.border)},
    0 2px 12px ${(p) => transparentize(0.8, p.theme.border.default)};

  transform: ${(p) => placement(p.$placement)};
`;

export const MenuItem = styled.div<{ $disabled: boolean }>`
  padding: 8px 12px;
  white-space: nowrap;
  font-size: 14px;

  cursor: ${(p) => (p.$disabled ? "not-allowed" : "pointer")};
  color: ${(p) => (p.$disabled ? p.theme.text.disabled : p.theme.text.default)};

  &:hover {
    background-color: ${(p) => p.theme.background.quaternary};
  }

  &:first-of-type {
    border-radius: ${BORDER_RADIUS} ${BORDER_RADIUS} 0 0;
  }

  &:last-of-type {
    border-radius: 0 0 ${BORDER_RADIUS} ${BORDER_RADIUS};
  }
`;

export const TextItem = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${Icon} {
    font-size: 16px;
  }
`;
