import styled from "styled-components";
import { Image as _Image } from "_/components/image";

export const LayerControlContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  height: 100%;
  width: max-content;

  padding: 1rem;

  border-radius: 5px;

  background-color: ${(p) => p.theme.background.secondary};
`;

export const PointCloudControlContainer = styled.div`
  width: 100%;
  height: max-content;

  border-radius: 5px;

  background-color: ${(p) => p.theme.background.secondary};
`;

export const LayerSliderContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;

export const ControlLabel = styled.span`
  font-size: 0.875rem;
  color: ${(p) => p.theme.text.muted};
  font-weight: 600;
`;

export const PhotoWidgetContainer = styled.div`
  border-radius: 5px;

  aspect-ratio: 4 / 3;

  background-color: ${(p) => p.theme.background.secondary};
`;

const IMAGE_MARGIN = 10;

export const Image = styled(_Image)`
  width: calc(100% - ${2 * IMAGE_MARGIN}px);
  height: calc(100% - ${2 * IMAGE_MARGIN}px);
  margin: ${IMAGE_MARGIN}px;
`;

export const RightSideContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

export const Checkbox = styled.input`
  accent-color: ${(p) => p.theme.color.palette.electricBlue};

  // Invert the color of the checkbox when it's not checked, so that it has a dark background rather than a white one.
  &:not(:checked) {
    filter: invert(90%);
  }
`;

export const LabelledCheckboxContainer = styled.label`
  display: flex;
  vertical-align: middle;
  margin: 1rem;
  gap: 0.5rem;
`;

export const ProgressWrapper = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
