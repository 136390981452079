import React, { ReactElement, ReactNode, forwardRef, useRef } from "react";
import { t } from "@lingui/macro";

import { Select, SelectProps, Option } from "_/components/select";
import { useMaterials, Material } from "_/data/materials";

import * as S from "./styled";

/**
 * Return the text-only representation of a material's manufacturer, name, and
 * color.
 */
const materialName = (material: Material) => {
  return `${material.manufacturer.name} ${material.name}`;
};

export const SimulationIcon = ({
  capabilities,
}: {
  capabilities: Material["capabilities"];
}) => {
  if (!capabilities.simulation) return null;

  const icon = (
    <svg
      width="1em"
      height="1em"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 29 29"
      fill="currentColor"
    >
      <path d="m14.5,0C6.49,0,0,6.49,0,14.5s6.49,14.5,14.5,14.5,14.5-6.49,14.5-14.5S22.51,0,14.5,0Zm9.87,16.91h-7.46v7.46c0,.42-.34.76-.76.76h-3.3c-.42,0-.76-.34-.76-.76v-7.46h-7.46c-.42,0-.76-.34-.76-.76v-3.3c0-.42.34-.76.76-.76h7.46v-7.46c0-.42.34-.76.76-.76h3.3c.42,0,.76.34.76.76v7.46h7.46c.42,0,.76.34.76.76v3.3c0,.42-.34.76-.76.76Z" />
    </svg>
  );
  const tooltip = capabilities.optimization
    ? t`common.materials.optimizable`
    : t`common.materials.simulatable`;
  return (
    <S.SimulationIconContainer
      title={tooltip}
      $optimizable={capabilities.optimization}
    >
      {icon}
    </S.SimulationIconContainer>
  );

  return icon;
};

/**
 * Component for displaying a material's name and manufacturer, with a block of
 * color prefix. If the material is simulatable, an icon displaying simulation
 * capability is also shown.
 */
export const MaterialName = ({ material }: { material: Material }) => {
  return (
    <S.MaterialName>
      <S.MaterialColorBox $color={material.color} />
      {materialName(material)}
      <SimulationIcon capabilities={material.capabilities} />
    </S.MaterialName>
  );
};

/**
 * Select component for displaying materials list dropdown.
 *
 * This will display materials visible to the currently logged in user.
 */
export const MaterialSelect = forwardRef(function MaterialSelect(
  props: Omit<SelectProps<Option<string>>, "options">,
  ref
): ReactElement {
  const { data: materials = [] } = useMaterials();

  // Use a ref to keep track of the initial material value, which might
  // be an archived material not otherwise included in the material options.
  const initialValueRef = useRef(props.value);

  const options = materials
    .filter((mat) => mat.id === initialValueRef.current || !mat.archived)
    .map((mat) => ({
      value: mat.id,
      label: materialName(mat),
      labelJsx: <MaterialName material={mat} />,
    }));

  return <Select {...props} ref={ref} options={options} />;
});

/** Show the material(s) of a revision, each on their own line */
export const MaterialsList = ({
  materials,
}: {
  materials: Material[] | undefined;
}): ReactNode => {
  if (!materials) return null;
  return (
    <div>
      {materials?.map((material) => (
        <MaterialName key={material.id} material={material} />
      ))}
    </div>
  );
};
