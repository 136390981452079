import React, {
  ReactElement,
  ReactNode,
  useState,
  useEffect,
  useRef,
  useMemo,
  useContext,
  useCallback,
} from "react";
import { createPortal } from "react-dom";
import { v4 } from "uuid";
import { t } from "@lingui/macro";

import { Uuid } from "_/types";

import { Button } from "_/components/button";
import { ViewerOverlayContext } from "_/components/project-detail/viewer-overlay-context";

import * as S from "./styled";

type ModalBaseProps = {
  rootElement: HTMLDivElement;
  title?: string;
  children?: ReactNode;
  open?: boolean;
  onClose?: () => void;
  escExit?: boolean;
};

// Base component for modals and similar overlay components.
const ModalBase = ({
  rootElement,
  title,
  open,
  escExit,
  children,
  onClose,
}: ModalBaseProps): ReactElement => {
  const containerRef = useRef<HTMLDivElement>(null);

  // Called when modal is closed by any closing method (e.g.
  // "X" button, escape key, etc.).
  const closeModal = useCallback(() => {
    onClose?.();
  }, [onClose]);

  // Handler for key events to escape from the modal.
  const escapeKeydown = useCallback(
    (e: KeyboardEvent) => {
      if (escExit && e.key === "Escape") {
        e.stopPropagation();
        closeModal();
      }
    },
    [closeModal, escExit]
  );

  // Move focus when the modal opens, then return focus to the originally
  // focussed element when the modal closes.
  useEffect(() => {
    if (containerRef.current === null) {
      return;
    }

    containerRef.current.focus();
    const oldFocus = document.activeElement as HTMLElement;

    return () => {
      oldFocus.focus();
    };
  }, [containerRef]);

  // Setup escape key handler.
  useEffect(() => {
    if (open) {
      document.addEventListener("keydown", escapeKeydown);
    }

    return () => {
      document.removeEventListener("keydown", escapeKeydown);
    };
  }, [open, escapeKeydown]);

  const header = (
    <S.Header>
      <S.Title>{title}</S.Title>
      {onClose && (
        <S.CloseIcon variant="Close" role="button" onClick={onClose} />
      )}
    </S.Header>
  );

  const content =
    typeof children === "string" ? (
      <S.TextMessage>{children}</S.TextMessage>
    ) : (
      children
    );

  const modalBase = (
    <S.Overlay>
      <S.Container ref={containerRef} role="dialog" aria-label="Modal">
        {(title || onClose) && header}
        <S.Content>{content}</S.Content>
      </S.Container>
    </S.Overlay>
  );

  return open ? createPortal(modalBase, rootElement) : <></>;
};

interface ModalProps {
  title?: string;
  children?: ReactNode;
  open?: boolean;
  onClose?: () => void;
  escExit?: boolean;
}

interface UseModalResult<T> {
  /** Opens the modal, passing in data that the modal will use. */
  openModal: (data: T extends void ? T : T | undefined) => void;

  /**
   * Data passed when the modal was opened. Used to render this data inside
   * modal children without requiring the modal's parent to maintain this value
   * in a state variable.
   * */
  modalData: T extends void ? T : T | undefined;

  /** Closes the modal. */
  closeModal: () => void;

  /** Modal component itself. */
  Modal: ({
    title,
    children,
  }: {
    title: string;
    children: ReactNode;
  }) => React.JSX.Element | null;

  /** Is the modal currently open? */
  isModalOpen: boolean;
}

/**
 * Hook to ergonomically manage a modal. It:
 * - Removes the need to clear the modal's state between invocations. Each
 *   invocation starts fresh.
 * - Enables passing data to the modal children without having to create a
 *   separate state variable in the modal's parent.
 * - DRY modals: minimizes boilerplate code in the modal's parent and in modal
 *   content itself.
 *
 * Example usage:
 *
 * ```tsx
interface Foo {
  id: Uuid;
  name: string;
}
function ListOfFoos({ foos }: { foos: Foo[] }) {
  const { openModal, closeModal, Modal, modalData } = useModal<Foo>();

  return (
    <div>
      <h3>All Foos</h3>
      {foos.map((foo) => (
        <div key={foo.id}>
          {foo.name}
          <Button onClick={() => openModal(foo)}>Edit</Button>
        </div>
      ))}

      {modalData && (
        <Modal title="Edit Foo">
          <EditFooForm foo={modalData} onCancel={closeModal} />
        </Modal>
      )}
    </div>
  );
}
 * ```
 *  *
 * @returns A `UseModalResult` object with the following properties:
 * - `openModal`: Opens the modal, passing in data that the modal will use.
 * - `modalData`: Data passed when the modal was opened. Used to render this
     data inside modal children.
 * - `closeModal`: Close the modal.
 * - `Modal`: Modal component itself.
 * - `isModalOpen`: Is the modal currently open?
 */
export function useModal<T = undefined>(): UseModalResult<T> {
  type ModalData = UseModalResult<T>["modalData"];
  const [modalKey, setModalKey] = useState<Uuid | undefined>();
  const [modalData, setModalData] = useState<ModalData>();

  const closeModal = useCallback(() => {
    setModalKey(undefined);
    setModalData(undefined);
  }, []);

  const openModal = useCallback(
    (data: ModalData) => {
      // ignore attempts to re-open the same modal
      if (modalKey) return;

      // By using a unique key each time the modal is opened, we ensure that the
      // modal is re-rendered from scratch each time it is opened without data
      // being retained from previous invocations.
      setModalKey(v4());
      setModalData(data);
    },
    [modalKey]
  );

  const isModalOpen = !!modalKey;

  const ModalComponent = useCallback(
    ({ children, title }: { children: ReactNode; title: string }) =>
      modalKey ? (
        <Modal
          key={modalKey}
          title={title}
          open={isModalOpen}
          onClose={closeModal}
        >
          {children}
        </Modal>
      ) : null,
    [modalKey, closeModal, isModalOpen]
  );

  return {
    closeModal,
    openModal,
    modalData: modalData as ModalData,
    isModalOpen,
    Modal: ModalComponent,
  };
}

/**
 * Render a submit button and an optional cancel button for a modal.
 */
interface SubmitCancelButtonsProps {
  /**
   * Label for the cancel button. If omitted, "Save" (or its localized
   * equivalent) will be used.
   * */
  submitButtonLabel?: string;

  /**
   * Label for the cancel button. If omitted, "Cancel" (or its localized
   * equivalent) will be used.
   * */
  cancelButtonLabel?: string;

  /**
   * Optional function to run when the submit button is clicked. If omitted,
   * the `onSubmit` handler of the form is run instead.
   * */
  onSubmit?: () => void;

  /**
   * Optional function to run when the cancel button is clicked. If omitted,
   * the cancel button is not rendered at all.
   * */
  onCancel?: () => void;

  /**
   * Whether to disable the submit button. This is required because modals often
   * disable the submit button if there's been no user input (dirty is false) so
   * requiring it is a good way to make sure that callers are paying attention.
   * */
  disableSubmit: boolean;

  /**
   * Whether to disable the cancel button. This is rarely done, because modals
   * are almost always cancelable at all times. The only exception may be cases
   * where some operation is in progress and we want to wait for it to time out
   * before allowing the user to cancel.
   * */
  disableCancel?: boolean;
}

/** Container element for buttons in a modal */
export const ButtonContainer = S.ButtonContainer;

/**
 * Reusable UI for submit and cancel buttons for a modal.
 *
 * If no `onSubmit` callback is provided, reach-hook-form's `onSubmit`
 * will be used by default.
 */
export const SubmitCancelButtons = ({
  onSubmit,
  onCancel,
  submitButtonLabel = t`common.save`,
  cancelButtonLabel = t`common.cancel`,
  disableSubmit,
  disableCancel,
}: SubmitCancelButtonsProps): ReactElement => {
  return (
    <ButtonContainer>
      {onCancel && (
        <Button
          type="button"
          kind="secondary"
          disabled={disableCancel}
          onClick={onCancel}
        >
          {cancelButtonLabel}
        </Button>
      )}
      <Button type="submit" onClick={onSubmit} disabled={disableSubmit}>
        {submitButtonLabel}
      </Button>
    </ButtonContainer>
  );
};

// "Conventional" modal component that covers the entire screen and is dismissable
export function Modal({
  title,
  open = true,
  escExit = true,
  children,
  onClose,
}: ModalProps): ReactElement {
  const modalRoot = useMemo(() => {
    let root = document.getElementById("modal-root") as HTMLDivElement;

    // Create root if there isn't one in the DOM. This should only be required
    // during tests.
    if (root === null) {
      root = document.createElement("div");
      root.setAttribute("id", "modal-root");
      document.body.appendChild(root);
    }

    return root;
  }, []);

  return (
    <ModalBase
      rootElement={modalRoot}
      title={title}
      open={open}
      escExit={escExit}
      onClose={onClose}
    >
      {children}
    </ModalBase>
  );
}

type ViewerOverlayNoticeProps = {
  title?: string;
  children?: ReactNode;
};

// Component for displaying a non-dismissible notice in the viewer overlay.
export const ViewerOverlayNotice = ({
  title,
  children,
}: ViewerOverlayNoticeProps): ReactElement => {
  const rootElement = useContext(ViewerOverlayContext);

  if (!rootElement) {
    return <></>;
  }

  return (
    <ModalBase rootElement={rootElement} title={title} open={true}>
      {children}
    </ModalBase>
  );
};
