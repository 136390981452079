import React, { ReactElement } from "react";
import { t } from "@lingui/macro";

import * as S from "./styled";

export const LoadingOverlay = (): ReactElement => {
  const loadingText = t`component.loading-overlay.loading`;

  return <S.Overlay>{loadingText}</S.Overlay>;
};
