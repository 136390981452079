import React, { ReactElement } from "react";
import { v4 } from "uuid";
import { atom, useAtom } from "jotai";
import { append, reject } from "ramda";

import { Toast, ToastProps } from "_/components/toast";
import { Uuid } from "_/types";

import * as S from "./styled";

// Data type as used by the Toasts component.
type ToastData = ToastProps & { id: Uuid };

// Data specification required when queueing a new toast.
type ToastSpec = Omit<ToastProps, "onClose">;

const toastsAtom = atom<ToastData[]>([]);

export function useToasts(): [
  ToastData[],
  (spec: ToastSpec) => void,
  (id: Uuid) => void,
] {
  const [toasts, setToasts] = useAtom(toastsAtom);

  function removeToast(id: Uuid) {
    return () => {
      setToasts((ts) => reject((t: ToastData) => t.id === id, ts));
    };
  }

  function addToast(toast: ToastSpec) {
    const id = v4();
    const onClose = removeToast(id);
    setToasts((ts) => append({ id, onClose, ...toast }, ts));
  }

  return [toasts, addToast, (id) => removeToast(id)()];
}

export const Toasts = (): ReactElement => {
  const [toasts, _addToast, _removeToast] = useToasts();

  const toastEls = toasts.map((t: ToastData) => {
    const { id, ...rest } = t;

    return <Toast key={id} {...rest} />;
  });

  return <S.Container>{toastEls}</S.Container>;
};
