import { parseToHsl } from "polished";

// Global colors for the brand independent of the UI theme being used.
export const brandColors = {
  blue: "#1950FF",
  black: "#0B0D0F",
  darkGrey: "#3D3D3D",
  lightGrey: "#EAEAEA",
  red: "#E00D20",
  white: "#FFFFFF",
};

// These colors are specific for our material select color picker
// and other UI that shows a swatch of the material color.
export const materialColors = {
  black: "#28282b",
  white: "#faf9f6",
  grey: "#818589",
  red: "#d22b2b",
  orange: "#f28c28",
  yellow: "#ffea00",
  green: "#228b22",
  blue: "#1434a4",
  purple: "#5f3fd3",
  amber: "#e49b0f",
  tan: "#fff8dc",
  transparent: "#ffffff",
};

/**
 * Global color palette(s) for all color definitions.
 *
 * If a new color is needed, it should be named and added to the figma
 * styleguide color palette as well as here, then specified in the
 * appropriate location for each theme object.
 */

const reds = {
  scarletRed: "#d00400",
  tomatoRed: "#f62b12",
  crimsonRed: "#a40000",
  blushRed: "#ffc9ba",
};

const oranges = {
  mustardBrown: "#bd6a1f",
  sunsetOrange: "#ff9506",
};

const yellows = {
  pineappleYellow: "#ffd53f",
  pencilYellow: "#e7ac1d",
  butterYellow: "#ffe07a",
};

const greens = {
  acidGreen: "#cbff04",
  algaeGreen: "#516618",
  leafGreen: "#a2cc31",
  forestGreen: "#00783e",
  pineGreen: "#005929",
  kellyGreen: "#20b778",
  mintGreen: "#bae5ce",
};

const blues = {
  electricBlue: "#1950ff",
  deepSeaBlue: "#000bcb",
  twilightBlue: "#bacaff",
  indigoBlue: "#2e3c6b",
  /* Nice color used by GitHub for links; works well in dark and light mode */
  cornflowerBlue: "#2f81f7",
  oceanBlue: "#003ae6",
  midnightBlue: "#071540",
};

const teals = {
  deepTeal: "#00555d",
  glamourTeal: "#007888",
  maldivesTeal: "#00bbd5",
  blizzardTeal: "#a4e8f2",
};

const violets = {};

const whites = {
  eggshellWhite: "#fdfcfc",
  snowWhite: "#f9f9fb",
  white: "#ffffff",
};

const greys = {
  concreteGrey: "#ebecee",
  aluminumGrey: "#dddfe1",
  fogGrey: "#cfd5dd",
  steelGrey: "#caccce",
  ironGrey: "#a5a6a8",
  spanishGrey: "#929497",
  pebbleGrey: "#7f8286",
  nickelGrey: "#6b7075",
  anchorGrey: "#585e64",
  ashGrey: "#4a4b4d",
  smokeGrey: "#43464B",
};

const blacks = {
  black: "#000000",
  almostBlack: "#0b0d0f",
  obsidianBlack: "#131419",
  satinBlack: "#1c1d21",
  inkBlack: "#1e2125",
  oilBlack: "#272930",
  graniteBlack: "#2f3138",
  orcaBlack: "#36383f",
  onyxBlack: "#393d44",
  charcoalBlack: "#3b4149",
};

export const palette = {
  ...reds,
  ...oranges,
  ...yellows,
  ...greens,
  ...blues,
  ...teals,
  ...violets,
  ...whites,
  ...greys,
  ...blacks,
};

function sortColorsByLightness(colors: Record<string, string>) {
  // TODO: change to `toSorted` in 2025
  return Object.entries(colors)
    .sort(([_nameA, colorA], [_nameB, colorB]) => {
      const lightnessA = parseToHsl(colorA).lightness;
      const lightnessB = parseToHsl(colorB).lightness;

      return lightnessA - lightnessB;
    })
    .reduce(
      (acc, [name, color]) => {
        acc[name] = color;
        return acc;
      },
      {} as typeof colors
    );
}

// Exports palette sub-categorized by primary colors, for use with Storybook.
export const storyPalette = {
  reds: sortColorsByLightness(reds),
  oranges: sortColorsByLightness(oranges),
  yellows: sortColorsByLightness(yellows),
  greens: sortColorsByLightness(greens),
  blues: sortColorsByLightness(blues),
  teals: sortColorsByLightness(teals),
  violets: sortColorsByLightness(violets),
  whites: sortColorsByLightness(whites),
  greys: sortColorsByLightness(greys),
  blacks: sortColorsByLightness(blacks),
};
