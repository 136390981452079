import styled from "styled-components";
import { transparentize } from "polished";

import { PopoverProps } from "_/components/overlay";
import { isKeyOf } from "_/utils";

const sideMargin = ($placement: PopoverProps["placement"]) => {
  const mapping = {
    left: "right",
    right: "left",
    top: "bottom",
    bottom: "top",
  };

  const side = $placement.replace(/(Start)$/, "").replace(/(End)$/, "");
  if (!isKeyOf(mapping, side)) {
    throw new Error(`Invalid placement: ${$placement}`);
  }

  return `margin-${mapping[side]}: 4px`;
};

const pointPlacement = ($placement: PopoverProps["placement"]) => {
  // height of the triangular "point" on the tooltip bubble
  const pointSize = 12;
  // length in pixels from corner of bubble to "point", for "____Start" and "____End" placements of tooltip.
  const pointEdgePlacement = 10;

  switch ($placement) {
    case "leftStart":
      return `right: -${pointSize}px; top: ${pointEdgePlacement}px; transform: translateY(-50%) rotate(90deg);`;
    case "left":
      return `right: -${pointSize}px; top: 50%; transform: translateY(-50%) rotate(90deg);`;
    case "leftEnd":
      return `right: -${pointSize}px; top: calc(100% - ${pointEdgePlacement}px); transform: translateY(-50%) rotate(90deg);`;
    case "rightStart":
      return `left: -${pointSize}px; top: ${pointEdgePlacement}px; transform: translateY(-50%) rotate(-90deg);`;
    case "right":
      return `left: -${pointSize}px; top: 50%; transform: translateY(-50%) rotate(-90deg);`;
    case "rightEnd":
      return `left: -${pointSize}px; top: calc(100% - ${pointEdgePlacement}px); transform: translateY(-50%) rotate(-90deg);`;
    case "topStart":
      return `bottom: -${pointSize}px; left: ${pointEdgePlacement}px; transform: translateX(-50%) rotate(180deg);`;
    case "top":
      return `bottom: -${pointSize}px; left: 50%; transform: translateX(-50%) rotate(180deg);`;
    case "topEnd":
      return `bottom: -${pointSize}px; left: calc(100% - ${pointEdgePlacement}px); transform: translateX(-50%) rotate(180deg);`;
    case "bottomStart":
      return `top: -${pointSize}px; left: ${pointEdgePlacement}px; transform: translateX(-50%);`;
    case "bottom":
      return `top: -${pointSize}px; left: 50%; transform: translateX(-50%);`;
    case "bottomEnd":
      return `top: -${pointSize}px; left: calc(100% - ${pointEdgePlacement}px); transform: translateX(-50%);`;
  }
};

export const Wrapper = styled.div<{ $placement: PopoverProps["placement"] }>`
  display: flex;
  flex-direction: column;
  gap: 8px;

  width: max-content;

  color: ${(p) => p.theme.tooltip.text};
  background-color: ${(p) => p.theme.tooltip.background};

  border-radius: 4px;

  padding: 12px;
  ${(p) => sideMargin(p.$placement)};

  box-shadow:
    0 0 2px ${(p) => transparentize(0.8, p.theme.tooltip.shadow)},
    0 2px 12px ${(p) => transparentize(0.9, p.theme.tooltip.shadow)};

  &:after {
    content: "";
    position: absolute;
    pointer-events: none;
  }

  &::after {
    ${(p) => sideMargin(p.$placement)};
    ${(p) => pointPlacement(p.$placement)};
    border: 7px solid transparent;
    border-bottom-color: ${(p) => p.theme.tooltip.background};
  }
`;

export const Title = styled.h3`
  margin: 0;
  font-weight: 600;
  font-size: 12px;
`;

export const Body = styled.div`
  overflow: hidden;

  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: 0.2px;
  white-space: pre-line;
`;

export const Footer = styled.div`
  display: flex;
  margin-top: 8px;
  gap: 10px;
  justify-content: flex-end;

  & button {
    margin: 0;
    padding: 0;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.4px;

    &:focus:enabled {
      outline: none;
      box-shadow: none;
    }

    &:hover:enabled {
      color: ${(p) => p.theme.tooltip.hoverButtonText};
    }
  }
`;
