import styled from "styled-components";
import { transparentize } from "polished";

export const Shade = styled.div`
  height: 100vh;
  width: 100vw;

  background-color: ${(p) => transparentize(0.5, p.theme.background.primary)};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StackTrace = styled.pre`
  color: ${(p) => p.theme.color.brand.red};
  background-color: ${(p) => p.theme.background.secondary};
  padding: 1.5rem;
  border-radius: 0.5rem;
  font-family: monospace;
  white-space: pre-wrap;
  max-width: 80vw;
  max-height: 60vh;
  overflow-y: scroll;
  justify-self: flex-start;
  align-self: flex-start;
`;

export const DetailWrapper = styled.details`
  margin-bottom: 1rem;
`;
