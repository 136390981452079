import React from "react";
import { useForm } from "react-hook-form";
import { t } from "@lingui/macro";
import { pick } from "ramda";

import { Button } from "_/components/button";
import { dateOnlyString } from "_/components/date-time";
import { Machine } from "_/data/machines";
import { AdminMachineListing } from "_/components/machine-listing";
import { SubmitCancelButtons, useModal } from "_/components/modal";
import { FormTextField } from "_/components/text-field";
import { FormSelect } from "_/components/select";
import { FormField } from "_/components/form-field";
import { PermissionTest, testPermissions } from "_/components/permission-test";

import { useOrgs, useCurrentOrg } from "_/data/orgs";
import { ROOT_DOMAIN, useCurrentUserPermissions } from "_/data/rbac";

import { Uuid } from "_/types";
import { SERIAL_NUMBER_REGEX } from "_/utils/consts";

import * as S from "./styled";

/**
 * Form for creating a new machine, or editing existing machine details..
 */
const MachineEditForm = ({
  machine,
  onClose,
}: {
  machine?: Machine;
  onClose: () => void;
}) => {
  const defaultValues = machine
    ? {
        ...pick(["name"], machine),
        ...pick(["serial", "manufactureDate"], machine.hardware),
      }
    : {
        name: "",
        serial: "",
        manufactureDate: dateOnlyString({
          value: new Date(),
          dateDelimiter: "-",
        }),
      };

  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm({ defaultValues });

  const onSubmit = handleSubmit((_data) => {
    console.warn("Not implemented!");
    onClose();
  });

  return (
    <form onSubmit={onSubmit}>
      <FormTextField
        control={control}
        name="serial"
        label={t`common.serial-number`}
        placeholder={t`common.serial-number`}
        rules={{
          required: t`components.admin-view.serial-number-required`,
          pattern: {
            value: SERIAL_NUMBER_REGEX,
            message: t`components.admin-view.serial-number-invalid`,
          },
        }}
      />
      <FormTextField
        control={control}
        name="name"
        label={t`common.name`}
        placeholder={t`common.name`}
        rules={{
          required: t`components.admin-view.name-required`,
          maxLength: {
            value: 255,
            message: t`components.admin-view.validate-machine-name-length`,
          },
        }}
      />
      <FormTextField
        control={control}
        name="manufactureDate"
        label={t`common.machine-manufacture-date`}
        placeholder={t`common.machine-manufacture-date`}
        type="date"
        rules={{
          required: t`components.admin-view.machine-manufacture-date-required`,
        }}
      />
      <SubmitCancelButtons onCancel={onClose} disableSubmit={!isDirty} />
    </form>
  );
};

/**
 * Form for transferring machine hardware from one organization to another.
 */
const HardwareTransferForm = ({
  machine,
  onClose,
}: {
  machine: Machine;
  onClose: () => void;
}) => {
  const { data: orgs } = useOrgs();
  const defaultValues = { org: undefined as Uuid | undefined };
  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm({ defaultValues });

  const onSubmit = handleSubmit((data) => {
    console.log(`transfer machine ${machine.id}`, data);
    onClose();
  });

  if (!orgs?.length) return null;

  return (
    <form onSubmit={onSubmit}>
      <FormField label={t`common.serial-number`}>
        {machine.hardware.serial}
      </FormField>
      <FormField label={t`common.name`}>{machine.name}</FormField>
      <FormField label={t`common.machine-manufacture-date`}>
        {dateOnlyString({ value: machine.hardware.manufactureDate })}
      </FormField>
      <FormSelect
        control={control}
        name="org"
        label={t`common.admin-view.transfer-destination-org`}
        options={orgs.map((o) => ({
          label: o.name,
          value: o.id,
        }))}
      />
      <SubmitCancelButtons
        submitButtonLabel={t`components.admin-view.transfer-button-label`}
        onCancel={onClose}
        disableSubmit={!isDirty}
      />
    </form>
  );
};

/**
 * Main factory view for management page.
 */
export const FactoryMachines = () => {
  const editModal = useModal<Machine | undefined>();
  const transferModal = useModal<Machine>();

  const { data: org } = useCurrentOrg();
  const { data: permissions } = useCurrentUserPermissions(org.domainId);

  const canEdit = testPermissions("machines/write", permissions);

  const actions = canEdit
    ? (machine: Machine) => [
        {
          render: t`components.admin-view.transfer-machine`,
          onClick: () => transferModal.openModal(machine),
        },
        {
          render: t`common.edit`,
          onClick: () => editModal.openModal(machine),
        },
      ]
    : undefined;

  return (
    <S.AdminContent>
      <S.AdminContentSection>
        <S.AdminContentSectionTitleContainer>
          <h2>{t`components.admin-view.factory-machines`}</h2>
          <PermissionTest
            domainId={ROOT_DOMAIN}
            requiredPermissions={"machines/create"}
            render={({ allowed }) => (
              <Button
                onClick={() => editModal.openModal(undefined)}
                disabled={!allowed}
              >{t`components.admin-view.create-machine`}</Button>
            )}
          />
        </S.AdminContentSectionTitleContainer>
        <AdminMachineListing orgId={org.id} actions={actions} />
      </S.AdminContentSection>
      <editModal.Modal
        title={
          editModal.modalData
            ? t`components.admin-view.edit-machine`
            : t`components.admin-view.create-machine`
        }
      >
        {editModal.isModalOpen && (
          <MachineEditForm
            onClose={editModal.closeModal}
            machine={editModal.modalData}
          />
        )}
      </editModal.Modal>
      <transferModal.Modal title={t`components.admin-view.transfer-machine`}>
        {transferModal.modalData && (
          <HardwareTransferForm
            onClose={transferModal.closeModal}
            machine={transferModal.modalData}
          />
        )}
      </transferModal.Modal>
    </S.AdminContent>
  );
};
