import styled, { css } from "styled-components";

const activeStyles = css`
  border-color: ${(p) => p.theme.input.focused.border};
  background-color: ${(p) => p.theme.input.focused.background};
`;

export const Wrapper = styled.div<{ $dragActive: boolean; $invalid?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  border-radius: 8px;
  border: 1px dashed
    ${(p) =>
      p.$invalid ? p.theme.input.invalid.border : p.theme.input.default.border};

  background-color: ${(p) =>
    p.$invalid
      ? p.theme.input.invalid.background
      : p.theme.input.default.background};

  padding: 6px 8px 16px;

  height: 100%;

  &:hover {
    border-color: ${(p) =>
      p.$invalid ? p.theme.input.invalid.border : p.theme.input.hover.border};
  }

  &:focus {
    border-color: ${(p) =>
      p.$invalid ? p.theme.input.invalid.border : p.theme.input.focused.border};
  }

  ${(p) => (p.$dragActive ? activeStyles : null)}
`;

export const Chip = styled.span<{ $invalid?: boolean }>`
  background-color: ${(p) =>
    p.$invalid
      ? p.theme.input.invalid.border
      : p.theme.color.palette.twilightBlue};
  border-radius: 4px;
  padding: 4px 6px;

  color: ${(p) =>
    p.$invalid
      ? p.theme.color.palette.white
      : p.theme.color.palette.electricBlue};
  line-height: 16px;
  font-size: 12px;
  font-weight: 600;
`;

export const Description = styled.div<{ $invalid?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 8px;

  vertical-align: middle;

  margin-top: 8px;
  color: ${(p) =>
    p.$invalid ? p.theme.input.invalid.text : p.theme.input.default.text};

  font-size: 16px;
`;

// Avoids height jumping when the drag is active and the chip is removed.
export const DescriptionContainer = styled.div`
  display: flex;
  align-items: center;

  height: 24px;
`;

export const FileList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-bottom: 12px;
  width: 100%;
`;
