import styled from "styled-components";

export const RevisionOrJobNumber = styled.span`
  font-size: 0.75rem;
  padding: 0.125rem 0.25rem;
  align-self: center;
  margin-left: 0.125rem;
  color: ${(p) => p.theme.text.default};
  background-color: ${(p) => p.theme.badge.background};
  border-radius: 4px;
  white-space: nowrap;
`;
