import React, { ReactElement } from "react";

export const Home = (): ReactElement => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 30.0001L36 9L63 30.0001M57.0001 25.3333V63M57.0001 63H15M57.0001 63H63M15 63V25.3333M15 63H9M41.9999 63V41.9999H30.0001V63"
        stroke="currentColor"
        strokeWidth="0.075em"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
