import React, {
  HTMLAttributes,
  ReactNode,
  useRef,
  useMemo,
  useCallback,
  useState,
  ReactElement,
  MouseEventHandler,
} from "react";

import * as S from "./styled";
import { EventType, Placement, TimeoutHandle } from "./types";

export type PopoverProps = Omit<HTMLAttributes<HTMLDivElement>, "content"> & {
  /** Type of event to trigger the overlay display. */
  event?: EventType;
  /** Placement of the overlay content relative to the target. */
  placement: Placement;
  /** Target element for overlay to be bound to. */
  target: ReactNode;
  /** Content to display in the overlay. */
  content: ReactNode;
  /** Delay in milliseconds before hiding overlay with mouseout events. */
  delay?: number;
  /** Wait time in milliseconds before showing the panel with hover events. */
  wait?: number;
  /** If true, keeps overlay open regardless of hover / click state. */
  keepOpen?: boolean;
  /** Add `width: 100%` to the target wrapper element's styling */
  fullWidth?: boolean;
};

export const Overlay = ({
  wait = 0,
  delay = 250,
  event = "hover",
  placement = "bottomStart",
  keepOpen = false,
  fullWidth = false,
  target,
  content,
  ...props
}: PopoverProps): ReactElement => {
  const [showPanel, setShowPanel] = useState(false);
  const closeTimeout = useRef<TimeoutHandle | null>(null);
  const openTimeout = useRef<TimeoutHandle | null>(null);

  const clickable = event === "click";
  const hoverable = event === "hover";

  // Handler to close the panel and remove document listener.
  const closePanel = useCallback((_e: unknown) => {
    document.removeEventListener("click", closePanel);
    setShowPanel(false);
  }, []);

  // Handler to open the panel when hovered with optional timeout.
  const panelHover = useCallback(() => {
    clearTimeout(closeTimeout.current || undefined);
    clearTimeout(openTimeout.current || undefined);

    if (wait > 0) {
      openTimeout.current = setTimeout(() => setShowPanel(true), wait);
    } else {
      setShowPanel(true);
    }
  }, [openTimeout, closeTimeout, setShowPanel, wait]);

  const panelUnhover = useCallback(() => {
    clearTimeout(closeTimeout.current || undefined);
    clearTimeout(openTimeout.current || undefined);

    if (delay > 0) {
      closeTimeout.current = setTimeout(() => setShowPanel(false), delay);
    } else {
      setShowPanel(false);
    }
  }, [delay, closeTimeout, setShowPanel]);

  // Handler to toggle the content visibility and bind the `closePanel` handler.
  const togglePanel = useCallback<MouseEventHandler>(
    (e) => {
      e.stopPropagation();

      setShowPanel(!showPanel);

      if (showPanel) {
        document.removeEventListener("click", closePanel);
      } else {
        // dispatch a click event to the document to close any open panels, if they exist.
        document.dispatchEvent(new Event("click"));
        document.addEventListener("click", closePanel);
      }
    },
    [showPanel, closePanel]
  );

  // The panel contains the overlaid content.
  const panel = useMemo(() => {
    return showPanel || keepOpen ? (
      <S.PanelWrapper>
        <S.PanelContent
          onMouseEnter={hoverable ? () => panelHover() : undefined}
          onMouseLeave={hoverable ? () => panelUnhover() : undefined}
          $placement={placement}
        >
          {content}
        </S.PanelContent>
      </S.PanelWrapper>
    ) : null;
  }, [
    showPanel,
    keepOpen,
    hoverable,
    placement,
    panelHover,
    panelUnhover,
    content,
  ]);

  return (
    <S.Wrapper
      $fullWidth={fullWidth}
      onMouseLeave={hoverable ? panelUnhover : undefined}
      {...props}
    >
      <S.TargetWrapper
        onClick={clickable ? togglePanel : undefined}
        onMouseEnter={hoverable ? panelHover : undefined}
      >
        {target}
      </S.TargetWrapper>
      {panel}
    </S.Wrapper>
  );
};
