import styled from "styled-components";
import { Icon as _Icon } from "_/components/icon";

export const Icon = styled(_Icon)`
  font-size: 1.2em;

  cursor: pointer;

  &:active {
    opacity: 0.5;
  }
`;

export const DownloadItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 16px 0;
  border-bottom: none;
`;
