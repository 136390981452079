import styled from "styled-components";

export const LabelledSlider = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.375rem;

  width: 100%;

  padding: 1rem;
`;

export const ControlLabel = styled.span`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  font-size: 0.875rem;
  color: ${(p) => p.theme.text.muted};
  font-weight: 600;
`;

export const SliderValueLabel = styled.span`
  font-weight: 400;
  color: ${(p) => p.theme.text.muted};
  white-space: nowrap;
  margin-left: 0.5rem;

  /* 
    Use a font variant that makes numbers show up with consistent width. This is
    important so that the slider value doesn't jump around as the user changes
    the thumb positions. Note that our Neufile Grotesk font does not support
    this variant, so we use similar fonts that do support it.
  */
  font-family: Helvetica, Arial, sans-serif;
  font-variant-numeric: tabular-nums;
`;
