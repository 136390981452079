import React, { useState, useEffect, ReactElement } from "react";
import { isEmpty, all, has, __ } from "ramda";
import { useLocation } from "_/components/router";
import { t } from "@lingui/macro";
import { useForm } from "react-hook-form";

import { H5, P } from "_/components/typography";
import { useToasts } from "_/components/toasts";
import { FormTextField } from "_/components/text-field";
import { SubmitCancelButtons } from "_/components/modal";

import {
  useCheckUser,
  useRequestPasswordReset,
  usePasswordReset,
} from "_/data/users";

import { Uuid } from "_/types";

import { routeUrls } from "_/routes";

import * as S from "./styled";
/**
 * Expected query string recovery parameters.
 */
type RecoveryParams = {
  /**
   * Password recovery token.
   */
  t: string;
  /**
   * User account ID for which the recovery token is valid.
   */
  i: Uuid;
};

/**
 * Form component for requesting a password reset email.
 */
const RequestResetForm = (): ReactElement => {
  const [_, addToast] = useToasts();
  const [_location, setLocation] = useLocation();
  const requestPasswordReset = useRequestPasswordReset();
  const defaultValues = { email: "" };
  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm({ defaultValues });

  const onSubmit = handleSubmit(async (data) => {
    try {
      await requestPasswordReset.mutateAsync(data);

      setLocation(routeUrls.public.login);

      addToast({
        title: t`common.success`,
        content: t`components.request-reset-form.success-content ${data.email}`,
        kind: "success",
        timeout: 10_000,
      });
    } catch (_e) {
      addToast({
        title: t`common.error`,
        content: t`components.request-reset-form.error-content`,
        kind: "danger",
      });
    }
  });

  return (
    <S.FormWrapper>
      <H5>{t`components.request-reset-form.title`}</H5>
      <P>{t`components.request-reset-form.description`} </P>
      <form onSubmit={onSubmit}>
        <FormTextField
          label={t`common.email`}
          control={control}
          name="email"
          rules={{ required: t`components.request-reset-form.email-required` }}
          placeholder={t`common.email`}
        />
        <SubmitCancelButtons
          submitButtonLabel={t`common.send`}
          disableSubmit={!isDirty}
        />
      </form>
    </S.FormWrapper>
  );
};

type SubmitResetFormProps = {
  id: Uuid;
  token: string;
};

/**
 * Form for resetting password.
 */
const SubmitResetForm = ({ id, token }: SubmitResetFormProps): ReactElement => {
  const [_location, setLocation] = useLocation();
  const [_toasts, addToast] = useToasts();
  const passwordReset = usePasswordReset();
  const defaultValues = { new: { password: "", valid: false }, confirm: "" };
  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
  } = useForm({ defaultValues });

  const onSubmit = handleSubmit(async (data) => {
    if (!data.new.valid) {
      addToast({
        kind: "danger",
        title: t`common.error`,
        content: t`common.new-password-too-weak`,
        timeout: 5000,
      });

      return;
    }

    if (data.new.password !== data.confirm) {
      addToast({
        kind: "danger",
        title: t`common.error`,
        content: t`common.new-password-confirm-doesnt-match`,
        timeout: 5000,
      });

      return;
    }

    try {
      await passwordReset.mutateAsync({
        id,
        token,
        password: data.new.password,
      });

      setLocation(routeUrls.public.login);

      addToast({
        kind: "success",
        title: t`common.password-change-success-toast-title`,
        timeout: 5000,
      });
    } catch (_e) {
      addToast({
        kind: "danger",
        title: t`common.error`,
        content: t`common.password-change-error-toast-content`,
        timeout: 5000,
      });
    }
  });

  const allFieldsDirty = all(has(__, dirtyFields), ["new", "confirm"]);

  return (
    <S.FormWrapper>
      <H5>{t`components.submit-reset-form.title`}</H5>
      <form onSubmit={onSubmit}>
        <FormTextField
          control={control}
          name="new"
          rules={{ required: t`common.password-required` }}
          label={t`common.new-password-label`}
          type="new-password"
          autoComplete="new-password"
        />
        <FormTextField
          control={control}
          name="confirm"
          label={t`common.confirm-new-password-label`}
          rules={{ required: t`common.password-required` }}
          placeholder={t`common.password`}
          autoComplete="confirm-password"
          type="password"
        />
        <SubmitCancelButtons
          submitButtonLabel={t`common.change-password-submit`}
          disableSubmit={!allFieldsDirty}
        />
      </form>
    </S.FormWrapper>
  );
};

/**
 * Main account recovery view component.
 */
export const AccountRecovery = (): ReactElement => {
  const [location, setLocation] = useLocation();
  const [queryParams, setQueryParams] = useState<
    RecoveryParams | Record<string, never>
  >({});
  const { data: user } = useCheckUser();

  useEffect(() => {
    // Redirect home if the user is already logged in.
    if (user) {
      setLocation(routeUrls.index);
    }

    const searchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(searchParams.entries());

    setQueryParams((p) => ({ ...p, ...params }) as RecoveryParams);

    // Rewrite history to clear query params on pageload.
    setLocation(location, { replace: true });
  }, [location, user, setLocation, setQueryParams]);

  return isEmpty(queryParams) ? (
    <RequestResetForm />
  ) : (
    <SubmitResetForm id={queryParams.i} token={queryParams.t} />
  );
};
