import React, { ReactNode, ReactElement } from "react";

import { Icon, IconVariant } from "_/components/icon";

import * as S from "./styled";

export const Notice = ({
  kind,
  children,
}: {
  kind: "caution" | "warning" | "notice" | "tip";
  children: ReactNode;
}): ReactElement => {
  let bgColor = "rgba(0, 0, 0, 0.0)";
  let label = "";
  let icon: IconVariant = "Info";

  // TODO: Replace these colors with color theme colors.
  // TODO: Use l10n for these labels.
  // TODO: Re-evaluate these icons.
  switch (kind) {
    case "notice":
      bgColor = "#14294f";
      label = "Notice";
      icon = "Info";
      break;
    case "caution":
      bgColor = "#876507";
      label = "Caution";
      icon = "Warning";
      break;
    case "warning":
      bgColor = "#6e2215";
      label = "Warning";
      icon = "Warning";
      break;
    case "tip":
      bgColor = "#2052ec";
      label = "Tip";
      icon = "Info";
      break;
  }

  return (
    <S.Notice $bgColor={bgColor}>
      <S.NoticeHeader>
        <Icon variant={icon} label={label} />
        {label != "" && <S.NoticeTitle>{label}</S.NoticeTitle>}
      </S.NoticeHeader>
      {children}
    </S.Notice>
  );
};
