import styled from "styled-components";

import { UserAvatar as _UserAvatar } from "_/components/avatar";
import { Button as _Button } from "_/components/button";
import { Icon as _Icon } from "_/components/icon";
import { H5 } from "_/components/typography";

export const Wrapper = styled.div`
  background-color: ${(p) => p.theme.background.primary};
  height: 100%;
`;

export const Header = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 56px;
  padding-bottom: 40px;
`;

export const Nav = styled.nav`
  display: flex;
  justify-content: center;
`;

export const Form = styled.form``;

export const Section = styled.section`
  padding: 30px 0 90px;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 20px;
  min-width: 360px;
  max-width: fit-content;
`;

export const SectionTitle = styled(H5)`
  margin-bottom: 1.5rem;
`;

export const AvatarLayout = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  position: relative;
`;

export const AvatarActionsContainer = styled.div`
  position: absolute;
  display: flex;
  bottom: 0;
  left: 75%;
`;

export const ChangeAvatarButton = styled.button`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  gap: 8px;
  width: 40px;
  height: 40px;
  font-size: 18px;
  color: ${(p) => p.theme.text.default};
  background-color: ${(p) => p.theme.background.secondary};
  border: 1px solid ${(p) => p.theme.background.primary};
  border-radius: 100%;
  cursor: pointer;

  &:active:enabled {
    transform: scale(1.0325);
  }

  &:hover:enabled {
    background-color: ${(p) => p.theme.background.tertiary};
  }
`;

export const RemoveAvatarButton = styled(_Button)`
  color: ${(p) => p.theme.text.default};
  width: max-content;

  &:focus:enabled {
    outline: none;
    box-shadow: none;
  }

  &:hover:enabled {
    color: ${(p) => p.theme.text.hover};
  }
`;

export const ForgotPasswordWrapper = styled.div`
  color: ${(p) => p.theme.link.default};
  padding: 0;

  margin-top: -12px;
  margin-bottom: 16px;

  font-weight: lighter;

  a {
    font-size: 14px;
  }

  &:focus:enabled {
    outline: none;
    box-shadow: none;
  }
`;

export const SelectWrapper = styled.div`
  margin: 0.5rem 0 1rem;
`;

export const ClickableIcon = styled(_Icon)`
  cursor: pointer;
`;

export const OrgNameCell = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  min-width: 150px;
`;

export const TextFieldWrapper = styled.div`
  margin-bottom: 12px;
`;

export const OrgRoleLabel = styled.span<{ $global?: boolean }>`
  color: ${(p) => p.$global && p.theme.color.palette.tomatoRed};
`;
