import React, { HTMLAttributes, ReactElement } from "react";
import styled from "styled-components";

import * as S from "./styled";

import {
  MdAdminPanelSettings,
  MdCircle,
  MdMenuBook,
  MdOutlineAdd,
  MdOutlineApartment as MdOutlineCompany,
  MdOutlineArrowCircleDown,
  MdOutlineArrowCircleUp,
  MdOutlineArrowDropDown,
  MdOutlineArrowDropUp,
  MdOutlineBlock,
  MdOutlineBuildCircle,
  MdOutlineCheckCircle,
  MdOutlineChevronLeft,
  MdOutlineChevronRight,
  MdOutlineClose,
  MdOutlineConstruction,
  MdOutlineContentCopy,
  MdOutlineDelete,
  MdOutlineEdit,
  MdOutlineErrorOutline,
  MdOutlineExpandLess,
  MdOutlineExpandMore,
  MdOutlineFileDownload,
  MdOutlineFileUpload,
  MdOutlineFilterList,
  MdOutlineGridView,
  MdOutlineHelpOutline,
  MdOutlineImage,
  MdOutlineInfo,
  MdOutlineInsertDriveFile,
  MdOutlineLogout,
  MdOutlineMenu,
  MdOutlineMoreVert,
  MdOutlinePerson,
  MdOutlinePhotoCamera,
  MdOutlineViewHeadline,
  MdOutlineVisibility,
  MdOutlineVisibilityOff,
  MdOutlineWarningAmber as MdOutlineWarning,
} from "react-icons/md";

import * as AonIcons from "./aon-icons";

// Manually construct objects of third-party icons needed so that the
// webpack build can tree-shake, only using the required icon components.
const MdIcons = {
  MdAdminPanelSettings,
  MdCircle,
  MdMenuBook,
  MdOutlineAdd,
  MdOutlineArrowCircleDown,
  MdOutlineArrowCircleUp,
  MdOutlineArrowDropDown,
  MdOutlineArrowDropUp,
  MdOutlineBlock,
  MdOutlineBuildCircle,
  MdOutlineCheckCircle,
  MdOutlineChevronLeft,
  MdOutlineChevronRight,
  MdOutlineClose,
  MdOutlineCompany,
  MdOutlineConstruction,
  MdOutlineContentCopy,
  MdOutlineDelete,
  MdOutlineEdit,
  MdOutlineErrorOutline,
  MdOutlineExpandLess,
  MdOutlineExpandMore,
  MdOutlineFileDownload,
  MdOutlineFileUpload,
  MdOutlineFilterList,
  MdOutlineGridView,
  MdOutlineHelpOutline,
  MdOutlineImage,
  MdOutlineInfo,
  MdOutlineInsertDriveFile,
  MdOutlineLogout,
  MdOutlineMenu,
  MdOutlineMoreVert,
  MdOutlinePerson,
  MdOutlinePhotoCamera,
  MdOutlineViewHeadline,
  MdOutlineVisibility,
  MdOutlineVisibilityOff,
  MdOutlineWarning,
};

type StripPrefix<
  TPrefix extends string,
  T extends string,
> = T extends `${TPrefix}${infer R}` ? R : T;

type StripSuffix<
  TSuffix extends string,
  T extends string,
> = T extends `${infer R}${TSuffix}` ? R : T;

type StripPrefixFromKeys<T, TPrefix extends string> = {
  [K in keyof T & string as StripPrefix<TPrefix, K>]: T[K];
};
type StripSuffixFromKeys<T, TSuffix extends string> = {
  [K in keyof T & string as StripSuffix<TSuffix, K>]: T[K];
};

type MdIconsTransform1 = StripPrefixFromKeys<typeof MdIcons, "Md">;
type MdIconsTransform2 = StripPrefixFromKeys<MdIconsTransform1, "Outline">;
type MdIconsTransformFinal = StripSuffixFromKeys<MdIconsTransform2, "Outline">;

// Remove "MdOutline" or "Outline" prefixes and/or "Outline" suffix from
// Material Design icon names
const transformIconName = (name: keyof typeof MdIcons) => {
  return name
    .replace(/^(Md)/, "")
    .replace(/^(Outline)/, "")
    .replace(/(Outline)$/, "") as keyof MdIconsTransformFinal;
};
const MdIconsTransformedKeys = Object.fromEntries(
  Object.entries(MdIcons).map(([iconName, icon]) => [
    transformIconName(iconName),
    icon,
  ])
) as MdIconsTransformFinal;

const AonIconVariants = Object.keys(AonIcons);
const MdIconVariants = Object.keys(MdIconsTransformedKeys);

// export icon names for Storybook story, in order to render all icons from a list
export const iconNames = [...AonIconVariants, ...MdIconVariants];

export type IconVariant =
  | (typeof AonIconVariants)[number]
  | (typeof MdIconVariants)[number];

const iconRegistry = {
  ...AonIcons,
  ...MdIconsTransformedKeys,
};

type IconProps = HTMLAttributes<HTMLSpanElement> & {
  variant: IconVariant;
  label?: string;
};

const _Icon = (props: IconProps): ReactElement => {
  const { variant, label, ...restProps } = props;

  // returns object with different fill style for given icon variant
  const IconComponent = iconRegistry[variant];

  if (!IconComponent) {
    throw new Error(`Icon variant "${variant}" is invalid.`);
  }

  return (
    <S.Icon tabIndex={restProps.onClick ? 0 : -1} {...restProps}>
      <IconComponent aria-label={label} style={{ fontSize: "inherit" }} />
    </S.Icon>
  );
};

export const Icon = styled(_Icon)``;
