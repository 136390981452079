import { Vector3 } from "three";

import { Mesh } from "./index";

export function parseSTL(buf: ArrayBuffer): Mesh {
  const view = new DataView(buf);

  // ASCII STL files should start with the magic word "solid", however this is
  // not always adhered to depending on the software used to generate the file.
  //
  // We still check for the indication to hint at the filetype, however
  // attempt to load using both file parsers if the expected filetype is
  // incorrect.
  const magic = "solid";

  let ascii = true;
  for (let i = 0; i < magic.length; i++) {
    const char = view.getUint8(i);

    if (char != magic.charCodeAt(i)) {
      ascii = false;
    }
  }

  if (ascii) {
    try {
      return parseAsciiSTL(buf);
    } catch {
      console.warn("ASCII STL file not to spec. Parsing with binary reader.");
      return parseBinarySTL(buf);
    }
  } else {
    try {
      return parseBinarySTL(buf);
    } catch {
      console.warn("Binary STL file not to spec. Parsing with ASCII reader.");
      return parseAsciiSTL(buf);
    }
  }
}

function parseBinarySTL(buf: ArrayBuffer): Mesh {
  const data = new DataView(buf);
  const mesh = new Mesh();

  // Values are stored as 32 bit floats or uints.
  const valueSize = 4;

  // Binary header preamble is 80 bytes long, but can be ignored as we do not
  // care about STL colors.
  const headerLength = 80;

  // The mesh facet count is immediately located after the header as a uint32.
  const facetCount = data.getUint32(headerLength, true);

  // Each facet stores the normal and 3 vertices, followed by an
  // "attribute byte count" which we ignore.
  const vertexSize = valueSize * 3;
  const normalSize = valueSize * 3;
  const attribCountSize = 2;
  const facetSize = normalSize + vertexSize * 3 + attribCountSize;

  for (let iFacet = 0; iFacet < facetCount; iFacet++) {
    const offset = headerLength + valueSize + iFacet * facetSize;

    // Skip vertex normals.

    const facet: Vector3[] = [];

    // Parse the three vertices of the facet.
    for (let i = 0; i < 3; i++) {
      const vertexOffset = offset + normalSize + vertexSize * i;

      const x = data.getFloat32(vertexOffset + valueSize * 0, true);
      const y = data.getFloat32(vertexOffset + valueSize * 1, true);
      const z = data.getFloat32(vertexOffset + valueSize * 2, true);

      const point = new Vector3(x, y, z);

      facet.push(point);
    }

    mesh.addFacet(facet);
  }

  return mesh;
}

function parseAsciiSTL(buf: ArrayBuffer): Mesh {
  const bufString = new TextDecoder().decode(buf);
  // TODO: change to `toReversed` in 2025
  const data = bufString.trim().split("\n").reverse();

  const mesh = new Mesh();

  let line;

  while (data.length) {
    line = consume(data);
    expect(line.startsWith("solid"), 'Expecting "solid".');

    while (!line.startsWith("endsolid")) {
      line = consume(data);

      if (line.startsWith("facet normal")) {
        // Discard "outer loop" portion and skip vertex normals.
        line = consume(data);
        expect(!!line, "outer loop");

        const facet: Vector3[] = [];

        for (let i = 0; i < 3; i++) {
          const line = consume(data);
          const vertices = line.split(/\s+/).slice(1);

          const x = parseFloat(vertices[0]);
          const y = parseFloat(vertices[1]);
          const z = parseFloat(vertices[2]);

          facet.push(new Vector3(x, y, z));
        }

        mesh.addFacet(facet);

        // Discard "endloop" portion.
        expect(consume(data) === "endloop", 'Expecting "endloop".');
        expect(consume(data) === "endfacet", 'Expecting "endfacet".');
      }
    }
  }

  return mesh;
}

function consume(data: string[]): string {
  let s = data.pop();

  if (!s) {
    throw new Error("No data left to consume.");
  }

  s = s.trim();

  return s === "" ? consume(data) : s;
}

function expect(pred: boolean, msg: string): void {
  if (!pred) {
    throw new Error(msg);
  }
}
