import styled from "styled-components";

/* Border radius is set to an arbitrarily high value to ensure that the progress bar is fully rounded */

export const Progress = styled.progress`
  background-color: ${(p) => p.theme.progressBar.background};

  border: none;
  border-radius: 25px;

  width: 100%;
  height: 6px;

  margin: 6px 0;

  /* :-webkit-progress-bar targets the same element for Chrome/Safari as the properties above do for Firefox */

  &::-webkit-progress-bar {
    background-color: ${(p) => p.theme.progressBar.background};
    border-radius: 25px;
  }

  /* :-webkit-progress-value and -moz-progress-bar target the same element on their respective platforms */

  &::-webkit-progress-value {
    background-color: ${(p) => p.theme.progressBar.progress};
    border-radius: 25px;
  }

  &::-moz-progress-bar {
    background-color: ${(p) => p.theme.progressBar.progress};
    border-radius: 25px;
  }
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.span`
  display: flex;
`;

export const Title = styled.span`
  font-size: 12px;
`;

export const Percentage = styled.span`
  margin-left: auto;
  font-size: 12px;
`;

export const Wrapper = styled.div`
  color: ${(p) => p.theme.progressBar.text};
`;
