/**
 * Projects view page component.
 *
 * @category pages
 */

import React, { ReactElement, ReactNode, useState } from "react";
import { t } from "@lingui/macro";

import { Project, useProject } from "_/data/projects";

import { ProjectHeader } from "_/components/project-header";
import { AttemptsListing } from "_/components/attempts-listing";
import { Viewer } from "_/components/viewer";
import { Switch, Redirect, Route } from "_/components/router";
import { ViewerOverlayNotice } from "_/components/modal";

import { projectRoutes, projectUrls } from "_/routes";

import { ViewerOverlayContext } from "./viewer-overlay-context";

import { Uuid } from "_/types";
import { isValidUuid } from "_/utils";

import * as S from "./styled";

export const ProjectNotFoundError = (): ReactElement => (
  <ViewerOverlayNotice
    title={t`common.project-not-found`}
  >{t`common.project-not-found-description`}</ViewerOverlayNotice>
);

export const BadUrlError = (): ReactElement => (
  <ViewerOverlayNotice
    title={t`common.invalid-url`}
  >{t`common.invalid-id-description`}</ViewerOverlayNotice>
);

export const ProjectDetail = ({
  projectId,
}: {
  projectId: Uuid;
}): ReactNode => {
  const [overlayRef, setOverlayRef] = useState<HTMLDivElement | null>(null);

  const projectResult = useProject(projectId);
  if (projectResult.isLoading) return null;

  const project = projectResult.data;

  let output: ReactElement;
  if (project) {
    // TODO: if this project's domain differs from the org's domain, then switch
    // the current org to the org that this project belongs to.
    output = <ProjectDetailRoutes project={project} />;
  } else if (isValidUuid(projectId)) {
    output = <ProjectNotFoundError />;
  } else {
    output = <BadUrlError />;
  }

  return (
    <ViewerOverlayContext.Provider value={overlayRef}>
      <S.Wrapper>
        {project && <ProjectHeader project={project} />}
        <S.TabContentContainer>
          {output}
          <S.OverlayRoot ref={setOverlayRef} />
        </S.TabContentContainer>
      </S.Wrapper>
    </ViewerOverlayContext.Provider>
  );
};

const ProjectDetailRoutes = ({
  project,
}: {
  project: Project;
}): ReactElement => (
  <Switch>
    <Route path={projectRoutes.wildcards.combinedViewer}>
      <Viewer project={project} />
    </Route>
    <Route path={projectRoutes.history}>
      <AttemptsListing project={project} />
    </Route>
    <Route path={projectRoutes.wildcards.index}>
      <Redirect
        to={projectUrls.prepare(project.id, project.revisions.at(-1)?.id)}
        replace={true}
      />
    </Route>
  </Switch>
);
