import styled from "styled-components";

import * as MachineDetailsS from "_/components/machine-details/styled";

const Widget = MachineDetailsS.TemperatureWidget;

export const MachineTemperatureContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  padding: 1.5rem;
`;

export const ChartWidget = styled(Widget)`
  padding-bottom: 3rem;
`;

export const Wrapper = styled.div`
  height: calc(100vh - 500px);
  /*
    TODO: when we have more time later, consider switching chart in dark theme
    to use a dark background
  */
  background-color: ${(p) => p.theme.color.palette.white};
`;
