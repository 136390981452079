import React, { ReactElement } from "react";
import { Route } from "_/components/router";

import { Login } from "_/components/login";
import { EmailVerify } from "_/components/email-verify";
import { AccountRecovery } from "_/components/account-recovery";
import { AccountRsvp } from "_/components/account-rsvp";
import { publicUrls } from "_/routes";

import * as S from "./styled";

export const Public = (): ReactElement => {
  return (
    <S.Wrapper>
      <Route path={publicUrls.login}>
        <S.LoginContainer>
          <Route path={publicUrls.login} component={Login} />
        </S.LoginContainer>
      </Route>
      <Route path={publicUrls.verify} component={EmailVerify} />
      <Route path={publicUrls.recovery} component={AccountRecovery} />
      <Route path={publicUrls.rsvp} component={AccountRsvp} />
    </S.Wrapper>
  );
};
