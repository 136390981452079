import styled from "styled-components";

import { Link } from "_/components/router";

export const SmallProjectCard = styled(Link)`
  display: grid;
  align-content: space-between;
  align-items: start;
  gap: 1rem;
  padding: 1rem 1rem 1rem 1.25rem;
  background-color: ${(p) => p.theme.background.secondary};
  transition: background-color 0.1s ease;
  &:hover {
    background-color: ${(p) => p.theme.background.tertiary};
  }
  border-radius: 4px;
`;

export const FirstRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 1rem;
  height: 2.5rem;
`;

export const SecondRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CardMeta = styled.span`
  font-size: 0.75rem;
  & > label {
    color: ${(p) => p.theme.text.muted};
    font-weight: 600;
  }
  & > div {
    color: ${(p) => p.theme.text.default};
  }
`;

export const CardMetaContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: auto 0 0;
  padding-right: 0.5rem;
`;

export const ProjectPreviewContainer = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
`;

export const ProjectPreviewImage = styled.img<{ $visibility: string }>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100px;
  height: 100px;
  object-fit: cover;
  visibility: ${(p) => p.$visibility};
`;

export const ProjectPlaceholderSVG = styled.svg<{ $visibility: string }>`
  visibility: ${(p) => p.$visibility};
  position: absolute;
  left: 0;
  top: 0;
  width: 100px;
  height: 100px;
  object-fit: cover;
  color: ${(p) => p.theme.text.default};
`;

export const ProjectName = styled.div`
  max-height: 2.5rem;
  line-height: 1.25rem;
  overflow: hidden;
`;
