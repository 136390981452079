import React, { useEffect, useMemo } from "react";
import { useThree } from "@react-three/fiber";
import {
  CylinderGeometry,
  InstancedBufferGeometry,
  InstancedBufferAttribute,
  Object3D,
} from "three";
import { GCodeMaterial } from "./gcode-material";
import { GCodePrintData } from "./gcode-print";
import { Triple } from "_/types";

/**
 * Component for managing the instanced mesh representing an extrusion from a single toolhead.
 */
interface GCodeMeshProps {
  printData: GCodePrintData;
  color: number;
  topLayer?: number;
  bottomLayer?: number;
  pathStart?: number;
  pathEnd?: number;
  featureTypes?: number[];
  position?: Triple<number>;
  onAfterRender?: Object3D["onAfterRender"];
}

/**
 * Component for rendering an extrusion instanced mesh, generating geometry attributes and managing material uniform updates.
 */
export const GCodeMesh = ({
  printData,
  color,
  topLayer = printData.topLayer,
  bottomLayer = 0,
  pathStart = 0,
  pathEnd = 100,
  featureTypes = Array(16).fill(1),
  position = [0, 0, 0],
  onAfterRender,
}: GCodeMeshProps) => {
  const { invalidate } = useThree();

  const material = useMemo(() => new GCodeMaterial({ color }), [color]);

  const instancedGeometry = useMemo(() => {
    const cylinderGeometry = new CylinderGeometry(0.275, 0.275, 1, 4);
    cylinderGeometry.scale(1, 1, 0.5);

    const instancedGeometry = new InstancedBufferGeometry();
    instancedGeometry.index = cylinderGeometry.index;
    instancedGeometry.attributes.position =
      cylinderGeometry.attributes.position;
    instancedGeometry.attributes.uv = cylinderGeometry.attributes.uv;
    instancedGeometry.attributes.normal = cylinderGeometry.attributes.normal;

    instancedGeometry.setAttribute(
      "layer",
      new InstancedBufferAttribute(printData.segmentLayer, 1)
    );
    instancedGeometry.setAttribute(
      "featureType",
      new InstancedBufferAttribute(printData.segmentFeatureType, 1)
    );
    instancedGeometry.setAttribute(
      "startVertex",
      new InstancedBufferAttribute(printData.segmentStartPoints, 3)
    );
    instancedGeometry.setAttribute(
      "endVertex",
      new InstancedBufferAttribute(printData.segmentEndPoints, 3)
    );

    instancedGeometry.instanceCount = printData.totalSegments;

    return instancedGeometry;
  }, [printData]);

  useEffect(() => {
    function clampLayerSelection(layer: number) {
      return Math.min(Math.max(layer, 0), printData.topLayer);
    }

    material.uniforms.topLayer.value = clampLayerSelection(topLayer);
    material.uniforms.bottomLayer.value = clampLayerSelection(bottomLayer);
    material.uniforms.featureTypes.value = featureTypes;

    material.needsUpdate = true;
    invalidate();
  }, [
    invalidate,
    pathStart,
    pathEnd,
    topLayer,
    bottomLayer,
    featureTypes,
    material,
    printData.topLayer,
  ]);

  return (
    <mesh
      geometry={instancedGeometry}
      material={material}
      position={position}
      onAfterRender={onAfterRender}
      frustumCulled={false}
    />
  );
};
