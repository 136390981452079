import React, { ReactElement, ReactNode } from "react";
import { t } from "@lingui/macro";

import { PartialMachineListing } from "_/components/machine-listing";
import { HomeWhatsNew } from "_/components/home-whats-new";
import { JobsListing } from "_/components/jobs-listing";
import { ProjectCards } from "_/components/project-card";

import * as S from "./styled";

const ProjectsWidget = () => {
  return (
    <S.HomeWidget>
      <S.HomeWidgetTitleContainer>
        <h2>{t`common.projects`}</h2>
      </S.HomeWidgetTitleContainer>
      <S.Carousel>
        {
          // TODO: add a "no projects" placeholder with a big "create project" button
          <ProjectCards limit={10} />
        }
        {
          // add dummy elements to fill out the carousel in case there's few projects
          new Array(20).fill(0).map((_ignore, i) => (
            <div key={i + 1000} />
          ))
        }
      </S.Carousel>
    </S.HomeWidget>
  );
};

const MachinesWidget = () => {
  return (
    <S.HomeWidget>
      <S.HomeWidgetTitleContainer>
        <h2>{t`common.machines`}</h2>
      </S.HomeWidgetTitleContainer>
      <PartialMachineListing />
    </S.HomeWidget>
  );
};

const JobsWidget = () => {
  return (
    <S.HomeWidget>
      <S.HomeWidgetTitleContainer>
        <h2>{t`common.jobs`}</h2>
      </S.HomeWidgetTitleContainer>
      <JobsListing variant="home" limit={30} minCancelledJobMinutes={10} />
    </S.HomeWidget>
  );
};

/**
 * Placeholder for a link that doesn't do anything yet.
 */
export const NotImplementedLink = ({ children }: { children: ReactNode }) => (
  <a href="#" onClick={(e) => e.preventDefault()}>
    {children}
  </a>
);

/**
 * View for displaying the user's home page dashboard.
 *
 * @category pages
 */
export const HomeDashboard = (): ReactElement => {
  return (
    <S.OuterWrapper>
      <S.InnerWrapper>
        <ProjectsWidget />
        <S.NestedWidgetContainer>
          <MachinesWidget />
          <S.UseRemainingWidth>
            <JobsWidget />
          </S.UseRemainingWidth>
        </S.NestedWidgetContainer>
      </S.InnerWrapper>
      <S.RightPane>
        <HomeWhatsNew />
      </S.RightPane>
    </S.OuterWrapper>
  );
};
