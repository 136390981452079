import React, { ReactElement, useState } from "react";
import { t } from "@lingui/macro";

import {
  Attempt,
  AttemptStatus,
  Project,
  Revision,
  useProjectAttempts,
  useRevisionResources,
} from "_/data/projects";
import { useMachine } from "_/data/machines";
import { useUser } from "_/data/users";
import {
  useAttemptDatasetManifest,
  AttemptDatasetManifest,
} from "_/data/projects";
import { blobDownloadURL, BlobRecord } from "_/data/blob";
import { useMaterials } from "_/data/materials";

import { Tuple, Uuid } from "_/types";

import { Table, ColumnDef } from "_/components/table";
import { Badge, BadgeKind } from "_/components/badge";
import { Button, ButtonGroup } from "_/components/button";
import { AttemptDataDownloadList } from "_/components/attempt-data-download-list";
import { Modal } from "_/components/modal";
import { DateTime, dateTimeString } from "_/components/date-time";
import { Icon } from "_/components/icon";
import { MaterialName } from "_/components/materials";
import { Link, NeverUnderlineSpace } from "_/components/router";

import { machineUrls, projectUrls } from "_/routes";

import * as S from "./styled";

/**
 * Helper component for rendering "Machine" column of an Attempts entry.
 */
const MachineCell = ({ machineId }: { machineId: Uuid }): ReactElement => {
  const { data: machine } = useMachine(machineId);

  return <Link to={machineUrls.index(machineId)}>{machine?.name}</Link>;
};

/**
 * Helper component for rendering "Status" column of an Attempts entry.
 */
export const AttemptStatusDisplay = ({
  status,
}: {
  status: AttemptStatus;
}): ReactElement => {
  let kind: BadgeKind = "info";
  let statusLabel = t`common.unknown`;

  switch (status) {
    case "printing":
      kind = "info";
      statusLabel = t`common.printing`;
      break;
    case "paused":
      kind = "warning";
      statusLabel = t`common.paused`;
      break;
    case "complete":
      kind = "success";
      statusLabel = t`common.complete`;
      break;
    case "cancelled":
      kind = "muted";
      statusLabel = t`common.cancelled`;
      break;
    case "failed":
      kind = "error";
      statusLabel = t`common.failed`;
      break;
  }

  return <Badge kind={kind}>{statusLabel}</Badge>;
};

type ModalProps = {
  attemptId: Uuid;
  manifest: AttemptDatasetManifest[];
};

/**
 * Helper component for rendering "Data" column of an Attempts entry.
 */
const DataCell = ({
  revision,
  attempt,
  setModalProps,
}: {
  revision: Revision;
  attempt: Attempt;
  setModalProps: (modalProps: ModalProps) => void;
}): ReactElement => {
  const manifestQuery = useAttemptDatasetManifest(
    { projectId: revision.projectId, attemptId: attempt.id },
    { enabled: false }
  );

  return (
    <ButtonGroup>
      {attempt.endedAt && (
        <Button
          size="small"
          kind="secondary"
          onMouseOver={() => {
            // Only attempt to load the data if it hasn't been yet.
            if (manifestQuery.isStale || !manifestQuery.isIdle) {
              manifestQuery.refetch();
            }
          }}
          onClick={() =>
            setModalProps({
              attemptId: attempt.id,
              manifest: manifestQuery.data ?? [],
            })
          }
        >
          {t`common.download`}
        </Button>
      )}
      <Link to={projectUrls.analyze(revision.projectId, attempt.id)}>
        <Button size="small">{t`common.analyze`}</Button>
      </Link>
    </ButtonGroup>
  );
};

const MaterialsDisplay = ({
  materialIds: [materialId0, materialId1],
}: {
  materialIds: Tuple<string | null>;
}): JSX.Element => {
  const { data: materials } = useMaterials();

  const material0 = materials?.find((m) => m.id === materialId0);
  const material1 = materials?.find((m) => m.id === materialId1);

  return (
    <>
      {material0 && (
        <>
          <S.RevisionInfoLabel>{t`common.primary`}:</S.RevisionInfoLabel>
          <S.RevisionInfoValue>
            <MaterialName material={material0} />
          </S.RevisionInfoValue>
        </>
      )}
      {material1 && (
        <>
          <S.RevisionInfoLabel>{t`common.secondary`}:</S.RevisionInfoLabel>
          <S.RevisionInfoValue>
            <MaterialName material={material1} />
          </S.RevisionInfoValue>
        </>
      )}
    </>
  );
};

const FileLabelAndLink = ({ file }: { file: BlobRecord }) => {
  const fileTypeLabel = (file: BlobRecord) => {
    if (file.kind === "gcode") return "G-code";
    if (file.kind === "stl") return "STL";
    return t`common.other-file-type`;
  };

  return (
    <>
      <S.RevisionInfoLabel>{fileTypeLabel(file)}:</S.RevisionInfoLabel>
      <S.FileDownloadLink
        href={blobDownloadURL(file.id)}
        title={t`common.download-file ${file.name}`}
      >
        {file.name}
        <Icon variant="FileDownload" />
      </S.FileDownloadLink>
    </>
  );
};

const RevisionCreated = ({ revision }: { revision: Revision }) => {
  const { data: user } = useUser({ id: revision.creatorId });

  const createdAtString = dateTimeString({ value: revision.createdAt });
  const createdByString = user ? user.name : t`common.unknown`;

  return (
    <>
      <S.RevisionInfoLabel>{t`common.created`}:</S.RevisionInfoLabel>
      <S.RevisionInfoValue>{t`common.created-at-and-by ${createdAtString} ${createdByString}`}</S.RevisionInfoValue>
    </>
  );
};

const RevisionHeaderAndLink = ({
  project,
  revision,
}: {
  project: Project;
  revision: Revision;
}) => {
  return (
    <S.RevisionLink
      id={revision.id}
      to={projectUrls.prepare(project.id, revision.id)}
    >
      <span>{t`common.revision`}</span>
      <NeverUnderlineSpace />
      <S.RevisionNumber>#{revision.number}</S.RevisionNumber>
    </S.RevisionLink>
  );
};

const fileSorter = (a: BlobRecord, b: BlobRecord) => {
  // Sort G-code first, STL(s) second, and everything else last.
  // Within each group, sort alphabetically by name.
  const kindOrder = ["gcode", "stl"];
  const [aKindRank, bKindRank] = [a.kind, b.kind].map((kind) => {
    const rank = kindOrder.indexOf(kind);
    return rank >= 0 ? rank : kindOrder.length; // other kinds are last
  });
  const kindRank = aKindRank - bKindRank;
  if (kindRank) return kindRank;

  return a.name.localeCompare(b.name);
};

const OneRevision = ({
  project,
  revision,
  attempts,
  setModalProps,
}: {
  project: Project;
  revision: Revision;
  attempts: Attempt[];
  setModalProps: (props: ModalProps) => void;
}) => {
  const { data: files = [] } = useRevisionResources(revision);

  // TODO: change to `toSorted` in 2025
  const sortedFiles = [...files].sort(fileSorter);

  const jobNumber = (attempt: Attempt) =>
    attempts.length - attempts.indexOf(attempt);
  const jobDescription = (attempt: Attempt) =>
    t`common.job-description ${revision.number} ${jobNumber(attempt)}`;

  const columns: ColumnDef<Attempt>[] = [
    {
      header: t`common.job`,
      cell: ({ row: { original: rowData } }) => (
        <S.JobNumber title={jobDescription(rowData)}>
          {`#${revision.number}-${jobNumber(rowData)}`}
        </S.JobNumber>
      ),
    },
    {
      header: t`common.machine`,
      cell: ({ row: { original: rowData } }) => (
        <MachineCell machineId={rowData.machineId} />
      ),
    },
    {
      accessorKey: "startedAt",
      header: t`common.start-date`,
      cell: ({ row: { original: rowData } }) => (
        <DateTime value={rowData.startedAt} />
      ),
    },
    {
      accessorKey: "endedAt",
      header: t`common.end-date`,
      cell: ({ row: { original: rowData } }) => (
        <DateTime value={rowData.endedAt} />
      ),
    },
    {
      header: t`common.status`,
      cell: ({ row: { original: rowData } }) => (
        <AttemptStatusDisplay status={rowData.status} />
      ),
    },
    {
      header: t`components.attempts-listing.data`,
      cell: ({ row: { original: rowData } }) => (
        <DataCell
          revision={revision}
          attempt={rowData}
          setModalProps={setModalProps}
        />
      ),
    },
  ];

  return (
    <S.OneRevision>
      <S.RevisionInfo>
        <S.RevisionInfoColumn>
          <RevisionHeaderAndLink project={project} revision={revision} />
          <RevisionCreated revision={revision} />
        </S.RevisionInfoColumn>
        <S.RevisionInfoColumn>
          <MaterialsDisplay materialIds={revision.materials} />
        </S.RevisionInfoColumn>
        <S.RevisionInfoColumn>
          {sortedFiles.map((f) => (
            <FileLabelAndLink key={f.id} file={f} />
          ))}
        </S.RevisionInfoColumn>
      </S.RevisionInfo>
      {attempts?.length ? (
        <Table columns={columns} data={attempts} />
      ) : (
        <S.NoAttemptsContainer>{t`common.no-attempts`}</S.NoAttemptsContainer>
      )}
    </S.OneRevision>
  );
};

export const AttemptsListing = ({
  project,
}: {
  project: Project;
}): ReactElement | null => {
  const [modalProps, setModalProps] = useState<ModalProps | null>(null);
  const { data: attempts } = useProjectAttempts(project.id);
  const { revisions } = project;

  if (!revisions) return null;

  return (
    <S.Wrapper>
      <>
        {/* TODO: change to `toReversed` in 2025 */}
        {[...revisions].reverse().map((revision) => (
          <OneRevision
            key={revision.id}
            project={project}
            revision={revision}
            attempts={(attempts ?? []).filter(
              (a) => a.revisionId === revision.id
            )}
            setModalProps={setModalProps}
          ></OneRevision>
        ))}
        <Modal
          title={t`components.attempts-listing.downloads`}
          open={modalProps !== null}
          onClose={() => {
            setModalProps(null);
          }}
        >
          {modalProps && (
            <AttemptDataDownloadList
              projectId={project.id}
              attemptId={modalProps.attemptId}
              manifest={modalProps.manifest}
            />
          )}
        </Modal>
      </>
    </S.Wrapper>
  );
};
