import React, { useEffect, useMemo } from "react";
import {
  Vector2,
  Vector3,
  PlaneGeometry,
  InstancedBufferGeometry,
  InstancedBufferAttribute,
  ShaderMaterial,
  DataTexture,
  DataArrayTexture,
  RGBAFormat,
  RedFormat,
  FrontSide,
  FloatType,
  NearestFilter,
} from "three";
import { useThree } from "@react-three/fiber";
import { scaleSequential } from "d3-scale";
import { interpolateTurbo } from "d3-scale-chromatic";
import { rgb } from "d3-color";
import { useAtom } from "jotai";

import { View, ViewMode } from "_/state/viewer";

import billboardVert from "./glsl/billboard.vert";
import billboardFrag from "./glsl/billboard.frag";

function generateLookupTexture() {
  // Color scale used to map sensor values to colors
  const scale = scaleSequential(interpolateTurbo).domain([0, 1]);

  // Generate a lookup texture to use the color scale in the shader
  const textureWidth = 256;
  const data = new Uint8Array(textureWidth * 4); // RGBA

  for (let i = 0; i < textureWidth; i++) {
    const color = rgb(scale(i / textureWidth));
    const offset = i * 4;
    data[offset] = color.r;
    data[offset + 1] = color.g;
    data[offset + 2] = color.b;
    data[offset + 3] = 255;
  }

  const lookupTexture = new DataTexture(data, textureWidth, 1, RGBAFormat);
  lookupTexture.needsUpdate = true;
  return lookupTexture;
}

type PrintViewProps = {
  view: View<ViewMode.Print>;
};

export const PrintView = ({ view }: PrintViewProps) => {
  const { invalidate } = useThree();

  const [viewControls] = useAtom(view.controlsAtom);

  const { zRange, valueRange, colorRange, lineWidth, showNonExtrusionMotion } =
    viewControls;

  const instancedGeometry = useMemo(() => {
    const baseGeometry = new PlaneGeometry(1, 1);

    const instancedGeometry = new InstancedBufferGeometry();
    instancedGeometry.index = baseGeometry.index;
    instancedGeometry.attributes.position = baseGeometry.attributes.position;
    instancedGeometry.attributes.uv = baseGeometry.attributes.uv;
    instancedGeometry.attributes.normal = baseGeometry.attributes.normal;

    const { startPoints, endPoints, offsets, counts, extrusionValues } =
      view.data;

    instancedGeometry.setAttribute(
      "startVertex",
      new InstancedBufferAttribute(startPoints, 3)
    );

    instancedGeometry.setAttribute(
      "endVertex",
      new InstancedBufferAttribute(endPoints, 3)
    );

    instancedGeometry.setAttribute(
      "offset",
      new InstancedBufferAttribute(offsets, 1)
    );

    instancedGeometry.setAttribute(
      "count",
      new InstancedBufferAttribute(counts, 1)
    );

    instancedGeometry.setAttribute(
      "extrusionValue",
      new InstancedBufferAttribute(extrusionValues, 1)
    );

    instancedGeometry.instanceCount = counts.length;

    return instancedGeometry;
  }, [view.data]);

  const material = useMemo(() => {
    const { sensorValues, sensorTextureDimensions } = view.data;

    const [sensorTextureWidth, sensorTextureHeight, sensorTextureDepth] =
      sensorTextureDimensions;

    const sensorArrayTexture = new DataArrayTexture(
      sensorValues,
      sensorTextureWidth,
      sensorTextureHeight,
      sensorTextureDepth
    );

    sensorArrayTexture.format = RedFormat;
    sensorArrayTexture.type = FloatType;

    sensorArrayTexture.minFilter = NearestFilter;
    sensorArrayTexture.magFilter = NearestFilter;

    sensorArrayTexture.needsUpdate = true;

    return new ShaderMaterial({
      uniforms: {
        lineWidth: { value: lineWidth.range[1] / 2 },
        lookupTexture: { value: generateLookupTexture() },
        sensorArrayTexture: { value: sensorArrayTexture },
        sensorTextureDimensions: {
          value: new Vector3(
            sensorTextureWidth,
            sensorTextureHeight,
            sensorTextureDepth
          ),
        },
        zRange: { value: new Vector2() },
        valueRange: {
          value: new Vector2(),
        },
        colorLimit: {
          value: new Vector2(),
        },
        showNonExtrusionMotion: {
          value: false,
        },
      },
      vertexShader: billboardVert,
      fragmentShader: billboardFrag,
      side: FrontSide,
      transparent: false,
    });
  }, [view.data, lineWidth.range]);

  // Update shader material uniforms based on control changes
  useEffect(() => {
    material.uniforms.zRange.value.x = zRange.value[0];
    material.uniforms.zRange.value.y = zRange.value[1];
    material.uniforms.valueRange.value.x = valueRange.value[0];
    material.uniforms.valueRange.value.y = valueRange.value[1];
    material.uniforms.colorLimit.value.x = colorRange.value[0];
    material.uniforms.colorLimit.value.y = colorRange.value[1];
    material.uniforms.lineWidth.value = lineWidth.value;
    material.uniforms.showNonExtrusionMotion.value = showNonExtrusionMotion;
    invalidate();
  }, [
    zRange.value,
    valueRange.value,
    colorRange.value,
    lineWidth.value,
    showNonExtrusionMotion,
    material,
    invalidate,
  ]);

  return (
    <mesh
      geometry={instancedGeometry}
      material={material}
      frustumCulled={false}
    />
  );
};
