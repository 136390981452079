import React, { HTMLAttributes, ReactElement } from "react";
import BoringAvatar from "boring-avatars";
import { values } from "ramda";

import { brandColors } from "_/style";
import { Uuid } from "_/types";

import * as S from "./styled";

// TODO: change to `toSorted` in 2025
const PALETTE = values(brandColors).sort();

type AvatarProps = {
  /** Name for the object or user represented by the avatar. */
  name: string;
  /** Optional seed to use when using generated avatar. If not provided, the name is used instead.  */
  seed?: string | number;
  /** Image url (or data url) can be provided for the avatar image. */
  image?: string;
  size?: "small" | "medium" | "large";
} & HTMLAttributes<HTMLDivElement>;

export const Avatar = ({
  image,
  seed,
  name,
  size = "medium",
  ...props
}: AvatarProps): ReactElement => {
  const sizes = {
    large: 120,
    medium: 36,
    small: 24,
  };

  let avatar;
  if (!image) {
    avatar = (
      <BoringAvatar
        name={seed?.toString() || name}
        size={sizes[size] - 2}
        variant="bauhaus"
        colors={PALETTE}
      />
    );
  } else {
    avatar = <S.Image $imageUrl={image} />;
  }

  return (
    <S.Wrapper {...props} $size={sizes[size]}>
      {avatar}
    </S.Wrapper>
  );
};

interface ResourceAvatarProps {
  /**
   * Record object used for the avatar.
   */
  resource: { id: Uuid; avatarId?: Uuid; name: string };

  /**
   * Resource type.
   */
  resourceType: "users" | "organizations";

  /**
   * Avatar display size.
   */
  size?: AvatarProps["size"];
}

const ResourceAvatar = ({
  resource,
  size,
  resourceType,
}: ResourceAvatarProps) => {
  // The avatarId query param does not provide any value to the server but is
  // used as a cache-buster.

  const avatarUrl = resource.avatarId
    ? `/api/v0/${resourceType}/${resource.id}/avatar?v=${resource.avatarId}`
    : undefined;

  return (
    <Avatar
      seed={resource.id}
      name={resource.name}
      image={avatarUrl}
      size={size}
    />
  );
};

export const UserAvatar = (
  props: Omit<ResourceAvatarProps, "resourceType">
) => <ResourceAvatar resourceType="users" {...props} />;

export const OrgAvatar = (props: Omit<ResourceAvatarProps, "resourceType">) => (
  <ResourceAvatar resourceType="organizations" {...props} />
);
