import styled from "styled-components";
import { transparentize } from "polished";
import { Root, Track, Range, Thumb } from "@radix-ui/react-slider";

export type SliderSize = "small" | "large";

const thumbSize = (sliderSize: SliderSize) =>
  sliderSize === "small" ? 14 : 16;

const trackThickness = (sliderSize: SliderSize) =>
  sliderSize === "small" ? 4 : 5;

export const SliderRoot = styled(Root)<{ $size: SliderSize }>`
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  touch-action: none;
  width: 100%;
  height: ${(p) => thumbSize(p.$size)}px;
  cursor: pointer;

  &[data-orientation="vertical"] {
    height: 100%;
    width: ${(p) => thumbSize(p.$size)}px;
    flex-direction: column;
  }
`;

export const SliderTrack = styled(Track)<{ $size: SliderSize }>`
  background-color: ${(p) =>
    transparentize(0.5, p.theme.color.palette.electricBlue)};
  position: relative;
  flex-grow: 1;
  height: ${(p) => trackThickness(p.$size)}px;
  margin: 0 ${(p) => thumbSize(p.$size) / 2}px;

  &[data-orientation="vertical"] {
    margin: ${(p) => thumbSize(p.$size) / 2}px 0;
    height: 100%;
    width: ${(p) => trackThickness(p.$size)}px;
  }
`;

export const SliderRange = styled(Range)`
  position: absolute;
  background-color: ${(p) => p.theme.color.palette.electricBlue};
  height: 100%;

  &[data-orientation="vertical"] {
    height: unset;
    width: 100%;
    flex-direction: column;
  }
`;

// Map the caller's placement to the CSS property used to position the tooltip.
// Note that the CSS props are backwards from what you'd intuitively expect,
// because of how absolute positioning works. For example, for a vertical slider,
// the "before" position is on the left of the thumb, which requires setting the
// CSS property `right`.
const placementToCssProp = {
  "horizontal-before": "bottom",
  "horizontal-after": "top",
  "vertical-before": "right",
  "vertical-after": "left",
} as const;

export const SliderThumb = styled(Thumb)<{
  $size: SliderSize;
  $tooltipPlacement?: keyof typeof placementToCssProp | undefined;
}>`
  display: block;
  width: ${(p) => thumbSize(p.$size)}px;
  height: ${(p) => thumbSize(p.$size)}px;
  background-color: ${(p) => p.theme.color.palette.electricBlue};
  border-radius: 2px;

  &:hover {
    background-color: ${(p) => p.theme.color.palette.electricBlue};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${(p) => p.theme.color.palette.twilightBlue};
  }

  ${(p) =>
    p.$tooltipPlacement &&
    `
    &::after {
      content: attr(data-value);
      position: absolute;
      display: flex;
      place-items: center;
      height: ${thumbSize(p.$size)}px;

      white-space: nowrap;
      font-size: 0.875rem;
      /* 
        Use a font variant that makes numbers show up with consistent width. This is
        important so that the slider value doesn't jump around as the user changes
        the thumb positions. Note that our Neufile Grotesk font does not support
        this variant, so we use similar fonts that do support it.
      */
      font-family: Helvetica, Arial, sans-serif;
      font-variant-numeric: tabular-nums;

      ${placementToCssProp[p.$tooltipPlacement]}: calc(100% + 0.5rem);
      ${
        ["left", "right"].includes(placementToCssProp[p.$tooltipPlacement])
          ? `top: -50%;`
          : `left: 50%; transform: translateX(-50%);`
      } 

      padding: 0.5rem;
      border-radius: 0.25rem;
      background-color: ${transparentize(0.2, p.theme.background.quaternary)}; 

      opacity: 0;
      transition: opacity 0.2s ease-in-out;
    }
    &:hover::after {
      opacity: 1;
    }
  `}
`;
