import React, { Fragment } from "react";

import * as S from "./styled";

/**
 * Returns a boolean array corresponding to each character in the source string.
 * If a character is part of a match of any of the target words, then true will
 * be returned at that index. Otherwise false will be returned.
 */
function getMatchMap(text: string, matchWords: string[]): boolean[] {
  const lowerText = text.toLowerCase();
  const lowerMatchWords = matchWords.map((w) => w.toLowerCase());
  const map = Array<boolean>(lowerText.length).fill(false);
  for (let i = 0; i < lowerText.length; i++) {
    for (const w of lowerMatchWords) {
      if (lowerText.slice(i).startsWith(w)) {
        for (let j = 0; j < w.length; j++) {
          map[i + j] = true;
        }
      }
    }
  }
  return map;
}

interface TokenInfo {
  token: string;
  isMatch: boolean;
}

function tokenize(sourceText: string, matchWords: string[]): TokenInfo[] {
  const matchMap = getMatchMap(sourceText, matchWords);
  const tokens: TokenInfo[] = [];
  let lastWasMatch: boolean | undefined = undefined;
  for (let i = 0; i < sourceText.length; i++) {
    if (matchMap[i] === lastWasMatch) {
      tokens[tokens.length - 1].token += sourceText[i];
    } else {
      // new token
      lastWasMatch = matchMap[i];
      tokens.push({ token: sourceText[i], isMatch: lastWasMatch });
    }
  }
  return tokens;
}

/**
 * Prototype search algorithm that allows users to type words in any order and
 * still have them match. Feel free to replace with something better!
 *
 * Split both target and query into words. Space characters delimit words.
 *
 * Searches are case-insensitive.
 *
 * Next, consider it a match if all words in the query have a match in any of
 * the target string's words, regardless of order. So "PEEK bracket" or "Bracket
 * - PEEK" will match the same queries.
 *
 * Matches can be full words or partial "starts with" matches. So "brac" will
 * match "PEEK Bracket".
 *
 * @param target - string to search
 * @param query - what the user typed
 */
export function wordMatch(target: string, query: string) {
  const [targetWords, queryWords] = [target, query].map(getFilterWords);
  if (!queryWords?.length) return [];
  if (!targetWords?.length) throw new Error("Missing target words");

  for (let i = 0; i < queryWords.length; i++) {
    const queryWord = queryWords[i];
    const matchingTargetWords = targetWords.filter((w) =>
      w.startsWith(queryWord)
    );
    if (!matchingTargetWords.length) return undefined;
  }
  return queryWords;
}

/**
 * Split a string into substrings we'll use for the filtering algorithm in
 * `wordMatch()`
 * */
export function getFilterWords(s: string) {
  return s
    .toLowerCase()
    .split(/\s+/gi)
    .filter((s) => s.length > 0);
}

interface HighlightProps {
  text: string;
  query: string | undefined;
}

export default function Highlight(props: HighlightProps) {
  const { text, query } = props;
  if (!query) return <Fragment>{text}</Fragment>;
  const matchWords = getFilterWords(query);
  if (!matchWords || !matchWords.length) return <Fragment>{text}</Fragment>;
  const tokens = tokenize(text, matchWords);

  return (
    <>
      {tokens.map((t, i) => (
        <Fragment key={i}>
          {t.isMatch ? <S.Highlight>{t.token}</S.Highlight> : <>{t.token}</>}
        </Fragment>
      ))}
    </>
  );
}
