import React, { ReactElement } from "react";

import { docAssetPath } from "_/data/docs";

import * as S from "./styled";

/**
 * Image component for use in documentation.
 *
 * This will use the `src` attribute to specify the path relative to the
 * documentation asset image directory in order to simplify paths.
 */
export const DocsImg = ({
  src,
  title = "",
}: {
  src: string;
  title: string;
}): ReactElement => {
  let imgSrc = "";

  // URLs can only be parsed successfully if they are absolute,
  // so any relative URLs will throw an error. If this occurs, we
  // treat the URL as a relative path to a docs image in the
  // assets directory.
  try {
    const url = new URL(src);
    imgSrc = url.toString();
  } catch (_e) {
    imgSrc = docAssetPath(`images${src}`);
  }

  return <S.DocsImg src={imgSrc} title={title} />;
};
