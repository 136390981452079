import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 36px 56px;
  height: 100%;
`;

export const ProjectCards = styled.ul`
  display: grid;
  gap: 25px 25px;
  list-style: none;

  margin: 0;
  padding: 0;

  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
`;

export const SubHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 36px;
`;
