import React, { ReactElement } from "react";

import { docAssetPath } from "_/data/docs";
import { Icon } from "_/components/icon";

/**
 * File download component for use in documentation.
 *
 * The `src` attribute is used to specify the path relative to the
 * base asset directory in order to simplify paths.
 *
 * The file name that will be used for the download can be specified
 * with the `fileName` prop, or if it is not set, the file name will
 * be derived from the `src`.
 *
 * Similarly, the label displayed for the download link can be set
 * using the `label` prop, or if it is not set, the file name will
 * be used.
 */
export const FileDownload = ({
  src,
  label,
  fileName,
}: {
  src: string;
  fileName?: string;
  label?: string;
}): ReactElement => {
  const path = docAssetPath(src);

  const name = fileName || src.split("/").pop();

  return (
    <a
      href={path}
      download={name}
      style={{ display: "flex", alignItems: "center", gap: "0.25em" }}
    >
      {label || name}
      <Icon variant="FileDownload" />
    </a>
  );
};
