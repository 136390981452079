/**
 * Projects edit form component.
 *
 * @category forms
 */

import React, { ReactElement, useState, useEffect, useMemo } from "react";
import { AxiosProgressEvent } from "axios";
import { useLocation } from "_/components/router";
import { concat, remove } from "ramda";
import { useForm, Controller } from "react-hook-form";
import { t } from "@lingui/macro";

import { useCurrentOrg } from "_/data/orgs";
import {
  useCreateProject,
  useDeleteProject,
  useUpdateProject,
  useReviseProject,
  useRevisionResources,
  Project,
  Revision,
  RevisionChange,
} from "_/data/projects";
import { BlobRecord } from "_/data/blob";

import { FormTextField } from "_/components/text-field";
import { FormField } from "_/components/form-field";
import { UserSelect } from "_/components/user-select";
import { DropZone, FileRejection } from "_/components/dropzone";
import { MaterialSelect } from "_/components/materials";
import { Icon } from "_/components/icon";
import { ProgressBar } from "_/components/progress-bar";
import { useToasts } from "_/components/toasts";
import { SubmitCancelButtons } from "_/components/modal";

import { projectUrls } from "_/routes";

import * as S from "./styled";

const GCODE_EXTENSIONS = ["gcode", "g", "gc", "nc"];

const GCODE_ACCEPT = {
  "text/plain": GCODE_EXTENSIONS.map((ext) => `.${ext}`),
  "text/x.gcode": GCODE_EXTENSIONS.map((ext) => `.${ext}`),
};

const STL_EXTENSIONS = ["stl"];

const STL_ACCEPT = {
  "application/sla": STL_EXTENSIONS.map((ext) => `.${ext}`),
};

function bytesPretty(file: File | BlobRecord): string {
  const b = file instanceof File ? file.size : file.uncompressedSize;

  const units = ["B", "KB", "MB", "GB", "TB"];

  let value = b;
  let i = 0;

  for (; i < units.length; i++) {
    if (Math.abs(value) < 1000) {
      break;
    }

    value = value / 1000;
  }

  return `${value.toFixed(2)} ${units[i]}`;
}

// Determine the type of the file.
function fileType(file: File | BlobRecord): "gcode" | "stl" | undefined {
  // If file is a File, use the extension to determine its type.
  if (file instanceof File) {
    const extension = file.name.split(".").pop() as string;

    if (GCODE_EXTENSIONS.includes(extension)) {
      return "gcode";
    }
    if (STL_EXTENSIONS.includes(extension)) {
      return "stl";
    }
    console.warn("Unexpected file type for file:", file.name);
    return;
  } else {
    switch (file.kind) {
      case "stl":
      case "gcode":
        return file.kind;
      default:
        console.warn("Unexpected file type for blob:", file.name);
        return;
    }
  }
}

type RevisionSettings = {
  gcodeFile: BlobRecord | File | undefined;
  stlFiles: (File | BlobRecord)[];
  material1: string | null;
  material2: string | null;
};

const FileItem = ({
  file,
  onClick,
  onRemove,
}: {
  file: File | BlobRecord;
  onClick?: (e: React.MouseEvent) => void;
  onRemove?: (e: React.MouseEvent) => void;
}) => (
  <S.FileItem onClick={onClick}>
    <S.FileName>{file.name}</S.FileName>
    <S.FileSize>{bytesPretty(file)}</S.FileSize>
    {onRemove && <Icon variant="Delete" onClick={onRemove} />}
  </S.FileItem>
);

/**
 * Helper component for project revision form to be rendered within modal.
 */
export const ProjectEditForm = ({
  project,
  onComplete,
  isUploading,
  setIsUploading,
}: {
  project: Project;
  onComplete: () => void;
  isUploading: boolean;
  setIsUploading: (isUploading: boolean) => void;
}): ReactElement => {
  const updateProject = useUpdateProject();
  const reviseProject = useReviseProject();

  const [_location, setLocation] = useLocation();

  const [_, addToast] = useToasts();

  const revision = project.revisions[project.revisions.length - 1];

  const { data: files = [] } = useRevisionResources(revision);

  // Get the initial values for form fields that will create a new revision if changed
  const currentRevisionSettings = useMemo<RevisionSettings>(() => {
    return {
      gcodeFile: files.filter((f) => f.kind === "gcode")[0],
      stlFiles: files.filter((f) => f.kind === "stl"),
      material1: revision.materials[0],
      material2: revision.materials[1],
    };
  }, [files, revision.materials]);

  const [gcodeFile, setGcodeFile] = useState(currentRevisionSettings.gcodeFile);
  const [stlFiles, setStlFiles] = useState(currentRevisionSettings.stlFiles);

  const { control, handleSubmit, setValue, watch } = useForm({
    defaultValues: { ...project, ...currentRevisionSettings },
  });

  // Watch for changes to the material form fields
  const updatedMaterial1 = watch("material1");
  const updatedMaterial2 = watch("material2");

  const [willCreateRevision, setWillCreateRevision] = useState(false);

  const [uploadProgress, setUploadProgress] = useState<number>();

  // Update form value and validate whenever gcodeFile changes
  useEffect(() => {
    setValue("gcodeFile", gcodeFile);
  }, [gcodeFile, setValue]);

  // Update form value and validate whenever gcodeFile changes
  useEffect(() => {
    setValue("stlFiles", stlFiles);
  }, [stlFiles, setValue]);

  // Check for changes on form fields that will trigger the creation of
  // a new revision
  useEffect(() => {
    const materialsChanged =
      updatedMaterial1 !== currentRevisionSettings.material1 ||
      updatedMaterial2 !== currentRevisionSettings.material2;

    const gcodeFileChanged = currentRevisionSettings.gcodeFile !== gcodeFile;

    const stlFilesChanged =
      currentRevisionSettings.stlFiles.length !== stlFiles.length ||
      !currentRevisionSettings.stlFiles.every(
        (file, index) => file === stlFiles[index]
      );

    if (materialsChanged || gcodeFileChanged || stlFilesChanged) {
      setWillCreateRevision(true);
    } else {
      setWillCreateRevision(false);
    }
  }, [
    gcodeFile,
    stlFiles,
    updatedMaterial1,
    updatedMaterial2,
    currentRevisionSettings,
  ]);

  async function submit(data: Project & RevisionSettings) {
    try {
      // First update the project-level changes (project name and owner)
      await updateProject.mutateAsync(data);

      // If associated files or material choices have been updated, also
      // create a new revision for the project.
      if (willCreateRevision) {
        const revisionChange: RevisionChange = {
          gcode: data.gcodeFile,
          models: data.stlFiles,
          materials: {
            material1Id: data.material1,
            material2Id: data.material2,
          },
        };

        // Any of the files associated with this revision change are not
        // `BlobRecord`s, we will need to upload.
        const willUpload =
          data.gcodeFile instanceof File ||
          data.stlFiles.some((file) => file instanceof File);

        const onUploadProgress = willUpload
          ? (progressEvent: AxiosProgressEvent) =>
              setUploadProgress(progressEvent.progress)
          : undefined;

        if (willUpload) {
          setIsUploading(true);
        }

        await reviseProject.mutateAsync({
          projectId: project.id,
          revisionChange,
          onUploadProgress,
          onSuccess: (newRevision: Revision) => {
            addToast({
              title: t`components.project-edit.revision-number-created ${newRevision.number}`,
              kind: "success",
              timeout: 5_000,
            });

            setLocation(projectUrls.prepare(project.id, newRevision.id));
          },
        });

        setIsUploading(false);
      }
    } catch (e) {
      console.error("Error while updating project.", e);

      addToast({
        title: t`components.project-edit.revision-creation-failed`,
        kind: "warning",
        timeout: 10_000,
      });
    } finally {
      // Call `onComplete` to allow any cleanup to be done by the calling
      // component like closing modals, nav, etc.
      onComplete();
    }
  }

  function handleGcodeDrop(newFiles: File[], rejectedFiles: FileRejection[]) {
    // Take the first G-code file and set it as the G-code file.
    const newFile = newFiles[0];

    if (newFile) {
      setGcodeFile(newFiles[0]);
    }

    // If any STL files were dropped into the G-code dropzone, add them to the
    // list of STL files.
    const newStlFiles = rejectedFiles
      .filter((f) => fileType(f.file) === "stl")
      .map((f) => f.file);

    if (newStlFiles.length > 0) {
      setStlFiles((fs) => concat(fs, newStlFiles));
    }
  }

  function handleStlDrop(newFiles: File[], rejectedFiles: FileRejection[]) {
    setStlFiles((fs) => concat(fs, newFiles));

    // If any G-code files were dropped into the STL dropzone, set the G-code
    // file to the first one.
    const newGcodeFile = rejectedFiles.filter(
      (f) => fileType(f.file) === "gcode"
    )[0]?.file;

    if (newGcodeFile) {
      setGcodeFile(newGcodeFile);
    }
  }

  function removeStlFile(i: number) {
    setStlFiles((fs) => remove(i, 1, fs));
  }

  return (
    <form onSubmit={handleSubmit(submit)}>
      <FormTextField
        control={control}
        name="name"
        label={t`common.name`}
        rules={{ required: t`components.projects-view.name-required` }}
        placeholder={t`components.projects-view.project-name`}
      />
      <Controller
        control={control}
        name="ownerId"
        render={({ field, fieldState }) => (
          <UserSelect
            {...field}
            label={t`common.owner`}
            invalid={fieldState.invalid}
            hint={fieldState.error?.message}
            required
          />
        )}
      />
      <Controller
        control={control}
        name="material1"
        rules={{ required: t`components.projects-view.material-required` }}
        render={({ field, fieldState }) => (
          <MaterialSelect
            {...field}
            label={t`common.primary-material`}
            invalid={fieldState.invalid}
            hint={fieldState.error?.message}
            required
          />
        )}
      />
      <Controller
        control={control}
        name="material2"
        render={({ field, fieldState }) => (
          <MaterialSelect
            {...field}
            label={t`common.secondary-material`}
            invalid={fieldState.invalid}
            hint={fieldState.error?.message}
            isClearable
          />
        )}
      />
      <Controller
        control={control}
        name="gcodeFile"
        rules={{ required: t`components.projects-edit.gcode-required` }}
        render={({ fieldState }) => (
          <FormField
            label={t`common.gcode`}
            hint={fieldState.error?.message}
            invalid={fieldState.invalid}
          >
            <DropZone
              onDrop={handleGcodeDrop}
              text={t`components.project-edit.gcode-dropzone-text`}
              dropText={t`components.project-edit.dropzone-droptext`}
              chipText={t`components.project-edit.dropzone-chiptext`}
              accept={GCODE_ACCEPT}
              invalid={fieldState.invalid}
            >
              {gcodeFile && (
                <FileItem
                  file={gcodeFile}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                />
              )}
            </DropZone>
          </FormField>
        )}
      />
      <Controller
        control={control}
        name="stlFiles"
        render={({ fieldState }) => (
          <FormField
            label={t`components.project-edit.stl`}
            hint={fieldState.error?.message}
            invalid={fieldState.invalid}
          >
            <DropZone
              onDrop={handleStlDrop}
              text={t`components.project-edit.stl-dropzone-text`}
              dropText={t`components.project-edit.dropzone-droptext`}
              chipText={t`components.project-edit.dropzone-chiptext`}
              accept={STL_ACCEPT}
            >
              {stlFiles.map((f: File | BlobRecord, i: number) => (
                <FileItem
                  file={f}
                  key={`${i}_${f.name}`}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onRemove={(e) => {
                    e.stopPropagation();
                    removeStlFile(i);
                  }}
                />
              ))}
            </DropZone>
          </FormField>
        )}
      />
      {uploadProgress && (
        <ProgressBar
          value={uploadProgress}
          title={t`components.project-edit.uploading-files`}
        />
      )}
      <SubmitCancelButtons
        onCancel={onComplete}
        disableSubmit={isUploading}
        disableCancel={isUploading}
      />
    </form>
  );
};

/**
 * Helper component for project creation form to be rendered within modal.
 */
export const ProjectCreationForm = ({
  onComplete,
  isUploading,
  setIsUploading,
}: {
  onComplete: () => void;
  isUploading: boolean;
  setIsUploading: (isUploading: boolean) => void;
}): ReactElement => {
  const createProject = useCreateProject();
  const deleteProject = useDeleteProject();
  const reviseProject = useReviseProject();
  const { data: org } = useCurrentOrg();

  const [_location, setLocation] = useLocation();

  const [_, addToast] = useToasts();

  const [gcodeFile, setGcodeFile] = useState<File | BlobRecord>();
  const [stlFiles, setStlFiles] = useState<(File | BlobRecord)[]>([]);

  const defaultValues: Pick<Project, "name"> & RevisionSettings = {
    name: "",
    gcodeFile: undefined,
    stlFiles: [],
    material1: null,
    material2: null,
  };

  const { control, handleSubmit, setValue, trigger } = useForm({
    defaultValues,
  });

  const [uploadProgress, setUploadProgress] = useState<number>();

  // Update form value and validate whenever gcodeFile changes
  useEffect(() => {
    setValue("gcodeFile", gcodeFile);

    // Don't validate if there is no G-code. Otherwise, the error state
    // will be triggered before the user has interacted with the form.
    if (gcodeFile) {
      trigger("gcodeFile");
    }
  }, [gcodeFile, setValue, trigger]);

  // Update form value whenever stlFiles changes
  useEffect(() => {
    setValue("stlFiles", stlFiles);
  }, [stlFiles, setValue, trigger]);

  async function submit(data: Pick<Project, "name"> & RevisionSettings) {
    // Create the new project.
    const project = await createProject.mutateAsync({
      name: data.name,
      description: "",
      domainId: org.domainId,
    });

    const revisionChange: RevisionChange = {
      gcode: data.gcodeFile,
      models: data.stlFiles,
      materials: {
        material1Id: data.material1,
        material2Id: data.material2,
      },
    };

    // Try creating the first revision for the new project.
    try {
      setIsUploading(true);

      await reviseProject.mutateAsync({
        projectId: project.id,
        revisionChange,
        onUploadProgress: (progressEvent: AxiosProgressEvent) =>
          setUploadProgress(progressEvent.progress),
        onSuccess: (newRevision: Revision) => {
          setLocation(projectUrls.prepare(project.id, newRevision.id));
        },
      });
    } catch (e) {
      // Remove project if error during initial revision creation.
      console.warn("Error during revision creation:", e);

      addToast({
        title: t`components.project-edit.project-creation-failed`,
        kind: "warning",
        timeout: 10_000,
      });

      await deleteProject.mutateAsync({ id: project.id });

      throw e;
    }

    // Call `onComplete` to allow any cleanup to be done by the calling
    // component like closing modals, nav, etc.
    onComplete();
  }

  function handleGcodeDrop(newFiles: File[], rejectedFiles: FileRejection[]) {
    // Take the first G-code file and set it as the G-code file.
    const newFile = newFiles[0];

    if (newFile) {
      setGcodeFile(newFiles[0]);
    }

    // If any STL files were dropped into the G-code dropzone, add them to the
    // list of STL files.
    const newStlFiles = rejectedFiles
      .filter((f) => fileType(f.file) === "stl")
      .map((f) => f.file);

    if (newStlFiles.length > 0) {
      setStlFiles((fs) => concat(fs, newStlFiles));
    }
  }

  function handleStlDrop(newFiles: File[], rejectedFiles: FileRejection[]) {
    setStlFiles((fs) => concat(fs, newFiles));

    // If any G-code files were dropped into the STL dropzone, set the G-code
    // file to the first one.
    const newGcodeFile = rejectedFiles.filter(
      (f) => fileType(f.file) === "gcode"
    )[0]?.file;

    if (newGcodeFile) {
      setGcodeFile(newGcodeFile);
    }
  }

  function removeStlFile(i: number) {
    setStlFiles((fs) => remove(i, 1, fs));
  }

  return (
    <form onSubmit={handleSubmit(submit)}>
      <FormTextField
        control={control}
        name="name"
        label={t`common.name`}
        rules={{ required: t`components.projects-view.name-required` }}
        placeholder={t`components.projects-view.project-name`}
      />
      <Controller
        control={control}
        name="material1"
        rules={{ required: t`components.projects-view.material-required` }}
        render={({ field, fieldState }) => (
          <MaterialSelect
            {...field}
            label={t`common.primary-material`}
            invalid={fieldState.invalid}
            hint={fieldState.error?.message}
          />
        )}
      />
      <Controller
        control={control}
        name="material2"
        render={({ field }) => (
          <MaterialSelect
            {...field}
            label={t`common.secondary-material`}
            isClearable
          />
        )}
      />
      <Controller
        control={control}
        name="gcodeFile"
        rules={{ required: t`components.projects-edit.gcode-required` }}
        render={({ fieldState }) => (
          <FormField
            label={t`common.gcode`}
            hint={fieldState.error?.message}
            invalid={fieldState.invalid}
          >
            <DropZone
              onDrop={handleGcodeDrop}
              text={t`components.project-edit.gcode-dropzone-text`}
              dropText={t`components.project-edit.dropzone-droptext`}
              chipText={t`components.project-edit.dropzone-chiptext`}
              accept={GCODE_ACCEPT}
              invalid={fieldState.invalid}
            >
              {gcodeFile && (
                <FileItem
                  file={gcodeFile}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                />
              )}
            </DropZone>
          </FormField>
        )}
      />
      <Controller
        control={control}
        name="stlFiles"
        render={() => (
          <FormField label={t`components.project-edit.stl`}>
            <DropZone
              onDrop={handleStlDrop}
              text={t`components.project-edit.stl-dropzone-text`}
              dropText={t`components.project-edit.dropzone-droptext`}
              chipText={t`components.project-edit.dropzone-chiptext`}
              accept={STL_ACCEPT}
            >
              {stlFiles.map((f: File | BlobRecord, i: number) => (
                <FileItem
                  file={f}
                  key={`${i}_${f.name}`}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onRemove={(e) => {
                    e.stopPropagation();
                    removeStlFile(i);
                  }}
                />
              ))}
            </DropZone>
          </FormField>
        )}
      />
      {uploadProgress && (
        <ProgressBar
          value={uploadProgress}
          title={t`components.project-edit.uploading-files`}
        />
      )}
      <SubmitCancelButtons
        onCancel={onComplete}
        disableSubmit={isUploading}
        disableCancel={isUploading}
      />
    </form>
  );
};
