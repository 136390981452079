/**
 * Machines table, used in machines view page and home page.
 *
 * @category components
 * @module machine-listing
 */

import React, { ReactNode } from "react";
import { t } from "@lingui/macro";
import dayjs from "dayjs";

import { useMachines, Machine, useMachinesByOrg } from "_/data/machines";
import {
  Attempt,
  Project,
  Revision,
  useLastAttemptForEachMachine,
  useProjects,
} from "_/data/projects";
import { Material, useMaterials } from "_/data/materials";

import {
  Table,
  ColumnDef,
  CustomCellLink,
  CustomCellStatic,
} from "_/components/table";
import { DateOnly, RelativeDateTime } from "_/components/date-time";
import { MenuItem, OverlayMenu } from "_/components/overlay-menu";
import { Button } from "_/components/button";
import { Icon } from "_/components/icon";
import { MaterialsList } from "_/components/materials";
import { AttemptCell } from "_/components/jobs-listing";
import { MachineStatusBadge } from "_/components/machine-header";

import { machineUrls } from "_/routes";

import { indexById } from "_/utils";

import * as S from "./styled";

type AdminMachineListingProps = {
  /** Allows choosing which org to show machines for. */
  orgId?: string;

  /** Adds custom actions to the actions menu. */
  actions?: (machine: Machine) => MenuItem[];
};

const ActionsMenu = ({
  machine,
  actions,
}: {
  machine: Machine;
  actions?: (machine: Machine) => MenuItem[];
}) => {
  const menuItems = actions
    ? actions(machine)
    : [
        {
          render: t`common.support`,
          // TODO: this menu item should open a modal with a link to the
          // support email and a checkbox to share this machine's data
          // with AON3D support.
          onClick: () => {},
        },
      ];
  return (
    <OverlayMenu
      target={
        <Button kind="secondary" size="small" icon>
          <Icon variant="MoreVert" />
        </Button>
      }
      placement="bottomStart"
      items={menuItems}
    />
  );
};

const machineColumnDefs: Record<string, () => ColumnDef<Machine>> = {
  name: () => ({
    accessorKey: "name",
    header: t`common.machine`,
    meta: { customTd: true },
    cell: ({ row: { original: rowData } }) => (
      <CustomCellLink to={machineUrls.index(rowData.id)}>
        {rowData.name}
      </CustomCellLink>
    ),
  }),
  model: () => ({
    id: "model",
    accessorKey: "hardware.model",
    header: t`common.machine-model`,
    sortingFn: (a, b, _columnId) => {
      return a.original.hardware.generation - b.original.hardware.generation;
    },
  }),
  serial: () => ({
    accessorKey: "hardware.serial",
    header: t`common.machine-serial`,
  }),
  manufactureDate: () => ({
    accessorKey: "hardware.manufactureDate",
    header: t`common.machine-manufacture-date`,
    cell: ({ row: { original: rowData } }) => (
      <DateOnly value={rowData.hardware.manufactureDate} />
    ),
  }),
};

export type MachineLastSeenKind = "now" | "stale" | "offline" | "never";

export const calculateMachineLastSeenKind = ({
  lastSeen,
  updatedAt,
}: {
  /** When the server last heard from the machine */
  lastSeen: number | undefined | null;
  /** When we've gotten the latest info from the server */
  updatedAt: number;
}): MachineLastSeenKind => {
  const lastSeenDayjs = lastSeen ? dayjs(lastSeen) : null;
  const staleTime = dayjs(updatedAt).subtract(1, "minutes");
  const offlineTime = dayjs(updatedAt).subtract(15, "minutes");

  if (lastSeenDayjs === null || lastSeenDayjs === undefined) {
    return "never";
  } else if (lastSeenDayjs?.isBefore(offlineTime)) {
    return "offline";
  } else if (lastSeenDayjs?.isBefore(staleTime)) {
    return "stale";
  } else {
    return "now"; // seen within 1 minute
  }
};

/**
 * Show the relative time that this machine last contacted the server, and a
 * small icon indicating how long it's been.
 * */
export const MachineLastSeen = ({
  lastSeen,
  updatedAt,
}: {
  /** When the server last heard from the machine */
  lastSeen: number | undefined | null;
  /** When we've gotten the latest info from the server */
  updatedAt: number;
}) => {
  const lastSeenKind = calculateMachineLastSeenKind({ lastSeen, updatedAt });

  const lastSeenColors = {
    now: "green",
    offline: "red",
    stale: "orange",
    never: "grey",
  } as const;

  return (
    <S.LastSeenCell>
      <S.LastSeenDot variant="Circle" $color={lastSeenColors[lastSeenKind]} />
      <RelativeDateTime
        value={lastSeen}
        isNow={lastSeenKind === "now"}
        defaultValue={t`components.machine-listing.never`}
      />
    </S.LastSeenCell>
  );
};

/**
 * Function to generate the `lastSeen` columnDef, based on when the machine records were last updated
 */
const lastSeenColumnDef = (updatedAt: number): ColumnDef<Machine> => {
  return {
    accessorKey: "lastSeen",
    header: t`common.machine-last-seen`,
    sortingFn: (a, b, _columnId) => {
      const aLastSeen = new Date(a.original.lastSeen ?? 0).getTime();
      const bLastSeen = new Date(b.original.lastSeen ?? 0).getTime();

      return aLastSeen - bLastSeen;
    },
    cell: ({ row: { original: rowData } }) => {
      const lastSeen = rowData.lastSeen
        ? new Date(rowData.lastSeen).getTime()
        : undefined;
      return <MachineLastSeen lastSeen={lastSeen} updatedAt={updatedAt} />;
    },
  };
};

const lastAttemptMaterialsColumnDef = (): ColumnDef<MachineWithLastAttempt> => {
  return {
    header: t`common.last-attempt-materials`,
    cell: ({ row: { original: rowData } }) => {
      return rowData.lastAttempt ? (
        <MaterialsList materials={rowData.lastAttemptMaterials} />
      ) : null;
    },
  };
};

const lastAttemptColumnDef = (): ColumnDef<MachineWithLastAttempt> => ({
  header: t`common.last-print-job`,
  meta: { customTd: true },
  cell: ({ row: { original: rowData } }) => {
    if (
      !rowData.lastAttempt ||
      !rowData.lastAttemptProject ||
      !rowData.lastAttemptRevision
    ) {
      return <CustomCellStatic />;
    }
    return (
      <AttemptCell
        project={rowData.lastAttemptProject}
        revision={rowData.lastAttemptRevision}
        attempt={rowData.lastAttempt}
      />
    );
  },
});

const machineStatusColumnDef = (updatedAt: number): ColumnDef<Machine> => ({
  id: "status",
  header: t`common.status`,
  cell: ({ row: { original: rowData } }) => (
    <MachineStatusBadge
      machine={rowData}
      lastSeen={
        rowData.lastSeen
          ? new Date(rowData.lastSeen ?? undefined).getTime()
          : undefined
      }
      updatedAt={updatedAt}
    />
  ),
});

/**
 * Function to generate ColumnDef for action items available on a machine record
 */
const actionsColumnDef = (
  actions?: (machine: Machine) => MenuItem[]
): ColumnDef<Machine> => {
  return {
    id: "actions",
    header: t`common.actions`,
    cell: ({ row: { original: rowData } }) => (
      <ActionsMenu machine={rowData} actions={actions} />
    ),
  };
};

const NoMachinesMessage = () => (
  <S.NoMachinesContainer>{t`components.machine-listing.no-machines`}</S.NoMachinesContainer>
);

const defaultMachineListSort = [
  { id: "model", desc: true },
  { id: "name", desc: false },
];

type MachineWithLastAttempt = Machine & {
  lastAttempt: Attempt | undefined;
  lastAttemptRevision: Revision | undefined;
  lastAttemptProject: Project | undefined;
  lastAttemptMaterials: Material[] | undefined;
};

export const FullMachineListing = (): ReactNode => {
  return <NonAdminMachineListing view="full" />;
};

export const PartialMachineListing = (): ReactNode => {
  return <NonAdminMachineListing view="partial" />;
};

const NonAdminMachineListing = ({
  view,
}: {
  view: "full" | "partial";
}): ReactNode => {
  const {
    data: machines = [],
    dataUpdatedAt: updatedAt,
    isLoading: machinesLoading,
  } = useMachines();
  const { data: materials = [], isLoading: materialsLoading } = useMaterials();
  const { data: projects = [], isLoading: projectsLoading } = useProjects();
  const { data: attemptsByMachine = {}, isLoading: lastAttemptsLoading } =
    useLastAttemptForEachMachine();

  // Loading state.
  if (
    machinesLoading ||
    materialsLoading ||
    projectsLoading ||
    lastAttemptsLoading
  ) {
    return null;
  }

  if (!machines.length) {
    return <NoMachinesMessage />;
  }

  const revisions = projects.flatMap((p) => p.revisions) ?? [];

  const materialByKey = indexById(materials);
  const projectsByKey = indexById(projects);
  const revisionsByKey = indexById(revisions);

  const machinesWithLastAttempt: MachineWithLastAttempt[] = machines.map(
    (machine) => {
      const lastAttempt = attemptsByMachine[machine.id];
      const lastAttemptRevision =
        lastAttempt && revisionsByKey[lastAttempt.revisionId];
      const lastAttemptProject =
        lastAttemptRevision && projectsByKey[lastAttemptRevision.projectId];
      const lastAttemptMaterials = lastAttemptRevision?.materials
        .map((id, i) => {
          const material = id ? materialByKey[id] : undefined;
          if (!material) {
            if (i === 0 && !id) {
              throw new Error(
                `Missing primary material in revision ${lastAttemptRevision.id}`
              );
            }
          }
          return material;
        })
        .filter(Boolean);

      const result = {
        ...machine,
        lastAttempt,
        lastAttemptRevision,
        lastAttemptProject,
        lastAttemptMaterials,
      };
      return result;
    }
  );

  // The casts below are OK because MachineWithLastAttempt contains all the
  // fields of Machine.
  const columns: ColumnDef<MachineWithLastAttempt>[] =
    view === "full"
      ? [
          machineColumnDefs.name() as ColumnDef<MachineWithLastAttempt>,
          machineColumnDefs.model() as ColumnDef<MachineWithLastAttempt>,
          machineStatusColumnDef(
            updatedAt
          ) as ColumnDef<MachineWithLastAttempt>,
          lastSeenColumnDef(updatedAt) as ColumnDef<MachineWithLastAttempt>,
          lastAttemptColumnDef(),
          lastAttemptMaterialsColumnDef(),
          actionsColumnDef() as ColumnDef<MachineWithLastAttempt>,
        ]
      : [
          machineColumnDefs.name() as ColumnDef<MachineWithLastAttempt>,
          machineColumnDefs.model() as ColumnDef<MachineWithLastAttempt>,
          machineStatusColumnDef(
            updatedAt
          ) as ColumnDef<MachineWithLastAttempt>,
        ];

  return (
    <Table
      columns={columns}
      data={machinesWithLastAttempt}
      initialState={{
        sorting: defaultMachineListSort,
        columnVisibility: { model: false },
      }}
    />
  );
};

export const AdminMachineListing = ({
  orgId,
  actions,
}: AdminMachineListingProps): ReactNode => {
  const { data: machines, dataUpdatedAt: updatedAt } = useMachinesByOrg(orgId);

  if (!machines) return null;
  if (!machines.length) return <NoMachinesMessage />;

  const { serial, manufactureDate } = machineColumnDefs;

  const columns = [
    machineColumnDefs.name(),
    machineColumnDefs.model(),
    serial(),
    manufactureDate(),
    lastSeenColumnDef(updatedAt),
    actionsColumnDef(actions),
  ];

  return (
    <Table
      columns={columns}
      data={machines}
      initialState={{
        sorting: defaultMachineListSort,
      }}
    />
  );
};
