import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;

  align-items: center;
  justify-content: center;

  opacity: 1;

  animation-name: ${fadeIn};
  animation-duration: 0.2s;
  animation-delay: 0.2s;
`;
