import styled from "styled-components";
import { transparentize } from "polished";

import { Link } from "_/components/router";
import { Icon } from "_/components/icon";

export const OuterWrapper = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: 0 3rem;
`;

export const InnerWrapper = styled.div`
  flex-basis: auto;
  height: 2.125rem;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  max-width: 668px;
`;

export const SearchIconWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0.875rem;
  height: 100%;
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.text.muted};
  transition: opacity 0.15s;
`;

export const SearchInput = styled.input.attrs({
  role: "combobox",
})`
  display: block;
  flex-grow: 1;
  font-size: 0.875rem;
  border-right: none;
  height: 2.125rem;
  border-radius: 2px;
  border: 1px solid ${(p) => p.theme.text.muted};
  padding-left: 2.75rem;
  color: ${(p) => p.theme.text.default};
  background-color: transparent;
  outline: none;
  transition:
    border 0.15s,
    border-radius 0.15s;
`;

export const SearchResults = styled.div`
  max-height: 0;
  overflow-x: hidden;
  overflow-y: auto;
  transition: max-height 0.15s;

  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  top: 100%;
  background-color: ${(p) => p.theme.background.primary};
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  z-index: 2;
`;

export const SearchControlContainer = styled.div<{
  $collapsed: boolean;
}>`
  position: relative;
  display: flex;
  flex-grow: 1;
  &:hover {
    background-color: ${(p) => p.theme.background.primary};
  }
  &:focus-within {
    background-color: ${(p) => p.theme.input.focused.background};
    color: ${(p) => p.theme.input.focused.text};
    & ${SearchInput} {
      border: 1px solid ${(p) => p.theme.input.focused.border};
    }
  }
  ${(p) =>
    p.$collapsed
      ? ""
      : `
  &:is(:focus-within, :has(${SearchResults}:hover)) ${SearchResults} {
    max-height: 80vh;
    border: 1px solid ${p.theme.modal.border};
    border-top-width: 0;
    box-shadow:
      0 0 4px ${transparentize(0.8, p.theme.overlayMenu.border)},
      0 2px 12px ${transparentize(0.8, p.theme.border.default)};
    }
  `}

  &:is(:focus-within, :hover) ${SearchInput} {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

export const SearchResultTitle = styled.div`
  font-weight: 500;
  font-size: 0.875rem;
  color: ${(p) => p.theme.text.default};
`;

export const SearchResultDescription = styled.div`
  font-size: 0.75rem;
  color: ${(p) => p.theme.text.muted};
`;

export const Highlight = styled.span`
  font-weight: 600;
  color: ${(p) => p.theme.link.default};
`;

export const DescriptionSeparator = styled.span`
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  color: ${(p) => p.theme.divider.small};
`;

const BaseSearchResult = styled.div`
  padding: 0.625rem;
  padding-left: 1rem;
  border-bottom: 1px solid ${(p) => p.theme.divider.large};
  &:last-child {
    border-bottom: none;
  }
`;

const ProjectSearchResultWithProps = styled(BaseSearchResult)<{
  $selected: boolean;
}>``;
export const ProjectSearchResult = styled(ProjectSearchResultWithProps)`
  cursor: pointer;
  padding-left: 1.125rem;
  &:hover {
    background-color: ${(p) =>
      p.$selected ? p.theme.background.tertiary : p.theme.background.secondary};
    & ${SearchResultTitle} {
      text-decoration: underline;
    }
  }
  background-color: ${(p) =>
    p.$selected ? p.theme.background.tertiary : "transparent"};
`;

const SearchResultsSection = styled.div`
  display: flex;
  flex-direction: column;
  scrollbar-width: auto;
  scrollbar-color: ${(p) => p.theme.header.background} transparent;

  &:not(:first-of-type) {
    border-top: 1px solid ${(p) => p.theme.divider.large};
  }
  &:first-of-type {
    padding-top: 0.25rem;
  }
  &:last-of-type {
    padding-bottom: 0.25rem;
  }
`;

export const ProjectSearchResultSection = styled(SearchResultsSection)``;

export const SearchResultsScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 60vh;
  scroll-snap-type: y mandatory;
  & > * {
    scroll-snap-align: start;
  }
`;

export const MachineSearchResultSection = styled(SearchResultsSection)`
  flex-shrink: 0;
  overflow-y: auto;
  overflow-x: hidden;
  height: max-content;
  padding-top: 0.25rem;
`;

export const SearchResultSectionHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0.5rem 0.25rem 1rem;
  cursor: default;
  font-size: 0.75rem;
  font-weight: 600;
  color: ${(p) => p.theme.text.muted};
`;

/* A search result that isn't clickable, used for messages. */
export const MessageSearchResult = styled(BaseSearchResult)`
  cursor: default;
`;

/* Machines have a single row for all machines, so a single result element */
export const MachinesSearchResult = styled(BaseSearchResult)`
  cursor: default;
  line-height: 2;

  /*
    Smaller padding ensures that the name of the first machine is left-aligned
    with the machine heading icon, even though its hover area (that's normally
    not visible) extends to the left of the icon. 
   */
  padding-left: 0.5625rem;
`;

// Help TS be OK with added props.
const MachineLinkWithProps = styled(Link)<{
  $selected: boolean;
}>``;
export const MachineLink = styled(MachineLinkWithProps)`
  padding: 0.5rem 0.5rem;
  font-weight: 500;
  font-size: 0.875rem;
  color: ${(p) => p.theme.text.default};
  border-radius: 2px;
  white-space: nowrap;
  line-height: 1.5;

  &:hover {
    text-decoration: underline;
    background-color: ${(p) =>
      p.$selected ? p.theme.background.tertiary : p.theme.background.secondary};
  }
  background-color: ${(p) =>
    p.$selected ? p.theme.background.tertiary : "transparent"};
}`;

export const NoResultsLabel = styled.div`
  font-size: 0.75rem;
  color: ${(p) => p.theme.text.muted};
`;

export const SectionIcon = styled(Icon)`
  font-size: 24px;
  & svg path {
    /* With tiny icons, need to make strokes a bit thicker for readability */
    stroke-width: 0.108em;
  }
`;

export const MachinesIconWrapper = styled.div`
  margin-left: -0.25rem;
`;
