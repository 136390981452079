import React, { ReactElement, ReactNode } from "react";
import { useDropzone, DropzoneOptions } from "react-dropzone";
export type { FileRejection } from "react-dropzone";

import * as S from "./styled";

type DropZoneProps = {
  chipText: string;
  text: string;
  dropText: string;
  invalid?: boolean;
  children?: ReactNode;
} & Pick<DropzoneOptions, "accept" | "validator" | "onDrop" | "maxSize">;

export const DropZone = (props: DropZoneProps): ReactElement => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    // Must be disabled for now because it is an unstable API in many browsers
    // and can unexpectedly prevent opening file picker dialogs.
    //
    // FIXME: If after April 2023, check if the FS API is stable and test
    // whether this can be safely removed without breaking upload functionality.
    useFsAccessApi: false,
    accept: props.accept,
    validator: props.validator,
    onDrop: props.onDrop,
    maxSize: props.maxSize,
  });

  const description = isDragActive ? props.dropText : props.text;

  return (
    // Pass additional props in via `getRootProps` to avoid conflicts.
    <S.Wrapper
      tabIndex={0}
      $invalid={props.invalid}
      {...getRootProps({ $dragActive: isDragActive })}
    >
      {props.children && <S.FileList>{props.children}</S.FileList>}
      <input {...getInputProps()} />
      <S.DescriptionContainer>
        <S.Description $invalid={props.invalid}>
          {description}
          {!isDragActive && (
            <S.Chip $invalid={props.invalid}>{props.chipText}</S.Chip>
          )}
        </S.Description>
      </S.DescriptionContainer>
    </S.Wrapper>
  );
};
