import styled from "styled-components";
import { Table as _Table } from "_/components/table";
import { Icon as _Icon } from "_/components/icon";

export const NoMachinesContainer = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  height: 50%;
`;

export const LastSeenCell = styled.div`
  display: flex;
  align-items: center;
`;

export const LastSeenDot = styled(_Icon)<{ $color: string }>`
  margin-right: 0.625em;
  color: ${(p) => {
    switch (p.$color) {
      case "grey":
        return p.theme.color.palette.ironGrey;
      case "red":
        return p.theme.color.palette.tomatoRed;
      case "orange":
        return p.theme.color.palette.sunsetOrange;
      case "green":
        return p.theme.color.palette.forestGreen;
      default:
        return p.theme.color.palette.ironGrey;
    }
  }};
`;
