import React, { ReactNode } from "react";

import * as S from "./styled";

type FormProps = {
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  children: ReactNode;
};

export const Form = ({ onSubmit, children }: FormProps) => {
  return <form onSubmit={onSubmit}>{children}</form>;
};

type FieldGroup = {
  title?: string;
  columns?: number;
  children: ReactNode;
};

export const FieldGroup = ({ title, children, columns = 1 }: FieldGroup) => {
  if (columns < 1) {
    throw new Error("columns must be greater than 0");
  }

  const titleEl = title ? <S.Title>{title}</S.Title> : null;

  return (
    <div>
      {titleEl}
      <S.Fields $columns={columns}>{children}</S.Fields>
    </div>
  );
};
