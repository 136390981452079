import React, { ReactElement, ReactNode } from "react";

import { docsUrls } from "_/routes";
import { useLocation, Link } from "_/components/router";

/**
 * Link replacement component for use in documentation.
 *
 * This will replace all relative MDX link paths with an absolute link that
 * points to the correct location within the Basis client.
 */
export const DocsLink = ({
  href,
  children,
}: {
  href: string;
  children: ReactNode;
}): ReactElement => {
  const [location, _] = useLocation();
  const base = docsUrls.index;

  if (href.startsWith("#")) {
    return <a href={href}>{children}</a>;
  }

  if (href.startsWith("http")) {
    return (
      <a href={href} target="_blank" rel="noreferrer">
        {children}
      </a>
    );
  }

  // Absolute urls (relative to docs).
  let to = href.startsWith("/") ? `${base}${href}` : href;

  // Relative urls.
  to = to.startsWith("./") ? `${location}${to.slice(1)}` : to;

  return <Link href={to}>{children}</Link>;
};
