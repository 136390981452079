import React, { useState } from "react";
import { cond, equals, always, T } from "ramda";

import { Tooltip } from "_/components/tooltip";
import { Icon, IconVariant } from "_/components/icon";

import { Uuid } from "_/types";

import { CopyState } from "./types";
import * as S from "./styled";

// Tooltip component for truncating UUIDs in table cells
export const UuidTooltip = ({ uuid }: { uuid: Uuid }) => {
  const truncatedUuid = uuid.split("-")[0] + "…";

  const target = <S.Target>{truncatedUuid}</S.Target>;

  // The `TooltipContent` component is recreated every time
  // the tooltip is opened, which results in the `copied`
  // state being reset to `false`.
  const TooltipContent = () => {
    const [copyState, setCopyState] = useState<CopyState>("notAttempted");

    function copyToClipboard() {
      navigator.clipboard
        .writeText(uuid)
        .then(() => setCopyState("copied"))
        .catch(() => {
          setCopyState("error");
        });
    }

    const iconVariant = cond([
      [equals("copied"), always("CheckCircle")],
      [equals("error"), always("Error")],
      [T, always("ContentCopy")],
    ])(copyState) as IconVariant;

    return (
      <S.Content>
        <S.UuidFullText
          onFocus={(e) => {
            e.target.select();
          }}
          value={uuid}
          readOnly
        />
        <S.CopyButton onClick={copyToClipboard} $state={copyState}>
          <Icon variant={iconVariant} />
        </S.CopyButton>
      </S.Content>
    );
  };

  return (
    <Tooltip
      target={target}
      content={<TooltipContent />}
      placement={"bottom"}
      delay={100}
      wait={500}
    />
  );
};
