import styled from "styled-components";

import { Icon } from "_/components/icon";

const PADDING = "12px 16px";
const TEXT_PADDING = "12px 8px";
const MARGIN = "40px";

export const Container = styled.div`
  position: relative;
  margin: ${MARGIN};

  background-color: ${(p) => p.theme.modal.background};
  border-radius: 4px;

  width: 556px;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(6px);

  display: flex;
  justify-content: center;
  align-items: center;

  pointer-events: all;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  // Slightly shift the header up to hide imperfections visible
  // in some browsers where the border radius is rendered between
  // the header and the outer container.
  margin: -1px 0 0;

  padding: ${PADDING};

  border-radius: 4px 4px 0 0;

  background-color: ${(p) => p.theme.modal.header};
  border-bottom: 1px solid ${(p) => p.theme.modal.border};
`;

export const Title = styled.span`
  font-size: 20px;
  width: 100%;
`;

export const Content = styled.div`
  padding: ${PADDING};
`;

export const TextMessage = styled.div`
  padding: ${TEXT_PADDING};
`;

export const CloseIcon = styled(Icon)`
  font-size: 28px;
  border: 1px solid transparent;

  &:hover,
  &:focus {
    color: ${(p) => p.theme.color.palette.tomatoRed};
    cursor: pointer;
  }

  &:focus {
    color: ${(p) => p.theme.color.palette.tomatoRed};
    border: 1px solid ${(p) => p.theme.color.palette.tomatoRed};
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  padding: 12px 0;
  justify-content: flex-end;
  gap: 1rem;
`;
