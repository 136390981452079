import React, { useEffect, useState, useRef, ReactElement } from "react";
import {
  OrbitControls,
  OrbitControlsChangeEvent,
  OrthographicCamera,
  PerspectiveCamera,
} from "@react-three/drei";
import { pick } from "ramda";

import {
  Vector3,
  OrthographicCamera as OrthographicCameraType,
  PerspectiveCamera as PerspectiveCameraType,
  Camera as CameraType,
} from "three";

import { ViewerState } from "_/state/viewer";

import { Update, Triple } from "_/types";

// Sets the amount of damping applied to camera pan, tilt, and zoom.
// Lower values will make camera controls feel more "loose", while a
// value of 1.0 will make controls perfectly rigid and direct.
const CAMERA_DAMPING = 0.9;

// Camera near and far planes.
const CAMERA_NEAR = 0.01;
const CAMERA_FAR = 10_000;

// Camera "Up" vector.
const CAMERA_UP: Triple<number> = [0, 0, 1];

// Camera update object.
interface CameraState {
  cameraPosition?: Triple<number>;
  cameraTarget?: Triple<number>;
  cameraZoom?: number;
}

// The amount of difference between two numbers that is considered "close enough".
const EPSILON = 1e-10;

// Returns true if two numbers are close enough to be considered equal.
function numbersAreSufficientlyClose(a: number, b: number): boolean {
  return Math.abs(a - b) < EPSILON;
}

// Returns true if two triples are close enough to be considered equal.
function triplesAreSufficientlyClose(
  a?: Triple<number>,
  b?: Triple<number>
): boolean {
  if (!a || !b) return false;

  for (let i = 0; i < a.length; i++) {
    if (!numbersAreSufficientlyClose(a[i], b[i])) {
      return false;
    }
  }
  return true;
}

// Returns true if two camera updates are close enough to be considered equal.
function cameraStatesAreSufficientlyClose(
  a?: CameraState,
  b?: CameraState
): boolean {
  if (!a || !b) return false;

  return (
    triplesAreSufficientlyClose(a.cameraPosition, b.cameraPosition) &&
    triplesAreSufficientlyClose(a.cameraTarget, b.cameraTarget) &&
    a.cameraZoom === b.cameraZoom
  );
}
type CameraProps = {
  /**
   * The viewer state object for the current view.
   */
  viewerState: ViewerState;

  /**
   * Setter to update the viewer state.
   *
   * This behaves exactly the same as a state updater for the builtin
   * react `useState`, but is updating the jotai viewer state.
   */
  setViewerState?: (update: Update<ViewerState>) => void;
};

export const Camera = ({
  viewerState,
  setViewerState,
}: CameraProps): ReactElement => {
  const orthographicCameraRef = useRef<OrthographicCameraType | null>(null);
  const perspectiveCameraRef = useRef<PerspectiveCameraType | null>(null);
  const cameraRef = useRef<CameraType>();

  const [_, setRenderCount] = useState(0);

  useEffect(() => {
    if (viewerState.cameraOrtho) {
      cameraRef.current = orthographicCameraRef.current as CameraType;
    } else {
      cameraRef.current = perspectiveCameraRef.current as CameraType;
    }
    // When the projection mode changes, a new camera is used and the camera
    // ref passed to the OrbitControls must be updated. By incrementing the
    // renderCount, a re-render is triggered using the updated cameraRef.
    setRenderCount((n) => n + 1);
  }, [viewerState.cameraOrtho]);

  const projectionCamera = viewerState.cameraOrtho ? (
    <OrthographicCamera
      makeDefault
      ref={orthographicCameraRef}
      up={CAMERA_UP}
      near={CAMERA_NEAR}
      far={CAMERA_FAR}
      position={viewerState.cameraPosition}
      zoom={viewerState.cameraZoom}
    />
  ) : (
    <PerspectiveCamera
      makeDefault
      ref={perspectiveCameraRef}
      up={CAMERA_UP}
      near={CAMERA_NEAR}
      far={CAMERA_FAR}
      position={viewerState.cameraPosition}
      fov={viewerState.cameraFov}
      zoom={viewerState.cameraZoom}
    />
  );

  return (
    <>
      {projectionCamera}
      <OrbitControls
        makeDefault
        camera={cameraRef.current}
        enableDamping
        dampingFactor={CAMERA_DAMPING}
        target={viewerState.cameraTarget}
        onChange={(e) => {
          // three.js's types are either out of date, or the types are a base
          // type that doesn't match what we're actually getting at runtime.
          const correctTypeE = e as OrbitControlsChangeEvent & {
            target: { target: Vector3; object: PerspectiveCameraType };
          };
          const update: CameraState = {
            cameraPosition: e?.target?.object.position.toArray(),
            cameraTarget: correctTypeE?.target?.target.toArray(),
            cameraZoom: correctTypeE?.target?.object.zoom,
          };

          const cameraState: CameraState = pick(
            ["cameraPosition", "cameraTarget", "cameraZoom"],
            viewerState
          );

          // Update the viewer session if the camera state has changed.
          if (!cameraStatesAreSufficientlyClose(update, cameraState)) {
            setViewerState?.({
              ...viewerState,
              ...update,
            });
          }
        }}
      />
    </>
  );
};
