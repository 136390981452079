import styled from "styled-components";

export const Title = styled.h4`
  font-family: monospace;
  text-transform: uppercase;
  color: ${(p) => p.theme.text.secondary};
  margin: 1rem 0 0;
`;

export const Fields = styled.div<{ $columns: number }>`
  display: grid;
  gap: 0 0.625rem;
  grid-template-columns: repeat(${(p) => p.$columns}, 1fr);
`;
