/**
 * Project cards component for home page and projects listing page.
 *
 * @category components
 */

import React, { useState } from "react";
import { t } from "@lingui/macro";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import { useUser } from "_/data/users";
import { indexById } from "_/utils";

import { UserAvatar } from "_/components/avatar";
import {
  Attempt,
  Project,
  sortAttempts,
  useAllAttempts,
  useProjects,
} from "_/data/projects";

import * as S from "./styled";
import { projectUrls } from "_/routes";
import { sortWith } from "ramda";
import { RelativeDateTime } from "_/components/date-time";

dayjs.extend(relativeTime);

const ProjectPlaceholderImage = ({
  $visibility,
}: {
  $visibility: "hidden" | "visible";
}) => (
  <S.ProjectPlaceholderSVG
    $visibility={$visibility}
    width="960"
    height="960"
    viewBox="0 0 960 960"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line x1="333" y1="37" x2="333" y2="628" />
    <line x1="43" y1="332" x2="43" y2="921" />
    <line x1="917" y1="628" x2="917" y2="37" />
    <line x1="627" y1="921" x2="627" y2="332" />
    <line x1="628" y1="331" x2="42" y2="331" />
    <line x1="918" y1="38" x2="332" y2="38" />
    <line x1="332" y1="627" x2="918" y2="627" />
    <line x1="42" y1="920" x2="628" y2="920" />
    <line x1="332.711" y1="37.7035" x2="42.7107" y2="330.703" />
    <line x1="333.711" y1="626.703" x2="43.7107" y2="919.703" />
    <line x1="916.479" y1="38.3338" x2="626.711" y2="331.703" />
    <line x1="917.313" y1="627.205" x2="627.252" y2="920.217" />
  </S.ProjectPlaceholderSVG>
);

const ProjectPreview = ({ project }: { project: Project }) => {
  const [loaded, setLoaded] = useState(false);
  return (
    <S.ProjectPreviewContainer>
      <ProjectPlaceholderImage $visibility={loaded ? "hidden" : "visible"} />
      <S.ProjectPreviewImage
        src={`/api/v0/projects/${project.id}/thumbnail?q=${project.updatedAt}`}
        $visibility={loaded ? "visible" : "hidden"}
        onLoad={() => setLoaded(true)}
      />
    </S.ProjectPreviewContainer>
  );
};

const ProjectCard = ({
  project,
  attempts,
}: {
  project: Project;
  attempts: Attempt[];
}) => {
  const projectURI = projectUrls.index(project.id);
  const { data: user } = useUser({ id: project.ownerId });

  // TODO: change to `toSorted` in 2025
  const lastAttempt = [...attempts].sort(sortAttempts)[0];

  return (
    <S.SmallProjectCard to={projectURI}>
      <S.FirstRowContainer>
        <S.ProjectName>{project.name}</S.ProjectName>
        {user ? (
          <div title={user?.name}>
            <UserAvatar resource={user} size="small" />
          </div>
        ) : null}
      </S.FirstRowContainer>
      <S.SecondRowContainer>
        <ProjectPreview project={project} />
        <S.CardMetaContainer>
          <S.CardMeta>
            <label>{t`common.modified-label-only`}</label>
            <div>
              <RelativeDateTime value={project.updatedAt} />
            </div>
          </S.CardMeta>
          <S.CardMeta>
            <label>{t`common.printed`}</label>
            <div>
              <RelativeDateTime
                value={lastAttempt?.startedAt}
                defaultValue={t`common.not-printed`}
              />
            </div>
          </S.CardMeta>
        </S.CardMetaContainer>
      </S.SecondRowContainer>
    </S.SmallProjectCard>
  );
};

// Sort by project updatedAt DESC
function sortUpdatedAt(a: Project, b: Project): number {
  return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
}

// Sort archived projects last
function sortArchived(a: Project, b: Project): number {
  const aIsArchived = a.status === "archived";
  const bIsArchived = b.status === "archived";
  if (aIsArchived === bIsArchived) return 0;
  return bIsArchived ? -1 : 1;
}

const projectSorter = sortWith([sortArchived, sortUpdatedAt]);

/**
 * Component to display a set of cards. Reused on home page and projects page.
 * */
export const ProjectCards = ({ limit = 200 }: { limit?: number }) => {
  const { data: allAttempts = [] } = useAllAttempts();
  const { data: allProjects = [] } = useProjects();

  const revisions = allProjects.flatMap((p) => p.revisions);
  const revisionsByKey = indexById(revisions);

  // TODO: change to `toSorted` in 2025
  const sorted = projectSorter([...allProjects]);

  const projects = sorted.slice(0, limit);

  const projectsAndAttempts = projects.map((p) => ({
    project: p,
    attempts: allAttempts.filter((a) =>
      p.revisions.includes(revisionsByKey[a.revisionId])
    ),
  }));

  // Construct set of project cards to display.
  const cards = projectsAndAttempts.map(({ project: p, attempts: a }) => {
    return <ProjectCard key={p.id} project={p} attempts={a} />;
  });

  return <>{cards}</>;
};
