import styled from "styled-components";

export const Wrapper = styled.div`
  margin: 1.5rem 1.625rem 3.75rem 1.625rem;
`;

export const HeaderArea = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Content = styled.div`
  margin-top: 1.375rem;
`;

export const FilterBar = styled.div`
  display: flex;
  gap: 1rem;
  padding: 0 1.25rem;
  align-items: center;
`;

export const FilterBarItem = styled.div`
  display: inline-flex;
  place-items: center;
  gap: 0.5rem;
`;

export const ThermalDataWrapper = styled.div`
  display: flex;
`;

export const ThermalsReading = styled.div`
  margin-right: 1rem;
  display: flex;
  align-items: center;
  background-color: ${(p) => p.theme.background.secondary};
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
`;

export const SensorLabel = styled.span`
  color: ${(p) => p.theme.text.muted};
  text-transform: uppercase;
  font-size: 0.625rem;
  margin-right: 0.5rem;
`;

export const SensorTemp = styled.span``;
export const SensorTarget = styled.span`
  color: ${(p) => p.theme.text.muted};
`;

export const LogMessage = styled.span`
  font-family: monospace;
  font-size: 0.875rem;
`;

export const StateChange = styled.div`
  display: flex;
  position: relative;

  .content {
    position: absolute;
    background-color: ${(p) => p.theme.background.primary};
  }
`;

export const RawJson = styled.pre`
  font-family: monospace;
`;
