import { useQuery } from "react-query";

import { ApiResponse, api } from "_/utils";

const SCOPE = "docs";
const KEYS = {
  manifest: () => [SCOPE, "manifest"],
  page: (slug?: string) => [SCOPE, "page", slug],
};

const ONE_HOUR = 1000 * 60 * 60;

export type DocMeta = {
  private: boolean;
  draft: boolean;
  title: string;
  slug: string;
  path: string;
  modifiedAt: Date;
};

type SlugMap = Record<string, DocMeta>;

export type LayoutNode = DocMeta & {
  children: LayoutNode[];
};

export type Manifest = {
  slugs: SlugMap;
  version: string;
  layout: LayoutNode[];
};

export function useDocsManifest() {
  async function getManifest(): Promise<Manifest> {
    const result = await api.get<ApiResponse<Manifest>>("/docs/manifest");
    return result.data.data;
  }

  return useQuery({
    queryFn: getManifest,
    queryKey: KEYS.manifest(),
    refetchOnWindowFocus: false,
    staleTime: ONE_HOUR,
  });
}

export function useDocPage(slug: string = "index") {
  async function getDocPage(): Promise<{ content: string; meta: DocMeta }> {
    const uri = `/docs/content/${slug}`;
    const result =
      await api.get<ApiResponse<{ content: string; meta: DocMeta }>>(uri);
    return result.data.data;
  }

  return useQuery({
    queryFn: getDocPage,
    queryKey: KEYS.page(slug),
    refetchOnWindowFocus: false,
    staleTime: ONE_HOUR,
  });
}

export function docAssetPath(src: string) {
  return `/api/v0/docs/assets/${src}`;
}
